import React from 'react';
import styled from 'styled-components';
import SubscribeInputField from './SubscribeInputField';
import Icon from 'Icon';

const Wrapper = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.5) url("${({ bgImg }) => bgImg}");
  /* height: 400px; */
  background-size: cover;
  padding: 0 0 200px;

  @media (max-width: 767px) {
    padding: 0 0 0px;
    background: #fff;
  }
`;

const Container = styled.div`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 70%;
  }
  
  @media (max-width: 580px) {
    width: 90%;
  }
`;
const HeaderWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 1100px;

  @media (max-width: 900px) {
    width: 90%;
  }
  
  @media (max-width: 580px) {
    width: 90%;
  }
`;
const LogoWrapper = styled.div`
  width: 290px;
  padding: 60px 0 20px;
  img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 250px;
  }
  
  @media (max-width: 900px) {
    width: 230px;
    padding: 30px 0 20px;
  }
  
  @media (max-width: 580px) {
    width: 180px;
    padding: 20px 0 10px;
  }
`;

const HeadingText = styled.div`
  font-family: Playfair;
  font-size: 54px;
  font-weight: 900;
  line-height: 1.19;
  text-align: center;
  color: #ffffff;

  @media (max-width: 1024px) {
    font-size: 42px;
  }
  
  @media (max-width: 767px) {
    color: #383e53;
  }

  @media (max-width: 580px) {
    font-size: 26px;
  }

`;

const BannerDesc = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.52;
  text-align: center;
  color: #ffffff;
  margin: 30px 0;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
  
  @media (max-width: 767px) {
    color: rgba(56, 62, 83, 0.75);
  }

  @media (max-width: 580px) {
    font-size: 16px;
    margin: 15px 0;
  }
`;

const HintText = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  text-align: center;
  color: #ffffff;
`;

const SubscribeInputFieldWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const LetMeSeeItButtonWrapper = styled.div`
  text-align: center;
  margin: 40px 0 20px;

  @media (max-width: 1024px) {
    margin: 30px 0 20px;
  }
`;

const LetMeSeeItButton = styled.a`
  font-family: GothamBookRegular;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.83;
  letter-spacing: 0.72px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  padding: 10px;

  &:hover {
    color: #ffffff;
  }
`;

const LetMeSeeItButtonIcon = styled.span`
  margin-left: 5px;
`;

const BulbImageWrapper = styled.div`
  position: absolute;
  width: 25%;
  top: 0;
  right: 0;
  img {
    width: 100%;
  }

   @media (max-width: 767px) {
    width: 30%;
  }
`;

const AlbertImageWrapper = styled.div`
  position: absolute;
  width: 10%;
  top: 20%;
  left: 0;
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 10%;
    top: 20px;
  }
`;

const BezosImageWrapper = styled.div`
  position: absolute;
  width: 30%;
  left: 0;
  bottom: 0;
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 45%;
    left: 0;
  }
`;

const AmbaniImageWrapper = styled.div`
  position: absolute;
  width: 20%;
  left: 26%;
  bottom: 0;
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

// const Block1ImageWrapper = styled.div``;
const CovidImageWrapper = styled.div`
  position: absolute;
  width: 40%;
  right: -10%;
  bottom: -15%;
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const BannerImagesWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5) url("${({ bgImg }) => bgImg}");
  height: ${({ bgImg }) => bgImg ? 400 : 0}px;
  background-size: cover;

  @media (max-width: 767px) {
    position: relative;
  }

  @media (max-width: 580px) {
    height: ${({ bgImg }) => bgImg ? 250 : 0}px;
  }
`;

const Banner = ({ data, expTestingKey, onSubscribeSuccess, isSubscribe }) => {
  console.log("Banner -> data", data)
  const imageSetData = data[`image_set_${expTestingKey}`] ? data[`image_set_${expTestingKey}`].addl_data : data.image_set.addl_data;
  
  return (
  <Wrapper
    bgImg={imageSetData.bg}
  >
    <HeaderWrapper>
      <LogoWrapper>
        <img src={window.innerWidth > 767 ? imageSetData.white_logo : imageSetData.red_logo} alt="" />
      </LogoWrapper>
    </HeaderWrapper>
    <Container>
        <HeadingText>{data[`headline_${expTestingKey}`] ? data[`headline_${expTestingKey}`].content : data.headline.content}</HeadingText>
        <BannerDesc>{data[`subtitle_${expTestingKey}`] ? data[`subtitle_${expTestingKey}`].content : data.subtitle.content}</BannerDesc>
        <HintText>{data[`disclaimer_${expTestingKey}`] ? data[`disclaimer_${expTestingKey}`].content : data.disclaimer.content}</HintText>
      <SubscribeInputFieldWrapper>
          <SubscribeInputField onSubscribeSuccess={onSubscribeSuccess} isSubscribe={isSubscribe}
            expTestingKey={expTestingKey}/>
      </SubscribeInputFieldWrapper>
        <LetMeSeeItButtonWrapper>
          <LetMeSeeItButton href={data[`original_link_${expTestingKey}`] ? data[`original_link_${expTestingKey}`].content : data.original_link.content}>
            {data[`original_link_text_${expTestingKey}`] ? data[`original_link_text_${expTestingKey}`].content : data.original_link_text.content}
            <LetMeSeeItButtonIcon>
              <Icon name="sliderArrowRight" size={16} color="#fff" />
            </LetMeSeeItButtonIcon>
          </LetMeSeeItButton>
        </LetMeSeeItButtonWrapper>
    </Container>
    <BannerImagesWrapper
      bgImg={window.innerWidth > 767 ? null : imageSetData.bg}
    >
      <BulbImageWrapper>
        <img src={imageSetData.bulb} alt=""/>
      </BulbImageWrapper>
      <AlbertImageWrapper>
        <img src={imageSetData.albert} alt=""/>
      </AlbertImageWrapper>
      <BezosImageWrapper>
        <img src={imageSetData.bezos} alt=""/>
      </BezosImageWrapper>
      <AmbaniImageWrapper>
        <img src={imageSetData.ambani} alt=""/>
      </AmbaniImageWrapper>
      {/* <Block1ImageWrapper>
        <img src={imageSetData.block1} alt=""/>
      </Block1ImageWrapper> */}
      <CovidImageWrapper>
        <img src={imageSetData.covid} alt=""/>
      </CovidImageWrapper>
    </BannerImagesWrapper>
  </Wrapper>
);}

export default Banner;