import React, { useState } from 'react';
import styled from 'styled-components';
import { Image, Icon } from 'semantic-ui-react';
import CustomIcon from 'Icon';
import ShareDialogBox from '../NewsDigests/Mobile/ArticleItem/ShareDialogBox';
import { postTagFollow } from 'Api';
import CategorySlider from '../NewsDigests/Desktop/CategorySlider';

const Wrapper = styled.div`
  font-family: 'Metropolis', sans-serif;
  padding: 50px 0;
  
  @media (max-width: 580px) {
    padding: 30px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 580px) {
    flex-direction: column-reverse;
    padding: 30px 0;
    margin-top: 20px;
  }
`;

const LeftSideWrapper = styled.div`
  flex: 1;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const RighSideWrapper = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 580px) {
    margin: 0 auto;
  }
`;

const TagTitle = styled.div`
  font-size: 70px;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: -1.11px;
  color: #ffffff;
  padding-right: 15px;

  @media (max-width: 1024px) {
    font-size: 64px;
  }

  @media (max-width: 768px) {
    font-size: 50px;
  }
  
  @media (max-width: 580px) {
    font-size: 44px;
  }
`;

const TagDesc = styled.div`
  font-size: 20px;
  line-height: 1.6;
  color: #ffffff;
  margin-top: 15px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 580px) {
    margin-top: 5px;
    line-height: 1.4;
  }
`;

const TagImageWrapper = styled.div`
  width: 90%;
  margin: 0 0 0 auto;
  min-height: ${({ imageActive }) => imageActive ? 'auto' : '114px'};
  padding: 20px 0;
  background-color: ${({ imageActive }) => imageActive ? 'transparent' : '#4b84d7'};
  border-radius: 6px;

  img {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 580px) {
    margin: 15px auto 0;
  }
`;

const FollowButton = styled.div`
  width: 90%;
  border-radius: 3px;
  background-color: ${({ isSelected }) => isSelected ? '#e02020' : 'rgba(255, 255, 255, 0.65)'};
  font-size: 12px;
  text-align: center;
  color: ${({ isSelected }) => isSelected ? '#fff' : '#272727' };
  padding: 7px 5px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;

  @media (max-width: 580px) {
    margin: 15px auto 0;
  }
`;

const ShareButton = styled.div`
  width: 90%;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.65);
  font-size: 12px;
  text-align: center;
  color: #272727;
  padding: 7px 5px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 4px;
  }

  @media (max-width: 580px) {
    margin: 15px auto 0;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  position: ${({ fixed }) => fixed ? 'fixed' : 'static'};
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0;
  background-color: transparent;
  z-index: 10;
  padding: 10px 0;
  ${({ fixed }) => fixed ? `
    box-shadow: 0 2px 2px -2px rgba(0,0,0,0.15);
  ` : ``}

  .slick-prev:before, .slick-next:before {
    color: #000;
  }

  .slick-slide {
    outline: none !important;
  }

  .slick-arrow:before {
    display: none;
  }

  .slick-prev, .slick-next {
    height: auto;
  }

  .slick-next {
    right: -40px;
  }
`;

const SliderContainer = styled.div`
  width: calc(100% - 63px);
  margin: 0 auto;
  text-transform: uppercase;
  max-width: calc(1200px - 63px);

  @media (max-width: 1024px) {
    width: calc(100% - 63px);
  }
  @media (max-width: 900px) {
    width: calc(100% - 63px);
  }
  
  @media (max-width: 768px) {
    width: calc(100% - 30px);
    /* padding: 0 30px; */
  }
`;

const TagDetail = ({ data, updateData, categoryData }) => {
  const [shareArticleData, setShareArticleData] = useState(null);

  const handleFollow = () => {
    postTagFollow({
      tags: data.selected ? [] : [data.id], 
      unSelectedTags: !data.selected ? [] : [data.id] }).then(response => {
      updateData(response.data && response.data.results && response.data.results[0])

    }).catch(error => {
      console.log("🚀 ~ file: TagDetail.js ~ line 155 ~ postTagFollow ~ error", error)

    })
  }
  return (
    <>
      {data && (
        <Wrapper>
          <SliderWrapper
              className="slider-wrapper"
            >
              <SliderContainer>
                <CategorySlider
                  sliderData={categoryData}
                  onClickItem={() => {}}
                  itemTextColor="#fff"
                />
              </SliderContainer>
            </SliderWrapper>
          <ContentWrapper>

            <RighSideWrapper>
              {data.cover_image && (
                <TagImageWrapper imageActive={data.cover_image}>
                    <Image src={data.cover_image}/>
                </TagImageWrapper>
              )}
              <FollowButton onClick={handleFollow} isSelected={data.selected}>
                <Icon name="bell outline" />
                {data.selected ? 'Following' : 'Follow'}
              </FollowButton>
              <ShareButton
                onClick={() => {
                  setShareArticleData(data);
                }}
              >
                <CustomIcon name="share" size={14}/>
                Share
              </ShareButton>
            </RighSideWrapper>
            <LeftSideWrapper>
              <TagTitle>{data.title || ''}</TagTitle>
              <TagDesc>{data.description || ''}</TagDesc>
            </LeftSideWrapper>
            <ShareDialogBox
              onClose={() => {
                setShareArticleData(null);
              }}
              open={!!shareArticleData}
              data={shareArticleData}
              shareUrl=" "
              isDesktop
              shareText={data.share_text}
            />
          </ContentWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default TagDetail;