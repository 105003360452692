import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { DigestPublishInfo } from '../../CommonComponents';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
const Wrapper = styled.div`
  background-color: #f4f4f4;
  padding: 30px;
  border-top: 4px solid #e13530;
  margin: 25px 0 10px;

  @media (max-width: 580px) {
    padding: 15px;
  }
`;

const SectionHeader = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  font-family: 'PT Serif', Georgia, serif;
  font-size: 38px;
  line-height: 1.1;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 768px) {
    font-size: 34px;
  }
  @media (max-width: 580px) {
    font-size: 24px;
  }
`;

const CardWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
`;
const CardTitleWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
  }
`;

const CardContentWrapper = styled.div`
  width: 65%;
  padding-right: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

const CardTitle = styled(Link)`
  display: block;
  font-family: 'Metropolis', sans-serif;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.8);

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 768px) {
    width: 64%;
    padding-right: 15px;
    font-size: 20px;
  }
  
  @media (max-width: 580px) {
    width: 74%;
    padding-right: 10px;
    font-size: 16px;
  }

`;
const CardMobileImageWrapper = styled.div`
  display: none;
  border-radius: 7px;
  overflow: hidden;
  @media (max-width: 768px) {
    display: block;
    flex: 1;
  }
`;

const ReadMoreButton = styled(Link)`
  display: block;
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: #e02020;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CardImageWrapper = styled(Link)`
  display: block;
  flex: 1;
  border-radius: 7px;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  &.desktop-video {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const DigestPublishInfoWrapper = styled.div`
  margin: 15px 0 20px;
`;
const FocusCard = ({ data, onDigestReadLater, onDigestBookMark, onClickShare }) => {
  return (
    <Wrapper>
      <SectionHeader>
        Hear the other side to the argument
      </SectionHeader>
      <CardWrapper>
        <CardContentWrapper>
          <CardTitleWrapper>
            <CardTitle
              to={`/digests/${data.slug}`}
              target="_blank"
            >{data.title}</CardTitle>
            <CardMobileImageWrapper
              to={`/digests/${data.slug}`}
              target="_blank"
            >
              {(data.cover_pic && data.cover_pic.includes('https://www.youtube.com/watch?v')) ? (
                <VideoContainer>
                  <ReactPlayer
                    controls
                    playing={false}
                    volume="0"
                    muted
                    width="100%"
                    height="100%"
                    url={data.cover_pic}
                    config={{
                      youtube: {
                        // playerVars: { showinfo: 0 }
                      },
                    }}
                  />
                </VideoContainer>
              ) : (
                  <Image src={data.cover_pic} />
                )}
            </CardMobileImageWrapper>
          </CardTitleWrapper>
          <DigestPublishInfoWrapper>
            <DigestPublishInfo 
              data={data} 
              onDigestReadLater={value => onDigestReadLater(value, false, true)}
              onDigestBookMark={value => onDigestBookMark(value, false, true)}
              onClickShare={onClickShare}
              hideFollowButton
            />
          </DigestPublishInfoWrapper>
          <ReadMoreButton
            to={`/digests/${data.slug}`}
            target="_blank"
          >Read more</ReadMoreButton>
        </CardContentWrapper>
        <CardImageWrapper
          to={`/digests/${data.slug}`}
          target="_blank"
        >
          {(data.cover_pic && data.cover_pic.includes('https://www.youtube.com/watch?v')) ? (
            <VideoContainer className="desktop-video">
              <ReactPlayer
                controls
                playing={false}
                volume="0"
                muted
                width="100%"
                height="100%"
                url={data.cover_pic}
                config={{
                  youtube: {
                    // playerVars: { showinfo: 0 }
                  },
                }}
              />
            </VideoContainer>
          ) : (
            <Image src={data.cover_pic} />
          )}
        </CardImageWrapper>
      </CardWrapper>
    </Wrapper>
  );
}

export default FocusCard;