import React from 'react';
import styled from 'styled-components';
import { Image, Loader, Icon } from 'semantic-ui-react';

import { grey } from 'colorPalette';

const Wrapper = styled.div`
  margin-top: 15px;

`;
const ListWrapper = styled.div`
`;

const SectionTitle = styled.div`
  font-size: 18px;
  /* color: ${grey.level2}; */
  margin-bottom: 5px;
  padding: 0 10px;
`;
const ListItemWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 15px 10px;
  border-bottom: 1px solid ${grey.level3};
`;

const DigestContent = styled.div`
  width: 70%;
  padding-right: 10px;
  position: relative;
`;

const ActionButton = styled.div`
  padding: 5px 0;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const ImageWrapper = styled.div`
  width: 30%;
  img {
    width: 100%;
  }
`;

const HostName = styled.div`
  font-size: 12px;
  color: ${grey.level2};
`;

const PostedDate = styled.div`
  font-size: 12px;
  color: ${grey.level2};
`;

const DigestTitle = styled.div`
  font-size: 14px;
  /* color: ${grey.level0}; */
  font-weight: 700;
  margin-bottom: 5px;
`;

const LoaderWrapper = styled.div`
  padding: 25px 15px;
  position: relative;
`;

const NoDataFound = styled.div`
  margin-top: 50px;
  font-size: 16px;
  font-weight: 900;
  color: ${grey.level3};
  text-align: center;
`;
const DigestListView = ({ 
  data,
  lodingDigestList, 
  digestRef, 
  onClickDigest ,
  onClickDigestAction,
}) => {
  return (
    <Wrapper ref={digestRef}>
      {!lodingDigestList && (
        <SectionTitle>Digests</SectionTitle>
      )}
      {lodingDigestList && data && data.length === 0 && (
        <LoaderWrapper>
          <Loader active />
        </LoaderWrapper>
      )}
      {data && data.length > 0 && (
        <ListWrapper>
          {data.map((item, index) => (
            <ListItemWrapper 
              key={item.id}
              onClick={() => onClickDigest(item, index)}
            >
              <DigestContent>
                <DigestTitle>{item.title}</DigestTitle>
                <HostName>{item.source.host}</HostName>
                <PostedDate>{item.first_published_at_human}</PostedDate>
                <ActionButton
                  onClick={e => {
                    e.stopPropagation();
                    onClickDigestAction(item);
                  }}
                >
                  <Icon name="ellipsis vertical" />
                </ActionButton>
              </DigestContent>
              <ImageWrapper>
                <Image src={
                  item.cover_pic.indexOf('youtube.com') > -1 ?
                    `https://i3.ytimg.com/vi/${item.cover_pic.substring(item.cover_pic.indexOf('=') + 1, item.cover_pic.length)}/hqdefault.jpg`
                    : item.cover_pic
                } />
              </ImageWrapper>
            </ListItemWrapper>
          ))}
          {lodingDigestList && data && data.length > 0 && (
            <LoaderWrapper>
              <Loader active />
            </LoaderWrapper>
          )}
        </ListWrapper>
      )}
      {!lodingDigestList && data.length === 0 && (
        <NoDataFound>No Data</NoDataFound>
      )}
    </Wrapper>
  )
};

export default DigestListView;