import React from 'react';
import styled from 'styled-components';
import Page404 from '../NewsDigests/Desktop/404page';
import Footer from '../NewsDigests/Desktop/DesktopDigestItem/Footer';
import Header from 'Components/Header';

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const PageNotFound = () => (
  <div>
    <Header isHideRightSide />
    <Wrapper>
      <Page404 />
    </Wrapper>
    <Footer />
  </div>
);

export default PageNotFound;