import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { primaryColor, white } from 'colorPalette';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DigestCreatedByInfo, DigestPublishInfo } from '../../../CommonComponents';
import coverImgPlaceholder from 'Container/NewsDigests/Assets/coverImgPlaceholder.jpg';

const DigestCardImage = styled.div`
  /* overflow: hidden;
  img {
    transition: transform .5s;
    width: 100%;
    height: 100%;
  } */

  img {
    width: 100%;
  }
  position: relative;
`;

const Container = styled.div`
  padding: 10px 0;
  height: 100%;
`;
const Wrapper = styled(Link)`
  display: block;
  padding-bottom: 45px;
  position: relative;
  height: calc(100% - 20px);
  margin: 10px 0;
  /* height: 480px; */
  cursor: pointer;
  box-shadow: 2px 2px 20px 5px rgba(0,0,0,0.07);
  margin: 10px 0;

  /* @media (max-width: 1024px) {
    height: 430px;
  } */

  &:hover {
    .article-img {
      transform: scale(1.5);
      transform-origin: center;
    }
  }
`;

const DigestCardContent = styled.div`
  padding: 5px 50px 20px 25px;

  @media (max-width: 1024px) {
    padding: 0 15px;
  }
  
  @media (max-width: 580px) {
    padding: 0 10px;
  }
`;

const DigestCardTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 900;
  letter-spacing: -0.2px;
  color: ${props => props.theme.fontColor.level2};
  margin: 10px 0;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
  }
`;

const DigestsTagsList = styled.div`
  display: flex;
  margin: 12px 0 0;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    margin: 12px 0 5px;
  }
`;

const DigestTag = styled(Link)`
  display: inline-block;
  padding: 7px;
  background-color: ${primaryColor.level1};
  border-radius: 2px;
  color: ${white};
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  margin: 5px 10px 5px 0;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${white};
  }
  span {
    color: rgba(255, 255, 255, .5);
  }

  @media (max-width: 580px) {
    font-size: 12px;
    line-height: 12px;
    margin: 3px 3px 3px 0;
  }
`;


const ReadNowButtom = styled.div`
  position: absolute;
  left: 25px;
  bottom: 20px;
  border: 1px solid rgba(${props => props.theme.fontColor.level2RGB}, .2);
  font-family:  Metropolis, Lato, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 18px;
  padding: 8px 10px;
  width: 110px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.fontColor.level2};
  cursor: pointer;
  /* margin-bottom: 10px; */

  @media (max-width: 1024px) {
    left: 10px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
    width: 100px;
    left: 5px;
  }

  &:hover {
    background-color: ${primaryColor.level1};
    color: ${white};
    border: none;
  }

`;

const DigestInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
`;
// const DigestIconWrapper = styled.div`
//   overflow: hidden;
//   padding-right: 5px;
//   width: 45px;
//   img  {
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//   }

//   @media (max-width: 768px) {
//     padding-right: 10px;
//     border-right: 2px solid #fff;
//     img {
//       width: 30px;
//       height: 30px;
//     }
//   }
// `;
// const DigestPublishInfo = styled.div`
//   padding-left: 5px;
//   flex: 1;
//   @media (max-width: 768px) {
//     padding-left: 10px;
//   }
// `;
// const DigestHostName = styled.div`
//   font-size: 14px;
//   line-height: 16px;
//   font-weight: 900;
//   color: ${white};
//   font-family: 'Metropolis', sans-serif;
//   word-break: break-all;
//   text-shadow: 0 0 6px #000000;
// `;
// const DigestPublishAt = styled.div`
//   font-size: 14px;
//   line-height: 16px;
//   font-weight: 500;
//   color: ${white};
//   font-family: 'Metropolis', sans-serif;
//   word-break: break-all;
//   text-shadow: 0 0 6px #000000;
//   text-transform: capitalize;
// `;

const DesktopDigestCard = ({ item, digestDescText, history }) => {
  const { t: translation } = useTranslation();
  return (
    <Container>

      <Wrapper  
        to={`/digests/${item.slug}`}
        onClick={() => {
          logAmplitudeEvent('RelatedDigest Clicked', {
                  current_digest: window.location.href,
                  related_digest: item.slug
          });
          window.scroll(0, 0);
        }}>
        <DigestCardImage>
          <Image src={
            item.cover_pic && item.cover_pic.indexOf('youtube.com') > -1 ?
              `https://i3.ytimg.com/vi/${item.cover_pic.substring(item.cover_pic.indexOf('=') + 1, item.cover_pic.length)}/hqdefault.jpg`
              : (item.cover_pic || coverImgPlaceholder)
          } />
          <DigestInfoWrapper>
            <DigestPublishInfo data={item} showCount={1} color="#fff" showTextShadow hideFollowButton disableShowModal/>
            {/* {item.source && item.source.host_data && item.source.host_data.logo_link && (
              <DigestIconWrapper>
                <Image src={item.source && item.source.host_data && item.source.host_data.logo_link} />
              </DigestIconWrapper>
            )}
            <DigestPublishInfo>
              <DigestHostName>{item.source && item.source.host}</DigestHostName>
              {item.source && item.source.authors && item.source.authors.length > 0 && (
                <DigestPublishAt>{item.source.authors.map((author, index) => `${index !== 0 ? ',' : ''} ${author.name}`)}</DigestPublishAt>
              )}
            </DigestPublishInfo> */}
          </DigestInfoWrapper>
        </DigestCardImage>
        <DigestCardContent>
          {item.categories && item.categories.length > 0 && (
            <DigestsTagsList>
              {item.categories.slice(0, 1).map(item => (
                <DigestTag 
                  to={`/category/${item.slug}`}
                  onClick={e => {
                    e.stopPropagation();
                    // e.preventDefault();
                    // history.push(`/category/${item.slug}`)
                  }}
                  key={item.id}
                ><span>#</span>{`${item.title}`}</DigestTag>
              ))}
            </DigestsTagsList>
          )}
          <DigestCardTitle>{item.title}</DigestCardTitle>
          <DigestCreatedByInfo data={item} />
          <ReadNowButtom>{translation("digestDetail.relatedDigest.readNow")}</ReadNowButtom>
        </DigestCardContent>
      </Wrapper>
    </Container>
  );
}

export default DesktopDigestCard;