import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { grey, black, primaryColor, white } from 'colorPalette';

const Wrapper = styled.div`
  width: 100%;
  height: ${({ open }) => open ? 55 : 0}px;
  overflow: hidden;
  position: ${({ fixed }) => fixed ? 'absolute' : 'unset'};
  top: ${({ headerHeight }) => headerHeight}px;
  left: 0;
  z-index: 10;
  transition: height .2s;
  font-family: 'Metropolis', sans-serif;

  @media (max-width: 768px) {
    /* top: 0; */
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${white};
  width: ${({ width }) => width || '50%'};
  margin: 0 auto;
  border-bottom: 2px solid ${grey.level5};

  @media (max-width: 900px) and (min-width: 768px) {
    width: ${({ width }) => width || '60%'};
  }


  @media (max-width: 768px) and (min-width: 600px) {
    width: ${({ width }) => width || '70%'};
  }
  
  @media (max-width: 600px) and (min-width: 480px) {
    width: ${({ width }) => width || '100%'};
  }
  
  @media (max-width: 480px) {
    width: ${({ width }) => width || '100%'};
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabItem = styled.div`
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 900;
  color: ${({ active }) => active ? black : grey.level0};
  border-bottom: 4px solid ${({ active }) => active ? primaryColor.level1 : 'transparent'};
  cursor: pointer;
  text-transform: uppercase;
`;

const RefreshButton = styled.div`
  padding: 5px;

  i {
    color: ${grey.level2};
  }
`;

const DigestHeader = ({
  activeTab,
  showProfile,
  fixed,
  showDigestHeader,
  width,
  myFeedTitle,
  headerHeight,
  onClickRefresh
}) =>{
  const [openHeader, setOpenHeader] = useState(false);
  const timeoutRef = useRef(null);
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setOpenHeader(showDigestHeader);
    }, 200);
  }, [showDigestHeader])
  const history = useHistory();
  return (
    <Wrapper
      fixed={fixed}
      open={openHeader}
      headerHeight={headerHeight}
    >
      <Container
        width={width}
      >
        {showProfile && (
          <div></div>
        )}
        <TabsWrapper>
          <TabItem 
            active={activeTab === 'discover'}
            onClick={() => history.push('/discover')}
          >DISCOVER</TabItem>
          <TabItem 
            active={activeTab === 'myFeed'}
            onClick={() => history.push('/category/feed', {
              exploreData: null,
              categoryData: null,
            })}
          >{myFeedTitle || 'MY FEED'}</TabItem>
        </TabsWrapper>
        {activeTab !== 'discover' && (
          <RefreshButton
            onClick={() => {
              onClickRefresh();
            }}
          >
            <Icon name="redo" size="large" />
          </RefreshButton>
        )}
      </Container>
    </Wrapper>
  );
};

export default DigestHeader;