import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StarRatings from 'react-star-ratings';
import { Image } from 'semantic-ui-react';
import Slider from "react-slick";
import Icon from 'Icon';

const Wrapper = styled.div`
  padding: 55px 0;
  width: 80%;
  max-width: 1100px;
  margin: 40px auto;
  border-radius: 14px;
  display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    padding: 30px;
    margin: 20px auto;
    width: 95%;
  }
`;

const PeoplesListDesktop = styled.div`
  max-height: 300px;
  overflow-y: auto;
  width: 50%;
  margin-right: 30px;
  padding-right: 20px;
`;

const PersonInfoCard = styled.div`
  padding:${({ isMobile }) => isMobile ? '15px 20px' : '30px 35px'};
  display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};
  align-items: center;
  border-radius: 14px;
  ${({ active }) => active ? `
    box-shadow: 5px 7px 57px 0 rgba(157, 156, 195, 0.27);
    background-color: #ffffff;
  ` : ``}
  cursor: ${({ isMobile }) => isMobile ? 'auto' : 'pointer'};
  margin: 15px;
  outline: none;

  @media (max-width: 580px) {
    margin: 0;
  }
`;
const PersonInfoImage = styled.div`
  width: ${({ isMobile }) => isMobile ? 80 : 60}px;
  height: ${({ isMobile }) => isMobile ? 80 : 60}px;
  border-radius: 50%;
  margin: ${({ isMobile }) => isMobile ? '0 auto' : '0 30px 0 0'};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;
const PersonContent = styled.div`
  flex: 1;
  text-align: ${({ isMobile }) => isMobile ? 'center' : 'left'};
  margin-top: ${({ isMobile }) => isMobile ? 15 : 0}px;
`;
const PersonName = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 1.52;
  color: #333333;

  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 580px) {
    font-size: 18px;
  }
`;
const PersonTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.52;
  color: rgba(70, 70, 70, 0.8);
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 580px) {
    font-size: 18px;
  }
`;
const PersonDescription = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.52;
  color: rgba(70, 70, 70, 0.8);

  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 580px) {
    font-size: 18px;
  }
`;

const PersonDescriptionWrapper = styled.div`
  flex: 1;
  text-align: ${({ isMobile }) => isMobile ? 'center' : 'left'};
`;

const PersonDescriptionTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.32;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 580px) {
    font-size: 22px;
  }
`;

const PersonsListMobile = styled.div`
  width: 50%;
  margin: 0 auto;

  @media (max-width: 580px) {
    width: 80%;
  }
`;
const RatingWrapper = styled.div`
  margin: 10px 0;
`;

const NextPrevIcon = styled.div`
  cursor: pointer;
  opacity: .7;
  width: 60px;
  height: auto;

  @media (max-width: 1024px) {
    width: 50px;
  }
  &.slick-prev {
    left: -100px;
    @media (max-width: 1024px) {
      left: -50px;
    }
    
    @media (max-width: 768px) {
      left: -40px;
    }
  }
  
  &.slick-next {
    right: -100px;
    @media (max-width: 1024px) {
      right: -50px;
    }
    
    @media (max-width: 768px) {
      right: -40px;
    }
  }
  &:hover {
    opacity: 1;
  }
`;


const NextIcon = ({ onClick, className, currentSlide, slideCount, ...props }) => {
  const count = 1;
  return (
    <>
      {slideCount - count !== currentSlide && (
        <NextPrevIcon
          className={className}
          onClick={onClick}
          prev
        >
          <Icon name="sliderArrowRight" size={50} color="#c3cff5"/>
        </NextPrevIcon>
      )}
    </>
  );
}
const PrevIcon = ({ onClick, className, currentSlide }) => (
  <>
    {currentSlide > 0 && (
      <NextPrevIcon
        className={className}
        onClick={onClick}>
        <Icon name="sliderArrowLeft" size={50} color="#c3cff5"/>
      </NextPrevIcon>
    )}
  </>
);

const Testimonial = ({ data }) => {
  const [testimonialData, setTestimonialData] = useState(null);
  const [selectedPersonData, setSelectedPersonData] = useState(null);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const personsData  = Object.keys(data).map(item => {
        let personData = data[item].content ? JSON.parse(data[item].content) : null;
        personData = {
          ...personData,
          id: data[item].id,
        }
        return personData;
      });

      setTestimonialData(personsData);
      setSelectedPersonData(personsData[0]);
    }
  }, [data]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextIcon totalLength={testimonialData && testimonialData.length} />,
    prevArrow: <PrevIcon />,
    afterChange: value => {
      setSelectedPersonData(testimonialData[value])
    }
  };
  return (
    <>
      {testimonialData && testimonialData.length > 0 && (
        <Wrapper
          isMobile={window.innerWidth <= 768}
        >
          {window.innerWidth > 768 ? (
            <PeoplesListDesktop>
              {testimonialData.map(item => (
                  <PersonInfoCard
                    active={selectedPersonData && selectedPersonData.id === item.id}
                    key={item.id}
                    onClick={() => setSelectedPersonData(item)}
                  >
                    <PersonInfoImage>
                      <Image src={item.image} />
                    </PersonInfoImage>
                    <PersonContent>
                      <PersonName>{item.name}</PersonName>
                      <PersonTitle>{item.desig}</PersonTitle>
                    </PersonContent>
                  </PersonInfoCard>
                )
              )}
            </PeoplesListDesktop>
          ) : (
            <PersonsListMobile>
              <Slider {...sliderSettings}>
                {testimonialData.map(item => (
                  <PersonInfoCard
                    key={item.id}
                    isMobile
                  >
                    <PersonInfoImage
                      isMobile
                    >
                      <Image src={item.image} />
                    </PersonInfoImage>
                    <PersonContent
                      isMobile
                    >
                      <PersonName>{item.name}</PersonName>
                      <PersonTitle>{item.desig}</PersonTitle>
                    </PersonContent>
                  </PersonInfoCard>
                )
                )}
              </Slider>
            </PersonsListMobile>
          )}
          <PersonDescriptionWrapper
            isMobile={window.innerWidth <= 768}
          >
            <PersonDescriptionTitle>
              {selectedPersonData.title}
            </PersonDescriptionTitle>
            <RatingWrapper>
              <StarRatings
                starRatedColor="#ffa70b"
                rating={selectedPersonData.rating}
                starDimension="20px"
                starSpacing="5px"
              />
            </RatingWrapper>
            <PersonDescription
            >
              {selectedPersonData && selectedPersonData.description}
            </PersonDescription>
          </PersonDescriptionWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default Testimonial;