export const users = [
  { id:1,
    name: "Suraj Serigar",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCngFC9xA4eBFdmLqiZvbTHQGCLs-t426XToLalWvIU",
    profession: "UX Engineer",
    quote:
      " “Definitely 💯 times better than inShorts. Hand's down. Everything is perfect, and the fact that you can directly reach out to the owner of the app to share feedback/suggestions on WhatsApp, & they reply instantly is the icing on the cake. Keep rocking F labs team! Up & onwards!”",
  },
  {
    id:2,
    name: "Samyukt G",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCkZZ9dpEtElj35Wf5VBuhRuO4pmxz-zSUCl7fjM9g",
    profession: "Junior Front-end Developer",
    quote:
      "“I've tried out pretty much every app that provides news summaries from multiple sources, it's safe to say this is the best one. It doesn't shove ads up your face like inshorts and actually provides a proper and descriptive summary unlike some of the other ones. The app is rather easy to use as well. Highly recommend it for whatever your usecase is.”",
  },
  { id:3,
    name: "Sivarama Krishnan",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCkY6MHi4bAd9pRUAHHy8VS_xI0ZW8VoZW2JN5Mbs9U",
    profession: "UX Engineer",
    quote:
      " “Dailybrief has truly helped me in my goal of starting to read news snippets. With the picky UI/UX freak I am, I have tried multiple apps but dailybrief is really a standout with the way it has designed the app. Kudos to the entire product team and the designers for this. Specifically the latest update on UI/UX front made me write this detailed review. Kudos.”",
  },
  {
    id:4,
    name: "Zeus K",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCmsbRiFb8zx3xubZbdy10gI5scEL3fqgLialQqD2w",
    profession: "Junior Front-end Developer",
    quote:
      "“I downloaded Daily Brief after a friend recommended me and honestly it's one of the most used apps on my mobile. The briefs written by the writers are both to the point and highly intellectual. The kind of improvement done by the UI/UX team has been really nice. Last but not the least, kudos to the founder for creating such an app. Really appreciate it. Thank you so much! Keep it up!”",
  },
  { id:5,
    name: "Pranjal Singh",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCngrAArRv6GWEsJT6nLtxdd7_TgCGr-PUX4lqIEPS4",
    profession: "UX Engineer",
    quote:
      " “Undoubtedly the best app for genuine readers. This is only news app that has been on my phone for the longest. Love you guys.”",
  },
  {
    id:6,
    name: "Dinesh l",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCmTc1lsizotcbYwFGQmJHpuhFUMTbQLQQ-j5uJzeQ",
    profession: "Junior Front-end Developer",
    quote:
      "“Short and Crisp. Summarized well and easy to get the overall picture within a minute. Install without a doubt and give it a try, you won't regret it.”",
  },
  { id:7,
    name: "Sarang Bombatkar",
    image: "https://lh3.googleusercontent.com/a/AEdFTp5j3HWlNGSTMLhCNC-du_IJ2AvqsT0wrCIfui_M=mo",
    profession: "UX Engineer",
    quote:
      " “I dont usually review apps or rate them, but this one deserves one. Bhai kya app banaaye ho, been using this since 3 months and its super useful.”",
  },
  {
    id:8,
    name: "Salman Kevin",
    image: "https://lh3.googleusercontent.com/a-/AD5-WCmkbqefeL_5EMNZaD-8PHfqbWZ_kv2CInIVHX1iylU",
    profession: "Junior Front-end Developer",
    quote:
      "“others review : Better than most of the news apps Naahh... My review: the best 👍💯 (Uninstalled the Inshorts🙃)”",
  },
  { id:9,
    name: "VISHNU VENUGOPAL",
    image: "https://lh3.googleusercontent.com/a-/AD5-WClf51FMnL2XDg3reMCYDak87hAatobWK563J4YIxw",
    profession: "UX Engineer",
    quote:
      " “Shut up and take my money”",
  },
  {
    id:10,
    name: "Soumyajit Saha",
    image: "https://lh3.googleusercontent.com/a-/AD5-WClXuhRoCdCTPm_TEWYbrtC6KDdJJbH_EY0vGQXt2GA",
    profession: "Junior Front-end Developer",
    quote:
      "“Exceptionally Good App. The writing style of the brief of the articles is concise enough to be read in 1-2 min. Descriptive enough to give the full picture. Very much impartial too. And now it's my daily tool.”",
  },
];
