import React from 'react';
import styled from 'styled-components';
// import Slider from "react-slick";
import { white, primaryColor } from 'colorPalette';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DigestPublishInfo } from '../CommonComponents';
const Wrapper = styled.div`
  padding: 20px 15px;
  background-color: ${props => props.theme.secondaryColor.level3};
  margin: 10px 0;
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    @media (max-width: 580px) {
      width: ${window.innerWidth / 1.5}px !important;
    }
    
    @media (max-width: 400px) {
      width: ${window.innerWidth / 1.2}px !important;
    }
  }
  .slick-slide > div {
    height: 100% !important;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const ArticleItemWrapper = styled.div`
  margin: 10px;
  width: ${window.innerWidth}px !important;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, .4);
  /* height: 300px; */
  /* height: 100%; */
  outline: none;
  display: block !important;
  white-space: pre-wrap !important;
`;

const ArticleItemContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.secondaryColor.level1};
  /* margin: 0 auto; */
  margin-right: 20px;
  cursor: pointer;
`;

const ArticleImageWrapper = styled.div`
  position: relative;
  height: 200px;
  background-image: url("${({ bgImg }) => bgImg }");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  img {
    width: 100%;
  }
`;

const DigestTag = styled(Link)`
  display: inline-block;
  padding: 7px;
  background-color: ${primaryColor.level1};
  border-radius: 2px;
  color: ${white};
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  margin: 5px 5px 5px 0;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: ${white};
  }
  span {
    color: rgba(255, 255, 255, .5);
  }

  @media (max-width: 580px) {
    font-size: 12px;
    line-height: 12px;
    margin: 0 10px 0 0;
  }
`;

// const DigestCreatedBy = styled.div`
//   font-size: ${({ fontSize }) => fontSize || '14px'};
//   line-height: ${({ lineHeight }) => lineHeight || '18px'};
//   font-weight: 700;
//   opacity: .8;
//   /* display: flex; */
//   /* align-items: center; */
//   color: rgba(0, 0, 0, .8);
//   margin-top: ${({ marginTop }) => marginTop || '10px'};
//   vertical-align: top;
//   font-family: 'Metropolis', sans-serif;
//   span {
//     display: inline-block;
//     color: #333;
//     vertical-align: top;
//   }

//   .label {
//     font-weight: normal;
//   }

//   .divider {
//     display: inline-block;
//     width: 5px;
//     height: 5px;
//     background-color: ${grey.level2};
//     border-radius: 50%;
//     margin: 0 5px;
//     vertical-align: middle;
//   }

//   @media (max-width: 870px) {
//     font-size: 12px;
//     line-height: 16px;
//   }
// `;

const ArticleImageContent = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(to bottom,rgba(0,0,0,0) 52%,rgba(0,0,0,0.5) 83%);
   @media (max-width: 768px) {
    padding: 10px 15px 10px;
  }
`;

const ArticleTitle = styled.div`
  padding: 15px 15px 0;
  font-size: 16px;
  line-height: 20px;
  color: ${white};
  font-weight: 900;
  font-family: Metropolis-Bold, sans-serif;
  text-shadow: 1px 1px rgba(0,0,0,0.5);
  @media (max-width: 768px) {
    padding: 0;
  }

  @media (max-width: 580px) {
    font-size: 16px;
  }
`;

const ArticleItemContent = styled.div`
  padding: 15px;
  display: flex;
  flex: 1;
`;

const SectionTitle = styled.div`
  font-family: 'Metropolis-Bold', 'Lato', sans-serif;
  font-size: 16px;
  /* font-weight: 900; */
  color: ${props => props.theme.fontColor.level2};
  opacity: .8;
  margin-bottom: 10px;
`

// const sliderSettings = {
//   className: "slider variable-width",
//   dots: false,
//   infinite: false,
//   centerMode: false,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   arrows: true,
//   adaptiveHeight: true,
//   responsive: [
//     {
//       breakpoint: 769,
//       settings: {
//         slidesToShow: 2,
//       }
//     },
//     {
//       breakpoint: 580,
//       settings: {
//         slidesToShow: 1,
//         arrows: false,
//         variableWidth: true,
//       }
//     },
//   ]
// };

const SliderWrapper = styled.div`
  overflow: auto;

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track-piece {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  white-space: nowrap!important;
`;

// const DigestCreatedByInfoWrapper = styled.div`
//   width: 100%;
// `;
const MobileTopStories = ({ sideBarTopStories, onClickDigest }) => {
  const { t: translation } = useTranslation();
  return (
    <>
      {sideBarTopStories && (
      <Wrapper>
        <>
          <SectionTitle>{(sideBarTopStories && sideBarTopStories.content && sideBarTopStories.content.title) || translation('digestFeed.sidebarTopStoriesHeading')}</SectionTitle>
          {sideBarTopStories && sideBarTopStories.articles && sideBarTopStories.articles.length > 0 && (
            <SliderWrapper>
              <SliderContainer
                ref={a => {
                  if (!a) return;
                  const itemList = a.querySelectorAll('.slider-item');
                  let totalWidth = 0;
                  itemList.forEach(categoryItem => {
                    const width = window.innerWidth > 600 ? window.innerWidth/2 : window.innerWidth;
                    totalWidth = totalWidth + width - 50;
                  });
                  a.style.width = `${totalWidth}px`;
                }}
              >
                {sideBarTopStories.articles.map(item => (
                  <ArticleItemWrapper
                    key={item.id}
                    className="slider-item"
                  >
                    <ArticleItemContainer
                      to={`/digests/${item.slug}`}
                      onClick={() => {
                        window.scroll(0, 0);
                        onClickDigest(item);
                        // history.push(`/digests/${item.slug}`)
                      }}
                    >
                      <ArticleImageWrapper
                        bgImg={
                          item.cover_pic && item.cover_pic.indexOf('youtube.com') > -1 ?
                          `https://i3.ytimg.com/vi/${item.cover_pic.substring(item.cover_pic.indexOf('=') + 1, item.cover_pic.length)}/hqdefault.jpg`
                          : item.cover_pic
                        }
                      >
                        <ArticleImageContent>
                          {item.categories && item.categories.length > 0 && (
                            <>
                              {item.categories.slice(0, 1).map(item => (
                                <DigestTag 
                                  key={item.id}
                                  to={`/category/${item.slug}`}
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}
                                ><span>#</span>{`${item.title}`}</DigestTag>
                              ))}
                            </>
                          )}
                          <div>
                            <ArticleTitle>
                              {item.title}
                            </ArticleTitle>
                            {/* <DigestCreatedByInfoWrapper>
                              <DigestCreatedByInfo marginTop="2px" data={item} color="#fff"/>
                            </DigestCreatedByInfoWrapper> */}
                          </div>
                        </ArticleImageContent>
                      </ArticleImageWrapper>
                      <ArticleItemContent>
                        <DigestPublishInfo data={item} marginTop="0" visibleForAllDigest hideFollowButton/>
                        {/* <ArticleItemIconWrapper>
                          <Image src={item.source && item.source.host_data && item.source.host_data.logo_link} />
                        </ArticleItemIconWrapper>
                        <ArticleItemSubtitleWrapper>
                          <div>
                            {item.source && item.source.host}
                          </div>
                          <ArticleCreatedAt>{item.source && item.source.author && item.source.author.name}</ArticleCreatedAt>
                        </ArticleItemSubtitleWrapper> */}
                      </ArticleItemContent>
                    </ArticleItemContainer>
                  </ArticleItemWrapper>
                ))}
              </SliderContainer>
            </SliderWrapper>
          )}
        </>
        </Wrapper>
      )}
    </>
  );
}

export default MobileTopStories;