
const isStage = process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'tnd-staging' || process.env.REACT_APP_ENV === 'und-staging' || process.env.REACT_APP_ENV === 'oped-staging';

const loadYtApi = () => {
  if (!window.YT) { // If not, load the script asynchronously
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
    const tag1 = document.createElement('script');
    tag1.src = 'https://platform.twitter.com/widgets.js';
    tag1.async = true;
    const secondScriptTag = document.getElementsByTagName('script')[0];
    secondScriptTag.parentNode.insertBefore(tag1, secondScriptTag);
  }
};

const scriptData = {
  'staging': {
    googletagmanager: 'https://www.googletagmanager.com/gtag/js?id=UA-165938657-1',
    dataLayer: `window.dataLayer = window.dataLayer || [];
    function gtag(){if(window.dataLayer) { window.dataLayer.push(arguments);}}
    gtag('js', new Date());

    gtag('config', 'UA-165938657-1');`
  },
  'oped-production': {
    googletagmanager: 'https://www.googletagmanager.com/gtag/js?id=UA-165938657-1',
    dataLayer: `window.dataLayer = window.dataLayer || [];
    function gtag(){if(window.dataLayer) {window.dataLayer.push(arguments);}}
    gtag('js', new Date());

    gtag('config', 'UA-165938657-1');
    gtag('config', 'AW-945341511');
    gtag('event', 'conversion', {'send_to': 'AW-945341511/7tkbCJ61ydEBEMeI48ID'});
`,
    hotjar: `(function(h,o,t,j,a,r){
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings={hjid:1805557,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
    fb: `  !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '276187956756005');
          fbq('track', 'PageView');`,
    twtr: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o3tp0');
            twq('track','PageView');1`,
    lnkd: `_linkedin_partner_id = "2218612";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();`

  },
  'clip-production': {
    googletagmanager: 'https://www.googletagmanager.com/gtag/js?id=UA-165938657-1',
    dataLayer: `window.dataLayer = window.dataLayer || [];
    function gtag(){if(window.dataLayer) {window.dataLayer.push(arguments);}}
    gtag('js', new Date());

    gtag('config', 'UA-165938657-1');
    gtag('config', 'AW-945341511');
    gtag('event', 'conversion', {'send_to': 'AW-945341511/7tkbCJ61ydEBEMeI48ID'});
`,
    hotjar: `(function(h,o,t,j,a,r){
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings={hjid:1805557,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
    fb: `  !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '276187956756005');
          fbq('track', 'PageView');`,
    twtr: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o3tp0');
            twq('track','PageView');1`,
    lnkd: `_linkedin_partner_id = "2218612";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();`

  },
  'tnd-production': {
    googletagmanager: 'https://www.googletagmanager.com/gtag/js?id=UA-165938657-2',
    dataLayer: `window.dataLayer = window.dataLayer || [];
  function gtag(){if(window.dataLayer) { window.dataLayer.push(arguments);}}
  gtag('js', new Date());

  gtag('config', 'UA-165938657-2');`,
  fb: `  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '3120485644707711');
  fbq('track', 'PageView');`
  },
  'und-production': {
    googletagmanager: 'https://www.googletagmanager.com/gtag/js?id=UA-165938657-3',
    dataLayer: `window.dataLayer = window.dataLayer || [];
    function gtag(){if(window.dataLayer) { window.dataLayer.push(arguments);}}
    gtag('js', new Date());

    gtag('config', 'UA-165938657-3');`
  },
  'production': {
    googletagmanager: 'https://www.googletagmanager.com/gtag/js?id=UA-165938657-1',
    dataLayer: `window.dataLayer = window.dataLayer || [];
    function gtag(){if(window.dataLayer) { dataLayer.push(arguments); }}
    gtag('js', new Date());

    gtag('config', 'UA-165938657-1');`
  },
};

export const loadAnalyticsApi = () => {
  if (!isStage) {
    const tag1 = document.createElement('script');
    tag1.src = scriptData[process.env.REACT_APP_ENV].googletagmanager;
    tag1.async = true;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag1, firstScriptTag);
    

    if (scriptData[process.env.REACT_APP_ENV].hotjar) {
      const tag2 = document.createElement('script');
      tag2.innerHTML = scriptData[process.env.REACT_APP_ENV].hotjar;
      firstScriptTag.parentNode.insertBefore(tag2, firstScriptTag);
    };

    const tag3 = document.createElement('script');
    tag3.innerHTML = scriptData[process.env.REACT_APP_ENV].dataLayer;
    firstScriptTag.parentNode.insertBefore(tag3, firstScriptTag);
      

    if (scriptData[process.env.REACT_APP_ENV].fb) {
      const tag4 = document.createElement('script');
      tag4.innerHTML = scriptData[process.env.REACT_APP_ENV].fb;
      firstScriptTag.parentNode.insertBefore(tag4, firstScriptTag);
    };

    if (scriptData[process.env.REACT_APP_ENV].twtr) {
      const tag5 = document.createElement('script');
      tag5.innerHTML = scriptData[process.env.REACT_APP_ENV].twtr;
      firstScriptTag.parentNode.insertBefore(tag5, firstScriptTag);
    };

      if (scriptData[process.env.REACT_APP_ENV].lnkd) {
      const tag6 = document.createElement('script');
      tag6.innerHTML = scriptData[process.env.REACT_APP_ENV].lnkd;
      firstScriptTag.parentNode.insertBefore(tag6, firstScriptTag);
    };

  };
};

export const loadFontFamily = () => {
  const tag = document.createElement('link');
  tag.rel = "stylesheet"
  tag.href = "https://fonts.googleapis.com/css?family=Noto Sans";
  const firstScriptTag = document.getElementsByTagName('head')[0];
  firstScriptTag.appendChild(tag);
};

const initializeOneSignal = () => {
  if (window.OneSignal) {
      console.log("initializeOneSignal -> window.OneSignal._initCalled", window.OneSignal._initCalled)
    if (!window.OneSignal._initCalled) {
      const isTnd = process.env.REACT_APP_ENV === "tnd-production";
      const isTndStaging = process.env.REACT_APP_ENV === "tnd-staging";
      const isOped = process.env.REACT_APP_ENV === 'oped-production';
      const isClip = process.env.REACT_APP_ENV === 'clip-production';
      const isOpedStaging = process.env.REACT_APP_ENV === 'oped-staging';

      let key = "20982d20-0a65-4fbc-8465-83e829d1c50f";
      let safariWebId = "web.onesignal.auto.10485988-1822-4e96-b399-29edb7cde282"
      let subdomainName = "tnddigest.OS.TC";
      if (isTnd) {
        key = "ff5a16cd-255a-4172-a08d-b8c6b3f6dbc6";
        subdomainName = null;
        safariWebId = "web.onesignal.auto.184299bc-1c91-4dec-a5d4-b75cfd155372";
      } else if(isTndStaging) {
        key = "d4672dc2-8b70-4ff2-9e15-50f2b2219e7a";
        subdomainName = "xndstaging.OS.TC";
        safariWebId = "web.onesignal.auto.1774e9e6-150a-4896-aace-e43262a3e2ec";
      }
      if (isOped) {
        key = "bbe8d178-cf27-4969-8ab8-8cfcd810d3b1";
        subdomainName = null;
        safariWebId = "web.onesignal.auto.147da6f8-95e8-4f3e-9e77-8dc52e1c58f0";
      } else if (isOpedStaging) {

      }
      
      if (isClip) {
        key = "bbe8d178-cf27-4969-8ab8-8cfcd810d3b1";
        subdomainName = null;
        safariWebId = "web.onesignal.auto.147da6f8-95e8-4f3e-9e77-8dc52e1c58f0";
      }
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function () {
        window.OneSignal.init({
          appId: key,
          safariWebId: safariWebId,
          allowLocalhostAsSecureOrigin: true,
          subdomainName: subdomainName
        }).then(response => {
        console.log("initializeOneSignal -> response", response)
          
        })
        .catch(error => {
        console.log("initializeOneSignal -> error", error)
          
        });
      });
    }
  } else {
    setTimeout(() => {
      initializeOneSignal();
    }, 200);
  }
}
export const loadOnesignal = () => {
  const tag = document.createElement('script');
  tag.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
  tag.async = true;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  // OneSignal.initialize(key, {});
  setTimeout(() => {
    initializeOneSignal();
  }, 200);
}

export default loadYtApi;