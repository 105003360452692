import React from 'react';
import { Modal, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import CrossIcon from '../../cross.svg';

const SourceImageWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: ${({ length }) => length*27}px;
  
  span {
    text-transform: capitalize;
  }
`;

const AuthorDetail = styled.div`
  line-height: 30px;
  border-width: 1px;
  align-items: center;
`;

const AuthorName = styled.div`
: ${({ fontSize }) => fontSize || '12px' };
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 6px;
  align-items: center;
`;

const AuthorDescription = ({
 data: {
   profile_pic,
   description,
   name,
 },
 active,
 onClose
}) => {
  return (
    <Modal
      size="mini"
      open={active}
    >
      <Modal.Content>
        <div>
          <SourceImageWrapper>
            <Image
              src={profile_pic ? profile_pic : '/static/media/profile.1c53bde6.svg'}
              avatar
              onError={(ele) => {
                ele.target.src = '/static/media/profile.1c53bde6.svg';
              }}
            />
            <img
              style={{height: 15, width: 15}} src={CrossIcon}
              onClick={() => {
                onClose()
              }}
              alt=""
            />
          </SourceImageWrapper>
          <AuthorName>
            {name}
          </AuthorName>
          {
            description ? description.split('\n').map((data, key) => <AuthorDetail kay={key}>{data} </AuthorDetail>) : ''
          }
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AuthorDescription;