import React, { useEffect, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import 'prevent-pull-refresh';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';

import PageHeader from 'Components/Header';

import ArticleItem from './ArticleItem';
import { Loader, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import DigestHeader from '../DigestHeader';
import ShareDialogBox from './ArticleItem/ShareDialogBox';
// import TNDLogo from '../Assets/TND Logo.png';
import { grey } from 'colorPalette';
import Snackbar from './Snackbar';
import Div100vh from 'react-div-100vh'

const Container = styled(Div100vh)`
  /* height: calc(100vh - 60px); */
  padding-top: ${({ headerHeight }) => headerHeight}px;
  /* height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`}; */
  box-sizing: border-box;
  /* font-family: GothamBookRegular; */
  position: relative;
  font-family: 'Metropolis', sans-serif;

  @media (max-width: 768px) {
    /* padding-top: 0; */
  }

  .slick-slider,
  .slick-track {
    height: 100% !important;
  }
  .slick-list {
    height: 100% !important;
    /* height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`} !important; */
    /* height: calc(100vh - 60px) !important; */

     /* @media (max-width: 768px) { */
      /* height: calc(100vh - 60px) !important; */
    /* } */
  }

  .slick-arrow {
    display: none !important;
  }
  .slick-vertical .slick-slide {
    height: 100% !important;
    /* height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`} !important; */
    /* @media (max-width: 768px) {
      height: calc(100vh - 60px) !important;
    } */
    > div {
      height: 100%;
    }
  }
`;

const ArticleItemWrapper = styled.div`
  height: 100%;
  outline: none;
`;

const DigestTopHeaderWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 0;
  @media (max-width: 900px) and (min-width: 768px) {
    width: 60%;
  }

  @media (max-width: 768px) and (min-width: 600px) {
    width: 70%;
  }
  
  @media (max-width: 600px) and (min-width: 480px) {
    width: 90%;
  }
  
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const NoDataFoundWrapper = styled(Div100vh)`
  /* height: ${({ headerHeight }) => `calc(100vh - ${headerHeight + 20}px)`}; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;

  i {
    color: ${grey.level3} !important;
  }
`

const NoDataFoundText = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: ${grey.level3};
  margin-top: 15px;
`;

const TryAgainText = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 900;
  color: #0080ff;
  margin-top: 25px;
  cursor: pointer;
`;

// const DigestTopHeaderLogo = styled.div`
//   width: 155px;
// `;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_ACTIVE_ARTICLE':
        draft.activeArticle = action.value;
        draft.activeDigestId = action.activeDigestId;
        draft.showDigestHeader = action.showArticleHeader;
        break;
      case 'SET_ACTIVE_DIGESTS_ID':
        draft.activeDigestId = action.value;
        draft.latScrolledValue.length = 0;
        break;
      case 'SET_SHOW_DIGEST_HEADER':
        draft.showDigestHeader = action.value;
        break;
      case 'SET_NEWS_FEED_TITLE':
        draft.newsFeedTitle = action.value;
        break;
      case 'SET_DOWNLOAD_APP_MODAL':
        draft.openDownloadApp = action.value;
        break;
      case 'SET_DISABLE_SWIPE':
        draft.disableSwipe = action.value;
        break;
      case 'SET_LAST_SCROLLED':
        if (!draft.latScrolledValue.includes(action.value)) draft.latScrolledValue.push(action.value);
        break;
    }
  })

const initialState = {
  activeArticle: 1,
  showDigestHeader: false,
  newsFeedTitle: 'MY FEED',
  openDownloadApp: false,
  disableSwipe: false,
  activeDigestId: null,
  latScrolledValue: [],
};

const MobileNewsDigests = ({
  videoDigestList,
  loadVideoDigest,
  onChangePagination,
  activePagination,
  onDigestBookMark,
  onDigestReadLater,
  pageSize,
  onClickRefresh,
  onClickTags,
  selectedTags,
  openRefreshSuccessfully,
  exploreId,
  categoryId,
  typeRef,
  onVisitArticle,
  params,
  currentActiveDigest,
  latScrolledValue
}) => {
  const history = useHistory();
  const sliderRef = useRef(null);
  const digestHeaderRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const {
    activeArticle,
    showDigestHeader,
    newsFeedTitle,
    openDownloadApp,
    disableSwipe,
    activeDigestId
  } = state;

  const setScrollPos = scrollPercentRounded => {
    dispatch({ type: 'SET_LAST_SCROLLED', value: scrollPercentRounded });
    logAmplitudeEvent('Article Scrolled', {
      article_id: activeArticle.id,
      scroll_position: scrollPercentRounded,
      slug: activeArticle.slug,
      category_name: activeArticle && activeArticle.categories ? activeArticle.categories.map(item => item.title).join(',') : ''
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = e => {
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
    const  scrollPercentRounded = Math.round((h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100);
    if (activeArticle &&
      !latScrolledValue.includes(scrollPercentRounded) &&
      (
        scrollPercentRounded === 25 ||
        scrollPercentRounded === 50 ||
        scrollPercentRounded === 75 ||
        scrollPercentRounded === 95)
      ) {
        setScrollPos(scrollPercentRounded);
    } else {
      if (scrollPercentRounded >= 25 && !latScrolledValue.includes(25)) {
        setScrollPos(25)
      }
      if (scrollPercentRounded >= 50 && !latScrolledValue.includes(50)) {
        setScrollPos(50)
      }
      if (scrollPercentRounded >= 75 && !latScrolledValue.includes(75)) {
        setScrollPos(75)
      }
      if (scrollPercentRounded >= 95 && !latScrolledValue.includes(95)) {
        setScrollPos(95)
      }
    }
  }

  const anchorClick = e => {
    const anchor = e.target.closest("a"); 
    logAmplitudeEvent('ArticleBodyLink Clicked', {
      article_id: activeArticle.id,
      link_target: anchor.getAttribute('href'),
      slug: activeArticle.slug,
      category_name: activeArticle && activeArticle.categories ? activeArticle.categories.map(item => item.title).join(',') : ''
    });
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, [handleScroll]);
  
    useEffect(() => {
      if (window) {
        const anchors = document.getElementsByTagName("a");
        Array.prototype.forEach.call(anchors, anchor => {
          anchor.addEventListener('click', anchorClick);
        });
    }
      return () => {
        if (window) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const anchors = document.getElementsByTagName("a");
          Array.prototype.forEach.call(anchors, anchor => {
            anchor.removeEventListener('click', anchorClick);
          });
      }
    }
  });
  

  useEffect(() => {
    if (selectedTags) {
      dispatch({ type: 'SET_NEWS_FEED_TITLE', value: selectedTags.title });
    }
  }, [selectedTags]);

  useEffect(() => {
    if (params.typeSlug) {
      dispatch({ type: 'SET_NEWS_FEED_TITLE', value: params.typeSlug.split('-').join(' ') });
    }
  }, [params.typeSlug])

  useEffect(() => {
    if (history.location.hash === '#share') {
      setOpenShareDialog(true);
    } else {
      setOpenShareDialog(false);
    }
  }, [history.location]);
  useEffect(() => {
    if(videoDigestList && videoDigestList.length > 0 && !activeDigestId) {
      dispatch({ type: 'SET_ACTIVE_DIGESTS_ID', value: videoDigestList[0].id})
    }
  }, [activeDigestId, videoDigestList])

  // useEffect(() => {
  //   if (locationState && locationState.exploreData) {
  //     dispatch({ type: 'SET_NEWS_FEED_TITLE', value: locationState.exploreData.title });
  //   } else if (locationState && locationState.categoryData) {
  //     dispatch({ type: 'SET_NEWS_FEED_TITLE', value: locationState.categoryData.title });
  //   }
  // }, [locationState]);

  useEffect(() => {
    if (videoDigestList.length >= activePagination * pageSize && activeArticle >= videoDigestList.length - 1) {
      onChangePagination(activePagination + 1);
    }
  }, [activeArticle, activePagination, onChangePagination, pageSize, videoDigestList.length]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    initialSlide: currentActiveDigest || 0,
    swipe: !disableSwipe,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    lazyLoad: true,
    afterChange: value => {
      let showArticleHeader = false;
      if (activeArticle > value + 1) {
        showArticleHeader = true;
      }
      if (!videoDigestList[value].read) {
        onVisitArticle(videoDigestList[value]);
      }
      let replaceUrl = `/${videoDigestList[value].slug}`;
      if (exploreId) {
        replaceUrl = `/${typeRef}/${params.typeSlug}/${videoDigestList[value].slug}`;
      }
      if (categoryId) {
        replaceUrl = `/${typeRef}/${params.typeSlug}/${videoDigestList[value].slug}`;
      }
      dispatch({ type: 'SET_ACTIVE_ARTICLE', value: value + 1, showArticleHeader, activeDigestId: videoDigestList[value].id });
      history.replace(replaceUrl);
    },
  };

  let pageTitle = 'Thetravelnewsdigest';
  let activeDigest = null

  if (activeDigestId && videoDigestList.length > 0) {
    activeDigest = videoDigestList.filter(item => item.id === activeDigestId);
    if(activeDigest.length > 0) {
      pageTitle = activeDigest[0].title;
    }
  }
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <DigestTopHeaderWrapper
      >
        <PageHeader 
          headerRef={digestHeaderRef}
          fixed
        />
        {/* <DigestTopHeaderLogo>
          <Image src={TNDLogo} />
        </DigestTopHeaderLogo> */}
      </DigestTopHeaderWrapper>
      <Container
        onClick={(e) => {
          dispatch({ type: 'SET_SHOW_DIGEST_HEADER', value: !showDigestHeader });
        }}
        headerHeight={(digestHeaderRef.current && digestHeaderRef.current.offsetHeight) || 0}
      >
        <DigestHeader
          myFeedTitle={newsFeedTitle}
          headerHeight={(digestHeaderRef.current && digestHeaderRef.current.offsetHeight) || 0}
          showDigestHeader={showDigestHeader}
          activeTab="myFeed"
          fixed
          onClickRefresh={onClickRefresh}
        />
        {videoDigestList && videoDigestList.length > 0 && (
          <Slider ref={sliderRef} {...sliderSettings} >
            {videoDigestList.map(item => (
              <ArticleItemWrapper key={item.id}>
                <ArticleItem
                  headerHeight={(digestHeaderRef.current && digestHeaderRef.current.offsetHeight) || 0}
                  data={item}
                  onDigestBookMark={onDigestBookMark}
                  onDigestReadLater={onDigestReadLater}
                  openDownloadApp={openDownloadApp}
                  onClickTags={onClickTags}
                  onSetDisableSwipe={value => {
                    dispatch({ type: 'SET_DISABLE_SWIPE', value })
                  }}
                  onClickDownloadAppDropdown={(value) => {
                    // dispatch({ type: 'SET_DOWNLOAD_APP_MODAL', value });
                  }}
                />
              </ArticleItemWrapper>
            ))}
          </Slider>
        )}

        {videoDigestList && videoDigestList.length === 0 && !loadVideoDigest && (
          <NoDataFoundWrapper
            headerHeight={(digestHeaderRef.current && digestHeaderRef.current.offsetHeight) || 0}
          >
            <Icon name="warning sign" size="massive" />
            <NoDataFoundText>No Data</NoDataFoundText>
            <TryAgainText
              onClick={onClickRefresh}
            >Try Again</TryAgainText>
          </NoDataFoundWrapper>
        )}
        {loadVideoDigest && videoDigestList.length === 0 && (
          <Loader active />
        )}
        {openShareDialog && (
          <ShareDialogBox
            onClose={() => history.goBack()}
            open={openShareDialog}
            data={activeDigest && activeDigest[0]}
            shareUrl={activeDigest && activeDigest[0] && (activeDigest[0].source.sl || activeDigest[0].source.sl.link)}
            shareText={activeDigest && activeDigest.share_data && activeDigest.share_data.share_text}
          />
        )}
      </Container>
      {openRefreshSuccessfully && (
        <Snackbar message="Refreshed successfully" />
      )}
    </div>
  );
};

export default MobileNewsDigests;