import React,{useState} from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #000;
  padding: 80px 15px;
`;
const Container = styled.div`
  width: 100%;
`;
// const HeadingWrapper = styled.div`
//   max-width: 800px;
//   margin: 0px auto 50px auto;
//   text-align: center;
//   h2 {
//     font-family: Metropolis;
//     font-size: 32px;
//     line-height: 40px;
//     margin-bottom: 10px;
//     @media (max-width: 992px) {
//       font-size: 36px;
//       line-height: 40px;
//     }
//     @media (max-width: 767px) {
//       font-size: 26px;
//       line-height: 30px;
//     }
//   }
//   P {
//     opacity: 0.6;
//     font-family: Metropolis;
//     font-size: 18px;
//     line-height: 28px;
//     margin-bottom: 20px;
//   }
// `;
const ContentWrapper = styled.div`
  font-family: Metropolis;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const Block = styled.div`
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  vertical-align: top;
  width: 33%;
  @media (max-width: 992px) {
    width: 100%;
    text-align: center;
    margin: 5%;
  }
  img {
    display: inline-block;
    background-position: -465px 0;
    background-size: 1000%;
    height: 80px;
    width: 120px;
  }
  P {
    font-family: Metropolis;
    opacity: 0.6;
    font-size: 16px;
    line-height: 24px;
  }
`;

const MainTitle = styled.div`
  color: #369af8;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5%;
`;

const SubTextDiv = styled.div`
color: #727272;
font-size: 14px;
font-weight: 200;
line-height: 24px;
padding: 0 25%;
}
`;

const AwardSegment = ({ data }) => {
  const [awards] = useState([
    {
      id: 1,
      title: "Users love us",
      subtitle: "One of the highest rated news apps on the Play Store",
      img: "https://dude-hack.com/wp-content/uploads/2020/09/Senior-Dating-Sites-4.6-Star-Rating.png",
      alt_text: "4.6 Rating",
      h_val:75,
      w_val:120
    },
    {
      id: 2,
      title: "‘Rising Star’ app",
      subtitle:
        "Rated by Google Play as a ‘Rising Star’ and a ‘Made in India’ gem to follow.",
      img: "https://helios-i.mashable.com/imagery/articles/04EZglaVzAW19V6FIiDD3TA/images-1.fit_lim.size_376x.png",
      alt_text: "Play store",
      h_val:75,
      w_val:75
    },
    {
      id: 3,
      title: "They trust us",
      subtitle:
        "  Working with Paytm Money and many more prestige partners to deliver customized newsbriefs",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Paytm_Money_Logo.png/800px-Paytm_Money_Logo.png?20180426065129",
      alt_text: "Paytm money",
      h_val:75,
      w_val:170
    },
  ]);
  return (
    <Wrapper>
      <Container>
        {/* <HeadingWrapper>
          <h2>{data ? data.title : ""}</h2>
          <p>{data ? data.subtitle : ""}</p>
        </HeadingWrapper> */}
        <ContentWrapper>
          {awards.map((item) => (
            <Block>
              <img
                style={{ height: item.h_val, width:item.w_val }}
                src={item.img}
                alt={item.alt_text}
              />
              <MainTitle>{item.title}</MainTitle>
              <SubTextDiv>{item.subtitle}</SubTextDiv>
            </Block>
          ))}
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default AwardSegment;
