import React from 'react';
import styled from 'styled-components';

import tndTermsHtml from './tnd_terms';
import undTermsHtml from './und_terms';
import opedTermsHtml from './op_terms';
import clipTermsHtml from './clip_terms';

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const TermsPage = () => {
  let termsHtml = tndTermsHtml;
  if (process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging') {
    termsHtml = undTermsHtml;
  } else if (process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging') {
    termsHtml = opedTermsHtml;
  } else if (process.env.REACT_APP_ENV === 'clip-production' || process.env.REACT_APP_ENV === 'oped-staging') {
    termsHtml = clipTermsHtml;
  }
  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: termsHtml }} />
    </Wrapper>
  );
}

export default TermsPage;