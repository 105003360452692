import React from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { Image } from 'semantic-ui-react';
import ArtcileItem from './';
// import showAllIcon from './showAllIcon.svg';

const Wrapper = styled.div`
  padding: 25px 0 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
`;
// const ShowAllText = styled(Link)`
//   font-size: 12px;
//   line-height: 1.2;
//   font-weight: 600;
//   color: #272727;
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   &:hover {
//     color: #272727;
//   }

//   i {
//     color: #4cb09f;
//   }

//   span {
//     margin-left: 4px;
//   }

//   @media (max-width: 580px) {
//     font-size: 11px;
//   }
// `;

const Title = styled.div`
  font-size: 25px;
  line-height: 1.2;
  color: #272727;
  font-weight: 900;
  margin-bottom: 6px;
  font-family: Metropolis-Black, sans-serif;

  @media (max-width: 768px) {
    font-size: 19px;
    margin-bottom: 0;
  }
  
  // @media (max-width: 580px) {
  //   font-size: 16px;
  // }
`;

const Subtext = styled.div`
  font-size: 15px;
  line-height: 1.2;
  color: #939EAB;
  font-weight: bold;
  margin-bottom: 16px;
  font-family: Metropolis, sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 0;
  }
`;

const ArtcilesList  = styled.div``;

const ListVertical = ({
  data,
  onDigestReadLater,
  onClickShare,
  onClickCategory,
  setPlayingVideo
 }) => {

  // const generateShowAllUrl = () => {
  //   let url = '/'
  //   if (data.config && data.config.click_action === 'PILL') {
  //     url = `/category/${data.config.filter_key_id}?type=${data.config.filter_key}`;
  //   }
    
  //   if (data.config && data.config.click_action === 'FORMAT') {
  //     url = `/format/${data.id}`;
  //   }

  //   return url
  // }
  return (
    <>
      <Wrapper>
        <div>
          <Title>{data.section_name}</Title>
          <Subtext>The top things you must know today</Subtext>
        </div>
        {/* <ShowAllText
          to={generateShowAllUrl()}
          target="_blank"
        >
          {data.top_explore_text}
          <span>
            <Image src={showAllIcon} />
          </span>
        </ShowAllText> */}

      </Wrapper>
      {data.digests && data.digests.length > 0 && (
        <ArtcilesList>
          {data.digests.map(item => (
            <ArtcileItem
              data={item} 
              onDigestReadLater={onDigestReadLater}
              onClickShare={onClickShare}
              onClickCategory={onClickCategory}
              setPlayingVideo={setPlayingVideo}
            />
          ))}
        </ArtcilesList>
      )}
    </>
  );
};

export default ListVertical;