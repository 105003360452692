import React, { useState, useEffect, useRef, useReducer } from "react";
import styled from "styled-components";
import produce from "immer";
// import Slider from "react-slick";
import { Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageHeader from "Components/Header";
import { fetchConfig } from "Api";
//import { fetchCategoriesExplore, fetchConfig } from "Api";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { grey } from "colorPalette";
// import arrowLeft from './arrow_left.svg';
// import arrowRight from './arrow_right.svg';
import { logAmplitudeEvent, setUserProperties } from "Utils/amplitudeLoad";
// import Banner from './Banner';
// import TopDigests from './TopDigests';
import ArticleItem from "./ArticleItem";
import ShareDialogBox from "../Mobile/ArticleItem/ShareDialogBox";
import SideBarTopStories from "./SideBarTopStories";
import MobileTopStories from "./MobileTopStories";
import CategorySlider from "./CategorySlider";
import BottomSheetMenu from "./CategorySlider/BottomSheetMenu";
import ArticleListShimmer from "./ArticleListShimmer";
import MobileSearchPageTab from "./MobileSearchPageTab";
import DigestStreamView from "./DigestStreamView";
import TagsSuggestions from "./SuggestioFilterComponent/TagsSuggestion";
import PublicationsSuggestions from "./SuggestioFilterComponent/PublicationsSuggestions";
import PeopleSuggestions from "./SuggestioFilterComponent/PeopleSuggestions";
import CustomIcon from "Icon";
import DigestFooter from "../DigestFooter";

const MY_FEED_ITEM = {
  id: "myFeed",
  type: "feed",
  title: "MY FEED",
};

const SHOW_MOBILE_TOP_STORIES = [1];
const Wrapper = styled.div`
  /* overflow: auto; */
  /* height: 100vh; */
  /* margin-top: 4px; */
  overflow: hidden;
  font-family: "Metropolis", sans-serif;
  background-color: ${(props) => props.theme.primaryColor.level2};
`;

const Container = styled.div`
  width: 66%;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 20px 0 0;
    border-right: 0;
    width: 100%;
    background-color: ${({ showList }) =>
      showList ? "rgba(0,0,0,0.01)" : "#fff"};
  }
  /* @media (max-width: 1200px) {
    width: 65%;
  }
  
  @media (max-width: 1024px) {
    width: 74%;
    margin: 0 1% 0 5%;
  }
  
  @media (max-width: 870px) {
    width: 95%;
  }
  
  @media (max-width: 500px) {
    width: 100%;
  } */
`;

const SideBarTopStoriesWrapper = styled.div`
  width: 32.33%;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ArticlesListContainer = styled.div`
  /* height: calc(100vh - 160px); */
  /* overflow: auto; */
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  background-color: ${(props) => props.theme.primaryColor.level1};

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  position: ${({ fixed }) => (fixed ? "fixed" : "static")};
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0;
  background-color: ${(props) => props.theme.secondaryColor.level2};
  box-shadow: ${({ fixed }) =>
    fixed ? "0 1px 19px -10px rgba(0,0,0,0.3)" : ""};
  z-index: 10;
  /* padding: 5px 0; */
  // ${({ fixed }) =>
    fixed
      ? `
  //   box-shadow: 0 2px 2px -2px rgba(0,0,0,0.15);
  // `
      : ``}
  /* border-bottom: ${({ fixed }) => (fixed ? 1 : 0)}px solid ${grey.level3}; */

  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }

  .slick-slide {
    outline: none !important;
  }

  .slick-arrow:before {
    display: none;
  }

  .slick-prev,
  .slick-next {
    height: auto;
  }

  .slick-next {
    right: -40px;
  }

  @media (max-width: 768px) {
    padding: 0px 0 5px 0;
  }
`;

const SliderContainer = styled.div`
  // width: calc(80% - 63px);
  margin: 0 auto;
  text-transform: uppercase;
  // max-width: calc(1200px - 63px);

  @media (max-width: 1024px) {
    // width: calc(90% - 63px);
  }
  @media (max-width: 900px) {
    // width: calc(95% - 63px);
  }

  @media (max-width: 768px) {
    // width: calc(100% - 30px);
    /* padding: 0 30px; */
  }
`;

// const SliderItem = styled.div`
//   padding: ${({ active, explore }) => active ? '7px 10px 10px' : explore ? '7px 10px 10px' : '7px 20px 10px'};
//   font-size: ${({ active, explore }) => active ? explore ? '18px' : '14px' : explore ? '16px' : '14px'};
//   line-height: ${({ active, explore }) => active ? explore ? '24px' : '18px' : explore ? '24px' : '18px'};
//   font-weight: ${({ active, explore }) => active ? '700' : explore ? '500' : '500'};
//   font-family: Geneva, Verdana, sans-serif;
//   cursor: pointer;
//   outline: none;
//   letter-spacing: 1.2px;

//   color: ${({ active, explore }) => active ? '#rgba(0,0,0,0.80)' : explore ? '#7F7F7F' :'rgba(0,0,0,0.50)'};

//   &:hover {
//     color: #333;
//     /* font-size: ${({ explore }) => explore ? '20px' : '16px'};; */
//     /* line-height: ${({ explore }) => explore ? '26px' : '20px'}; */
//   }

//   @media (max-width: 768px) {
//     padding: ${({ active, explore }) => active ? '10px 10px 10px' : explore ? '7px 10px 10px' : '10px 10px 10px'};
//     font-size: ${({ active, explore }) => active ? explore ? '18px' : '14px' : explore ? '18px' : '14px'};
//     line-height: ${({ active, explore }) => active ? '18px' : explore ? '22px' : '18px'};
//   }

// `;

// const ExploreSliderItem = styled.div`
//   padding: 7px 0 10px;
//   font-size: ${({ active, explore }) => active ? explore ? '18px' : '14px' : explore ? '16px' : '14px'};
//   line-height: ${({ active, explore }) => active ? explore ? '24px' : '18px' : explore ? '24px' : '18px'};
//   font-weight: ${({ active, explore }) => active ? '700' : explore ? '500' : '700'};
//   cursor: pointer;
//   outline: none;
//   display: flex;
//   align-items: center;
//   color: ${({ active, explore }) => active ? '#rgba(0,0,0,0.80)' : explore ? '#7F7F7F' :'rgba(0,0,0,0.50)'};

//   &:hover {
//     color: #333;
//     /* font-size: ${({ explore }) => explore ? '20px' : '16px'};; */
//     /* line-height: ${({ explore }) => explore ? '26px' : '20px'}; */
//   }

//   @media (max-width: 768px) {
//     padding: 7px 0 10px;
//     font-size: ${({ active, explore }) => active ? explore ? '18px' : '14px' : explore ? '18px' : '14px'};
//     line-height: ${({ active, explore }) => active ? '18px' : explore ? '22px' : '18px'};
//   }

// `;

// const NoDataFound = styled.div`
//   margin-top: 100px;
//   text-align: center;
//   font-size: 16px;
//   font-weight: 900;
//   color: ${grey.level5};
// `;

// const LoaderWrapper = styled.div`
//   margin-top: 50px;
//   position: relative;
//   height: 100vh;
// `;

// const ExplorListWrapper = styled.div`
//   margin-left: 10%;
//   /* margin-top: 30px; */
//   position: fixed;
//   width: 10%;
//   top: ${({ sliderFixed, bannerHeight, hideBanner }) => sliderFixed ? 70 : hideBanner ? 130 : bannerHeight + 130}px;
//   left: 0;
//   z-index: 10;
//   /* padding: 10px; */
//   text-transform: capitalize !important;
//   font-size: 20px;
//   line-height: 30px;
//   background-color: rgba(0, 0, 0, 0.05);

//   @media (max-width: 1200px) {
//     margin-left: 3%;
//     width: 13%;
//   }

//   @media (max-width: 1024px) {
//     display: none;
//   }
// `;

// const ExploreListContainer = styled.div`
//   padding: 10px;
// `;

// const TopicsSoursesWrapper = styled.div`
//   margin-right: 8%;
//   /* margin-top: 30px; */
//   position: fixed;
//   width: 15%;
//   /* top: ${({ sliderFixed, bannerHeight }) => sliderFixed ? 70 : bannerHeight + 130}px; */
//   top: ${({ sliderFixed, bannerHeight, hideBanner }) => sliderFixed ? 70 : hideBanner ? 130 : bannerHeight + 130}px;
//   right: 0;
//   z-index: 10;
//   padding: 10px;

//   @media (max-width: 1200px) {
//     margin-right: 3%;
//     width: 17%;
//   }

//   @media (max-width: 1024px) {
//     display: none;
//   }
// `;

const NoDataFoundWrapper = styled.div`
  height: ${({ headerHeight }) => `calc(100vh - ${headerHeight + 20}px)`};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;

  i {
    color: ${grey.level3} !important;
  }
`;

const NoDataFoundText = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: ${grey.level3};
  margin-top: 15px;
`;

const TryAgainText = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 900;
  color: #0080ff;
  margin-top: 25px;
  cursor: pointer;
`;

// const SearchBarWrapper = styled.div`
//   width: 98%;
//   margin: 0 auto;
//   margin-top: 10px;
//   position: relative;
//   .input {
//     input {
//       padding: 10px 25px 10px 7px !important;
//       font-size: 18px !important;
//       text-overflow: ellipsis;
//     }
//     width: 100%;
//   }
//   .searchIcon {
//     position: absolute;
//     top: 46%;
//     transform: translateY(-50%);
//     right: 2px;

//     i {
//       font-size: 16px !important;
//       color: ${grey.level3} !important;
//     }
//   }
// `;

// const TopicsListWrapper = styled.div`
//   padding: 10px;
//   background-color: rgba(0, 0, 0, 0.05);
//   margin-bottom: 15px;
// `;

// const SourcesListWrapper = styled.div`
//   padding: 10px;
//   background-color: rgba(0, 0, 0, 0.05);
//   margin-bottom: 15px;
// `;

// const TopicsSourcesListContainer = styled.div`
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
// `;

// const FilterPills = styled.div`
//   width: 100%;
//   padding: 5px 15px;
//   border: 1px solid ${grey.level1};
//   font-size: 14px;
//   line-height: 18px;
//   margin: 5px 5px;
//   -webkit-hyphens: auto;
//   -ms-hyphens: auto;
//   hyphens: auto;
//   cursor: pointer;
// `;

// const FiltersTitle = styled.div`
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 20px;
// `;

const SearchPageTopBar = styled.div`
  width: 80%;
  margin: 10px auto;
  max-width: 1200px;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 900px) {
    width: 95%;
  }
`;

const SearchBarWrapper = styled.div`
  width: 100%;
  position: relative;
  input {
    border: 0;
    outline: 0;
    border-bottom: 0px solid #ababab;
    width: 100%;
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    padding: 10px 50px;
    font-family: "Metropolis", sans-serif;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 26px;
      padding: 10px 35px;
    }
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 24px !important;
  opacity: 0.18;

  @media (max-width: 768px) {
    left: 5px;
  }
  svg {
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const SerachClearIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 24px !important;
  opacity: 0.18;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 5px;
  }

  svg {
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const MobileSuggetionsWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const SliderWrapperContainer = styled.div`
  /* margin-bottom: 15px; */
  background-color: #e8ecfc;

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 580px) {
    margin: 0;
  }
`;

const LoaderMoreButton = styled.div`
  font-family: "Metropolis-Bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 15px;
  width: 300px;
  margin: 25px auto;
  background-color: #dc3d4d;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
`;

// const CategoryTitle = styled.div`
//   font-family: 'Metropolis', sans-serif;
//   font-size: 64px;
//   line-height: 66px;
//   font-weight: 800;
//   color: #000000;
//   margin: 15px 10px;

//   width: 80%;
//   margin: 0 auto;
//   max-width: 1200px;

//   @media (max-width: 1024px) {
//     width: 90%;
//   }

//   @media (max-width: 900px) {
//     width: 95%;
//   }

//   @media (max-width: 768px) {
//     /* width: 100%; */
//     font-size: 44px;
//     line-height: 46px;
//   }

// `;

// const FilterPills = styled.div`
//   width: 100%;
//   padding: 5px 15px;
//   border: 1px solid ${grey.level1};
//   font-size: 14px;
//   line-height: 18px;
//   margin: 5px 5px;
//   -webkit-hyphens: auto;
//   -ms-hyphens: auto;
//   hyphens: auto;
//   cursor: pointer;
// `;

// const FiltersTitle = styled.div`
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 20px;
// `;

// const NextPrevIcon = styled.div`
//   cursor: pointer;
//   opacity: .7;
//   &:hover {
//     opacity: 1;
//   }
// `;
/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "SET_EXPLOR_CATEGORIES_DATA":
        draft.exploreData = action.exploreData.map((item) => {
          item.type = "explore";
          return item;
        });
        draft.categoriesListData = action.categoriesData.map((item) => {
          item.type = "category";
          return item;
        });
        draft.categoriesData = action.categoriesData.map((item) => {
          item.type = "category";
          return item;
        });
        break;
      case "SET_CATEGORIE_DATA":
        if (action.value.length === 0) {
          draft.categoriesData = draft.categoriesListData || [];
        } else {
          draft.categoriesData = action.value.map((item) => {
            item.type = "category";
            return item;
          });
        }
        break;
      case "SET_ACTIVE_SLIDER":
        draft.activeSliderItem = action.value;
        break;
      case "SET_SLIDER_FIXED":
        draft.sliderFixed = action.value;
        if (action.value && action.isBannerFalse) {
          draft.hideBanner = true;
        }
        break;
      case "SET_ACTIVE_EXPLORE":
        draft.activeExploreItem = action.value;
        break;
      case "SET_SEARCH_VALUE":
        draft.searchValue = action.value;
        draft.activeSearchTab = "stories";
        break;
      case "SET_SHOW_EXPLORE_LIST":
        draft.showExploreList = action.value;
        break;
      case "SET_ACTIVE_SEARCH_TAB":
        draft.activeSearchTab = action.value;
        break;
      case "SET_TOGGLE_BOTTOM_SHEET_MENU":
        draft.openBottomSheetMenu = action.value;
        break;
    }
  });

const initialState = {
  exploreData: [],
  categoriesData: [],
  categoriesListData: [],
  activeSliderItem: MY_FEED_ITEM,
  sliderFixed: false,
  hideBanner: false,
  activeExploreItem: null,
  searchValue: "",
  showExploreList: false,
  activeSearchTab: "stories",
  openBottomSheetMenu: false,
};

// const checkParentDesc = (child) => {
//   var node = child.parentNode;
//   while (node != null && node.classList) {
//     node.classList.contains('articleDesc');
//     if (node.classList.contains('articleDesc')) {
//       return true;
//     }
//     node = node.parentNode;
//   }
//   return false;
// }

// const fetchParentElem = (child) => {
//   var node = child.parentNode;
//   while (node != null && node.classList) {
//     node.classList.contains('articleDesc');
//     if (node.classList.contains('articleDesc')) {
//       return node;
//     }
//     node = node.parentNode;
//   }
//   return false;
// }

const DesktopNewsDigest = ({
  videoDigestList,
  onDigestBookMark,
  onDigestReadLater,
  onChangePagination,
  activePagination,
  pageSize,
  onSetCategoryFilter,
  loadVideoDigest,
  onClickRefresh,
  exploreId,
  categoryId,
  typeRef,
  handleFetchDigestsList,
  filterSuggestion,
  onTopStoriesReadLater,
  onClickTags,
  selectedTopic,
  topStoriesLoader,
  bannerTopStories,
  sideBarTopStories,
  pathname,
  params,
  onVisitArticle,
  location,
  locationState,
  pillsData,
  disablePagination,
  loadMoreBtn,
  handleLoaderMore,
  theme,
  setTheme,
}) => {
  const history = useHistory();
  const articlesListWrapperRef = useRef(null);
  const articlesContainerRef = useRef(null);
  const sliderWrapperRef = useRef(null);
  const bannerHeightRef = useRef(null);
  const sideBarTopStoriesWrapperRef = useRef(null);
  const digestHeaderRef = useRef(null);
  const videoDigestListRef = useRef([]);
  const activatePagingationRef = useRef(false);
  const searchTimeoutRef = useRef(null);
  const activePaginationRef = useRef(activePagination);
  const [shareArticleData, setShareArticleData] = useState(null);
  const [loadDigestHeight, setLoadDigestHeight] = useState(false);
  const [playingVideoID, setPlayingVideoID] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t: translation } = useTranslation();
  const {
    exploreData,
    categoriesData,
    // activeSliderItem,
    sliderFixed,
    searchValue,
    // hideBanner,
    // activeExploreItem,
    // showExploreList,
    activeSearchTab,
    openBottomSheetMenu,
  } = state;

  // const anchorClick = e => {
  //   e.stopPropagation();
  //   const anchor = e.target.closest("a");
  //   const parentElem = fetchParentElem(anchor);
  //   if (parentElem) {
  //     const id = parentElem.getAttribute('data-digestId');
  //     logAmplitudeEvent('ArticleBodyLink Clicked', {
  //       article_id: id,
  //       link_target: anchor.getAttribute('href'),
  //     });
  //   }
  // }

  // useEffect(() => {
  //   if (window) {
  //     let anchors = document.getElementsByTagName("a");
  //     anchors = Array.prototype.slice.call(anchors);
  //     anchors = anchors.filter(anchor => {
  //       if (checkParentDesc(anchor)) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     anchors.forEach(anchor => {
  //       anchor.setAttribute('target', '_blank');
  //       anchor.addEventListener('click', anchorClick);
  //     });
  //   }
  //   return () => {
  //     if (window) {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       let anchors = document.getElementsByTagName("a");
  //       anchors = Array.prototype.slice.call(anchors);
  //       anchors = anchors.filter(anchor => {
  //         if (checkParentDesc(anchor)) {
  //           return true;
  //         }
  //         return false;
  //       });
  //       anchors.forEach(anchor => {
  //         anchor.removeEventListener('click', anchorClick);
  //       });
  //     }
  //   }
  // });

  useEffect(() => {
    // if (categoryId) {
    //   if (articlesListWrapperRef.current) {
    //     window.scroll(0, articlesListWrapperRef.current.offsetTop - 50);
    //   }
    // } else {
    window.scroll(0, 0);
    // }
  }, [categoryId]);

  useEffect(() => {
    if (pillsData) {
      dispatch({
        type: "SET_CATEGORIE_DATA",
        value: pillsData,
      });
    }
  }, [pillsData]);

  useEffect(() => {
    // setTimeout(() => {
    activatePagingationRef.current = false;
    activePaginationRef.current = activePagination;
    // }, 100);
  }, [activePagination]);

  useEffect(() => {
    videoDigestListRef.current = videoDigestList;
  }, [videoDigestList]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const searchValue = params.get("text");
      dispatch({ type: "SET_SEARCH_VALUE", value: searchValue });
    }
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = (e) => {
    if (sliderWrapperRef.current) {
      const height = sliderWrapperRef.current.offsetTop - window.scrollY;
      bannerHeightRef.current = height;
      setLoadDigestHeight(!loadDigestHeight);
    }
    if (
      sliderWrapperRef.current &&
      sliderWrapperRef.current.offsetTop - window.scrollY <= 0 &&
      !sliderFixed
    ) {
      dispatch({ type: "SET_SLIDER_FIXED", value: true, isBannerFalse: false });
    } else if (
      sliderWrapperRef.current &&
      sliderWrapperRef.current.offsetTop - window.scrollY >= 0 &&
      sliderFixed
    ) {
      dispatch({ type: "SET_SLIDER_FIXED", value: false });
    }
    if (
      window.scrollY + 250 >= document.body.scrollHeight - window.innerHeight &&
      !activatePagingationRef.current
    ) {
      if (
        videoDigestListRef.current.length + 1 >=
        activePaginationRef.current * pageSize
      ) {
        if (!disablePagination && !loadVideoDigest) {
          activatePagingationRef.current = true;
          onChangePagination(activePaginationRef.current + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (window) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (exploreData.length === 0 && categoriesData.length === 0) {
      fetchConfig()
        .then((response) => {
          console.log("fetchConfig RES", response.data);
          var userprop = {};
          if (response.data.testing.experiments) {
            for (const property in response.data.testing.experiments) {
              userprop[property] =
                response.data.testing.experiments[property].case;
            }
          }
          setUserProperties(userprop);
          dispatch({
            type: "SET_EXPLOR_CATEGORIES_DATA",
            exploreData:
              (response.data.explore && response.data.explore.results) || [],
            categoriesData: response.data.dashboard_top_tabs || [],
          });

          setTimeout(() => {
            dispatch({ type: "SET_SHOW_EXPLORE_LIST", value: true });
          }, 2000);
        })
        .catch((error) => {
          console.log("fetchConfig Error", error);
        });
      //=======================
      // fetchCategoriesExplore()
      //   .then((response) => {
      //     var userprop = {};
      //     if (response.data.testing.experiments) {
      //       for (const property in response.data.testing.experiments) {
      //         userprop[property] =
      //           response.data.testing.experiments[property].case;
      //       }
      //     }
      //     setUserProperties(userprop);
      //     dispatch({
      //       type: "SET_EXPLOR_CATEGORIES_DATA",
      //       exploreData:
      //         (response.data.explore && response.data.explore.results) || [],
      //       categoriesData: response.data.results || [],
      //     });

      //     setTimeout(() => {
      //       dispatch({ type: "SET_SHOW_EXPLORE_LIST", value: true });
      //     }, 2000);
      //   })
      //   .catch((error) => {
      //     if (window && window.Raven)
      //       window.Raven.captureException(error.response);
      //   });
    }
  }, [categoriesData,exploreData]);

  useEffect(() => {
    if (selectedTopic) {
      dispatch({ type: "SET_SEARCH_VALUE", value: selectedTopic.title });
    }
  }, [selectedTopic]);

  const onClickSearchSideBar = ({ text, isTagData, data }) => {
    history.push(`/search?text=${text}&topic=${isTagData ? data.slug : ""}`);
    dispatch({ type: "SET_ACTIVE_SEARCH_TAB", value: "stories" });
    handleFetchDigestsList({
      searchValue: isTagData ? null : text,
      topic: data,
      isClearSearchData: true,
      isTagData,
    });
  };
  const myFeedItem = [];
  let sliderData = [];
  if (categoriesData.length > 0) {
    sliderData = myFeedItem.concat(categoriesData);
  }
  // const camelize = (str) => {
  //   return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
  //     if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
  //     return index === 0 ? match.toLowerCase() : match.toUpperCase();
  //   });
  // }

  const isSearchPage = pathname.includes("search");
  let tagDetail = locationState && locationState.tagData;
  if (!tagDetail && pathname.includes("tags")) {
    tagDetail = {
      slug: params.tagSlug,
      is_stream: true,
    };
  }
  let isShowSearchOption = true;
  if (
    locationState &&
    locationState.tagData &&
    locationState.tagData.is_stream
  ) {
    isShowSearchOption = false;
  }

  if (pathname.includes("search")) {
    isShowSearchOption = false;
  }

  let selectedCategory = null;
  if (categoryId && categoriesData) {
    categoriesData.forEach((item) => {
      if (item.slug === categoryId) {
        selectedCategory = item;
      }
    });
  }
  let selectedExplore = null;
  if (exploreId && exploreData) {
    exploreData.forEach((item) => {
      if (item.slug === exploreId) {
        selectedExplore = item;
      }
    });
  }

  // const onClickHeroDigest = (data) => {
  //   logAmplitudeEvent('HeroDigest Clicked', {
  //     article_id: data.id,
  //     hero_category: selectedCategory ? selectedCategory.title : selectedExplore ? selectedExplore.title : 'feed',
  //     slug: data.slug,
  //     category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
  //   });
  // }

  const onClickSideBarDigest = (data) => {
    logAmplitudeEvent("SidebarDigest Clicked", {
      article_id: data.id,
      hero_category: selectedCategory
        ? selectedCategory.title
        : selectedExplore
        ? selectedExplore.title
        : "feed",
      slug: data.slug,
      category_name:
        data && data.categories
          ? data.categories.map((item) => item.title).join(",")
          : "",
    });
  };
  const onClickDigestsCategory = (item) => {
    dispatch({ type: "SET_ACTIVE_SLIDER", value: item });
    dispatch({ type: "SET_ACTIVE_EXPLORE", value: null });
    onSetCategoryFilter(item);
    logAmplitudeEvent("Category Selected", {
      category_id: item.id,
      category_name: item.title,
    });
    // history.push(`/category/${item.slug}`);
  };

  let UrlSearchValue = null;
  if (location.search) {
    const params = new URLSearchParams(location.search);
    UrlSearchValue = params.get("text");
  }

  useEffect(() => {
    if (openBottomSheetMenu) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  });
  return (
    <div>
      <PageHeader
        onLogout={() => {
          history.push("/category/feed");
        }}
        headerRef={digestHeaderRef}
        isLoadedExploreList
        exploreData={exploreData}
        pathname={pathname}
        params={params}
        searchValue={searchValue}
        handleFetchDigestsList={handleFetchDigestsList}
        setSearchValue={(value) =>
          dispatch({ type: "SET_SEARCH_VALUE", value })
        }
        showSearchBar={isShowSearchOption}
        onSetCategoryFilter={onSetCategoryFilter}
        handleMenuClick={() =>
          dispatch({ type: "SET_TOGGLE_BOTTOM_SHEET_MENU", value: true })
        }
        theme={theme}
        setTheme={setTheme}
      />
      {sliderData && sliderData.length > 0 && (
        <BottomSheetMenu
          open={openBottomSheetMenu}
          onDismiss={() => {
            dispatch({ type: "SET_TOGGLE_BOTTOM_SHEET_MENU", value: false });
          }}
          sliderData={sliderData}
          categoryId={categoryId}
          onClickItem={onClickDigestsCategory}
        />
      )}
      {tagDetail && tagDetail.is_stream ? (
        <DigestStreamView
          data={videoDigestList}
          tagDetail={tagDetail}
          onDigestReadLater={onDigestReadLater}
          onDigestBookMark={onDigestBookMark}
          onClickShare={(data) => setShareArticleData(data)}
          loadVideoDigest={loadVideoDigest}
        />
      ) : (
        <Wrapper>
          {/* <div>{translation('page1.Welcome to React')}</div> */}
          {/* <Banner 
            hide={hideBanner}
            onClickReadMore={() => {
              if(sliderWrapperRef && sliderWrapperRef.current) {
                window.scrollTo(0, sliderWrapperRef.current.offsetTop)
              }
            }}
          /> */}
          {/* {!isSearchPage && (
            <TopDigests 
              topStoriesLoader={topStoriesLoader}
              // sideBarTopStories={sideBarTopStories}
              bannerTopStories={bannerTopStories}
              onClickShare={data => setShareArticleData(data)}
              onClickCategory={onClickDigestsCategory}
            />
          )} */}
          {isSearchPage && (
            <SearchPageTopBar>
              <SearchBarWrapper>
                <SearchIconWrapper>
                  <CustomIcon name="search" size={25} />
                </SearchIconWrapper>
                <input
                  placeholder="Search..."
                  onChange={(e) => {
                    clearTimeout(searchTimeoutRef.current);
                    const value = e.target.value;
                    searchTimeoutRef.current = setTimeout(() => {
                      if (value.trim()) {
                        history.replace(`/search?text=${value}`);
                        handleFetchDigestsList({
                          searchValue: value,
                          isClearSearch: !value.trim(),
                        });
                      } else {
                        history.replace(`/search?text=`);
                        handleFetchDigestsList({
                          searchValue: null,
                          isClearSearch: true,
                        });
                      }
                    }, 2000);
                    dispatch({
                      type: "SET_SEARCH_VALUE",
                      value: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    if (
                      e.which === 13 &&
                      searchValue &&
                      searchValue.trim() !== ""
                    ) {
                      clearTimeout(searchTimeoutRef.current);
                      e.target.blur();
                      if (e.target.value.trim()) {
                        history.replace(`/search?text=${e.target.value}`);
                        handleFetchDigestsList({
                          searchValue: e.target.value,
                          isClearSearch: !e.target.value.trim(),
                        });
                      } else {
                        history.replace(`/search?text=`);
                        handleFetchDigestsList({
                          searchValue: null,
                          isClearSearch: true,
                        });
                      }
                    }
                  }}
                  value={searchValue}
                />
                {searchValue && searchValue.trim() !== "" && (
                  <SerachClearIcon
                    onClick={() => {
                      history.replace(`/search?text=`);
                      handleFetchDigestsList({
                        searchValue: null,
                        isClearSearch: true,
                      });
                      dispatch({ type: "SET_SEARCH_VALUE", value: null });
                    }}
                  >
                    <CustomIcon name="searchClear" size={25} />
                  </SerachClearIcon>
                )}
              </SearchBarWrapper>
              {searchValue && window.innerWidth <= 768 && (
                <MobileSearchPageTab
                  filterSuggestion={filterSuggestion}
                  activeSearchTab={activeSearchTab}
                  onChange={(value) =>
                    dispatch({ type: "SET_ACTIVE_SEARCH_TAB", value })
                  }
                />
              )}
            </SearchPageTopBar>
          )}
          {!isSearchPage && (
            <SliderWrapperContainer
              ref={(a) => {
                if (!a) return;
                const sliderWrapper = a.querySelector(".slider-wrapper");
                if (sliderWrapper) {
                  a.style.height = `${sliderWrapper.offsetHeight}px`;
                }
                sliderWrapperRef.current = a;
              }}
            >
              <SliderWrapper fixed={sliderFixed} className="slider-wrapper">
                <SliderContainer>
                  <CategorySlider
                    sliderData={sliderData}
                    categoryId={categoryId}
                    onClickItem={onClickDigestsCategory}
                  />
                  {/* <Slider {...sliderSettings}>
                    {sliderData.map(item => (
                      <SliderItem
                        active={categoryId === item.slug}
                        onClick={() => {
                          dispatch({ type: 'SET_ACTIVE_SLIDER', value: item });
                          dispatch({ type: 'SET_ACTIVE_EXPLORE', value: null });
                          onSetCategoryFilter(item);
                          logAmplitudeEvent('Category Selected', {
                            category_id: item.id,
                            category_name: item.title
                          });
                          history.push(`/digests/category/${item.slug}`);
                        }}
                      >
                        {item.title}
                      </SliderItem>
                    ))}
                  </Slider> */}
                </SliderContainer>
              </SliderWrapper>
            </SliderWrapperContainer>
          )}
          {/* {!isSearchPage && (
              <TopDigests
                topStoriesLoader={topStoriesLoader}
                onTopStoriesReadLater={onTopStoriesReadLater}
                // sideBarTopStories={sideBarTopStories}
                bannerTopStories={bannerTopStories}
                selectedCategory={selectedCategory}
                selectedExplore={selectedExplore}
                onClickShare={data => setShareArticleData(data)}
                onClickCategory={onClickDigestsCategory}
                onClickDigest={onClickHeroDigest}
              />
            )} */}
          {/* {!isSearchPage && (
              <CategoryTitle>{selectedExplore ? selectedExplore.title : selectedCategory && selectedCategory.title}</CategoryTitle>
            )} */}
          <ArticlesListContainer ref={articlesListWrapperRef}>
            <Container
              ref={articlesContainerRef}
              showList={videoDigestList.length > 0}
            >
              {activeSearchTab === "stories" && (
                <>
                  {videoDigestList &&
                    videoDigestList.length > 0 &&
                    videoDigestList.map((item, index) => (
                      <div key={item.id}>
                        <div>
                          <ArticleItem
                            data={item}
                            itemIndex={index}
                            onVisitArticle={onVisitArticle}
                            onClickTags={onClickTags}
                            onDigestBookMark={onDigestBookMark}
                            onDigestReadLater={onDigestReadLater}
                            onClickShare={(data) => setShareArticleData(data)}
                            onClickCategory={onClickDigestsCategory}
                            setPlayingVideo={(id) => setPlayingVideoID(id)}
                            playingVideoID={playingVideoID}
                            theme={theme}
                          />
                        </div>
                        {SHOW_MOBILE_TOP_STORIES.indexOf(index) > -1 && (
                          <MobileTopStories
                            sideBarTopStories={sideBarTopStories}
                            onClickDigest={onClickSideBarDigest}
                          />
                        )}
                      </div>
                    ))}
                  {!!loadMoreBtn && (
                    <>
                      <LoaderMoreButton onClick={handleLoaderMore}>
                        {loadMoreBtn || "See previous day's briefing"}
                      </LoaderMoreButton>
                      <DigestFooter isLoadMoreFooter />
                    </>
                  )}
                  {/* {!isSearchPage && videoDigestList && videoDigestList.length === 0 && searchValue && !loadVideoDigest && (
                  <NoDataFoundWrapper
                    headerHeight={(digestHeaderRef.current && digestHeaderRef.current.offsetHeight) || 0}
                  >
                    <Icon name="warning sign" size="massive" />
                    <NoDataFoundText>No Data</NoDataFoundText>
                    {!isSearchPage && (
                      <TryAgainText
                        onClick={onClickRefresh}
                      >Try Again</TryAgainText>
                    )}
                  </NoDataFoundWrapper>
                )} */}
                  {videoDigestList &&
                    videoDigestList.length === 0 &&
                    !loadVideoDigest &&
                    (!isSearchPage ||
                      (isSearchPage &&
                        UrlSearchValue &&
                        UrlSearchValue.trim !== "")) && (
                      <NoDataFoundWrapper
                        headerHeight={
                          (digestHeaderRef.current &&
                            digestHeaderRef.current.offsetHeight) ||
                          0
                        }
                      >
                        <Icon name="warning sign" size="massive" />
                        <NoDataFoundText>
                          {translation("digestFeed.noData")}
                        </NoDataFoundText>
                        {!isSearchPage && (
                          <TryAgainText onClick={onClickRefresh}>
                            {translation("digestFeed.tryAgain")}
                          </TryAgainText>
                        )}
                      </NoDataFoundWrapper>
                    )}
                  {/* {loadVideoDigest && videoDigestList.length > 0 && ( */}
                  {!disablePagination && (
                    <ArticleListShimmer theme={theme} innerLoader />
                  )}
                  {/* )} */}
                  {loadVideoDigest && videoDigestList.length === 0 && (
                    <ArticleListShimmer theme={theme} />
                  )}
                </>
              )}
              {/* {!loadMoreBtn && !loadVideoDigest && disablePagination && (
                  <DigestFooter isLoadMoreFooter />
                )} */}
              <MobileSuggetionsWrapper>
                {isSearchPage &&
                  activeSearchTab === "tags" &&
                  filterSuggestion &&
                  filterSuggestion.tags &&
                  filterSuggestion.tags.results && (
                    <TagsSuggestions
                      data={filterSuggestion.tags.results}
                      onClickItem={onClickSearchSideBar}
                    />
                  )}
                {isSearchPage &&
                  activeSearchTab === "publications" &&
                  filterSuggestion &&
                  filterSuggestion.sources &&
                  filterSuggestion.sources.results && (
                    <PublicationsSuggestions
                      data={filterSuggestion.sources.results}
                      onClickItem={onClickSearchSideBar}
                    />
                  )}
                {isSearchPage &&
                  activeSearchTab === "people" &&
                  filterSuggestion &&
                  filterSuggestion.people &&
                  filterSuggestion.people.results && (
                    <PeopleSuggestions
                      data={filterSuggestion.people.results}
                      onClickItem={onClickSearchSideBar}
                    />
                  )}
              </MobileSuggetionsWrapper>
              {/* {!loadVideoDigest && videoDigestList.length === 0 && (
              <NoDataFound>
                No data found
              </NoDataFound>
            )} */}
            </Container>
            {/* )} */}
            <SideBarTopStoriesWrapper ref={sideBarTopStoriesWrapperRef}>
              <SideBarTopStories
                listViewHeight={
                  (articlesContainerRef.current &&
                    articlesContainerRef.current.offsetHeight) ||
                  0
                }
                headerHeight={
                  (digestHeaderRef.current &&
                    digestHeaderRef.current.offsetHeight) ||
                  0
                }
                sliderFixed={sliderFixed}
                sideBarWidth={
                  sideBarTopStoriesWrapperRef.current &&
                  sideBarTopStoriesWrapperRef.current.offsetWidth
                }
                sideBarLeftPos={
                  sideBarTopStoriesWrapperRef.current &&
                  sideBarTopStoriesWrapperRef.current.offsetLeft
                }
                sideBarTopStories={sideBarTopStories}
                sideBarRef={sideBarTopStoriesWrapperRef}
                filterSuggestion={filterSuggestion}
                isSearchPage={isSearchPage}
                onClickSearchSideBar={onClickSearchSideBar}
                onClickDigest={onClickSideBarDigest}
              />
            </SideBarTopStoriesWrapper>
          </ArticlesListContainer>
        </Wrapper>
      )}
      {/* {exploreData && exploreData.length > 0 && showExploreList && (
        <ExplorListWrapper
          sliderFixed={sliderFixed}
          bannerHeight={(bannerHeightRef.current) || (sliderWrapperRef.current && sliderWrapperRef.current.offsetTop - window.scrollY) || 0}
          hideBanner={hideBanner}
        >
          <SearchBarWrapper>
            <Input
              placeholder="Search here..."
              onChange={e => {
                clearTimeout(searchTimeoutRef.current);
                const value = e.target.value;
                searchTimeoutRef.current = setTimeout(() => {
                  handleFetchDigestsList({ searchValue: value });
                }, 2000);
                dispatch({ type: 'SET_SEARCH_VALUE', value: e.target.value });
              }}
              onKeyUp={e => {
                if (e.which === 13 && searchValue && searchValue.trim() !== "") {
                  clearTimeout(searchTimeoutRef.current);
                  e.target.blur();
                  handleFetchDigestsList({ searchValue: e.target.value });
                }
              }}
              value={searchValue}
            />
            <div 
              className="searchIcon"
              onClick={() => {
                clearTimeout(searchTimeoutRef.current);
                handleFetchDigestsList({ searchValue });
              }}
            >
              <Icon name="search" />
            </div>
          </SearchBarWrapper>
          <ExploreListContainer>
            {exploreData.map(item => (
              <ExploreSliderItem
                // active={activeExploreItem && activeExploreItem.id === item.id}
                active={exploreId === item.slug}
                onClick={() => {
                  dispatch({ type: 'SET_ACTIVE_EXPLORE', value: item });
                  dispatch({ type: 'SET_ACTIVE_SLIDER', value: null });
                  onSetCategoryFilter(item);
                  logAmplitudeEvent('Explore Selected', {
                    explore_id: item.id,
                    explore_name: item.title
                  });
                  history.push(`/digests/explore/${item.slug}`);
                }}
                explore
              >
                {item.title}
              </ExploreSliderItem>
            ))}
          </ExploreListContainer>
        </ExplorListWrapper>
      )} */}

      {/* <TopicsSoursesWrapper
        sliderFixed={sliderFixed}
        bannerHeight={(bannerHeightRef.current) || (sliderWrapperRef.current && sliderWrapperRef.current.offsetTop - window.scrollY) || 0}
      >
        {digestsFilterTopics && digestsFilterTopics.length > 0 && (
          <TopicsListWrapper>
            <FiltersTitle>Topics</FiltersTitle>
            <TopicsSourcesListContainer>
              {digestsFilterTopics.map(filter => (
                <FilterPills
                  key={filter.id}
                  onClick={() => {
                    dispatch({ type: 'SET_SEARCH_VALUE', value: filter.title });
                    handleFetchDigestsList({ topic: filter });
                  }}
                  ref={a => {
                    if(a) {
                      const height = a.offsetHeight;
                      a.style.borderRadius = `${height/2}px`;
                    }
                  }}
                >{filter.title}</FilterPills>
              ))}
            </TopicsSourcesListContainer>
          </TopicsListWrapper>
        )}
        {digestsFilterSources && digestsFilterSources.length > 0 && (
          <SourcesListWrapper>
            <FiltersTitle>Sources</FiltersTitle>
            <TopicsSourcesListContainer>
              {digestsFilterSources.map(filter => (
                <FilterPills
                  key={filter.id}
                  onClick={() => {
                    dispatch({ type: 'SET_SEARCH_VALUE', value: filter.title });
                    handleFetchDigestsList({ source: filter });
                  }}
                  ref={a => {
                    if(a) {
                      const height = a.offsetHeight;
                      a.style.borderRadius = `${height / 2}px`;
                    }
                  }}
                >{filter.brand_name}</FilterPills>
              ))}
            </TopicsSourcesListContainer>
          </SourcesListWrapper>
        )}

      </TopicsSoursesWrapper> */}
      {/* {!!shareArticleData && ( */}
      <ShareDialogBox
        onClose={() => {
          setShareArticleData(null);
        }}
        open={!!shareArticleData}
        data={shareArticleData}
        shareUrl={
          shareArticleData &&
          (shareArticleData.source.sl || shareArticleData.source.link)
        }
        isDesktop
        shareText={
          shareArticleData &&
          shareArticleData.share_data &&
          shareArticleData.share_data.share_text
        }
      />
      {/* )} */}
    </div>
  );
};

export default DesktopNewsDigest;
