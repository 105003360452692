import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
// import sanitizeHtml from 'sanitize-html';
import VisibilitySensor from "react-visibility-sensor";
import DeeperButton from "../DeeperButton";
import { grey } from "colorPalette";
import FsLightbox from "fslightbox-react";
import { DigestAuthorInfo, DigestPublishInfo } from "../CommonComponents";
import { ApplyExpandablePoints, addHtmlElement } from "Utils/ExpandablePoints";
import RenderCollectionDigest from "../DesktopDigestItem/RenderCollectionDigest";
import MessageView from "./MessageView";
import ListVertical from "./ListVertical";
import ListHorizontal from "./ListHorizontal";
import CustomIcon from "Icon";
import { isMobile } from "react-device-detect";

const AmplitudePageType = "HomePage";

const Wrapper = styled(Link)`
  display: flex;
  padding: 0px 15px 0 0;
  min-height: 150px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 870px) {
    padding: ${({ lowpaddingtop }) => (lowpaddingtop ? 10 : 25)}px 10px 0 0;
  }
  @media (max-width: 768px) {
    padding: 0;
    width: calc(100% - 30px);
    margin: 10px auto;
  }
`;

const SectionName = styled.div`
  margin-top: ${({ firstCard }) => (firstCard ? 0 : 35)}px;
  margin-bottom: 5px;
  font-size: ${({ firstCard }) => (firstCard ? 29 : 24)}px;
  line-height: 1.2;
  font-family: Metropolis-Black, sans-serif;
  font-weight: 900;
  color: ${(props) => props.theme.fontColor.level2};
  letter-spacing: -0.5px;

  @media (max-width: 768px) {
    font-size: 22px;
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: ${({ firstCard }) => (firstCard ? 0 : 25)}px;
    margin-bottom: 5px;
  }

  @media (max-width: 580px) {
    font-size: 20px;
  }
`;

const SubSectionName = styled.div`
  margin-top: 5px;
  margin-bottom: 23px;
  font-family: Metropolis, sans-serif;
  font-weight: 300;
  color: rgba(${(props) => props.theme.fontColor.level2RGB}, 0.5);
  font-size: 16px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 14px;
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-bottom: 15px;
  }

  @media (max-width: 580px) {
    font-size: 14px;
  }
`;

const Container = styled.div`
  display: ${({ isFocusCover }) => (isFocusCover ? "block" : "block")};
  @media (max-width: 768px) {
    /* flex-direction: column-reverse; */
  }
`;

const OuterContainer = styled.div`
  border: 1px solid #f2f2f2;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0 ${(props) => props.theme.misc.main4};
  width: 100%;
  background-color: ${(props) => props.theme.secondaryColor.level1};
  @media (max-width: 870px) {
    padding-bottom: 25px;
  }
  @media (max-width: 768px) {
    padding: 10px 15px;
    flex-direction: column-reverse;
    box-shadow: 0 2px 16px 0 ${(props) => props.theme.misc.main4};
    border-radius: 10px;
  }
`;

const ArticleContentWrapper = styled.div`
  /* width: ${({ disableCoverPic, isFocusCover }) =>
    disableCoverPic || isFocusCover ? 100 : 63}%; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  padding: 5px 20px 10px 0;

  @media (max-width: 870px) {
    /* width: ${({ disableCoverPic }) => (disableCoverPic ? 100 : 65)}%; */
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px 0;
  }
`;
const ArticleImageWrapper = styled.div`
  width: 100%;
  max-height: ${({ isFocusCover }) => (isFocusCover ? "unset" : "200px")};
  position: relative;
  /* background: ${grey.level3} url(${({ bgImg }) => bgImg}); */
  /* background-size: 100% 100%; */
  /* background-position: 50% 50%; */
  /* background-repeat: no-repeat; */

  margin: ${({ isFocusCover }) => (isFocusCover ? "10px 0" : 0)};
  img {
    border-radius: 4px;
    width: 100%;
    /* height: 100%; */
  }
  @media (max-width: 768px) {
    /* margin-left: ${({ isFocusCover }) => (isFocusCover ? "0" : "10%")}; */
    /* height: 100px; */
    /* width: ${({ isFocusCover }) => (isFocusCover ? "100%" : "30%")}; */
    display: inline-block;
    /* vertical-align: top; */
  }
`;

const ArticleImageContainer = styled.div`
  margin: 5px 0px 20px 0;
  width: ${({ isFocusCover }) => (isFocusCover ? "100%" : "30%")};
  margin-left: ${({ isFocusCover }) => (isFocusCover ? 0 : "7%")};
  display: ${({ disableCoverPic }) => (disableCoverPic ? "none" : "block")};
  float: right;
  margin-bottom: ${({ isFocusCover }) => (isFocusCover ? 0 : 15)}px;

  @media (max-width: 768px) {
    /* display: block;
    height: auto; */
    /* width: 100%; */
    /* justify-content: space-between;
    margin-left: 0;
    margin: 0; */
    margin-bottom: 10px;
  }
`;

const ArticleTitle = styled.div`
  font-size: 19px;
  line-height: 1.3;
  font-family: Metropolis-Bold, sans-serif;
  color: rgba(${(props) => props.theme.fontColor.level2RGB}, 0.8);
  letter-spacing: -0.1px;

  @media (max-width: 500px) {
    font-size: 16px;
  }

  span {
    color: #e02020;
  }
`;

// const MobileViewArticleTitle = styled.div`
//   width: ${({ disableCoverPic }) => disableCoverPic ? '100%' : '60%'};
//   display: inline-block;
//   vertical-align: top;
// `;

const ArticleDesc = styled.div`
  font-size: 15px;
  line-height: 1.8;
  margin: 10px 0;
  color: ${({ isExpanded }) =>
    isExpanded
      ? (props) => props.theme.fontColor.level2
      : (props) => props.theme.fontColor.level1};
  font-family: "Merriweather", serif;
  word-break: break-word;
  hyphens: auto;

  .expandable-button {
    font-family: "Metropolis", sans-serif;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 580px) {
    font-size: 14px;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 10px;
    min-height: 10px;
    line-height: 1.8;
  }

  ul,
  ol {
    margin: 10px 0;
  }

  li {
    margin-bottom: 5px;
  }

  @media (max-width: 500px) {
    font-size: 14px;

    ul,
    ol {
      margin: 5px 0;
      padding-left: 20px;
    }
  }

  blockquote {
    margin: 15px 0;
    display: block;
    font-style: italic;
    position: relative;
    &:before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: #333;
      position: absolute;
      top: 0;
      left: -15px;
    }

    @media (max-width: 768px) {
      margin: 10px 0;
      &:before {
        left: -10px;
      }
    }
  }
  figure {
    text-align: center;

    img {
      width: 80% !important;
    }
  }

  a {
    color: #e12830;
  }
  .twitter-tweet {
    margin: 10px auto;
  }
  .twitter-tweet-rendered {
    margin: 10px auto;
  }
`;

const TagWrapper = styled(Link)`
  display: inline-block;
  /* padding: 0 0 7px 0; */
  border-radius: 2px;
  color: #616576;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  /* margin: 5px; */
  font-family: Metropolis, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  /* margin: 6px 6px 0px 0px; */
  &:hover {
    color: #939eab;
  }
  span {
    color: #616576;
  }

  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 10px;
    /* padding: 0 0 7px 0; */
  }
`;

const TagsListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  display: inline-block;
  padding: 0 0 13px 0;
  border-radius: 2px;
  color: #616576;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  /* margin: 5px; */
  font-family: Metropolis, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  /* margin: 6px 6px 0px 0px; */
  &:hover {
    color: #939eab;
  }
  span {
    color: #616576;
  }

  .divider {
    width: 3px;
    height: 3px;
    background-color: #616576;
    border-radius: 50%;
    margin: 0 6px;
    vertical-align: middle;
    display: inline-block;
  }
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 10px;
    padding: 0 0 7px 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const DigestPublishInfoWrapper = styled.div`
  /* margin-top: 12px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: 580px) {
    /* flex-direction: row; */
  }
`;

const DigestPublishInfoContainer = styled.div`
  flex: 1;
`;

const DeeperButtonWrapper = styled.div`
  width: 100px;

  @media (max-width: 580px) {
    /* display: none; */
    width: 80px;
  }
`;

const VideoContainer = styled.div`
  max-height: 200px;
  width: 100%;
  @media (max-width: 760px) {
    height: ${({ toggler }) => (toggler ? "100%" : "200px")};

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 560px) {
    ${({ toggler }) => (toggler ? "100%" : "60px")};

    iframe {
      max-height: ${({ toggler }) => (toggler ? "100%" : "200px")};
    }
  }

  > div {
    margin: 0 auto;
  }
  position: relative;

  img {
    border-radius: 4px;
  }
`;

const VideoInfoWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  padding: 3px 5px 3px 8px;
  font-size: 11px;
  color: #fff;
  background-color: #000;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: 700;
`;

const Lightbox = styled(FsLightbox)``;

// const DigestCreatedByInfoWrapper = styled.div`
// `;

const ArticleItem = ({
  data,
  onDigestReadLater,
  onClickShare,
  onClickCategory,
  setPlayingVideo,
  playingVideoID,
  itemIndex,
  isRelatedDigest,
  theme,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [digestDescRef, setDigestDesc] = useState(null);
  const history = useHistory();
  const [toggler, setToggler] = useState(false);
  const [toggleClose, setToggleClose] = useState(true);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    if (data && data.digest) {
      const callback = async () => {
        setDigestDesc(addHtmlElement(data.digest));
      };
      callback();
    }
  }, [data]);

  const applyStopPropagationAnchor = (a) => {
    if (!a) return;

    const anchors = a.querySelectorAll("a");
    if (anchors && anchors.length > 0) {
      anchors.forEach((elm) => {
        elm.setAttribute("target", "_blank");
        elm.onclick = (e) => {
          e.stopPropagation();
        };
      });
    }
  };

  const collectionDigestsBody = [];
  let count = 0;
  if (data && digestDescRef && data.type.code === "300") {
    const regex = /\[@([\w-]+)]\((\d+)\)/gm;
    let m;
    const matchArray = [];
    while ((m = regex.exec(digestDescRef)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      const matchObject = {
        digestTitle: m[0],
        slug: m[1],
        id: m[2],
        index: m.index,
      };
      matchArray.push(matchObject);
    }

    if (matchArray && matchArray.length > 0) {
      matchArray.forEach((item, index) => {
        if (index === 0) {
          const str = digestDescRef.substring(0, item.index);
          const obj = {
            str,
            isCollectionDigest: false,
          };
          collectionDigestsBody.push(obj);
        } else {
          const prevItem = matchArray[index - 1];
          const prevItemEndIndex = prevItem.index + prevItem.digestTitle.length;
          if (item.index > prevItemEndIndex) {
            const str = digestDescRef.substring(prevItemEndIndex, item.index);
            const obj = {
              str,
              isCollectionDigest: false,
            };
            collectionDigestsBody.push(obj);
          }
        }
        collectionDigestsBody.push({
          ...item,
          index: count,
          isCollectionDigest: true,
        });

        if (index === matchArray.length - 1) {
          const itemEndIndex = item.index + item.digestTitle.length;
          const str = digestDescRef.substring(itemEndIndex);
          if (str && str.trim() !== "") {
            const obj = {
              str,
              isCollectionDigest: false,
            };
            collectionDigestsBody.push(obj);
          }
        }

        count++;
      });
    }
  }

  useEffect(() => {
    if (playingVideoID && playingVideoID !== data.id && videoRef.current) {
      videoRef.current.pauseVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingVideoID]);

  // const createYTRef = useCallback(() => {
  //   const validUrl = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  //   if (data.cover_pic.match(validUrl)) {
  //     let video_id = data.cover_pic.match(validUrl)[1];
  //     videoRef.current = new window.YT.Player(`youtubePlayer-${data.id}`, {
  //       videoId: video_id,
  //       height: '100%',
  //       width: '100%',
  //       allowfullscreen: true,
  //       playerVars: {
  //         fs: 0,
  //       },
  //       events: {
  //         onReady: value => {
  //         },
  //       },
  //     });
  //     if (videoRef.current) {
  //       videoRef.current.addEventListener("onStateChange", (state) => {
  //         if (state.data === 1) {
  //           setPlayingVideo(data.id);
  //         }
  //       });
  //     }
  //   }
  // }, [data, setPlayingVideo]);

  const loadVideo = useCallback(() => {
    if (data.cover_pic && !videoRef.current && window.YT) {
      if (typeof window.YT.Player === "undefined") {
        window.onYouTubeIframeAPIReady = () => {
          // createYTRef();
        };
      } else {
        // createYTRef();
      }
    }
  }, [data.cover_pic]);

  useEffect(() => {
    if (
      data &&
      data.cover_pic &&
      data.cover_pic.includes("https://www.youtube.com/watch?v") &&
      !videoRef.current
    ) {
      loadVideo();
    }
  }, [data, loadVideo]);

  let digestDesc = isExpanded
    ? digestDescRef || ""
    : (data && data.digest_preview) || "";
  let isFocusCover = data.is_cover_pic_focused;
  if (!isFocusCover && isMobile && data.cover_pic) {
    isFocusCover = data.cover_pic.includes("https://www.youtube.com/watch?v");
  }
  const isExpand2Click =
    data.click_action && data.click_action.action === "EXPAND2";

  const updateHtml = useCallback(() => {
    const container2 = document.querySelector(`#render-html-${data.id}`);
    if (!container2) {
      setTimeout(() => {
        updateHtml();
      }, 200);
    } else {
      container2.innerHTML = "";

      const range = document.createRange();
      range.setStart(container2, 0);
      container2.appendChild(
        range.createContextualFragment(
          isExpanded ? digestDescRef || "" : (data && data.digest_preview) || ""
        )
      );
    }
  }, [data, digestDescRef, isExpanded]);
  useEffect(() => {
    updateHtml();
  }, [updateHtml, isExpanded]);

  const validUrl =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  let video_id =
    data.cover_pic &&
    data.cover_pic.match(validUrl) &&
    data.cover_pic.match(validUrl)[1];

  const showDate =
    data.first_published_at_human &&
    data.feed_visible_fields &&
    data.feed_visible_fields.first_published_at;
  return (
    <>
      {data &&
        (data.design_type === "DIGEST" || !data.design_type) &&
        !(data.feedback && data.feedback.question) && (
          <>
            {data && data.section_name && (
              <SectionName firstCard={itemIndex === 0}>
                {data && data.section_name}
              </SectionName>
            )}
            {data && data.subsection_name && (
              <SubSectionName>{data && data.subsection_name}</SubSectionName>
            )}
            <Wrapper
              key={data.id}
              to={`/digests/${data.slug}`}
              lowpaddingtop={data && data.section_name}
              onClick={(e) => {
                if (toggleClose) {
                  if (isRelatedDigest) {
                    window.scroll(0, 0);
                  } else {
                    if (isExpand2Click) {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsExpanded(!isExpanded);
                    } else {
                      window.scroll(0, 0);
                    }
                  }
                } else {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <VisibilitySensor partialVisibility>
                <OuterContainer>
                  <Container isFocusCover={isFocusCover}>
                    <ArticleImageContainer
                      disableCoverPic={!data.cover_pic}
                      isFocusCover={isFocusCover}
                    >
                      {/* {(window.innerWidth <= 768 && !isFocusCover) && (
                      <MobileViewArticleTitle
                        disableCoverPic={!data.cover_pic}
                      >
                        <ArticleTitle>
                          {
                            data.feed_visible_fields && data.feed_visible_fields.big_number && data.feed_visible_fields.big_number.visible && (
                              <span>{data.feed_visible_fields.big_number.number}</span>
                            )
                          }
                          {data.title}
                        </ArticleTitle>
                      </MobileViewArticleTitle>
                    )} */}
                      {/* {(data.cover_pic && !isFocusCover) && ( */}
                      {data.cover_pic &&
                      data.cover_pic.indexOf("youtube.com") > -1 ? (
                        <>
                          <VideoContainer
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (toggleClose) {
                                setToggler(!toggler);
                                setToggleClose(false);
                                setCurrentScrollPosition(window.scrollY);
                              }
                            }}
                            toggler={toggler}
                          >
                            <Image
                              src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`}
                            />
                            <VideoInfoWrapper>
                              <CustomIcon
                                name="playIcon"
                                size={12}
                                color="#fff"
                              />
                              {`   ${data.timestamp_length || ""}`}
                            </VideoInfoWrapper>
                            {/* <div id={`youtubePlayer-${data.id}`} style={{ pointerEvents: 'none' }}/> */}
                            <Lightbox
                              toggler={toggler}
                              sources={[data.cover_pic]}
                              disableLocalStorage
                              key={data.id}
                              onClose={() => {
                                setToggleClose(true);
                                window.scrollTo(0, currentScrollPosition);
                                setCurrentScrollPosition(0);
                              }}
                            />
                          </VideoContainer>
                        </>
                      ) : (
                        <ArticleImageWrapper isFocusCover={isFocusCover}>
                          {data.visible_fields.ad_target !== null ? (
                            <Image
                              onClick={() =>
                                window.open(
                                  data.visible_fields.ad_target,
                                  "_blank" // <- This is what makes it open in a new window.
                                )
                              }
                              src={
                                data.cover_pic
                                  ? data.cover_pic.indexOf("youtube.com") > -1
                                    ? `https://i3.ytimg.com/vi/${data.cover_pic.substring(
                                        data.cover_pic.indexOf("=") + 1,
                                        data.cover_pic.length
                                      )}/hqdefault.jpg`
                                    : data.cover_pic
                                  : data.cover_pic
                              }
                            />
                          ) : (
                            <Image
                              src={
                                data.cover_pic
                                  ? data.cover_pic.indexOf("youtube.com") > -1
                                    ? `https://i3.ytimg.com/vi/${data.cover_pic.substring(
                                        data.cover_pic.indexOf("=") + 1,
                                        data.cover_pic.length
                                      )}/hqdefault.jpg`
                                    : data.cover_pic
                                  : data.cover_pic
                              }
                            />
                          )}

                          {/* <p>Advertisment Pic</p> */}
                        </ArticleImageWrapper>
                      )}
                    </ArticleImageContainer>
                    <ArticleContentWrapper
                      disableCoverPic={!data.cover_pic}
                      isFocusCover={isFocusCover}
                    >
                      {/* {(data.cover_pic && isFocusCover) && (
                      <ArticleImageWrapper isFocusCover={isFocusCover} >
                        <Image src={
                          data.cover_pic ? data.cover_pic.indexOf('youtube.com') > -1 ?
                            `https://i3.ytimg.com/vi/${data.cover_pic.substring(data.cover_pic.indexOf('=') + 1, data.cover_pic.length)}/hqdefault.jpg`
                            : data.cover_pic : data.cover_pic} />
                        {window.innerWidth > 768 && data.categories && data.categories.length > 0 && data.feed_visible_fields && data.feed_visible_fields.show_category && (
                          <TagsListWrapper>
                            {data.categories.slice(0, 2).map(category => (
                              <TagWrapper
                                key={category.id}
                                to={`/category/${category.slug}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // e.preventDefault();
                                  onClickCategory(category);
                                }}
                              >
                                <span>#</span>
                                {`${category.title}`}
                              </TagWrapper>
                            ))}
                          </TagsListWrapper>
                        )}
                      </ArticleImageWrapper>
                    )} */}
                      {/* {(window.innerWidth > 768 || isFocusCover) && ( */}
                      <TagsListWrapper>
                        <DigestAuthorInfo data={data.digest_author} />
                        {data.categories.slice(0, 1).map((category) => (
                          <TagWrapper
                            key={category.id}
                            to={`/category/${category.slug}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              // e.preventDefault();
                              if (onClickCategory) {
                                onClickCategory(category);
                              }
                            }}
                          >
                            <span>#</span>
                            {`${category.title}`}
                          </TagWrapper>
                        ))}
                        {showDate && (
                          <span>
                            <span className="divider" />
                            {`${data.first_published_at_human}`}
                          </span>
                        )}
                      </TagsListWrapper>
                      <ArticleTitle>
                        {data.feed_visible_fields &&
                          data.feed_visible_fields.big_number &&
                          data.feed_visible_fields.big_number.visible && (
                            <span>
                              {data.feed_visible_fields.big_number.number}
                            </span>
                          )}
                        {data.title}
                      </ArticleTitle>
                      {/* )} */}
                      <div>
                        {/* {data.feed_visible_fields && data.feed_visible_fields.format_human && ( */}
                        {/* <DigestCreatedByInfoWrapper>
                          <DigestCreatedByInfo data={data} marginTop="5px"/>
                        </DigestCreatedByInfoWrapper> */}
                        {/* )} */}
                        {/* {window.innerWidth > 768 && (
                        <ArticleTitle>
                          {
                            data.feed_visible_fields && data.feed_visible_fields.big_number && data.feed_visible_fields.big_number.visible && (
                              <span>{data.feed_visible_fields.big_number.number}</span>
                            )
                          }
                          {data.title}
                        </ArticleTitle>)} */}
                        {data &&
                        data.type.code === "300" &&
                        data.collection_digests &&
                        data.collection_digests.length > 0 ? (
                          <>
                            {collectionDigestsBody &&
                              collectionDigestsBody.length > 0 && (
                                <div ref={applyStopPropagationAnchor}>
                                  {collectionDigestsBody.map((item, index) => (
                                    <>
                                      {item.isCollectionDigest ? (
                                        <RenderCollectionDigest
                                          item={item}
                                          collectionDigests={
                                            data.collection_digests
                                          }
                                          history={history}
                                          playingVideoID={playingVideoID}
                                          setPlayingVideo={(value) => {
                                            setPlayingVideo(value);
                                          }}
                                          theme={theme}
                                        />
                                      ) : (
                                        <ArticleDesc
                                          className="articleDesc"
                                          isExpanded={isExpanded}
                                        >
                                          {digestDescRef &&
                                          data.markup &&
                                          data.markup.apply ? (
                                            <ArticleDesc
                                              id={`render-html-${data.id}`}
                                              isExpanded={isExpanded}
                                              // dangerouslySetInnerHTML={{ __html: item.str || '' }}
                                            />
                                          ) : (
                                            // <ReactMarkdown source={data.digest} />
                                            <ArticleDesc
                                              isExpanded={isExpanded}
                                            >
                                              {item.str
                                                .split(/\n/)
                                                .map((para, index) => (
                                                  <p key={index}>{para}</p>
                                                ))}
                                            </ArticleDesc>
                                          )}
                                        </ArticleDesc>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                          </>
                        ) : (
                          <ArticleDesc
                            className="articleDesc"
                            isExpanded={isExpanded}
                            ref={(a) => {
                              applyStopPropagationAnchor(a);
                              setTimeout(() => {
                                ApplyExpandablePoints(a);
                              }, 50);
                            }}
                          >
                            {digestDesc && data.markup && data.markup.apply ? (
                              <ArticleDesc
                                id={`render-html-${data.id}`}
                                isExpanded={isExpanded}
                                // dangerouslySetInnerHTML={{ __html: digestDesc || '' }}
                              />
                            ) : (
                              // <ReactMarkdown source={digestDesc} />
                              <ArticleDesc isExpanded={isExpanded}>
                                {digestDesc &&
                                  digestDesc
                                    .split(/\n/)
                                    .map((para, index) => (
                                      <p key={index}>{para}</p>
                                    ))}
                              </ArticleDesc>
                            )}
                          </ArticleDesc>
                        )}
                      </div>
                      {/* {data.categories && data.categories.length > 0 && window.innerWidth <= 768 && (
                      <TagsListWrapper>
                        {data.categories.slice(0, 2).map(category => (
                          <TagWrapper
                            key={category.id}
                            to={`/category/${category.slug}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              // e.preventDefault();
                              onClickCategory(category);
                            }}
                          >
                            <span>#</span>
                            {`${category.title}`}
                          </TagWrapper>
                        ))}
                      </TagsListWrapper>
                    )} */}
                    </ArticleContentWrapper>
                  </Container>
                  {!isExpand2Click && (
                    <DeeperButton
                      data={data}
                      onDigestReadLater={onDigestReadLater}
                      onClickShare={onClickShare}
                      buttonText={data.feed_button_text}
                      padding="10px 0"
                      isExpand2Click={isExpand2Click}
                      theme={theme}
                    />
                  )}
                  <DigestPublishInfoWrapper>
                    {isExpand2Click && (
                      <DeeperButtonWrapper>
                        <DeeperButton
                          data={data}
                          hideDeeperText
                          onDigestReadLater={onDigestReadLater}
                          onClickShare={onClickShare}
                          buttonText={data.feed_button_text}
                          padding="10px 0"
                          isExpand2Click={isExpand2Click}
                          theme={theme}
                        />
                      </DeeperButtonWrapper>
                    )}
                    <DigestPublishInfoContainer>
                      <DigestPublishInfo
                        data={data}
                        isHostClickable
                        amplitudePageType={AmplitudePageType}
                        hideFollowButton
                      />
                    </DigestPublishInfoContainer>
                  </DigestPublishInfoWrapper>
                </OuterContainer>
              </VisibilitySensor>
            </Wrapper>
          </>
        )}
      {data && data.design_type === "MSG_VIEW" && <MessageView data={data} />}

      {data && data.design_type === "LIST_VERTICAL" && (
        <ListVertical
          data={data}
          onDigestReadLater={onDigestReadLater}
          onClickShare={onClickShare}
          onClickCategory={onClickCategory}
          setPlayingVideo={setPlayingVideo}
        />
      )}
      {data && data.design_type === "GRID_HORIZONTAL" && (
        <ListHorizontal
          data={data}
          onDigestReadLater={onDigestReadLater}
          onClickShare={onClickShare}
          onClickCategory={onClickCategory}
          setPlayingVideo={setPlayingVideo}
        />
      )}
    </>
  );
};

export default ArticleItem;
