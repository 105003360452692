import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #ffffff;
  border-bottom:1px solid #000;
  padding: 80px 15px;
`;
const Container = styled.div`
  width: 100%;
  max-width:1130px;
  margin: 0px auto;
`;
const HeadingWrapper = styled.div`
  max-width:800px;
  margin: 0px auto 50px auto;
  text-align:center;
  h2{
    font-family: Metropolis;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 10px;
    @media (max-width: 992px) {
       font-size: 36px;
       line-height: 40px;
    }
    @media (max-width: 767px) {
       font-size: 26px;
       line-height: 30px;
    }
  }
  P{
    opacity: 0.6;
    font-family: Metropolis;
    font-size: 18px;
    line-height: 28px;
    margin-bottom:20px;
  }
`;
const ContentWrapper = styled.div`
  font-family: Metropolis;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Block = styled.div`
    flex: 0 0 auto;
    width: 33.33333333%;
    margin-bottom:30px;
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: 992px) {
      width:100%;
      text-align:center;
    }
    img{
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
    h4{
      font-family: Metropolis;
      font-size: 20px;
      line-height: 24px;
      margin-top:15px;
    }
    P{
      font-family: Metropolis;
      opacity: 0.6;
      font-size: 16px;
      line-height: 24px;
    }
`;


const SegmentTagAndText = ({data}) => {
  return (
    <Wrapper>
      <Container>
        <HeadingWrapper>
          <h2>{data ? data.title : ''}</h2>
          <p>{data ? data.subtitle : ''}</p>
        </HeadingWrapper>
        <ContentWrapper>
          {(data) && (data.list.length) && data.list.map(({icon, title, subtitle}, index) =>
            <Block key={index}>
              <img src={icon} alt="" />
              <h4>{title}</h4>
              <p>{subtitle}</p>
            </Block>
          )}
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default SegmentTagAndText;
