import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
// import {primaryColor } from 'colorPalette';
import { Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import FsLightbox from "fslightbox-react";

import DigestFooter from "../../DigestFooter";
import { Link } from "react-router-dom";
import SideBarTopStoriesShimmer from "./SideBarTopStoriesShimmer";
import TagsSuggestions from "../SuggestioFilterComponent/TagsSuggestion";
import PublicationsSuggestions from "../SuggestioFilterComponent/PublicationsSuggestions";
import PeopleSuggestions from "../SuggestioFilterComponent/PeopleSuggestions";
import CustomIcon from "Icon";

const Wrapper = styled.div`
  width: ${({ sideBarWidth }) => sideBarWidth}px;
  min-height: calc(100vh - 100px);
  /* min-height: ${({ isSearchPage }) =>
    isSearchPage ? `calc(100vh)` : "auto"}; */
  /* height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`}; */
  position: ${({ fixSidebar }) => (fixSidebar ? "fixed" : "static")};
  /* top: ${({ headerHeight }) => headerHeight}px; */
  left: ${({ sideBarLeftPos }) => sideBarLeftPos}px;
  padding: 40px 0 50px 0;

  /* display: flex; */
  /* flex-direction: column; */
  /* overflow: auto; */
  ${({ fixSidebar }) =>
    fixSidebar === "bottom"
      ? `
    bottom: 0;
  `
      : ``}

  ${({ fixSidebar, isSearchPage }) =>
    fixSidebar === "top"
      ? `
    top: ${isSearchPage ? 0 : 50}px;
  `
      : ``}
  
  ${({ sidebarTranslateY }) =>
    sidebarTranslateY
      ? `
    transform: translate(0, ${sidebarTranslateY}px);
  `
      : `transform: translate(0, 0);`};
`;

const Container = styled.div`
  /* height: ${({ footerHeight }) => `calc(100% - ${footerHeight + 15}px)`}; */
  margin-bottom: 10px;
  padding: 0 0 0 35px;
  border-left: 1px solid
    rgba(${(props) => props.theme.fontColor.level2RGB}, 0.2);
  // border-left: 1px solid rgba(0, 0, 0, .1);
`;

const ArticleItemWrapper = styled(Link)`
  display: block;
  cursor: pointer;
  margin-top: 30px;
  // padding: 30px 0;
  // border-top: ${({ firstArticle }) =>
    firstArticle ? 0 : 1}px solid rgba(0, 0, 0, .1);
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 900;
  color: rgba(${(props) => props.theme.fontColor.level2RGB}, 0.8);
  font-family: "Metropolis-Black", "Lato", sans-serif;
`;

const ArticleListWrapper = styled.div`
  /* margin-top: 15px; */
`;

const ArticleIconWrapper = styled.div`
  width: 80px;
  overflow: hidden;
  margin-top: 10px;
  img {
    width: 100%;
    border-radius: 4px;
  }
`;

const ArticleContentWrapper = styled.div`
  // padding: 10px 10px 0;
  // margin-left: 20px;
  padding: 10px 10px 30px 0;
  margin-left: 10px;
  flex: 1;
  border-bottom: ${({ lastArticle }) => (lastArticle ? 0 : 1)}px solid
    rgba(0, 0, 0, 0.1);
`;

const ArtcileTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
  color: ${(props) => props.theme.fontColor.level3};
  font-family: "Metropolis-Bold", sans-serif;
`;

// const DigestCreatedBy = styled.div`
//   font-size: ${({ fontSize }) => fontSize || '14px'};
//   line-height: ${({ lineHeight }) => lineHeight || '18px'};
//   font-weight: 500;
//   opacity: .8;
//   /* display: flex; */
//   /* align-items: center; */
//   color: ${({ color }) => color || 'rgba(0, 0, 0, .8)'};
//   margin-top: ${({ marginTop }) => marginTop || '10px'};
//   vertical-align: top;
//   font-family: 'Metropolis', sans-serif;
//   span {
//     display: inline-block;
//     color: ${({ color }) => color || '#333'};
//     vertical-align: top;
//   }

//   .formatHuman {
//   }

//   .label {
//     font-weight: normal;
//   }

//   .divider {
//     display: inline-block;
//     width: 5px;
//     height: 5px;
//     background-color: ${grey.level2};
//     border-radius: 50%;
//     margin: 0 5px;
//     vertical-align: middle;
//   }

//   @media (max-width: 870px) {
//     font-size: 14px;
//     line-height: 18px;
//   }
// `;

const DigestCreatedByName = styled.div`
  font-size: ${({ fontSize }) => fontSize || "10px"};
  line-height: ${({ lineHeight }) => lineHeight || "12px"};
  font-weight: 500;
  /* display: flex; */
  /* align-items: center; */
  // color: ${({ color }) => color || "rgba(0, 0, 0, .50)"};
  color: rgba(${(props) => props.theme.fontColor.level2RGB}, 0.5);
  margin-top: ${({ marginTop }) => marginTop || "10px"};
  vertical-align: top;
  font-family: "Metropolis", sans-serif;

  @media (max-width: 870px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
// const ArticleCreatedAt = styled.div`
//   font-size: 12px;
//   line-height: 16px;
//   color: rgba(0, 0, 0, .8);
//   font-family: 'Metropolis', sans-serif;
//   margin-top: 3px;
// `;

const SideBarSectionWrapper = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

// const DigestTag = styled(Link)`
//   display: inline-block;
//   border-radius: 2px;
//   color: ${primaryColor.level1};
//   font-size: 12px;
//   line-height: 10px;
//   font-weight: 700;
//   margin: 0px 10px 0 50px;
//   text-transform: uppercase;
//   cursor: pointer;

//   &:hover {
//     color: ${primaryColor.level1};
//   }
//   span {
//     color: ${primaryColor.level1};
//   }

//   @media (max-width: 580px) {
//     font-size: 12px;
//     line-height: 12px;
//     margin: 3px 3px 3px 0;
//   }
// `;

const VideoContainer = styled.div`
  max-height: 200px;
  width: 100%;
  @media (max-width: 760px) {
    max-height: ${({ toggler }) => (toggler ? "100%" : "60px")};
  }
  @media (max-width: 560px) {
    ${({ toggler }) => (toggler ? "100%" : "60px")};

    iframe {
      max-height: ${({ toggler }) => (toggler ? "100%" : "60px")};
    }
  }

  > div {
    margin: 0 auto;
  }
  position: relative;
  img {
    border-radius: 4px;
  }
`;

const VideoInfoWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  padding: 2px 2px 2px 3px;
  font-size: 10px;
  color: #fff;
  background-color: #000;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Lightbox = styled(FsLightbox)``;

const SideBarTopStories = ({
  headerHeight,
  sliderFixed,
  sideBarWidth,
  sideBarTopStories,
  sideBarLeftPos,
  sideBarRef,
  listViewHeight,
  isSearchPage,
  filterSuggestion,
  onClickDigest,
  onClickSearchSideBar,
}) => {
  const footerRef = useRef(null);
  const sideBarWrapperRef = useRef(null);
  const windowScrollTop = useRef(window.scrollY);
  const [fixSidebar, setFixSidebar] = useState(false);
  // const [bottomFixedSidebar, setBottomFixedSidebar] = useState(false);
  // const [topFixedSidebar, setTopFixedSidebar] = useState(false);
  const [onPositionSidebar, setOnPositionSidebar] = useState(true);
  const [sidebarTranslateY, setSidebarTranslateY] = useState(null);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(null);
  // const [toggler, setToggler] = useState(null);
  const { t: translation } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = (e) => {
    if (sideBarRef.current && sideBarTopStories && sideBarTopStories.articles) {
      let isUp = false;
      let isDown = false;
      if (window.scrollY > windowScrollTop.current) {
        isUp = false;
        isDown = true;
      } else {
        isUp = true;
        isDown = false;
      }

      windowScrollTop.current = window.scrollY;
      if (sideBarWrapperRef.current.offsetHeight > window.innerHeight) {
        if (!onPositionSidebar) {
          if (sidebarTranslateY) {
            if (isUp && !onPositionSidebar) {
              if (
                sideBarRef.current.offsetTop + sidebarTranslateY >=
                window.scrollY
              ) {
                // setBottomFixedSidebar(false);
                // setTopFixedSidebar(true);
                if (
                  sideBarWrapperRef.current &&
                  listViewHeight > sideBarWrapperRef.current.offsetHeight
                ) {
                  setFixSidebar("top");
                }
                setSidebarTranslateY(null);
              }
            } else if (sidebarTranslateY && isDown) {
              if (
                sideBarRef.current.offsetTop +
                  sidebarTranslateY +
                  window.innerHeight +
                  50 <=
                window.scrollY
              ) {
                // setBottomFixedSidebar(true);
                // setTopFixedSidebar(false);
                if (
                  sideBarWrapperRef.current &&
                  listViewHeight > sideBarWrapperRef.current.offsetHeight
                ) {
                  setFixSidebar("bottom");
                }
                setSidebarTranslateY(null);
              }
            }
          } else {
            if (fixSidebar === "bottom" && isUp && !sidebarTranslateY) {
              const translateY =
                window.scrollY -
                sideBarRef.current.offsetTop -
                window.innerHeight;
              setSidebarTranslateY(translateY - 100);
              setFixSidebar(null);
              // setBottomFixedSidebar(false);
            } else if (fixSidebar === "top" && isDown && !sidebarTranslateY) {
              const translateY =
                window.scrollY - sideBarRef.current.offsetTop + 50;
              setSidebarTranslateY(translateY);
              setFixSidebar(null);
            }
          }
        } else if (fixSidebar || sidebarTranslateY) {
          // setBottomFixedSidebar(false);
          // setTopFixedSidebar(false);
          setFixSidebar(null);
          setSidebarTranslateY(null);
        }
      }

      if (sideBarWrapperRef.current.offsetHeight < window.innerHeight) {
        if (sideBarRef.current.offsetTop - window.scrollY <= 0) {
          setFixSidebar("top");
        } else {
          setFixSidebar(null);
        }
      } else {
        if (
          sideBarRef.current.offsetTop +
            sideBarWrapperRef.current.offsetHeight <=
          window.scrollY + window.innerHeight
        ) {
          if (!fixSidebar && !sidebarTranslateY) {
            setOnPositionSidebar(false);
            if (
              sideBarWrapperRef.current &&
              listViewHeight > sideBarWrapperRef.current.offsetHeight
            ) {
              setFixSidebar("bottom");
            }
            // setBottomFixedSidebar(true);
          }
        } else {
          if (!onPositionSidebar) {
            setOnPositionSidebar(true);
            // setBottomFixedSidebar(false);
            // setTopFixedSidebar(false);
            setFixSidebar(null);
          }
        }
      }

      if (window.scrollY <= 10) {
        setFixSidebar(null);
        setOnPositionSidebar(true);
        setSidebarTranslateY(null);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  console.log("sideBarTopStories.article", sideBarTopStories);
  return (
    <Wrapper
      headerHeight={headerHeight}
      fixSidebar={fixSidebar}
      sideBarWidth={sideBarWidth}
      sideBarLeftPos={sideBarLeftPos}
      footerHeight={footerRef.current && footerRef.current.offSetHeight}
      ref={sideBarWrapperRef}
      sidebarTranslateY={sidebarTranslateY}
      isSearchPage={isSearchPage}
      // bottomFixedSidebar={bottomFixedSidebar}
      // topFixedSidebar={topFixedSidebar}
    >
      {sideBarTopStories && sideBarTopStories.articles && (
        <Container>
          {!isSearchPage && (
            <>
              <SectionTitle>
                {(sideBarTopStories.content &&
                  sideBarTopStories.content.title) ||
                  translation("digestFeed.sidebarTopStoriesHeading")}
              </SectionTitle>
              <ArticleListWrapper>
                {sideBarTopStories.articles.map((item, index) => {
                  const validUrl =
                    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                  let video_id =
                    item.cover_pic &&
                    item.cover_pic.match(validUrl) &&
                    item.cover_pic.match(validUrl)[1];
                  return (
                    <ArticleItemWrapper
                      key={item.id}
                      to={`/digests/${item.slug}`}
                      onClick={() => {
                        window.scroll(0, 0);
                        onClickDigest(item);
                      }}
                      // firstArticle={index === 0}
                    >
                      {/* {item.categories && item.categories.length > 0 && (
                          item.categories.slice(0, 1).map(item => (
                              <DigestTag 
                                to={`/category/${item.slug}`}
                                onClick={e => {
                                  e.stopPropagation();
                                  // e.preventDefault();
                                  // history.push(`/category/${item.slug}`)
                                }}
                                key={item.id}
                              ><span>#</span>{`${item.title}`}</DigestTag>
                            ))
                        )} */}
                      <ContentWrapper>
                        <ArticleIconWrapper>
                          {item.cover_pic &&
                          item.cover_pic.indexOf("youtube.com") > -1 ? (
                            <>
                              <VideoContainer
                                onClick={(e) => {
                                  // e.preventDefault();
                                  // e.stopPropagation();
                                  // if (!toggler) {
                                  // setToggler(true);
                                  // setCurrentScrollPosition(window.scrollY);
                                  // } else {
                                  // window.scrollTo(0, currentScrollPosition);
                                  // setCurrentScrollPosition(0);
                                  // setToggler(!toggler);
                                  // }
                                }}
                                // toggler={toggler}
                              >
                                <Image
                                  src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`}
                                />
                                <VideoInfoWrapper>
                                  <CustomIcon
                                    name="playIcon"
                                    size={8}
                                    color="#fff"
                                  />
                                  {`   ${item.timestamp_length || ""}`}
                                </VideoInfoWrapper>
                                {/* <div id={`youtubePlayer-${data.id}`} style={{ pointerEvents: 'none' }}/> */}
                              </VideoContainer>
                              <Lightbox
                                // toggler={toggler}
                                sources={[item.cover_pic]}
                                disableLocalStorage
                                key={item.id}
                                onClose={() => {
                                  window.scrollTo(0, currentScrollPosition);
                                  setCurrentScrollPosition(0);
                                }}
                              />
                            </>
                           ) : item.cover_pic ? (
                            <Image src={item.cover_pic} />
                          ) : (
                            <Image src={item.cover_pic} />
                          )}
                        </ArticleIconWrapper>
                        <ArticleContentWrapper
                          lastArticle={
                            index === sideBarTopStories.articles.length - 1
                          }
                        >
                          <ArtcileTitle>{item.title}</ArtcileTitle>
                          {/* <DigestCreatedBy
                          marginTop="5px"
                          fontSize="14px"
                          lineHeight="16px"
                          color="rgba(0, 0, 0, 1)"
                        >
                            {item.first_published_at_human && (
                            <span className="label">
                              {`${item.first_published_at_human}`}
                            </span>
                          )}
                          {item.format_human && (<span>
                            {item.format_human && item.first_published_at_human && (
                              <span className="divider" />
                            )}
                            <span className="formatHuman">{`${item.format_human}`}</span>
                          </span>)}
                        </DigestCreatedBy> */}
                          {item.source &&
                          item.source.authors &&
                          item.source.authors.length > 0 ? (
                            <DigestCreatedByName
                              marginTop="10px"
                              fontSize="11px"
                              lineHeight="12px"
                            >
                              {item.source.authors.map(
                                (author, index) =>
                                  `${index !== 0 ? "," : ""} ${author.name}${
                                    index === item.source.authors.length - 1 &&
                                    item.source &&
                                    item.source.host
                                      ? ` in ${
                                          (item.source && item.source.host) ||
                                          ""
                                        }`
                                      : ""
                                  }`
                              )}
                            </DigestCreatedByName>
                          ) : (
                            <DigestCreatedByName
                              marginTop="10px"
                              fontSize="11px"
                              lineHeight="12px"
                            >
                              {(item.source && item.source.host) || ""}
                            </DigestCreatedByName>
                          )}
                          {/* <ArtcileSubtitle>{item.subtitle}</ArtcileSubtitle> */}
                          {/* <ArticleCreatedAt>{item.first_published_at_human}</ArticleCreatedAt> */}
                        </ArticleContentWrapper>
                      </ContentWrapper>
                    </ArticleItemWrapper>
                  );
                })}
              </ArticleListWrapper>
            </>
          )}
          {isSearchPage &&
            filterSuggestion &&
            filterSuggestion.tags &&
            filterSuggestion.tags.results &&
            filterSuggestion.tags.results.length > 0 && (
              <SideBarSectionWrapper>
                <SectionTitle>Tags</SectionTitle>
                <TagsSuggestions
                  data={filterSuggestion.tags.results}
                  onClickItem={onClickSearchSideBar}
                />
              </SideBarSectionWrapper>
            )}
          {isSearchPage &&
            filterSuggestion &&
            filterSuggestion.sources &&
            filterSuggestion.sources.results &&
            filterSuggestion.sources.results.length > 0 && (
              <SideBarSectionWrapper>
                <SectionTitle>Publications</SectionTitle>
                <PublicationsSuggestions
                  data={filterSuggestion.sources.results}
                  onClickItem={onClickSearchSideBar}
                />
              </SideBarSectionWrapper>
            )}
          {isSearchPage &&
            filterSuggestion &&
            filterSuggestion.people &&
            filterSuggestion.people.results &&
            filterSuggestion.people.results.length > 0 && (
              <SideBarSectionWrapper>
                <SectionTitle>People</SectionTitle>
                <PeopleSuggestions
                  data={filterSuggestion.people.results}
                  onClickItem={onClickSearchSideBar}
                />
              </SideBarSectionWrapper>
            )}
          {(!sideBarTopStories || !sideBarTopStories.articles) && (
            <SideBarTopStoriesShimmer />
          )}
          {!isSearchPage && (
            <DigestFooter footerRef={footerRef} sliderFixed={sliderFixed} />
          )}
        </Container>
      )}
    </Wrapper>
  );
};

export default SideBarTopStories;
