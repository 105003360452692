import React from 'react';
import styled from 'styled-components';
import PageHeader from 'Components/Header';
import Footer from '../NewsDigests/Desktop/DesktopDigestItem/Footer';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 80vh;
  font-size: 18px;
  background-color: ${props => props.theme.primaryColor.level1};
  color: ${props => props.theme.fontColor.level2};
  padding: 25px 10vw;
  @media (max-width: 580px) {
    width: 100%;
    font-size: 14px;
  }
`;

const Address = styled.address`
  margin-bottom: 25px;
  p {
    margin-bottom: 0;
  }
`;

const AboutPage = ({
  setGuestUuid,
  theme,
  setTheme
}) => {
  return (
    <div>
      <PageHeader 
        onLogin={() => {
          if (setGuestUuid) {
            setGuestUuid()
          }
        }}
        theme={theme}
        setTheme={setTheme}
      />
      <Wrapper>
        <p>
          Ideastoday (ideastoday.in) and DailyBrief (getdailybrief.com) are owned by Ideas Today Labs Private Limited.
        </p>
        <p>
          The owners can be contacted at:
        </p>
       

        <Address>
          <p>Ideas Today Labs Private Limited,</p>
          <p>305, The Summit Business Bay,</p>
            <p>Off Andheri Kurla Road,</p>
            <p>Mumbai, Maharashtra 400093.</p>
          <p>hello@ideastoday.in</p>
          <p>+91 9051884667</p>
        </Address>
      </Wrapper>
      <Footer theme={theme} />
    </div>
  )
};

export default AboutPage;