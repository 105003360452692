import React, { useReducer, useEffect, useRef } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { useHistory } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import PageHeader from 'Components/Header';
import {
  fetchTagsDetail,
  fetchDigestListApi,
  deleteReadLater,
  addReadLater,
  fetchCategoriesExplore,
} from 'Api';
import TagDetail from './TagDetail';
import DigestItem from './DigestItem';
import TagsPageShimmer from './TagsPageShimmer'
import ShareDialogBox from '../NewsDigests/Mobile/ArticleItem/ShareDialogBox';
import Page404 from '../NewsDigests/Desktop/404page';

const PAGE_SIZE = 5;
const AmplitudePageType = 'HomePage';

const Container = styled.div``;
const PageWrapper = styled.div``;

const ContentContainer = styled.div`
  background-color: ${({ bgColor}) => bgColor ? bgColor : props => props.theme.primaryColor.level1};
`;

const ContentWrapper = styled.div`
  width: 60%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 70%;
  }
  
  @media (max-width: 768px) {
    width: 90%;
  }
  
  @media (max-width: 580px) {
    width: 95%;
  }
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'SET_SEARCH_VALUE':
        draft.searchValue = action.value;
        draft.activeSearchTab = 'stories';
        break;
      case 'SET_TAG_DETAIL':
        draft.tagDetail = action.value;
      break;
      case 'SET_DIGEST_LIST_DATA':
        draft.digestListData = draft.digestListData.concat(action.value);
        draft.isLoadingDigestList = false;
      break;
      case 'SET_LOADING_DIGEST_LIST':
        draft.isLoadingDigestList = action.value;
      break;
      case 'SET_READ_LATER_LOADER':
        draft.readLaterLoader = action.value;
        break;
      case 'SET_DIGEST_READ_LATER':
        draft.readLaterLoader = false;
        draft.digestListData = draft.digestListData.map(digest => {
          if (digest.id === action.data.id) {
            digest.readlater = action.value;
          }
          return digest;
        });
      break;
      case 'SET_ACTIVE_PAGE':
        draft.activePage = action.value;
        break;
      case 'SET_SHARE_ARTICLE_DATA':
        draft.shareArticleData = action.value;
      break;
      case 'SET_CATEGORY_DATA':
        draft.categoryData = action.value;
      break;
      case 'SET_SHOW_404_PAGE':
        draft.show404Page = action.value;
      break;
    }
  });

const initialState = {
  searchValue: '',
  tagDetail: null,
  digestListData: [],
  isLoadingDigestList: false,
  readLaterLoader: false,
  show404Page: false,
  activePage: 1,
  shareArticleData: null,
  categoryData: [],
}
const TagsPage = ({
  handleUserNotFound,
  location: { state: locationState, pathname },
  match: { params },
  theme,
  setTheme,
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const activePageRef = useRef(1);
  const activatePagingationRef = useRef(false);
  const digestListDataRef = useRef([]);
  const {
    searchValue,
    tagDetail,
    digestListData,
    readLaterLoader,
    activePage,
    isLoadingDigestList,
    shareArticleData,
    categoryData,
    show404Page
  } = state;

  const handleFetchDigestsList = () => {
    dispatch({ type: 'SET_LOADING_DIGEST_LIST', value: true });
    fetchDigestListApi({
      digestType: '200,275,250,300, 255',
      page: activePage,
      size: PAGE_SIZE,
      tags: params.tagSlug
    }).then(response => {
      dispatch({ type: 'SET_DIGEST_LIST_DATA', value: response.data.results });
    }).catch(error => {
      console.log("🚀 ~ file: index.js ~ line 60 ~ handleFetchDigestsList ~ error", error)
    })
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchTagsDetail = () => {
    fetchTagsDetail({ tagSlug: params.tagSlug}).then(response => {
      dispatch({ type: 'SET_TAG_DETAIL', value: response.data })
    })
    .catch(error => {
      if (error.response.status === 404) {
        dispatch({ type: 'SET_SHOW_404_PAGE', value: true });
      }
    })
    
    fetchCategoriesExplore().then(response => {
      dispatch({ type: 'SET_CATEGORY_DATA', value: response.data.results})
    })
    .catch(error => {
      console.log("🚀 ~ file: index.js ~ line 46 ~ HandleFetchTagsDetail ~ error", error)
    })
  }

  useEffect(() => {
    if (params.tagSlug) {
      handleFetchTagsDetail();
      handleFetchDigestsList();
      if(digestListData.length === 0) {
        window.scroll(0, 0);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tagSlug]);

  const onDigestReadLater = (data, isRelatedDigest, isFocusCard) => {
    if (!readLaterLoader) {
      dispatch({ type: 'SET_READ_LATER_LOADER', value: true });
      if (data.readlater) {
        deleteReadLater({ digestId: data.id }).then(response => {
          if (response.status === 202) {
            dispatch({ type: 'SET_DIGEST_READ_LATER', data, value: false, isRelatedDigest, isFocusCard });
            // ToastsStore.success('Removed from Read Later.');
          }
        })
          .catch(error => {
            if (window && window.Raven) window.Raven.captureException(error.response);
            ToastsStore.error('Something went wrong.');
          })
      } else {
        addReadLater({ digestId: data.id, data, pageType: AmplitudePageType }).then(response => {
          if (response.status === 201) {
            dispatch({ type: 'SET_DIGEST_READ_LATER', data, value: true, isRelatedDigest, isFocusCard });
            // ToastsStore.success('Saved to Read Later.');
          }
        })
          .catch(error => {
            if (window && window.Raven) window.Raven.captureException(error.response);
            ToastsStore.error('Something went wrong.');
          })
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = e => {
    if (window.scrollY + 100 >= document.body.scrollHeight - window.innerHeight && !activatePagingationRef.current && !isLoadingDigestList) {
      if (digestListDataRef.current.length >= activePageRef.current * PAGE_SIZE) {
        activatePagingationRef.current = true;
        dispatch({ type: 'SET_ACTIVE_PAGE', value: activePageRef.current + 1 });
      }
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, [handleScroll]);

  useEffect(() => {
    activatePagingationRef.current = false;
    activePageRef.current = activePage;
    if (activePage > 1) {
      handleFetchDigestsList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    digestListDataRef.current = digestListData;
  }, [digestListData]);

  return (
    <Container>
      <PageHeader 
        onLogout={() => {
          history.push('/category/feed');
        }}
        pathname={pathname}
        params={params}
        handleUserNotFound={handleUserNotFound}
        searchValue={searchValue}
        // showSearchBar={true}
        handleFetchDigestsList={handleFetchDigestsList}
        setSearchValue={value => dispatch({ type: 'SET_SEARCH_VALUE', value })}
        theme={theme}
        setTheme={setTheme}
      />
      <ToastsContainer
        position={ToastsContainerPosition.BOTTOM_LEFT}
        store={ToastsStore}
      />
      <PageWrapper>
        {show404Page ? (
          <Page404 />
        ): (
          <>
            <ContentContainer bgColor="#7ca9ea">
              <ContentWrapper>
                <TagDetail
                  data={tagDetail}
                  updateData={value => {
                    dispatch({ type: 'SET_TAG_DETAIL', value: value ? value : { ...tagDetail, selected: false }});
                  }}
                  categoryData={categoryData || []}
                />
              </ContentWrapper>
            </ContentContainer>
            <ContentContainer>
              <ContentWrapper>
                {digestListData && digestListData.length > 0 && digestListData.map((item, index) => (
                  <DigestItem 
                    data={item}
                    index={index + 1}
                    onDigestReadLater={onDigestReadLater}
                    key={item.id}
                    isStream={tagDetail && tagDetail.is_stream}
                    setShareArticleData={data => {
                      dispatch({ type: 'SET_SHARE_ARTICLE_DATA', value: data });
                    }}
                  />
                ))}
                {isLoadingDigestList && (
                  <TagsPageShimmer innerLoader={digestListData.length > 0} />
                )}
              </ContentWrapper>
            </ContentContainer>
          </>
        )}
      </PageWrapper>
      <ShareDialogBox
        onClose={() => {
          dispatch({ type: 'SET_SHARE_ARTICLE_DATA', value: null });
        }}
        open={!!shareArticleData}
        data={shareArticleData}
        shareUrl=" "
        isDesktop
        shareText={shareArticleData && shareArticleData.share_data && shareArticleData.share_data.share_text}
      />
    </Container>
  )
};

export default TagsPage;