import React from "react";
import styled from "styled-components";
import { Header, Image } from "semantic-ui-react";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    min-height: 65vh;
    border-bottom: 1px solid #000000;

    @media (max-width: 768px) {
        min-height: 100vh;
    }
`;

const Wrapper = styled.div`
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    jusify-content: space-around;
    padding: 30px 0;
    height: 100%;

    @media (max-width: 768px) {
        width: 90%;
        justify-content: space-around;
        flex-direction: column-reverse;
    }
`;

const BannerContent = styled.div`
    flex-basis: 60%;

    @media (max-width: 768px) {
        margin-top: 50px;
    }
`;

const PageTitle = styled(Header)`
    font-size: 32px;
    line-height: 40px;
    font-family: GothamBookRegular;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
    }
`;

const HeadingWrapper = styled.div``;

const BannerDesc = styled.div`
    font-size: 18px;
    line-height: 28px;
    margin-top: 15px;
    color: #6a7278;

    @media (max-width: 768px) {
        font-size: 15px;
        line-height: 24px;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Illustration = styled(Image)`
    width: 100%;
    @media (max-width: 768px) {
        width: 90vw;
    }
`;

const ListWrapper = styled.div`
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0 20px 0 0;

    @media (max-width: 768px) {
        margin-bottom: 30px;
        padding-right: 0;
        width: 100%;
    }
`;

const ListItemTitle = styled.p`
    font-weight: bold;
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;

    @media (max-width: 768px) {
        font-size: 15px;
        line-height: 24px;
    }
`;

const ListItemDesc = styled.p`
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;

    @media (max-width: 768px) {
        font-size: 15px;
        line-height: 24px;
    }
`;

const SoonLabel = styled.button`
    background-color: #ffc107;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    width: 42px;
    height: 20px;
    display: inline-block;
    border: none;
    font-family: GothamBookRegular;
    // margin-top: 4px;
    margin-left: 5px;

    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`

const Section4 = ({ data4 }) => {
    return (
        <Container>
            <Wrapper>
                <BannerContent>
                    <HeadingWrapper>
                        <PageTitle as="h2">{data4 && data4.title}</PageTitle>
                    </HeadingWrapper>
                    <BannerDesc>{data4 && data4.subtitle}</BannerDesc>
                    <ListWrapper>
                        {data4 &&
                            data4.list.map((item) => (
                                <ListItem>
                                    <ListItemTitle>{item.title}
                                        {item.soonlabel && item.soonlabel !== "" ?
                                                <SoonLabel>
                                                    {item.soonlabel}
                                                    {/* soon */}
                                                </SoonLabel>
                                            : null
                                        }
                                    </ListItemTitle>
                                    <ListItemDesc>{item.subtitle}</ListItemDesc>
                                </ListItem>
                            ))}
                    </ListWrapper>
                </BannerContent>

                <ImageWrapper>
                    <Illustration src={data4 && data4.image} />
                </ImageWrapper>
            </Wrapper>
        </Container>
    );
};

export default Section4;
