import React from 'react';
import styled from 'styled-components';

import tndPrivacyHtml from './tnd_privacy';
import undPrivacyHtml from './und_privacy';
import opedPrivacyHtml from './op_privacy';
import clipPrivacyHtml from './clip_privacy';

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const PrivacyPage = () => {
  let privacyHtml = tndPrivacyHtml;
  if (process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging') {
    privacyHtml = undPrivacyHtml;
  } else if (process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging') {
    privacyHtml = opedPrivacyHtml;
  } else if (process.env.REACT_APP_ENV === 'clip-production') {
    privacyHtml = clipPrivacyHtml;
  }
  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: privacyHtml }}/>
    </Wrapper>
  );}

export default PrivacyPage;