/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, {
  useReducer,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";
import styled from "styled-components";
import produce from "immer";
import { useHistory, Link } from "react-router-dom";
import { getUpdatedHtml } from "../PlayerFile";
//import ReactHlsPlayer from "react-hls-player";
// import { Image } from 'semantic-ui-react';
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { Helmet } from "react-helmet";
// import sanitizeHtml from 'sanitize-html';
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
//import Iframe from "react-iframe";
import PageHeader from "Components/Header";
import { grey, black } from "colorPalette";
import ShareDialogBox from "../../Mobile/ArticleItem/ShareDialogBox";
import SubscribeToNewsLetterFull from "../../../SubscribeToNewsLetterFull";

import {
  deleteBookmarkDigest,
  bookmarkDigest,
  deleteReadLater,
  addReadLater,
  fetchDigestDetail,
  addReadDigest,
  // postHostFollow
} from "Api";
import Footer from "./Footer";
import { logAmplitudeEvent } from "Utils/amplitudeLoad";
import Page404 from "../404page";
import { DigestAuthorInfo, DigestCreatedByInfo } from "../CommonComponents";
import RenderCollectionDigest from "./RenderCollectionDigest";
import RenderDigestCover from "./RenderDigestCover";
import DigestViewShimmer from "./DigestViewShimmer";
import UserExperience from "./UserExperience";
import DigestActions from "../DigestActions";
import StickyNewsLetterForm from "./StickyNewsLetterForm";
import DigestActionWrapper from "./DigestActionsWrapper";
import FocusCard from "./FocusCard";
import FeedbackBox from "./FeedbackBox";
import MobileAppBannerAds from "./MobileAppBannerAds";

import { ApplyExpandablePoints, addHtmlElement } from "Utils/ExpandablePoints";
import { DigestPublishInfo } from "../CommonComponents";

const AmplitudePageType = "ArticlePage";

const Wrapper = styled.div`
  padding-top: ${({ headerHeight }) => headerHeight}px;
  background-color: ${(props) => props.theme.primaryColor.level1};
  @media (max-width: 768px) {
    padding-top: 20px;
  }
  font-family: "Metropolis", sans-serif;
`;

const Container = styled.div`
  padding: 20px 0;
  width: 50%;
  max-width: 680px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.primaryColor.level1};

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
    max-width: unset;
  }
`;

const ArticleContentWrapper = styled.div`
  margin-top: 10px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

// const CommentPageContainer = styled.div`
//   background-color: ${(props) => props.theme.secondaryColor.level3};
//   padding-top: 20px;
// `;

// const CommentPageWrapper = styled.div`
//   width: 50%;
//   max-width: 680px;
//   margin: 0 auto;

//   @media (max-width: 1024px) {
//     width: 60%;
//   }

//   @media (max-width: 900px) {
//     width: 70%;
//   }

//   @media (max-width: 768px) {
//     width: 90%;
//   }
// `;

// const RelatedDigestsWrapper = styled.div`
//   width: 75%;
//   margin: 0 auto;
//   max-width: 1200px;
//   @media (max-width: 1024px) {
//     width: 90%;
//   }

//   /* @media(max-width: 900px) {
//     width: 85%;
//   } */

//   @media (max-width: 768px) {
//     width: calc(100% - 80px);
//   }

//   @media (max-width: 580px) {
//     width: 100%;
//   }
// `;

const ArticleTitle = styled.div`
  font-size: 32px;
  line-height: 1.3;
  font-weight: 500;
  margin-top: 20px;
  font-family: "PT Serif", Georgia, serif;
  color: rgba(${(props) => props.theme.fontColor.level2RGB}, 0.8);
  flex: 1;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 1.3;
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 580px) {
    font-size: 20px;
    line-height: 1.3;
  }
`;

const TagsListWrapper = styled.div`
  width: calc(100% - 10px);
  display: flex;
  align-items: left;
  flex-wrap: wrap;
`;

const TagWrapper = styled(Link)`
  font-family: Metropolis, sans-serif;
  display: inline-block;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  margin: 0 5px 10px 0;
  text-transform: uppercase;
  cursor: pointer;
  color: #616576;
  &:hover {
    color: #939eab;
  }
  span {
    color: #616576;
  }

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

// const DigestInfoText = styled.div`
//   color: #000;
//   font-size: 14px;
//   line-height: 18px;
//   /* display: flex; */
//   /* align-items: center; */
//   /* flex-wrap: wrap; */
//   flex: 1;
//   font-family: 'Metropolis', sans-serif;

//   @media (max-width: 768px) {
//     font-size: 12px;
//     line-height: 16px;
//     width: 90%;
//     margin: 0 auto;
//   }

//   span {
//     margin-right: 5px;
//     color: rgba(0, 0, 0, .8);
//   }

//   a {
//   color: #000;
//   }

//   .divider {
//     margin: 0 5px;
//   }
// `;

// const PublicationExternalLink = styled.span`
//   margin-left: 5px;
//   display: inline-block;

//   @media (max-width: 580px) {
//     i, svg {
//       width: 14px;
//       height: 14px;
//     }
//   }
// `;

// const DigestIconWrapper = styled.div`
//   overflow: hidden;
//   padding-right: 5px;
//   width: 45px;
//   img  {
//     width: 30px;
//     height: 30px;
//     border-radius: 50%;
//   }

//   @media (max-width: 768px) {
//     padding-right: 10px;
//     border-right: 2px solid #fff;
//     img {
//       width: 30px;
//       height: 30px;
//     }
//   }
// `;

const DigestPublishWrapper = styled.div`
  display: flex;
  align-items: center;
  /* width: calc(100% - 90px); */
  /* width: 100%; */
  margin: 5px 5px 5px 0;
  width: 100%;
`;

const DigestActionsContainer = styled.div`
  flex: 1;
`;
const ArticleDesc = styled.div`
  font-size: 15px;
  line-height: 1.8;
  margin: 10px 0;
  color: ${(props) => props.theme.fontColor.level2};
  font-family: "Merriweather", serif;
  img {
    max-width: 100%;
  }
  h1,
  h2,
  h3 {
    font-family: "PT Serif", Georgia, serif !important;
  }
  @media (max-width: 1024px) {
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }

  p {
    font-size: 15px;
    line-height: 1.8;
    color: ${(props) => props.theme.fontColor.level2};
    font-family: "Merriweather", serif;
    margin-bottom: 12px;
    min-height: 10px;

    @media (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  ul {
    @media (max-width: 768px) {
      padding-left: 20px;
    }
  }

  li {
    margin: 0 0 8px 0;
  }

  blockquote {
    margin: 15px 0;
    display: block;
    font-style: italic;
    position: relative;

    &:before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: #333;
      position: absolute;
      top: 0;
      left: -15px;
    }

    @media (max-width: 768px) {
      margin: 10px 0;
      &:before {
        left: -10px;
      }
    }
  }

  figure {
    text-align: center;

    img {
      width: 80% !important;
    }
  }

  .twitter-tweet {
    margin: 10px auto;
  }
  .twitter-tweet-rendered {
    margin: 10px auto;
  }
`;

// const ArticleStatistics = styled.div`
//   color: rgba(0, 0, 0, 0.8);
//   font-size: 16px;
//   font-weight: 700;
//   display: flex;
//   align-items: center;
//   padding: 0;
//   /* cursor: pointer; */
//   span {
//     margin-right: 4px;
//     display: inline-block;
//   }

//   .divider {
//     margin: 0 6px 0 2px;
//     width: 5px;
//     height: 5px;
//     background-color: rgba(0, 0, 0, 0.8);
//     border-radius: 50%;
//   }
// `;

const ArticleShareItems = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 580px) {
    ${({ isTop }) =>
      isTop
        ? `
      justify-content: flex-end;
      margin-top: ${({ isTop }) => (isTop ? 10 : 0)}px;
    `
        : ``};
  }
`;

const ReadAtButton = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 1px solid ${grey.level3};
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Metropolis", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 30px; */
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    /* padding: 15px; */
  }

  @media (max-width: 580px) {
    /* padding: 15px 35px 15px 15px; */
  }

  &:hover {
    color: #333;
  }
`;

const ArtcleInfoWrapper = styled.div`
  position: fixed;
  top: 30%;
  left: 10%;
  padding: 20px;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: ${({ hide }) => (hide ? "hidden" : "unset")};

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ArticleInfoTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: ${grey.level4};
  font-weight: 700;
  text-align: center;
`;

const ArticleInfoButton = styled.a`
  display: block;
  padding: 3px 15px 5px;
  border: 1px solid ${grey.level3};
  color: ${grey.level2};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    color: ${black};
    border: 1px solid ${black};
  }
`;

const ArticleInfoDivider = styled.div`
  width: 70%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 20px;
`;

const ArticleTagsList = styled.div`
  margin: 30px 14px 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin: 10px 0 15px;
  }
`;

const ArticleTag = styled(Link)`
  display: block;
  padding: 5px 10px;
  background-color: ${grey.level6};
  color: ${grey.level2};
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin: 5px 10px 5px 0px;
  border-radius: 4px;

  &:hover {
    color: ${grey.level2};
  }
  @media (max-width: 768px) {
    font-size: 14px;
    margin: 5px;
  }
`;

const LoadMoreTags = styled.div`
  font-weight: 500;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const ArticlePublishActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid ${grey.level5};
  border-bottom: 1px solid ${grey.level5};
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  /* @media (max-width: 580px) {
     display: block;
   } */
`;

const DigestCreatedByInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 15px auto 0;
  }
`;

// const ArticleNewsLetterWrapper = styled.div`
//   margin: 35px 0;

//   @media (max-width: 768px) {
//     margin: 25px 0;
//   }
// `;
// const ArticleNewsLetterText = styled.div`
//   text-align: center;
//   font-family: 'Metropolis', sans-serif;
//   font-size: 22px;
//   line-height: 1.3;
//   font-weight: 600;
//   color: #333333;
//   cursor: pointer;

//   div {
//     margin-top: 5px;
//   }

// `;

// const ArticleNewsLetterSubtitle = styled.div`
//   font-size: 18px;
//   line-height: 1.3;
//   font-weight: 500;
//   color: rgba(101,101,101,0.8);
//   text-align: center;
//   font-family: 'Metropolis', sans-serif;
//   cursor: pointer;
//   text-decoration: underline;

//   div {
//     margin-top: 10px;
//   }

// `

const StaffDetailWrapper = styled.div`
  font-size: 16px;
  color: rgba(${(props) => props.theme.fontColor.level2RGB}, 0.5);
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ReadOnActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ReadReasonWrapper = styled.a`
  padding: 20px;
  border: 1px solid ${grey.level3};
  border-radius: 6px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
  display: block;
  width: calc(100% - 20px);
  margin: 20px auto;
`;

const ReadReasonTitle = styled.div`
  color: #000;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
`;

const ReadReasonDesc = styled.div`
  color: ${grey.level2};
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
`;

// const FollowDigestButton = styled.div`
//   width: 120px;
//   text-align: center;
//   padding: 7px 10px;
//   background-color: ${({ isFollowed }) => isFollowed ? '#E12830' :grey.level7};
//   font-size: 16px;
//   line-height: 1.3;
//   border-radius: 4px;
//   cursor: pointer;
//   color: ${({ isFollowed }) => isFollowed ? '#fff' : '#000'};
//   /* color: ${white}; */
// `;

const DesktopDigestCover = styled.div`
  // display: ${({ isFocus }) => (isFocus === true ? "block" : "none")};

  // @media (max-width: 768px) {
  //   display: ${({ isVideoDigest }) => (isVideoDigest ? "block" : "none")};
  // }
`;

// const MobileDigestCover = styled.div`
//   display: ${({ isFocus }) => (isFocus === true ? "none" : "block")};

//   // @media (max-width: 768px) {
//   width: 160px;
//   margin-left: 10px;
//   // }

//   @media (max-width: 580px) {
//     display: block;
//     width: 130px;
//   }
// `;

const ArticleTitleWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  @media (max-width: 768px) {
    width: 90%;
    margin: 15px auto;
  }
`;

const inititalValue = {
  latScrolledValue: [],
  articleData: null,
  shareArticleData: null,
  articleItemInfo: false,
  openLoginModal: false,
  reloadComment: false,
  bookmarkLoader: false,
  readLaterLoader: false,
  articleLoading: false,
  digestsNotFound: null,
  loadMoreArticleTag: false,
  subscribeNewsLetter: false,
  isCalledDigestEndEvent: false,
  showAppBannerAds: window.innerWidth <= 768 ? true : false,
  playingVideoID: null,
  isFollowed: false,
  isFollowedLoading: false,
};
/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "SET_ARTICLE_DATA":
        draft.articleLoading = false;
        draft.latScrolledValue.length = 0;
        if (action.value && action.value.type.code === "255") {
          draft.showVideoOverlay = true;
        }
        if (action.read) {
          draft.articleData.read = true;
        } else {
          draft.articleData = action.value;
        }

        draft.isFollowed =
          action.value &&
          action.value.source &&
          action.value.source.host_data &&
          action.value.source.host_data.selected;
        break;
      case "SET_DIGEST_BOOKMARK":
        draft.bookmarkLoader = false;
        if (action.isFocusCard) {
          draft.articleData.focus_card.like = action.value;
        } else {
          draft.articleData.like = action.value;
          if (action.value) {
            draft.articleData.likes += 1;
          } else {
            draft.articleData.likes -= 1;
          }
        }
        break;
      case "SET_DIGEST_READ_LATER":
        draft.readLaterLoader = false;
        if (action.isRelatedDigest) {
          draft.articleData.related_articles =
            draft.articleData.related_articles.map((item) => {
              if (item.id === action.data.id) {
                item.readlater = action.value;
              }

              return item;
            });
        } else if (action.isFocusCard) {
          draft.articleData.focus_card.readlater = action.value;
        } else {
          draft.articleData.readlater = action.value;
        }
        break;
      case "SET_SHARE_ARTICLE_DATA":
        draft.shareArticleData = action.value;
        break;
      case "SET_ARTICLE_ITEM_INFO":
        draft.articleItemInfo = action.value;
        break;
      case "SET_OPEN_LOGIN_MODAL":
        draft.openLoginModal = action.value;
        break;
      case "SET_RELOAD_COMMENT":
        draft.reloadComment = action.value;
        break;
      case "SET_BOOKMARK_LOADER":
        draft.bookmarkLoader = action.value;
        break;
      case "SET_READ_LATER_LOADER":
        draft.readLaterLoader = action.value;
        break;
      case "SET_ARTICLE_LOADING":
        draft.articleLoading = action.value;
        break;
      case "SET_DIGEST_NOT_FOUNT_ISSUE":
        draft.digestsNotFound = action.value;
        draft.articleLoading = false;
        break;
      case "SET_LOAD_MORE_ARTICLE_TAG":
        draft.loadMoreArticleTag = action.value;
        break;
      case "SET_LAST_SCROLLED":
        if (!draft.latScrolledValue.includes(action.value))
          draft.latScrolledValue.push(action.value);
        break;
      case "SET_PLAYING_VIDEO":
        draft.playingVideoID = action.value;
        break;
      case "SET_SHOW_SUBSCRIBE_NEWS_LETTER":
        logAmplitudeEvent("DigestBody NewsletterText Clicked", {
          article_id: draft.articleData.id,
          slug: draft.articleData.slug,
          category_name:
            draft.articleData && draft.articleData.categories
              ? draft.articleData.categories.map((item) => item.title).join(",")
              : "",
        });
        draft.subscribeNewsLetter = action.value;
        break;
      case "SET_NEWSLETTER_TEXT_VISIBLE":
        if (draft.articleData.newsletter_text) {
          draft.articleData.newsletter_text.visible = false;
        }
        break;
      case "SET_CALLED_DIGEST_END_EVENT":
        draft.isCalledDigestEndEvent = action.value;
        break;
      case "SET_SHOW_APP_BANNER_ADS":
        draft.showAppBannerAds = action.value;
        break;
      case "SET_HOST_FOLLOWED":
        draft.isFollowed = action.value;
        draft.isFollowedLoading = false;
        break;
      case "SET_HOST_FOLLOWED_LOADING":
        draft.isFollowedLoading = action.value;
        break;
    }
  });

const ArticleShareWrapper = ({
  articleData,
  onDigestReadLater,
  onDigestBookMark,
  onClickShare,
  isTop,
}) => (
  <ArticleShareItems isTop={isTop}>
    <DigestActions
      data={articleData}
      onDigestReadLater={onDigestReadLater}
      onDigestBookMark={onDigestBookMark}
      onClickShare={onClickShare}
    />
    {/* <MenuItems
      onClick={() => onDigestReadLater(articleData)}
      title="Read Later"
    // onClick={() => onClickDownloadAppDropdown(true)}
    >
      <Image src={articleData.readlater ? readLaterAdded : addReadLaterImage} />
    </MenuItems>
    <BookmarkArticle
      onClick={() => onDigestBookMark(articleData)}
      title="Like"
    // onClick={() => onClickDownloadAppDropdown(true)}
    >
      <Icon
        name={articleData.like ? "heart" : "heart outline"}
        size="large"
        color={articleData.like ? 'red' : 'grey'}
      />
    </BookmarkArticle>
    <ShareArticle
      onClick={onClickShare}
    // onClick={() => onClickDownloadAppDropdown(true)}
    >
      <Icon
        name="share alternate"
        size="large"
        color="grey"
      />
    </ShareArticle> */}
  </ArticleShareItems>
);

const checkParentDesc = (child) => {
  var node = child.parentNode;
  while (node != null && node.classList) {
    node.classList.contains("articleDesc");
    if (node.classList.contains("articleDesc")) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const DesktopDigestItem = ({
  match: { params },
  location: { state: locationState, pathname, search },
  setGuestUuid,
  handleUserNotFound,
  theme,
  setTheme,
}) => {
  const history = useHistory();
  // const commentWrapperRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const articleInfoTextRef = useRef(null);
  const articleInfoRef = useRef(null);
  const commentBoxRef = useRef(null);
  const headerRef = useRef(null);
  const videoRef = useRef(null);
  const videoIframeRef = useRef(null);
  const readAtButtonRef = useRef(null);
  const isLoginRef = useRef(localStorage.getItem("userData"));
  const [state, dispatch] = useReducer(reducer, inititalValue);
  const { t: translation } = useTranslation();
  //----player test

  const [videoPlayId, setVideoPlayId] = useState(null);
  //const [videoThumbnail, setVideoThumbnail] = useState(null);
  // const digestDescRef = useRef(null);
  const [digestDescRef, setDigestDesc] = useState(null);
  const [getLatestHtml, setGetLatestHtml] = useState("");
  const {
    articleData,
    shareArticleData,
    articleItemInfo,
    openLoginModal,
    reloadComment,
    bookmarkLoader,
    readLaterLoader,
    articleLoading,
    digestsNotFound,
    loadMoreArticleTag,
    latScrolledValue,
    playingVideoID,
    subscribeNewsLetter,
    isCalledDigestEndEvent,
    showAppBannerAds,
    // isFollowed,
    // isFollowedLoading
  } = state;

  const setScrollPos = (scrollPercentRounded, isDigestEnd) => {
    dispatch({ type: "SET_LAST_SCROLLED", value: scrollPercentRounded });
    if (articleData) {
      logAmplitudeEvent("Article Scrolled", {
        article_id: articleData.id,
        scroll_position: scrollPercentRounded,
        slug: articleData.slug,
        category_name:
          articleData && articleData.categories
            ? articleData.categories.map((item) => item.title).join(",")
            : "",
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = (e) => {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    const scrollPercentRounded = Math.round(
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    );
    if (
      articleData &&
      !latScrolledValue.includes(scrollPercentRounded) &&
      (scrollPercentRounded === 25 ||
        scrollPercentRounded === 50 ||
        scrollPercentRounded === 75 ||
        scrollPercentRounded === 95)
    ) {
      setScrollPos(scrollPercentRounded);
    } else {
      if (scrollPercentRounded >= 25 && !latScrolledValue.includes(25)) {
        setScrollPos(25);
      }
      if (scrollPercentRounded >= 50 && !latScrolledValue.includes(50)) {
        setScrollPos(50);
      }
      if (scrollPercentRounded >= 75 && !latScrolledValue.includes(75)) {
        setScrollPos(75);
      }
      if (scrollPercentRounded >= 95 && !latScrolledValue.includes(95)) {
        setScrollPos(95);
      }
    }

    if (readAtButtonRef.current && !isCalledDigestEndEvent) {
      if (
        readAtButtonRef.current.offsetTop + 60 <
        window.scrollY + window.innerHeight
      ) {
        logAmplitudeEvent("Article Scrolled", {
          article_id: articleData.id,
          scroll_position: scrollPercentRounded,
          article_end: true,
          slug: articleData.slug,
          category_name:
            articleData && articleData.categories
              ? articleData.categories.map((item) => item.title).join(",")
              : "",
        });
        dispatch({ type: "SET_CALLED_DIGEST_END_EVENT", value: true });
      }
    }
    if (
      articleInfoTextRef.current &&
      articleInfoTextRef.current.offsetTop - 80 < window.scrollY &&
      !articleItemInfo
    ) {
      if (articleInfoRef.current && commentBoxRef.current) {
        const articleInfoBottomPosition =
          articleInfoRef.current.offsetHeight +
          articleInfoRef.current.offsetTop;
        if (
          !(
            commentBoxRef.current.offsetTop - window.scrollY <
            articleInfoBottomPosition
          )
        ) {
          dispatch({ type: "SET_ARTICLE_ITEM_INFO", value: true });
        }
      }
    } else if (articleItemInfo) {
      let isHide =
        articleInfoTextRef.current &&
        articleInfoTextRef.current.offsetTop - 80 > window.scrollY;
      if (articleInfoRef.current && commentBoxRef.current) {
        const articleInfoBottomPosition =
          articleInfoRef.current.offsetHeight +
          articleInfoRef.current.offsetTop;
        if (
          commentBoxRef.current.offsetTop - window.scrollY <
          articleInfoBottomPosition
        ) {
          isHide = true;
        }
      }
      if (isHide) {
        dispatch({ type: "SET_ARTICLE_ITEM_INFO", value: false });
      }
    }
  };

  useEffect(() => {
    const anchorClick = (e) => {
      const anchor = e.target.closest("a");
      logAmplitudeEvent("ArticleBodyLink Clicked", {
        article_id: articleData.id,
        link_target: anchor.getAttribute("href"),
        slug: articleData.slug,
        category_name:
          articleData && articleData.categories
            ? articleData.categories.map((item) => item.title).join(",")
            : "",
      });
    };

    if (articleData !== null) {
      console.log("articleData:", articleData.id);
      const file = getUpdatedHtml(
        videoPlayId,
        0,
        articleData.custom_thumbnail,
        articleData.video_summary
      );
      setGetLatestHtml(file);
    }
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    if (articleData && articleData.cover_pic.match(validUrl)) {
      let video_id = articleData.cover_pic.match(validUrl)[1];
      setVideoPlayId(video_id);
      //setVideoThumbnail(articleData.custom_thumbnail);
      console.log("video_id", video_id);
    }
    if (window) {
      let anchors = document.getElementsByTagName("a");
      anchors = Array.prototype.slice.call(anchors);
      anchors = anchors.filter((anchor) => {
        if (checkParentDesc(anchor)) {
          return true;
        }
        return false;
      });
      anchors.forEach((anchor) => {
        anchor.setAttribute("target", "_blank");
        anchor.addEventListener("click", anchorClick);
      });
    }
    return () => {
      if (window) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let anchors = document.getElementsByTagName("a");
        anchors = Array.prototype.slice.call(anchors);
        anchors = anchors.filter((anchor) => {
          if (checkParentDesc(anchor)) {
            return true;
          }
          return false;
        });
        anchors.forEach((anchor) => {
          anchor.removeEventListener("click", anchorClick);
        });
      }
    };
  }, [articleData, videoPlayId]);

  // $(document).ready(function() {

  //   $(window).scroll(function(e){
  //     var scrollTop = $(window).scrollTop();
  //     var docHeight = $(document).height();
  //     var winHeight = $(window).height();
  //     var scrollPercent = (scrollTop) / (docHeight - winHeight);
  //     var scrollPercentRounded = Math.round(scrollPercent*100);

  //       $('#scrollPercentLabel>span').html(scrollPercentRounded);
  //       repositionLabel();
  //   });

  //   $(window).resize(function(){
  //     repositionLabel();
  //   });

  //   function repositionLabel() {
  //     $('#scrollPercentLabel').css({
  //       position:'fixed',
  //       left: ($(window).width() - $('#scrollPercentLabel').outerWidth()) / 2,
  //       top: (($(window).height() - $('#scrollPercentLabel').outerHeight()) / 2) - $('#scrollPercentLabel').height()
  //     });
  //   }

  //   repositionLabel();

  // });

  useEffect(() => {
    if (reloadComment) {
      setTimeout(() => {
        dispatch({ type: "SET_RELOAD_COMMENT", value: false });
      }, 1000);
    }
  }, [reloadComment]);

  useEffect(() => {
    if (openLoginModal) {
      setTimeout(() => {
        dispatch({ type: "SET_OPEN_LOGIN_MODAL", value: false });
      }, 1000);
    }
  }, [openLoginModal]);

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (window) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  useEffect(() => {
    if (!articleData && !articleLoading && !digestsNotFound) {
      dispatch({ type: "SET_ARTICLE_LOADING", value: true });
      fetchDigestDetail({ digestId: params.digestSlug })
        .then((response) => {
          logAmplitudeEvent("Fetched Digest", {
            article_id: response.data.id,
            slug: response.data.slug,
            category_name:
              response.data && response.data.categories
                ? response.data.categories.map((item) => item.title).join(",")
                : "",
          });
          if (
            params.digestSlug &&
            typeof parseInt(params.digestSlug) === "number"
          ) {
            history.replace(
              `/digests/${response.data.slug || response.data.id}${search}`
            );
          }
          dispatch({ type: "SET_ARTICLE_DATA", value: response.data });
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.status === 403 &&
            handleUserNotFound
          ) {
            handleUserNotFound();
          } else if (error && error.response && error.response.status === 404) {
            dispatch({
              type: "SET_DIGEST_NOT_FOUNT_ISSUE",
              value: error.response.data.detail || error.response.data.msg,
            });
          }
          if (window && window.Raven)
            window.Raven.captureException(error.response);
          // ToastsStore.error('Something went wrong.');
        });
    }
  }, [
    articleData,
    articleLoading,
    digestsNotFound,
    handleUserNotFound,
    history,
    params.digestSlug,
    search,
  ]);

  useEffect(() => {
    if (articleData && !articleLoading && !digestsNotFound) {
      dispatch({ type: "SET_ARTICLE_LOADING", value: true });
      // dispatch({ type: 'SET_ARTICLE_DATA', value: null });
      fetchDigestDetail({ digestId: params.digestSlug })
        .then((response) => {
          logAmplitudeEvent("Fetched Digest", {
            article_id: response.data.id,
          });
          dispatch({ type: "SET_ARTICLE_DATA", value: response.data });
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.status === 403 &&
            handleUserNotFound
          ) {
            handleUserNotFound();
          }

          if (window && window.Raven)
            window.Raven.captureException(error.response);
          if (error && error.response && error.response.status === 404) {
            dispatch({
              type: "SET_DIGEST_NOT_FOUNT_ISSUE",
              value: error.response.data.detail || error.response.data.msg,
            });
          }
          // ToastsStore.error('Something went wrong.');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (articleData && !articleData.read) {
      logAmplitudeEvent("Article Read", {
        article_id: articleData.id,
        article_type: articleData.type.code,
        slug: articleData.slug,
        category_name:
          articleData && articleData.categories
            ? articleData.categories.map((item) => item.title).join(",")
            : "",
      });
      addReadDigest({ digests: [articleData.id] })
        .then((response) => {
          dispatch({ type: "SET_ARTICLE_DATA", read: true });
        })
        .catch((error) => {
          if (window && window.Raven)
            window.Raven.captureException(error.response);
        });
    }
  }, [articleData]);

  useEffect(() => {
    // if (!(locationState && locationState.articleData)) {
    //   history.push('/digests');
    // }
  }, [history, locationState]);

  const onDigestBookMark = (data, isRelatedDigest, isFocusCard) => {
    if (!bookmarkLoader) {
      dispatch({ type: "SET_BOOKMARK_LOADER", value: true });
      if (data.like) {
        deleteBookmarkDigest({ digestId: data.id })
          .then((response) => {
            if (response.status === 202) {
              dispatch({
                type: "SET_DIGEST_BOOKMARK",
                value: false,
                digestId: data.id,
                isFocusCard,
              });
              // ToastsStore.success('Digest bookmark removed successfully.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      } else {
        bookmarkDigest({ digestId: data.id, data, pageType: AmplitudePageType })
          .then((response) => {
            if (response.status === 201) {
              dispatch({
                type: "SET_DIGEST_BOOKMARK",
                value: true,
                digestId: data.id,
                isFocusCard,
              });
              // ToastsStore.success('Digest bookmark added successfully.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      }
    }
  };

  const onDigestReadLater = (data, isRelatedDigest, isFocusCard) => {
    if (!readLaterLoader) {
      dispatch({ type: "SET_READ_LATER_LOADER", value: true });
      if (data.readlater) {
        deleteReadLater({ digestId: data.id })
          .then((response) => {
            if (response.status === 202) {
              dispatch({
                type: "SET_DIGEST_READ_LATER",
                data,
                value: false,
                isRelatedDigest,
                isFocusCard,
              });
              // ToastsStore.success('Removed from Read Later.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      } else {
        addReadLater({ digestId: data.id, data, pageType: AmplitudePageType })
          .then((response) => {
            if (response.status === 201) {
              dispatch({
                type: "SET_DIGEST_READ_LATER",
                data,
                value: true,
                isRelatedDigest,
                isFocusCard,
              });
              // ToastsStore.success('Saved to Read Later.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      }
    }
  };

  let articleTagsData =
    (articleData &&
      articleData.tag.filter((item) => !item.is_internal).slice(0, 5)) ||
    [];
  if (loadMoreArticleTag) {
    articleTagsData = articleData.tag;
  }

  const createYTRef = useCallback(() => {
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (articleData.cover_pic && articleData.cover_pic.match(validUrl)) {
      let video_id = articleData.cover_pic.match(validUrl)[1];
      videoRef.current = new window.YT.Player(`youtubePlayer`, {
        videoId: video_id,
        height: "100%",
        width: "100%",
        allowfullscreen: true,
        playerVars: {
          fs: 0,
        },
        events: {
          onReady: (value) => {
            dispatch({
              type: "SET_VIDEO_TOTAL_DURATION",
              value: videoRef.current.getDuration(),
            });
          },
        },
      });
      videoIframeRef.current = videoRef.current.f;
    }
  }, [articleData]);

  const loadVideo = useCallback(() => {
    if (
      articleData.cover_pic &&
      articleData.cover_pic &&
      !videoRef.current &&
      window.YT
    ) {
      if (typeof window.YT.Player === "undefined") {
        window.onYouTubeIframeAPIReady = () => {
          createYTRef();
        };
      } else {
        createYTRef();
      }
    }
  }, [articleData, createYTRef]);

  useEffect(() => {
    if (
      articleData &&
      articleData.cover_pic &&
      articleData.cover_pic.includes("https://www.youtube.com/watch?v") &&
      !videoRef.current
    ) {
      loadVideo();
    }
  }, [loadVideo, articleData]);

  useEffect(() => {
    if (articleData && articleData.digest) {
      const callback = async () => {
        setDigestDesc(addHtmlElement(articleData.digest));
      };
      callback();
      // digestDescRef  = articleData.digest;
    }
  }, [articleData]);

  const collectionDigestsBody = [];
  let count = 0;
  if (articleData && digestDescRef && articleData.type.code === "300") {
    const regex = /\[@([\w-]+)]\((\d+)\)/gm;
    let m;
    const matchArray = [];
    while ((m = regex.exec(digestDescRef)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      const matchObject = {
        digestTitle: m[0],
        slug: m[1],
        id: m[2],
        index: m.index,
      };
      matchArray.push(matchObject);
    }

    if (matchArray && matchArray.length > 0) {
      matchArray.forEach((item, index) => {
        if (index === 0) {
          const str = digestDescRef.substring(0, item.index);
          const obj = {
            str,
            isCollectionDigest: false,
          };
          collectionDigestsBody.push(obj);
        } else {
          const prevItem = matchArray[index - 1];
          const prevItemEndIndex = prevItem.index + prevItem.digestTitle.length;
          if (item.index > prevItemEndIndex) {
            const str = digestDescRef.substring(prevItemEndIndex, item.index);
            const obj = {
              str,
              isCollectionDigest: false,
            };
            collectionDigestsBody.push(obj);
          }
        }
        collectionDigestsBody.push({
          ...item,
          index: count,
          isCollectionDigest: true,
        });

        if (index === matchArray.length - 1) {
          const itemEndIndex = item.index + item.digestTitle.length;
          const str = digestDescRef.substring(itemEndIndex);
          if (str && str.trim() !== "") {
            const obj = {
              str,
              isCollectionDigest: false,
            };
            collectionDigestsBody.push(obj);
          }
        }

        count++;
      });
    }
  }
  // const onHandleFollow = () => {
  //   if(!isFollowedLoading) {
  //     dispatch({ type: 'SET_HOST_FOLLOWED_LOADING', value: true});
  //     postHostFollow({ hosts: [articleData.source.host_data.id]}).then(response => {

  //       dispatch({ type: 'SET_HOST_FOLLOWED', value: response.data.results[0] && response.data.results[0].selected})
  //     })
  //     .catch(error => {

  //     })
  //   }
  // }

  const redirectUrl = process.env.REACT_APP_ENV.includes("production")
    ? "https://dashboard.travelnewsdigest.in/digests/"
    : "https://sdashboard.travelnewsdigest.in/digests/";

  const updateHtml = useCallback(() => {
    const container2 = document.querySelector(`#render-html-${articleData.id}`);
    if (!container2) {
      setTimeout(() => {
        updateHtml();
      }, 200);
    } else {
      container2.innerHTML = "";

      const range = document.createRange();
      range.setStart(container2, 0);
      container2.appendChild(range.createContextualFragment(digestDescRef));
    }
  }, [articleData, digestDescRef]);

  useEffect(() => {
    if (articleData && digestDescRef) {
      updateHtml();
    }
  }, [articleData, digestDescRef, updateHtml]);

  console.log("articleData-----", articleData);
  return (
    <>
      <Helmet>
        <title>{articleData && articleData.title}</title>
      </Helmet>
      <ToastsContainer
        position={ToastsContainerPosition.BOTTOM_LEFT}
        store={ToastsStore}
      />
      <PageHeader
        fixed
        openLogin={openLoginModal}
        isDigestDetailView
        digestSlug={articleData && articleData.slug}
        onLogin={() => {
          if (setGuestUuid) {
            setGuestUuid();
          }
          dispatch({ type: "SET_RELOAD_COMMENT", value: true });
          isLoginRef.current = localStorage.getItem("userData");
          if (localStorage.getItem("subscribed")) {
            dispatch({ type: "SET_NEWSLETTER_TEXT_VISIBLE" });
          }
        }}
        headerRef={headerRef}
        handleUserNotFound={handleUserNotFound}
        showEdit={localStorage.getItem("privilege")}
        redirectUrl={redirectUrl}
        articleData={articleData && articleData.id}
        theme={theme}
        setTheme={setTheme}
      />
      <Wrapper
        headerHeight={
          (headerRef && headerRef.current && headerRef.current.offsetHeight) ||
          0
        }
        ref={scrollContainerRef}
      >
        <Container>
          {digestsNotFound && !articleData && <Page404 />}
          {articleLoading && <DigestViewShimmer />}
          {!articleLoading && articleData && (
            <>
              <ArticleTitleWrapper>
                {/* <h1>Video Mobile</h1> */}
                {/* {
                // window.innerWidth <= 768 && 
                !articleData.cover_pic.includes('https://www.youtube.com/watch?v') && (
                <MobileDigestCover isFocus={articleData && articleData.is_cover_pic_focused}>
                    <RenderDigestCover
                      data={articleData}
                      history={history}
                      playingVideoID={playingVideoID}
                      isMobileDigestCover
                      setPlayingVideo={value => {
                        dispatch({ type: 'SET_PLAYING_VIDEO', value })
                      }}
                      theme={theme}
                    />
                </MobileDigestCover>
                  )} */}
                <ArticleTitle>
                  <DigestAuthorInfo
                    avatarSize={"mini"}
                    usernameStyle={{
                      fontSize: 14,
                      marginLeft: 10,
                      fontFamily: "Metropolis",
                    }}
                    data={articleData.digest_author}
                  />
                  <TagsListWrapper>
                    {articleData.categories.slice(0, 3).map((category) => (
                      <TagWrapper
                        to={`/category/${category.slug}`}
                        key={category.slug}
                      >
                        <span>#</span>
                        {`${category.title}`}
                      </TagWrapper>
                    ))}
                  </TagsListWrapper>
                  {articleData.title}
                </ArticleTitle>
              </ArticleTitleWrapper>
              <DigestCreatedByInfoWrapper>
                <DigestCreatedByInfo data={articleData} />
                <DigestActionsContainer>
                  <DigestActionWrapper
                    data={articleData}
                    shareUrl={
                      articleData &&
                      (articleData.source.sl || articleData.source.link)
                    }
                    onDigestReadLater={onDigestReadLater}
                    onDigestBookMark={onDigestBookMark}
                    onClickShare={(data) => {
                      dispatch({
                        type: "SET_SHARE_ARTICLE_DATA",
                        value: data || articleData,
                      });
                    }}
                  />
                </DigestActionsContainer>
              </DigestCreatedByInfoWrapper>
              {articleData.type.code !== "300" && (
                <ArticlePublishActionWrapper>
                  <DigestPublishWrapper>
                    <DigestPublishInfo data={articleData} />
                    {/* {articleData.source && articleData.source.host_data && articleData.source.host_data.logo_link && articleData.type.code !== '300' && (
                      <DigestIconWrapper>
                        <Image src={articleData.source && articleData.source.host_data && articleData.source.host_data.logo_link} />
                      </DigestIconWrapper>
                    )}
                      <DigestInfoText
                        ref={articleInfoTextRef}
                      >
                        {articleData.source && articleData.source.host && articleData.type.code !== '300'&& (
                          <>
                            <a
                              href={articleData.source && articleData.source.link}
                              onClick={event => {
                                event.stopPropagation();
                                logAmplitudeEvent('Article Opened', {
                                  article_id: articleData.id,
                                  article_type: articleData.type.code,
                                  action: 'bottom',
                                  pageType: AmplitudePageType,
                                  slug: articleData.slug,
                                  category_name: articleData && articleData.categories ? articleData.categories.map(item => item.title).join(',') : ''
                                });
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >{`${articleData.source && articleData.source.host}`}
                              <PublicationExternalLink>
                                <Icon name="externalLink" size={16} />
                              </PublicationExternalLink>
                            </a>
                            {/* {articleData.first_published_at_human && (<span className="divider">/</span>)} */}
                    {/* </>
                        )}
                      {articleData.source && articleData.source.authors && articleData.source.authors.length > 0 && (
                        <div>{articleData.source.authors.map((author, index) => `${index !== 0 ? ',' : ''} ${author.name}`)}</div>
                      )}
                      </DigestInfoText> */}
                  </DigestPublishWrapper>
                  {/* {articleData.source && articleData.source.host_data && articleData.source.host_data.id && (
                    <FollowDigestButton
                      onClick={onHandleFollow}
                      isFollowed={isFollowed}
                    >
                      {isFollowed ? 'Following' : 'Follow'}
                    </FollowDigestButton>
                  )} */}
                  {/* <ArticleShareWrapper
                      isTop
                      onClickShare={() => {
                        dispatch({ type: 'SET_SHARE_ARTICLE_DATA', value: articleData });
                      }}
                    />
                  </DigestActionsContainer>
                  {/* <ArticleShareWrapper
                    isTop
                    onClickShare={() => {
                      dispatch({ type: 'SET_SHARE_ARTICLE_DATA', value: articleData });
                    }}
                    onDigestBookMark={onDigestBookMark}
                    onDigestReadLater={onDigestReadLater}
                    articleData={articleData}
                  /> */}
                </ArticlePublishActionWrapper>
              )}
              {/* {articleData.cover_pic.includes('https://www.youtube.com/watch?v') && ( */}
              {articleData.type.code === "255" ? (
                <iframe
                  width="100%"
                  height="315"
                  frameborder="0"
                  id="yt-player"
                  //src="https://www.youtube-nocookie.com/embed/XPMrnR1_Biw"
                  //src={`https://www.youtube.com/embed/Ea3bAPtom1k`}
                  //src={`https://d3dpeyyyopomgn.cloudfront.net/opinion/vijay-testing/index_umang.html?videoId=${videoPlayId}&startTime=&frame=${1}&c=0&uid=dhdusnide&thumbnail=${videoThumbnail}`}
                  srcDoc={getLatestHtml}
                  //allow="accelerometer; autoplay"
                  allowfullscreen
                ></iframe>
              ) : articleData.type.code === "295" ? (
                // <p>mux video</p>
                // <ReactHlsPlayer
                //   src={articleData.cover_pic}
                //   autoPlay={true}
                //   controls={true}
                //   width="100%"
                //   height="315"
                // />
                <ReactPlayer
                  controls
                  playing={true}
                  volume="1"
                  // muted
                  width="100%"
                  height={315}
                  url={articleData.cover_pic}
                />
              ) : (
                <DesktopDigestCover
                  isFocus={articleData && articleData.is_cover_pic_focused}
                  isVideoDigest={articleData.cover_pic.includes(
                    "https://www.youtube.com/watch?v"
                  )}
                >
                  <RenderDigestCover
                    data={articleData}
                    history={history}
                    playingVideoID={playingVideoID}
                    isMobileDigestCover
                    setPlayingVideo={(value) => {
                      dispatch({ type: "SET_PLAYING_VIDEO", value });
                    }}
                    theme={theme}
                  />
                </DesktopDigestCover>
              )}

              <ArticleContentWrapper>
                {/* {articleData.format_human && articleData.created_by && (
                  <DigestCreatedBy>
                    {articleData.format_human && (
                      <span className="label">
                        {`${articleData.format_human}`}
                      </span>
                    )}
                    {articleData.format_human && articleData.created_by.name && (
                      <span className="divider" />
                    )}
                    {articleData.created_by.name && (<span>{`By ${articleData.created_by.name}`}</span>)}
                  </DigestCreatedBy>
                )} */}
                {articleData &&
                articleData.type.code === "300" &&
                articleData.collection_digests &&
                articleData.collection_digests.length > 0 ? (
                  <>
                    {collectionDigestsBody &&
                      collectionDigestsBody.length > 0 && (
                        <div>
                          {collectionDigestsBody.map((item, index) => (
                            <>
                              {item.isCollectionDigest ? (
                                <RenderCollectionDigest
                                  item={item}
                                  collectionDigests={
                                    articleData.collection_digests
                                  }
                                  history={history}
                                  playingVideoID={playingVideoID}
                                  setPlayingVideo={(value) => {
                                    dispatch({
                                      type: "SET_PLAYING_VIDEO",
                                      value,
                                    });
                                  }}
                                  theme={theme}
                                />
                              ) : (
                                <ArticleDesc className="articleDesc">
                                  {digestDescRef &&
                                  articleData.markup &&
                                  articleData.markup.apply ? (
                                    <ArticleDesc
                                      dangerouslySetInnerHTML={{
                                        __html: item.str || "",
                                      }}
                                    />
                                  ) : (
                                    // <ReactMarkdown source={articleData.digest} />
                                    <ArticleDesc>
                                      {item.str
                                        .split(/\n/)
                                        .map((para, index) => (
                                          <p key={index}>{para}</p>
                                        ))}
                                    </ArticleDesc>
                                  )}
                                </ArticleDesc>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                  </>
                ) : (
                  <ArticleDesc
                    className="articleDesc"
                    ref={(a) => {
                      setTimeout(() => {
                        ApplyExpandablePoints(a);
                      }, 50);
                    }}
                  >
                    {digestDescRef &&
                    articleData.markup &&
                    articleData.markup.apply ? (
                      <ArticleDesc
                        id={`render-html-${articleData.id}`}
                        // dangerouslySetInnerHTML={{ __html: digestDescRef || '' }}
                      />
                    ) : (
                      // <ReactMarkdown source={digestDescRef} />
                      <ArticleDesc>
                        {digestDescRef &&
                          digestDescRef
                            .split(/\n/)
                            .map((para, index) => <p key={index}>{para}</p>)}
                      </ArticleDesc>
                    )}
                  </ArticleDesc>
                )}
                {articleData.tag && articleData.tag.length > 0 && (
                  <ArticleTagsList>
                    {articleTagsData.map((item) => (
                      <ArticleTag
                        key={item.id}
                        to={{
                          // pathname: item.is_stream ? `/tags/${item.slug}` : `/search`,
                          pathname: `/tags/${item.slug}`,
                          state: {
                            tagData: item,
                          },
                        }}
                        // onClick={() => {
                        //   history.push('/category/feed', {
                        //     tagData: item
                        //   });
                        // }}
                      >
                        {item.title}
                      </ArticleTag>
                    ))}
                    {articleData.tag.length > 5 && (
                      <LoadMoreTags
                        onClick={() => {
                          dispatch({
                            type: "SET_LOAD_MORE_ARTICLE_TAG",
                            value: !loadMoreArticleTag,
                          });
                        }}
                      >
                        {loadMoreArticleTag
                          ? translation("digestDetail.showLessTags")
                          : translation("digestDetail.showMoreTags")}
                      </LoadMoreTags>
                    )}
                  </ArticleTagsList>
                )}
                {subscribeNewsLetter && (
                  <SubscribeToNewsLetterFull
                    subscription={true}
                    subscribe={(value) => {
                      dispatch({
                        type: "SET_SHOW_SUBSCRIBE_NEWS_LETTER",
                        value,
                      });
                      if (localStorage.getItem("subscribed")) {
                        dispatch({ type: "SET_NEWSLETTER_TEXT_VISIBLE" });
                      }
                    }}
                  />
                )}
                {(articleData.format_human ||
                  (articleData.digest_author &&
                    articleData.digest_author.name)) && (
                  <StaffDetailWrapper>
                    {`${articleData.format_human} ${
                      articleData.format_human &&
                      articleData.digest_author &&
                      articleData.digest_author.name
                        ? "by"
                        : ""
                    } ${
                      articleData.digest_author &&
                      articleData.digest_author.name
                    }`}
                  </StaffDetailWrapper>
                )}
                {articleData.type.code !== "300" && (
                  <ReadReasonWrapper
                    // href={`${articleData.source.link}`}
                    // target="_blank"
                    onClick={() => {
                      logAmplitudeEvent("Article Opened", {
                        article_id: articleData.id,
                        article_type: articleData.type.code,
                        action: "click-link",
                        pageType: AmplitudePageType,
                        slug: articleData.slug,
                        category_name:
                          articleData && articleData.categories
                            ? articleData.categories
                                .map((item) => item.title)
                                .join(",")
                            : "",
                      });
                    }}
                  >
                    {articleData.source.read_reason &&
                      articleData.source.read_reason_title && (
                        <ReadReasonTitle>
                          {articleData.source.read_reason &&
                            articleData.source.read_reason_title}
                        </ReadReasonTitle>
                      )}
                    {articleData.source.read_reason && (
                      <ReadReasonDesc>
                        {articleData.source.read_reason}
                      </ReadReasonDesc>
                    )}
                    <ReadAtButton>
                      <DigestPublishInfo
                        isReadAtButton
                        data={articleData}
                        hideFollowButton
                        isCenterAlign
                      />
                      {/* <DigestIconWrapper>
                        <Image src={articleData.source && articleData.source.host_data && articleData.source.host_data.logo_link} />
                      </DigestIconWrapper>
                      {`${translation("digestDetail.readOnButtonText")} ${articleData.source.host}`}
                      */}
                    </ReadAtButton>
                  </ReadReasonWrapper>
                )}
                {/* <ArticleFooterStatisticsWrapper>
                  
                </ArticleFooterStatisticsWrapper> */}
                <ReadOnActionsWrapper ref={readAtButtonRef}>
                  <DigestActionWrapper
                    data={articleData}
                    shareUrl={
                      articleData &&
                      (articleData.source.sl || articleData.source.link)
                    }
                    onDigestReadLater={onDigestReadLater}
                    onDigestBookMark={onDigestBookMark}
                    onClickShare={() => {
                      dispatch({
                        type: "SET_SHARE_ARTICLE_DATA",
                        value: articleData,
                      });
                    }}
                  />
                </ReadOnActionsWrapper>
                {articleData &&
                  articleData.focus_card &&
                  Object.keys(articleData.focus_card).length > 0 && (
                    <FocusCard
                      data={articleData.focus_card}
                      onDigestReadLater={onDigestReadLater}
                      onDigestBookMark={onDigestBookMark}
                      onClickShare={(data) => {
                        dispatch({
                          type: "SET_SHARE_ARTICLE_DATA",
                          value: data || articleData,
                        });
                      }}
                    />
                  )}
                {articleItemInfo && articleData.type.code === "275" && (
                  <ArtcleInfoWrapper
                    ref={articleInfoRef}
                    hide={!articleItemInfo}
                  >
                    <ArticleInfoTitle>
                      {articleData.source.host}
                    </ArticleInfoTitle>
                    <ArticleInfoButton
                      href={articleData.source.link}
                      target="_blank"
                      onClick={(event) => {
                        logAmplitudeEvent("Article Opened", {
                          article_id: articleData.id,
                          article_type: articleData.type.code,
                          action: "click-side-button",
                          page_type: AmplitudePageType,
                          slug: articleData.slug,
                          category_name:
                            articleData && articleData.categories
                              ? articleData.categories
                                  .map((item) => item.title)
                                  .join(",")
                              : "",
                        });
                      }}
                    >
                      {translation("digestDetail.readOriginalText")}
                    </ArticleInfoButton>
                    <ArticleInfoDivider />
                    <ArticleShareWrapper
                      onClickShare={() => {
                        dispatch({
                          type: "SET_SHARE_ARTICLE_DATA",
                          value: articleData,
                        });
                      }}
                      onDigestBookMark={onDigestBookMark}
                      onDigestReadLater={onDigestReadLater}
                      articleData={articleData}
                    />
                  </ArtcleInfoWrapper>
                )}
                <UserExperience
                  articleData={articleData}
                  data={articleData.actions}
                  digestId={articleData.id}
                />
                <FeedbackBox
                  questionData={
                    articleData.feedback && articleData.feedback.question
                  }
                  articleData={articleData}
                />
              </ArticleContentWrapper>
            </>
          )}
        </Container>
              
        {!!shareArticleData && (
          <ShareDialogBox
            onClose={() => {
              dispatch({ type: "SET_SHARE_ARTICLE_DATA", value: null });
            }}
            data={shareArticleData}
            open={!!shareArticleData}
            shareUrl={
              shareArticleData.source.sl || shareArticleData.source.link
            }
            isDesktop
            shareText={
              shareArticleData &&
              shareArticleData.share_data &&
              shareArticleData.share_data.share_text
            }
          />
        )}
      </Wrapper>
      {articleData && process.env.REACT_APP_ENV === "oped-production" ? (
        <StickyNewsLetterForm
          onSubscribe={() => {
            dispatch({ type: "SET_NEWSLETTER_TEXT_VISIBLE" });
          }}
          data={articleData.newsletter_text}
        />
      ) : null}
      {articleData && (
        <MobileAppBannerAds
          showAppBannerAds={showAppBannerAds}
          digestSlug={articleData && articleData.slug}
          onContinueMobileWeb={() => {
            dispatch({ type: "SET_SHOW_APP_BANNER_ADS", value: false });
          }}
        />
      )}
      <Footer />
    </>
  );
};

export default DesktopDigestItem;
