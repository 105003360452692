import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { grey } from 'colorPalette';

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  font-size: 16px;
  color: ${grey.level2};
  border-top: 1px solid ${grey.level5};

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const Footer = () => (
  <Wrapper>
    {`© ${moment(new Date()).format('YYYY')} Fairfest Media Limited | hello@thevideodigest.com`}
  </Wrapper>
);

export default Footer;