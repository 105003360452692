import React, { useState } from 'react';
import styled from 'styled-components';
import { grey } from 'colorPalette';
import { Image } from 'semantic-ui-react';
import DigestActions from '../DigestActions';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import DigestSourceInfo from './DigestSourceInfo';
import AuthorDescription from './AuthorDescription';
import _ from 'lodash';

const DigestCreatedBy = styled.div`
  font-size: ${({ fontSize }) => fontSize || '12px' };
  font-weight: 500;
  /* display: flex; */
  /* align-items: center; */
  color: ${props => props.theme.fontColor.level2};
  margin-top: ${({ marginTop }) => marginTop || '10px'};
  vertical-align: top;
  span {
    display: inline-block;
    color: ${props => props.theme.fontColor.level2};
    vertical-align: middle;
  }

  .label {
    color: ${({ color }) => color || '#f32826'};
    font-family: Metropolis, sans-serif;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .divider {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: ${({ color }) => color || grey.level2};
    border-radius: 50%;
    margin: 0 5px;
    vertical-align: middle;
  }

  @media (max-width: 870px) {
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export const DigestCreatedByInfo = ({ 
  data, 
  marginTop,
  fontSize,
  lineHeight,
  color
}) => {
  const showFormatHuman = data.feed_visible_fields && data.feed_visible_fields.format_human && data.format_human;
  const showDate = data.first_published_at_human && data.feed_visible_fields && data.feed_visible_fields.first_published_at;
  return (
    <>
      <DigestCreatedBy 
        marginTop={marginTop}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
      >
        {showFormatHuman && (
          <span className="label">
            {`${data.format_human}`}
          </span>
        )}
        {showDate && (<span>
          {showFormatHuman && showDate && (
            <span className="divider" />
          )}
          {`${data.first_published_at_human}`}
        </span>)}
      </DigestCreatedBy>
    </>
  );
}

const PublishInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

const DigestInfoText = styled.div`
  /* width: calc(100% - 110px); */
  display: flex;
  align-items: center;
  font-family: 'Metropolis', sans-serif;
  @media (max-width: 870px) {
    /* width: calc(100% - 90px); */
  }

  @media (max-width: 768px) {
    /* width: 100%; */
    /* margin-top: 10px; */
  }
`;

const DigestInfo = styled.div`
  color: rgba(0, 0, 0, .8);
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  font-family: Metropolis, sans-serif;
  span {
    margin-right: 4px;
  }

  @media (max-width: 870px) {
    font-size: 14px;
    line-height: 18px;
  }
  
  @media (max-width: 580px) {
    font-size: 12px;
    line-height: 16px;
  }

  a {
    color: #000;
  }

  .divider {
    margin: 0 4px;
  }
`;

const DigestInfoPubleshed = styled.div`
  a, span {
    display: block;
  }
`;

const DigestIconWrapper = styled.div`
  overflow: hidden;
  padding-right: 5px;
  width: 45px;
  img  {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    padding-right: 10px;
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

const ArticleShareItems = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-end;
    margin-top: 10px;
  }
`;

export const DigestPublishInfoOld = ({ 
  data,
  onDigestReadLater,
  onDigestBookMark,
  onClickShare,
  isHostClickable,
  amplitudePageType,
  visibleForAllDigest
}) => (
    <PublishInfoWrapper>
      <DigestInfoText>
        {data.source && data.source.host_data && data.source.host_data.logo_link && data && data.digest && (data.type.code !== '300' || visibleForAllDigest) && (
          <DigestIconWrapper>
            <Image src={data.source && data.source.host_data && data.source.host_data.logo_link} />
          </DigestIconWrapper>
        )}
        <DigestInfo>
          <>
            <DigestInfoPubleshed>
              {data && data.type && (data.type.code !== '300' || visibleForAllDigest) && data.source && data.source.host && (
                <>
                  {isHostClickable ? (
                    <a
                      href={data.source && data.source.link}
                      onClick={event => {
                        logAmplitudeEvent('Article Opened', {
                          article_id: data.id,
                          article_type: data.type.code,
                          action: 'click',
                          page_type: amplitudePageType,
                          slug: data.slug,
                          category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
                        });
                        event.stopPropagation();
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`${data.source && data.source.host}`}</a>
                  ) : (
                    <>
                      {data.source && data.source.host}
                    </>
                  )}
                </>
              )}
              {data.source && data.source.authors && data.source.authors.length > 0 && (
                <span>{data.source.authors.map((author, index) => `${index !== 0 ? ',' : ''} ${author.name}`)}</span>
              )}
            </DigestInfoPubleshed>
          </>
        </DigestInfo>
      </DigestInfoText>
      {(onDigestReadLater || onDigestBookMark || onClickShare) && (
        <ArticleShareItems>
          <DigestActions
            data={data}
            onDigestReadLater={onDigestReadLater}
            onDigestBookMark={onDigestBookMark}
            onClickShare={onClickShare}
          />
        </ArticleShareItems>
      )}
  </PublishInfoWrapper>
);

export const DigestPublishInfo = props => <DigestSourceInfo {...props} />

const LinkWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  color: #000;
  position: relative;
  div:hover {
    color: #000;
  }
`;

const LinkedSourceImgWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ length }) => length*27}px;

  @media (max-width: 580px) {
    width: ${({ length }) => length * 27}px;
  }
`;

const SourceImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ length }) => length*27}px;

  span {
    text-transform: capitalize;
  }
`;

export const DigestAuthorInfo = ({
  avatarSize = '',
  usernameStyle = {},
  data: {
    name,
    profile_pic,
    description,
  }}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  return (
    <>
      <LinkWrapper
        width={'auto'}
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}
      >
        <LinkedSourceImgWrapper>
          <SourceImageWrapper>
            <Image
              src={profile_pic ? profile_pic : '/static/media/profile.1c53bde6.svg'}
              avatar
              size={avatarSize}
              onError={(ele) => {
                ele.target.src = '/static/media/profile.1c53bde6.svg';
              }}
            />
            <span style={_.isEmpty(usernameStyle) ? {} : usernameStyle}>{name}</span>
          </SourceImageWrapper>
        </LinkedSourceImgWrapper>
      </LinkWrapper>
      {
        isOpenModal && <AuthorDescription
          data={{name, profile_pic, description}}
          active={isOpenModal}
          onClose={() => setOpenModal(false)}
        />
      }
    </>
  );
}
