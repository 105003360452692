import React, { useState, useEffect } from "react";
import styled from "styled-components";

import PageHeader from "Components/Header";
import Segment1 from "./Segment1";
// import Segment2 from "./Segment2";
import Segment3 from "./Segment3";
import Segment4 from "./Segment4";
// import Segment5 from "./Segment5";
import Segment6 from "./Segment6";
import Footer from "../NewsDigests/Desktop/DesktopDigestItem/Footer";
import { fetchNewsLetterLandingData } from "Api";
import { Loader } from "semantic-ui-react";
//import SegmentMobileScreen from "./SegmentMobileScreen";
import SegmentTagAndText from "./SegmentTagAndText";
import AwardSegment from "./AwardSegment";
import TestimonialSegment from "./TestimonialSegment";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageWrapper = styled.div`
  font-family: GothamBookRegular;
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: calc(100vh - 100px);
`;

const HomePage = ({ theme, setTheme, location: { pathname } }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchNewsLetterLandingData({ marcommPage: "clip_landing" })
      .then((response) => {
        setData(response.data.marcomm);
      })
      .catch((error) => {
        console.log("ClipLanding -> error", error);
      });
  }, []);

  // const dummyData = {
  //     count: 5,
  //     marcomm: [
  //         {
  //             title: "Investing for Everyone",
  //             subtitle:
  //                 "Commission-free investing, plus the tools you need to put your money in motion. Sign up and get your first stock for free. Certain limitations apply.",
  //             image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/1x__284c8d0c799d3c9649ca021c00228275.png"
  //         },
  //         {
  //             title:
  //                 "See our fee schedule to learn more about cost.",
  //         },
  //         {
  //             title: "Introducing IPO Access",
  //             subtitle:
  //                 "Get in at the IPO price. Now, you can become one of the first public investors in upcoming IPOs.",
  //             image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/balloon__ef7d8a9bb1c7845fcb7a6799c35d513e.svg",
  //             list: [
  //                 {
  //                     title: "It's your turn",
  //                     subtitle: "No minimum account balances or special status requirements.",
  //                     icon: "https://user-images.githubusercontent.com/48391286/129017143-a99a7f50-f950-4a08-8dd0-143b84c93be2.png",
  //                 },
  //                 {
  //                     title: "Be one of the first",
  //                     subtitle: "Request shares in new companies before their stock starts trading on public exchanges.",
  //                     icon: "https://user-images.githubusercontent.com/48391286/129016837-7a998503-49bc-4811-82ec-eae3af02c326.png",
  //                 },
  //                 {
  //                     title: "Get a fair shot",
  //                     subtitle: "While IPO shares are limited, IPO Access gives you the same opportunity to invest, regardless of order size or account value.",
  //                     icon: "https://user-images.githubusercontent.com/48391286/129016897-779490eb-1e11-4cfc-9e77-1502a861ca9f.png",
  //                 },
  //             ],
  //         },
  //         {
  //             title: "Introducing Fractional Shares",
  //             subtitle:
  //                 "Invest in thousands of stocks with as little as $1.",
  //             image: "https://user-images.githubusercontent.com/48391286/129002211-c992471e-0b06-4ad3-a2b7-2890677403b2.png",
  //             list: [
  //                 {
  //                     title: "Invest Any Amount",
  //                     subtitle: "Choose how much you want to invest, and we’ll convert from dollars to parts of a whole share.",
  //                 },
  //                 {
  //                     title: "Build a Balanced Portfolio",
  //                     subtitle: "Customize your portfolio with pieces of different companies and funds to help reduce risk.",
  //                 },
  //                 {
  //                     title: "Trade in Real Time",
  //                     subtitle: "Trades placed during market hours are executed at that time, so you’ll always know the share price.",
  //                 },
  //             ],
  //         },
  //         {
  //             // list: [
  //             //     {
  //             //         id: 1,
  //             //         title: "Learn As You Grow",
  //             //         subtitle: "Our goal is to make investing in financial markets more affordable, more intuitive, and more fun, no matter how much experience you have (or don’t have).",
  //             //         value: "Learn",
  //             //         image: "https://user-images.githubusercontent.com/48391286/129025348-d8c452b1-cdfc-405f-91b6-7047fd20a523.png",
  //             //     },
  //             //     {
  //             //         id: 2,
  //             //         title: "Manage Your Portfolio",
  //             //         subtitle: "Keep your portfolio in your pocket. Everything you need to manage your assets is available in a single app.",
  //             //         value: "Manage",
  //             //         image: "https://user-images.githubusercontent.com/48391286/129025918-b3d6d3b1-8d6d-4a3d-b4e7-2d7e00b57c85.png",
  //             //     },
  //             //     {
  //             //         id: 3,
  //             //         title: "Keep Tabs on Your Money",
  //             //         subtitle: "Set up customized news and notifications to stay on top of your assets as casually or as relentlessly as you like. Controlling the flow of info is up to you.",
  //             //         value: "Customize",
  //             //         image: "https://user-images.githubusercontent.com/48391286/128992870-7462168d-28af-4ef3-b335-6ea08bdd04ae.png",
  //             //     },
  //             // ],
  //             image:"https://user-images.githubusercontent.com/48391286/129025348-d8c452b1-cdfc-405f-91b6-7047fd20a523.png",
  //             title: "Learn As You Grow",
  //             subtitle: "Our goal is to make investing in financial markets more affordable, more intuitive, and more fun, no matter how much experience you have (or don’t have)."
  //         },
  //         {
  //             title: "Our Products",
  //             menus: [
  //                 {
  //                     id: 1,
  //                     title: "Cash Management",
  //                     content: "Earn 0.30% APY* on your uninvested cash and get more flexibility with your brokerage account.",
  //                     image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/2x__18e347b9d536d7fb0481c150a7663380.png",
  //                 },
  //                 {
  //                     id: 2,
  //                     title: "Stocks & Funds",
  //                     content: "Get mobile access to the markets. Invest commission-free in individual companies or bundles of investments (ETFs).",
  //                     image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/2x__5df2bc2eacf940aec8941d4618def751.png",
  //                 },
  //                 {
  //                     id: 3,
  //                     title: "Options",
  //                     content: "Be bullish on stocks you believe in and bearish on the ones you don’t. It’s your call.",
  //                     image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/2x__6b723a2039380d5ec649051d4af288ef.png",
  //                 },
  //                 {
  //                     id: 4,
  //                     title: "Gold",
  //                     content: "Access research reports, trade on margin at a 2.5% annual rate, and make bigger deposits with quicker access to funds—all starting $5 per month.",
  //                     image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/2x__771700fb180b3447b7dd92d184558f3d.png",
  //                 },
  //                 {
  //                     id: 5,
  //                     title: "Crypto",
  //                     content: "Tap into the cryptocurrency market to buy, HODL, and sell Bitcoin, Ethereum, Dogecoin, and more, 24/7 with Robinhood Crypto.",
  //                     image: "https://cdn.robinhood.com/assets/robinhood/brand/_next/static/images/2x__fbf9cc4b8f1791cda8d2a43adc700cbd.png",
  //                 },
  //             ],
  //         },
  //     ],
  // };

  return (
    <PageContainer>
      <PageHeader
        isShowMenu={pathname.includes("/homepage")}
        fixed
        theme={theme}
        setTheme={setTheme}
      />
      {data ? (
        <PageWrapper>
          <Segment1 data1={data && data[0]} />
          {/*<SegmentMobileScreen />*/}
          <Segment4 data4={data && data[3]} />
          <Segment3 data3={data && data[2]} />
          <AwardSegment />
          <TestimonialSegment/>

          <SegmentTagAndText data={data && data[6]} />

          <Segment6 data6={data && data[5]} />
        </PageWrapper>
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <Footer theme={theme} />
    </PageContainer>
  );
};

export default HomePage;
