import React from 'react';
import styled from 'styled-components';
import { grey } from 'colorPalette';
const Wrapper = styled.div`
  margin-top: 15px;
`;

const FiltersContainer = styled.div`
  margin-bottom: ${({ topics }) => topics ? 15 : 0}px;
`;

const ListWrapper = styled.div`
  padding: 5px 10px;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  padding: 0 10px;
`;

const FilterPills = styled.span`
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid ${grey.level5};
`;
const RenderTopicsSources = ({ 
  digestsFilterSources,
  digestsFilterTopics,
  onSelectFilters
}) => {
  return (
    <Wrapper>
      {digestsFilterTopics && digestsFilterTopics.length > 0 && (
        <FiltersContainer topics>
          <SectionTitle>Topics</SectionTitle>
          <ListWrapper>
            {digestsFilterTopics.map(item => (
              <FilterPills
                key={`${item.id}-topic`}
                onClick={() => {
                  onSelectFilters({ topic: item });
                }}
                ref={a => {
                  if (a) {
                    const height = a.offsetHeight;
                    a.style.borderRadius = `${height / 2}px`;
                  }
                }}
              >{item.title}</FilterPills>
            ))}
          </ListWrapper>
        </FiltersContainer>
      )}
      {digestsFilterSources && digestsFilterSources.length > 0 && (
        <FiltersContainer>
          <SectionTitle>Sources</SectionTitle>
          <ListWrapper>
            {digestsFilterSources.map(item => (
              <FilterPills
                key={`${item.id}-sorce`}
                onClick={() => {
                  onSelectFilters({ source: item });
                }}
                ref={a => {
                  if (a) {
                    const height = a.offsetHeight;
                    a.style.borderRadius = `${height / 2}px`;
                  }
                }}
              >{item.brand_name}</FilterPills>
            ))}
          </ListWrapper>
        </FiltersContainer>

      )}
    </Wrapper>
  )
};

export default RenderTopicsSources;