import React, { useReducer, useEffect, useRef } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Input, Image, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import PageHeader from 'Components/Header';
import { grey } from 'colorPalette';
import {
  fetchDigestListApi,
  deleteReadLater,
  addReadLater,
  deleteBookmarkDigest,
  bookmarkDigest
} from 'Api';
import DigestListView from './DigestListView';
import RenderTopicsSources from './RenderTopicsSources';
import CrossIcon from '../../cross.svg';
import BackIcon from './back.svg';
import { white } from 'colorPalette';
import addReadLaterImage from '../../Assets/add_read_later.png';
import readLaterAdded from '../../Assets/read_later_added.png';
import ShareDialogBox from '../ArticleItem/ShareDialogBox';

const PAGE_SIZE = 10;
const Wrapper = styled.div`
`;

const AmplitudePageType = 'SearchPage';

const SearchBarWrapper = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  .input {
    width: 100%;
    input {
      padding: 12px 40px;
      font-size: 18px;
      border-bottom: 2px solid rgba(34,36,38,.15);
      color: ${grey.level0};
      outline: none;
      &::placeholder {
        color: ${grey.level0};
      }
    }
  }
`;

const SearchBarContainer = styled.div`
  position: relative;
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 23px;
  i {
    font-size: 20px !important;
    color: ${grey.level2} !important;
  }
`;

const ClearSearchWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 17px;
  i {
    font-size: 20px !important;
    color: ${grey.level2} !important;
  }
`;

const ActionsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 100;
`;

const ActionContainer = styled.div`
  padding: 10px 20px;
  background-color: ${white};
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  margin: 25px;
  font-size: 16px;
  color: ${grey.level2};
  font-weight: 700;

  i {
    margin-right: 25px;
    font-size: 18px !important;
  }
`;

const ReadLaterImage = styled.div`
  width: 20px;
  margin-right: 25px;
`;
/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case "SET_DIGEST_LIST":
        draft.lodingDigestList = false;
        draft.loadFirstTime = true;
        if (action.isRefresh) {
          draft.digestList = action.value;
        } else {
          draft.digestList = draft.digestList.concat(action.value);
        }
        if (action.digestsFilterSources ) {
          draft.digestsFilterSources = action.digestsFilterSources;
        }

        if (action.digestsFilterTopics) {
          draft.digestsFilterTopics = action.digestsFilterTopics;
        }
      break;
      case 'SET_FETCH_DIGESTS_LOADER':
        draft.lodingDigestList = action.value;
        if (action.isRefresh) {
          draft.digestList = [];
        }
        break;
      case 'SET_ACTIVE_PAGINATION':
        draft.activePagination = action.value;
        break;
      case 'SET_SELECTED_FILTER':
        draft.selectedTopic = action.topic;
        draft.selectedSource = action.source;
        if(action.topic) {
          draft.digestSearchValue = action.topic.title;
        }

        if(action.source) {
          draft.digestSearchValue = action.source.brand_name;
        }
        draft.activePagination = 1;
        break;
      case 'SET_SEARCH_VALUE':
        draft.digestSearchValue = action.value;
        draft.selectedTopic = null;
        draft.selectedSource = null;
        draft.activePagination = 1;
        break;
      case 'SET_DIGESTS_ACTION_DATA':
          draft.digestsActionData = action.value;
        break;
      case 'SET_READ_LATER_LOADER':
        draft.readLaterLoader = action.value;
        break;
      case 'SET_DIGEST_READ_LATER':
        draft.readLaterLoader = false;
        if(draft.digestsActionData) {
          draft.digestsActionData = null;
        }
        draft.digestList = draft.digestList.map(digest => {
          if (digest.id === action.digestId) {
            digest.readlater = action.value;
          }
          return digest;
        })
        break;
      case 'SET_BOOKMARK_LOADER':
        draft.bookmarkLoader = action.value;
        break;
      case 'SET_DIGEST_BOOKMARK':
        draft.bookmarkLoader = false;
        if (draft.digestsActionData) {
          draft.digestsActionData = null;
        }
        draft.digestList = draft.digestList.map(digest => {
          if (digest.id === action.digestId) {
            digest.like = action.value;
            if (action.value) {
              digest.likes += 1;
            } else {
              digest.likes -= 1;
            }
          }
          return digest;
        })
        break;
      case 'SET_SHARE_ARTICLE_DATA':
        draft.shareArticleData = action.value;
        draft.digestsActionData = null;
      break;
    }
  });

const initialState = {
  digestList: [],
  activePagination: 1,
  digestsFilterSources: [],
  digestsFilterTopics: [],
  lodingDigestList: true,
  selectedTopic: null,
  selectedSource: null,
  digestSearchValue: null,
  loadFirstTime: false,
  digestsActionData: null,
  readLaterLoader: false,
  bookmarkLoader: false,
  shareArticleData: null,
}
const SearchPage = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const activatePagingationRef = useRef(false);
  const activePaginationRef = useRef(1);
  const videoDigestListRef = useRef(null);
  const searchTimeoutRef = useRef(null);
  const articleActionRef = useRef(null);
  const {
    activePagination ,
    digestList,
    selectedTopic,
    selectedSource,
    lodingDigestList,
    digestsFilterTopics,
    digestsFilterSources,
    digestSearchValue,
    loadFirstTime,
    digestsActionData,
    readLaterLoader,
    bookmarkLoader,
    shareArticleData
  } = state;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDigestsList = ({ isRefresh, isClearSearch, searchValue, topic, source }) => {
    dispatch({ type: 'SET_FETCH_DIGESTS_LOADER', value: true, isRefresh });
    fetchDigestListApi({
      digestType: '200,275',
      excludeTypes: 255,
      // digestType: 275,
      page: isRefresh ? 1 : activePagination,
      size: PAGE_SIZE,
      tags: !isClearSearch ? ((topic && topic.id) || ((searchValue && !source) ? null : (selectedTopic && selectedTopic.id))) : null,
      webSources: !isClearSearch ? ((source && source.id) || ((searchValue && !topic) ? null : (selectedSource && selectedSource.id))) : null,
      search: isClearSearch ? '' : (searchValue || digestSearchValue),
    }).then(response => {
      const suggestedFilter = response.data.suggested_filters;
      dispatch({
        type: 'SET_DIGEST_LIST',
        value: response.data.results,
        isRefresh,
        unread_count: response.data.unread_count,
        digestsFilterSources: (suggestedFilter && suggestedFilter.sources && suggestedFilter.sources.results) || [],
        digestsFilterTopics: (suggestedFilter && suggestedFilter.tags && suggestedFilter.tags.results) || [],
      });
    })
      .catch(error => {
        if (window && window.Raven) window.Raven.captureException(error.response);
        dispatch({ type: 'SET_DIGEST_LIST', value: [] });
      });
  };

  useEffect(() => {
    activatePagingationRef.current = false;
    activePaginationRef.current = activePagination; 
  }, [activePagination]);

  useEffect(() => {
    if (activePagination > 1) {
      fetchDigestsList({ isRefresh: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePagination]);

  useEffect(() => {
    if(digestList && digestList.length === 0 && !loadFirstTime) {
      fetchDigestsList({ isRefresh: false });
    }
  }, [digestList, fetchDigestsList, loadFirstTime]);

  useEffect(() => {
    videoDigestListRef.current = digestList;
  }, [digestList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = e => {
    if (window.scrollY + 100 >= document.body.scrollHeight - window.innerHeight && !activatePagingationRef.current) {
      if (videoDigestListRef.current.length >= activePaginationRef.current * PAGE_SIZE) {
        activatePagingationRef.current = true;
        // onChangePagination(activePaginationRef.current + 1);
        dispatch({ type: 'SET_ACTIVE_PAGINATION', value: activePaginationRef.current + 1 })
      }

    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, [handleScroll]);

  const onDigestReadLater = (data) => {
    if (!readLaterLoader) {
      dispatch({ type: 'SET_READ_LATER_LOADER', value: true });
      if (data.readlater) {
        deleteReadLater({ digestId: data.id }).then(response => {
          if (response.status === 202) {
            dispatch({ type: 'SET_DIGEST_READ_LATER', value: false, digestId: data.id });
          }
        })
          .catch(error => {
            if (window && window.Raven) window.Raven.captureException(error.response);
          })
      } else {
        addReadLater({ digestId: data.id, data, pageType: AmplitudePageType}).then(response => {
          if (response.status === 201) {
            dispatch({ type: 'SET_DIGEST_READ_LATER', value: true, digestId: data.id });
          }
        })
          .catch(error => {
            if (window && window.Raven) window.Raven.captureException(error.response);
          })
      }
    }
  }

  const onDigestBookMark = (data) => {
    if (!bookmarkLoader) {
      dispatch({ type: 'SET_BOOKMARK_LOADER', value: true });
      if (data.like) {
        deleteBookmarkDigest({ digestId: data.id }).then(response => {
          if (response.status === 202) {
            dispatch({ type: 'SET_DIGEST_BOOKMARK', value: false, digestId: data.id });
          }
        })
          .catch(error => {
            if (window && window.Raven) window.Raven.captureException(error.response);
          })
      } else {
        bookmarkDigest({ digestId: data.id, data, pageType: AmplitudePageType }).then(response => {
          if (response.status === 201) {
            dispatch({ type: 'SET_DIGEST_BOOKMARK', value: true, digestId: data.id });
          }
        })
          .catch(error => {
            if (window && window.Raven) window.Raven.captureException(error.response);
          })
      }
    }
  }
  return (
    <Wrapper
      ref={a => {
        if (a && a.querySelector('#headerWrapper') && a.querySelector('#searchBarWrapper')) {
          const searchbarWrapper = a.querySelector('#searchBarWrapper');
          a.style.paddingTop = `${a.querySelector('#headerWrapper').offsetHeight + searchbarWrapper.offsetHeight}px`;
          searchbarWrapper.style.top = `${a.querySelector('#headerWrapper').offsetHeight}px`;
        }
      }}
    >
      <PageHeader fixed />
      <SearchBarWrapper
        id="searchBarWrapper"
      >
        <SearchBarContainer>
          <Input 
            placeholder={`Search for news, topics & sources`}
            value={digestSearchValue}
            onChange={e => {
              clearTimeout(searchTimeoutRef.current);
              searchTimeoutRef.current = setTimeout(() => {
                if (digestSearchValue && digestSearchValue.trim() !== "") {
                  fetchDigestsList({ isRefresh: true, searchValue: digestSearchValue });
                }
              }, 2000);
              dispatch({ type: 'SET_SEARCH_VALUE', value: e.target.value });
            }}
            onKeyUp={e => {
              if (e.which === 13 && digestSearchValue && digestSearchValue.trim() !== "") {
                clearTimeout(searchTimeoutRef.current);
                fetchDigestsList({ isRefresh: true, searchValue: digestSearchValue });
              }
            }}
          />
          <BackButtonWrapper
            onClick={() => history.goBack()}
          >
            <Image src={BackIcon} />
            {/* <Icon name="arrow left" /> */}
          </BackButtonWrapper>
          {digestSearchValue && digestSearchValue.trim() !== "" && (
            <ClearSearchWrapper
              onClick={() => {
                dispatch({ type: 'SET_SEARCH_VALUE', value: '' });
                setTimeout(() => {
                  fetchDigestsList({ isRefresh: true, isClearSearch: true, searchValue: null, topic: null,source: null });
                }, 100);
              }}
            >
              {/* <Icon name="close" /> */}
              <Image src={CrossIcon} />
            </ClearSearchWrapper>
          )}
        </SearchBarContainer>
      </SearchBarWrapper>
      <RenderTopicsSources 
        digestsFilterSources={digestsFilterSources}
        digestsFilterTopics={digestsFilterTopics}
        onSelectFilters={({ topic, source }) => {
          fetchDigestsList({ isRefresh: true,topic, source });
          dispatch({ type: 'SET_SELECTED_FILTER', topic, source });
        }}
      />
      <DigestListView 
        digestRef={videoDigestListRef}
        data={digestList}
        lodingDigestList={lodingDigestList}
        onClickDigestAction={item => {
          if(!digestsActionData) {
            dispatch({ type: 'SET_DIGESTS_ACTION_DATA', value: item });
          }
        }}
        onClickDigest={(item, index) => {
          history.push(`/${item.slug}`, {
            searchPageData: {
              currentDigestIndex: index,
              currentDigest: item,
              activePagination,
              selectedTopic,
              selectedSource,
              searchValue: digestSearchValue,
              digestList
            }
          })
        }}
      />
      {digestsActionData && (
        <ActionsWrapper
          onClick={() => {
            dispatch({ type: 'SET_DIGESTS_ACTION_DATA', value: null });
          }}
        >
          <ActionContainer 
            onClick={e => e.stopPropagation()}
            ref={articleActionRef}
          >
            <ActionItem
              onClick={() => {
                dispatch({ type: 'SET_SHARE_ARTICLE_DATA', value: digestsActionData });
              }}
            >
              <Icon
                name="share alternate"
                size="large"
                color="black"
              />
              Share
            </ActionItem>
            <ActionItem
              onClick={() => onDigestReadLater(digestsActionData)}
            >
              <ReadLaterImage>
                <Image src={digestsActionData && digestsActionData.readlater ? readLaterAdded : addReadLaterImage} />
              </ReadLaterImage>
              {digestsActionData && digestsActionData.readlater ? 'Removed from Read Later': 'Save to Read Later'}
            </ActionItem>
            <ActionItem
              onClick={() => onDigestBookMark(digestsActionData)}
            >
              <Icon
                name={digestsActionData && digestsActionData.like ? "heart" : "heart outline"}
                size="large"
                color={digestsActionData && digestsActionData.like ? 'red' : 'black'}
              />
              {digestsActionData && digestsActionData.like ? 'Unlike' : 'Like'}
            </ActionItem>
          </ActionContainer>
        </ActionsWrapper>
      )}
      {!!shareArticleData && (
        <ShareDialogBox
          isSearchShare
          onClose={() => {
            dispatch({ type: 'SET_SHARE_ARTICLE_DATA', value: digestsActionData });
          }}
          open={!!shareArticleData}
          data={shareArticleData}
          shareUrl={shareArticleData.source.sl || shareArticleData.source.link}
          shareText={shareArticleData && shareArticleData.share_data && shareArticleData.share_data.share_text}
        />
      )}
    </Wrapper>
  );
};

export default SearchPage;