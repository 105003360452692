import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Radio } from 'semantic-ui-react'

const Wrapper = styled.div``;
const ItemWrapper = styled.div`
  margin: 15px 0;
  .ui.radio.checkbox {
    width: 100%;
  }
`;

const RadioQuestionType = ({ data, handlePublishData }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (selectedValue) {
      handlePublishData(selectedValue);
    }
  }, [selectedValue, handlePublishData])
  return (
    <Wrapper>
      {data && data.length > 0 && data.map(item => (
        <ItemWrapper>
          <Radio
            label={item.title}
            name='radioGroup'
            value={item.id}
            checked={selectedValue === item.id}
            onChange={(e, { value }) => { setSelectedValue(value)}}
          />
        </ItemWrapper>
      ))}
    </Wrapper>
  )
};

export default RadioQuestionType;