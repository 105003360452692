import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  display: ${({ open }) => open ? 'none' : 'none'};
  z-index: 1000;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 768px) {
    display: ${({ open }) => open ? 'flex' : 'none'};
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.primaryColor.level1};;
  padding: 0 20px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const DragAreaWrapper = styled.div`
  padding: 10px 0;
`;
const DragAreaIcon = styled.div`
  width: 100px;
  height: 5px;
  background-color: rgba(0, 0, 0, .5);
  margin: 0 auto;
  border-radius: 5px;
`;

const MenuList = styled.div``;
const SliderItem = styled(Link)`
  display: flex;
  align-items: center;
  font-family: 'Metropolis-Bold', sans-serif;
  font-size: 15px;
  padding: 10px 10px;
  color: ${({ active }) => active ? '#DC3D4D' : props => props.theme.misc.menuBarFont};
`;

const ImageWrapper = styled.div`
  margin-right: 25px;
`;

const BottomSheetMenu = ({
  open,
  onDismiss,
  sliderData,
  onClickItem,
  categoryId
}) => {
  const pageYRef = useRef(0);
  const [selectedCat, selectCategory] = useState(sliderData.find(data => data.selected));

  useEffect(() => {
    if (categoryId) {
      if (isNaN(parseInt(categoryId, 10))) {
        selectCategory({ slug: categoryId, id: categoryId })
      }
    }
  }, [categoryId])
  return (
    <Wrapper
      open={open}
      onClick={onDismiss}
    >
      <Container
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <DragAreaWrapper 
          onTouchMove={(event) => {
            if (pageYRef.current === 0) {
              pageYRef.current = event.touches[0].pageY;
            } else {
              if (event.touches[0].pageY - pageYRef.current > 20) {
                onDismiss();
              }
            }
          }}
        >
          <DragAreaIcon />
        </DragAreaWrapper>
        <MenuList>
          {sliderData.map((item, index) => (
            <SliderItem
              to={item.slug ? `/category/${item.slug}` : `/category/${item.id}?type=${item.filter_key}`}
              onClick={() => {
                onDismiss();
                selectCategory(item);
                onClickItem(item);
              }}
              index={index}
              className="category-item"
              key={item.id}
              id={`${item.id}-category`}
              active={selectedCat && (selectedCat.id === item.id || selectedCat.slug === item.slug) ? true : !selectedCat ? index === 0 ? true : item.selected : null}
            >
              <ImageWrapper>
                <Image width={24} src={item.icon} />
              </ImageWrapper>
              {item.title}
            </SliderItem>
          ))}
        </MenuList>
      </Container>
    </Wrapper>
  );
};

export default BottomSheetMenu;