import React, { useEffect, useReducer, useRef } from 'react';
import styled from 'styled-components';
import PageHeader from 'Components/Header';
import produce from 'immer';
import { white, primaryColor, grey } from 'colorPalette';
import { useHistory } from 'react-router-dom';

import { fetchCategoriesExplore } from 'Api';
import ExploreListView from './ExploreListView';
import CategoriesListView from './CategoriesListView';
import DigestHeader from '../DigestHeader';
import { Loader, Icon } from 'semantic-ui-react';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';

const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  position: relative;
  box-shadow: 0 0 25px 0 #d8d8d8;
  background-color: ${white};
  padding-top: ${({ headerHeight }) => headerHeight}px;
  font-family: 'Metropolis', sans-serif;

  @media (max-width: 768px) {
    min-height: calc(100vh - 40px);
  }

  @media (max-width: 900px) and (min-width: 768px) {
    width: 60%;
  }


  @media (max-width: 768px) and (min-width: 600px) {
    width: 70%;
  }
  
  @media (max-width: 600px) and (min-width: 480px) {
    width: 90%;
  }
  
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Container = styled.div`

`;

const SectionTitle = styled.div`
  padding: 10px 10px 20px;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  &:after {
    content: '';
    width: 50px;
    height: 5px;
    background-color: ${primaryColor.level1};
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const SectionWrapper = styled.div`
  padding: 10px 0;
`;

const LoaderWrapper = styled.div`
  height: calc(100vh - 50px);
`;

const SearchBarWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 13px;
  display: flex;
  align-items: center;
  background-color: ${grey.level6};
  font-size: 14px;
  cursor: pointer;
   i {
     font-size: 18px !important;
   }
  span {
    margin-left: 15px;
  }
`;


/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'SET_EXPLORE_DATA':
        draft.exploreData = action.value;
        draft.categoriesData = action.categories;
        draft.loadingData = false;
        break;
      case 'SET_LOADING_DATA':
        draft.loadingData = action.value;
        break;
    }
  })

const initialState = {
  exploreData: [],
  categoriesData: [],
  loadingData: true,
};

const DiscoverPage = () => {
  const history = useHistory();
  const digestHeaderRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { exploreData, categoriesData, loadingData } = state;

  useEffect(() => {
    dispatch({ type: 'SET_LOADING_DATA', value: true });
    fetchCategoriesExplore().then(response => {
      dispatch({
        type: 'SET_EXPLORE_DATA',
        value: response.data.explore.results,
        categories: response.data.results,
      })
    })
    .catch(error => {
      if (window && window.Raven) window.Raven.captureException(error.response);
    })
  }, []);
  // const camelize = (str) => {
  //   return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
  //     if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
  //     return index === 0 ? match.toLowerCase() : match.toUpperCase();
  //   });
  // }
  return (
    <>
      <PageHeader fixed headerRef={digestHeaderRef}/>
      <Container      >
        <Wrapper
          headerHeight={(digestHeaderRef.current && digestHeaderRef.current.offsetHeight) || 0}
        >
          <DigestHeader
            activeTab="discover"
            showProfile
            showDigestHeader
            width="100%"
          />
            {!loadingData ? (
              <>
                <SearchBarWrapper
                  onClick={() => {
                    history.push('/discover/search');
                  }}
                >
                  <Icon name="search" />
                  <span>{`Search for news, topics & sources`}</span>
                </SearchBarWrapper>
                <SectionWrapper>
                  <SectionTitle>Explore</SectionTitle>
                  <ExploreListView 
                    onExploreSelect={item => {
                    logAmplitudeEvent('Explore Selected', {
                      explore_id: item.id, 
                      explore_name: item.title
                    });
                      history.push(`/explore/${item.slug}`);
                    }}
                    exploreData={exploreData}
                  />
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Categories</SectionTitle>
                  <CategoriesListView
                    categoriesData={categoriesData}
                    onSelectCategory={category => {
                      logAmplitudeEvent('Category Selected', {
                        category_id: category.id,
                        category_name: category.title
                      });
                      history.push(`/category/${category.slug}`);
                    }}
                  />
                </SectionWrapper>
              </>
            ): (
              <LoaderWrapper>
                <Loader active />
              </LoaderWrapper>
            )}
        </Wrapper>
      </Container>
    </>
  );
};

export default DiscoverPage;