import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Image, Icon } from 'semantic-ui-react';
import LinkedSourcesList from './LinkedSourcesList';
import { postHostFollow } from 'Api';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import CustomIcon from 'Icon';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  text-align: left;
  -webkit-tap-highlight-color: transparent;
  ${({ isReadAtButton }) => isReadAtButton ? `
    padding: 20px 20px 20px 20px;
  `: ``};

   @media (max-width: 1024px) {
    ${({ isReadAtButton }) => isReadAtButton ? `
      padding: 15px;
    `: ``};
  }

  @media (max-width: 580px) {
    ${({ isReadAtButton }) => isReadAtButton ? `
      padding: 15px 35px 15px 15px;
    `: ``};
  }

  ${({ isCenterAlign }) => isCenterAlign ? `
    width: 100%;
    justify-content: center;
  ` :``}
`;

const ExternalLinkIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);

  @media (max-width: 580px) {
    right: 10px;

    i, svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  color: #000;
  padding-right: ${({ hideFollowButton }) => hideFollowButton ? 10 : 115}px;
  position: relative;
  width: ${({ width }) => width};
  &:hover {
    color: #000;
  }

  ${({ isReadAtButton }) => isReadAtButton ? `
    padding: 20px 20px 20px 20px;
  `: ``};

  @media (max-width: 1024px) {
    ${({ isReadAtButton }) => isReadAtButton ? `
      padding: 15px;
    `: ``};
  }

  @media (max-width: 580px) {
    ${({ isReadAtButton }) => isReadAtButton ? `
      padding: 15px 35px 15px 15px;
    `: ``};
  }

  ${({ isCenterAlign }) => isCenterAlign ? `
    justify-content: center;
  ` : ``};
  @media (max-width: 767px) {
    padding-right: ${({ hideFollowButton }) => hideFollowButton ? 10 : 95}px;
  }
`;

const LinkedSourceImgWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ length }) => length*27}px;

  @media (max-width: 580px) {
    width: ${({ length }) => length * 27}px;
  }
`;

const SouceImageWrapper = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-left: ${({ index }) => index === 0 ? 0 : '-6px' };
  overflow: hidden;
  position: relative;
  z-index: ${({ index }) => (4 - index)};

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 580px) {
    width: 30px;
    height: 30px;
  }
`;

const SourceImageOtherWrapper = styled(SouceImageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ color }) => color || '#939eab'};
  color: ${({ color }) => color || '#939eab'};
  i {
    margin: 0;
    margin-top: -3px;
  }
`;

const SourceInfoWrapper = styled.div`
  flex: 1;
  color: ${props => props.theme.fontColor.level1};
  /* color: #696969; */
  font-size: 11px;
  line-height: 1.2;
  /* font-weight: 900; */
  flex: ${({ isCenterAlign }) => isCenterAlign ? 'unset' : 1};
  text-shadow: ${({ showTextShadow }) => showTextShadow ? '1px 1px rgba(0,0,0,0.5)' : 'unset'};
  font-family: 'Metropolis-Bold', sans-serif;
  padding-left: ${({ isMultiple }) => isMultiple ? 5 : 10}px;

  div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    /* font-weight: 500; */
    /* opacity: 0.8; */
    font-family: 'Metropolis', sans-serif;
  }

  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

const FollowButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  color: ${({ isFollowed }) => isFollowed ? '#fff' : '#9a9797'};
  border: 1px solid ${({ isFollowed }) => isFollowed ? '#ed3833' : '#d8d8d8'};
  background-color: ${({ isFollowed }) => isFollowed ? '#ed3833' : 'transparent'};
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 12px;
    width: 90px;
  }
`;
const DigestSourceInfo = ({
  data, 
  showCount,
  color,
  showTextShadow,
  disableShowModal,
  hideFollowButton,
  isCenterAlign,
  isReadAtButton
}) => {
  const [linkedSourceData, setLinkedSourceData] = useState(data.linked_sources || []);
  const [showLinkedSourcesModal, setShowLinkedSourcesModal] = useState(false);
  const [isFollowedLoading, setIsFollowedLoading] = useState(false);

  useEffect(() => {
    setLinkedSourceData(data.linked_sources);
  }, [data.id, data.linked_sources]);

  useEffect(() => {
    if (showLinkedSourcesModal) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return () => {
      clearAllBodyScrollLocks();
    }
  });

  const onHandleFollow = (host) => {
    if (!isFollowedLoading) {
      setIsFollowedLoading(true);
      postHostFollow({ hosts: [host.id] }).then(response => {
        setIsFollowedLoading(false);
        const newData = response.data.results[0];
        const updatedList = linkedSourceData.map(item => {
          if (item.id === host.id && newData) {
            return {
              ...item,
              ...newData,
            }
          }

          return item;
        });

        setLinkedSourceData(updatedList);
      })
        .catch(error => {

        })
    }
  }

  const openDirectSource = (linkedSourceData && (linkedSourceData.length === 1 && linkedSourceData[0].links && linkedSourceData[0].links.length === 1)) && !disableShowModal;
  
  return (
    <>
      {linkedSourceData && openDirectSource && linkedSourceData.length > 0 && (
        <LinkWrapper
          href={(linkedSourceData[0] && linkedSourceData[0].links && linkedSourceData[0].links[0] && linkedSourceData[0].links[0].link) || linkedSourceData[0].host_name} 
          width={(!hideFollowButton || isCenterAlign) ? '100%' : 'auto'}
          target="_blank"
          onClick={(e) => {
              e.stopPropagation();
          }}
          isCenterAlign={isCenterAlign}
          isReadAtButton={isReadAtButton}
          hideFollowButton={hideFollowButton}
        >
          <LinkedSourceImgWrapper
            length={linkedSourceData.length > (showCount || 2) ? ((showCount + 1) || 3) : linkedSourceData.length}
          >
            {linkedSourceData.slice(0, showCount || 2).map((item, index) => (
              <SouceImageWrapper key={index} index={index}>
                <Image src={item && item.logo_link} />
              </SouceImageWrapper>
            ))}
            {linkedSourceData.length > (showCount || 2) && (
              <SourceImageOtherWrapper color={color}>
                <Icon name="ellipsis horizontal" />
              </SourceImageOtherWrapper>
            )}
          </LinkedSourceImgWrapper>
          <SourceInfoWrapper color={color} isCenterAlign={isCenterAlign} showTextShadow={showTextShadow}>
            <div>{data.feed_sources_text.title_1}</div>
            {data.feed_sources_text.title_2 && data.feed_sources_text.title_3 && (
              <div><span>{data.feed_sources_text.title_2}</span> {data.feed_sources_text.title_3}</div>
            )}
          </SourceInfoWrapper>
          {isCenterAlign && (
            <ExternalLinkIcon>
              <CustomIcon name="externalLink" />
            </ExternalLinkIcon>
          )}
          {!hideFollowButton && (
            <FollowButtonWrapper
              isFollowed={linkedSourceData[0].selected}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onHandleFollow(linkedSourceData[0]);
              }}
            >
              <Icon name="bell outline" />
              {linkedSourceData[0].selected ? 'Following' : 'Follow'}
            </FollowButtonWrapper>
          )}
        </LinkWrapper>
      )}
      {!openDirectSource && linkedSourceData.length > 0 && (
        <Wrapper isCenterAlign={isCenterAlign} isReadAtButton={isReadAtButton} onClick={(e) => {
          if (!disableShowModal) {
            e.preventDefault();
            e.stopPropagation();
            setShowLinkedSourcesModal(true);
          }
        }}>
          <LinkedSourceImgWrapper
            length={linkedSourceData.length > (showCount || 2) ? ((showCount + 1) || 3) : linkedSourceData.length}
          >
            {linkedSourceData.slice(0, showCount || 2).map((item, index) => (
              <SouceImageWrapper key={index} index={index}>
                <Image src={item && item.logo_link} />
              </SouceImageWrapper>
            ))}
            {linkedSourceData.length > (showCount || 2) && (
              <SourceImageOtherWrapper color={color}>
                <Icon name="ellipsis horizontal" />
              </SourceImageOtherWrapper>
            )}
          </LinkedSourceImgWrapper>
          {data.feed_sources_text && (
            <SourceInfoWrapper 
              isMultiple={linkedSourceData.length > 1}
              ref={a => {
                if (!a) return;

                const childElemnts = a.querySelectorAll('div');
                childElemnts.forEach(childElemnt => {
                  childElemnt.style.whiteSpace = 'unset';
                  // const width = a.offsetWidth;
                  // childElemnt.style.display = 'inline-block';
                  childElemnt.style.width = 'auto';
                  // childElemnt.style.maxWidth = `${width - 10}px`;
                  childElemnt.style.whiteSpace = 'nowrap';
                  childElemnt.style.position = 'relative';
                })
              }}
              isCenterAlign={isCenterAlign}
              color={color}
              showTextShadow={showTextShadow}
            >
              <div>{data.feed_sources_text.title_1}</div>
              {/* {linkedSourceData.length === 2 && ( */}
              <div><span>{data.feed_sources_text.title_2}</span> {data.feed_sources_text.title_3}</div>
              {/* )} */}
              {/* {linkedSourceData.length > 2 && (
                <div><span>and</span> Others</div>
              )} */}
            </SourceInfoWrapper>
          )}
        </Wrapper>
      )}

      {showLinkedSourcesModal && data && (
        <LinkedSourcesList
          data={linkedSourceData}
          title={data.d_bottom_sources_text && data.d_bottom_sources_text.title}
          updateListData={data => {
            setLinkedSourceData(data);
          }}
          onClose={() => {
            setShowLinkedSourcesModal(false);
          }}
        />
      )}
    </>
  )
};

export default DigestSourceInfo;