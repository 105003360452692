import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from 'Icon';
import { grey } from 'colorPalette';

import { Image } from 'semantic-ui-react';

import BookMarkHover from '../DigestActions/BookmarkIconHover.svg';
import BookMarkIcon from '../DigestActions/BookmarkIcon.svg';
import ShareIcon from '../DigestActions/ShareIcon.svg';
import DarkShareIcon from '../DigestActions/darkShareIcon.svg';
import ShareIconHover from '../DigestActions/ShareIconHover.svg';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: ${({ padding }) => padding || '15px 15px 10px 15px' };
  margin:${({ margin }) => margin || 0};
  align-items: center;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width*40 || 0}px;
  justify-content: space-between;
  margin-left: ${({ width }) => width ? 13 : 0}px;

  @media (max-width: 580px) {
    width: ${({ width }) => width * 34 || 0}px;
  }
`;

const ActionButton = styled.div`
  padding: 8px;
  border: 1px solid ${grey.level3};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 35px;

  @media (max-width: 580px) {
    padding: 7px;
    i, svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const DeeperButtonWrapper = styled.div`
  /* padding: 10px; */
  /* border: 1px solid ${({ color }) => color || grey.level3}; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 3px;
  flex: 1;
  font-family: 'Metropolis', sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.3;
  color: ${({ color }) => color || '#E12830'};
  /* text-align: center; */
`;

const DeeperButtonIconWrapper = styled.div`
  color: ${({ color }) => color || '#E12830'};
  margin-left: 5px;

  @media (max-width: 580px) {
    i, svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const DeeperButton = ({
  data,
  iconColor,
  onDigestReadLater,
  onDigestBookMark,
  onClickShare,
  buttonText,
  padding,
  color,
  margin,
  isExpand2Click,
  hideDeeperText,
  theme
}) => {
  const [hoverShareIcon, setHoverShareIcon] = useState(false);
  const [hoverBookmarkIcon, setHoverBookmarkIcon] = useState(false);
  const showShare = onClickShare && (!data.feed_visible_fields || (data.feed_visible_fields && data.feed_visible_fields.show_share));
  const showReadLater = onDigestReadLater && (!data.feed_visible_fields || (data.feed_visible_fields && data.feed_visible_fields.show_bookmark));
  const showBookmark = (onDigestBookMark && (!data.feed_visible_fields || (data.feed_visible_fields && data.feed_visible_fields.show_bookmark)));
  return (
  <Wrapper
    padding={padding}
      margin={margin}
  >
    <ActionButtonsWrapper
        width={((showReadLater ? 1 : 0) + (showBookmark ? 1 : 0) + (showShare ? 1 : 0))}
    >
      {showShare && (
        <ActionButton
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onClickShare(data);
          }}
          onMouseOver={() => setHoverShareIcon(true)}
          onMouseOut={() => setHoverShareIcon(false)}
        >
          {/* <Icon
            name='share'
            color={iconColor || "#383e53"}
            size={17}
          /> */}
          { hoverShareIcon ? (
            <Image src={ShareIconHover} />
          ) : (theme === "light" ? (
            <Image src={ShareIcon} />
              ):(
            <Image src={DarkShareIcon} />
            )
          )}
        </ActionButton>
      )}
      {showReadLater && (
        <ActionButton
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onDigestReadLater(data);
          }}
        >
          <Icon
            name={data.readlater ? 'readLaterAdded' : 'addReadLater'}
            // key="button-icon"
            size={17}
            color={data.readlater ? '#E02020' : (iconColor || '#383e53')}
          />
        </ActionButton>
      )}
      {showBookmark && (
        <ActionButton
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onDigestBookMark(data);
          }}
          onMouseOver={() => setHoverBookmarkIcon(true)}
          onMouseOut={() => setHoverBookmarkIcon(false)}
        >
          {/* <Icon
            name={data.like ? 'likeAdded' : 'like'}
            color={data.like ? '#E02020' : (iconColor || '#383e53')}
            size={17}
          /> */}
          {hoverBookmarkIcon ? (
            <Image src={BookMarkHover} />
          ) : (
            <Image src={data.like ? BookMarkHover : BookMarkIcon} />
          )}
        </ActionButton>
      )}
    </ActionButtonsWrapper>
    {hideDeeperText && (
      <DeeperButtonWrapper color={color}>
        {!isExpand2Click && (
          <>
            {buttonText || 'Tell me more'}
            <DeeperButtonIconWrapper
              color={color}
            >
              <Icon name="arrowRight" color={color || '#E12830'} size={18}/>
            </DeeperButtonIconWrapper>
          </>
        )}
      </DeeperButtonWrapper>
    )}
  </Wrapper>
);}

export default DeeperButton;