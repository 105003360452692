const insertAfter = (referenceNode, newNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export const ApplyExpandablePoints = (ref) => {
  if(!ref) return;

  const elements = ref.querySelectorAll('.expandable_point');
  if (elements && elements.length > 0) {
    elements.forEach(element => {
      const classList = element.className.split(/\s+/);
      if (classList.indexOf('applied-click') === -1) {
        classList.push('applied-click');
        element.className = classList.join(' ');

        const showMoreElement = document.createElement("div");
        showMoreElement.innerHTML = element.getAttribute('data-more-text');
        showMoreElement.className = 'expandable-button';
        showMoreElement.style.display = 'block';
        showMoreElement.style.cursor = 'pointer';
        showMoreElement.style.marginBottom = '20px';
        showMoreElement.style.textDecoration = 'underline';
        showMoreElement.style.color = element.getAttribute('underline-color');
        showMoreElement.style.textDecorationColor = element.getAttribute('underline-color');

        insertAfter(element, showMoreElement);

        const showLessText = element.getAttribute('data-less-text');
        let showLessElement = null;
        if (!showLessText || showLessText !== '') {
          showLessElement = document.createElement("div");
          showLessElement.innerHTML = showLessText;
          showLessElement.className = 'expandable-button';
          showLessElement.style.display = 'none';
          showLessElement.style.cursor = 'pointer';
          showLessElement.style.marginBottom = '20px';
          showLessElement.style.textDecoration = 'underline';
          showLessElement.style.color = element.getAttribute('underline-color');
          showLessElement.style.textDecorationColor = element.getAttribute('underline-color');
          insertAfter(element, showLessElement);
        }
        element.style.display = 'none';

        showMoreElement.onclick = (e) => {
          e.stopPropagation();
          e.preventDefault();
          showMoreElement.style.display = 'none';
          showLessElement.style.display = 'block';
          element.style.display = 'block';
        }
        
        showLessElement.onclick = (e) => {
          e.stopPropagation();
          e.preventDefault();
          showMoreElement.style.display = 'block';
          showLessElement.style.display = 'none';
          element.style.display = 'none';
        }
      }
      
    });
  }
}

const extractContent = (node, appendNode) => {
  const children = node.childNodes;
  let record = false;
  let container = document.createDocumentFragment();

  for (let i = 0, l = children.length; i < l; i++) {
    const child = children[i];

    if (record) {
      container.appendChild(child.cloneNode(true));
    }
    if (child.nodeType === 8 && (child.nodeValue.includes('START_EXPANDABLE') || child.nodeValue.includes('END_EXPANDABLE'))) {
      record = !record;
    }
  }
  
  return container;
}


export const findCommentsNode = (el) => {
  const arr = [];
  for (let i = 0; i < el.childNodes.length; i++) {
    const node = el.childNodes[i];
    if (node.nodeType === 8) {
      arr.push(node);
    } else {
      arr.push.apply(arr, findCommentsNode(node));
    }
  }
  return arr;
};

export const addExpandablePointElm = (ref) => {
  if (!ref) return

  const commentNodes = findCommentsNode(ref);
  if(commentNodes && commentNodes.length > 0) {
    commentNodes.forEach(comment => {
      if (comment.data !== 'END_EXPANDABLE') {
        const nodeValuesArray = comment.textContent.split('-');
        const elemAttributesObj = {};
        nodeValuesArray.forEach(value => {
          const splitvalue = value.split("=");
          elemAttributesObj[splitvalue[0].replaceAll('_', '-')] = splitvalue[1] && splitvalue[1].replaceAll('"', "");
        });
        
        const newElem = document.createElement('span');
        newElem.className = 'expandable_point';
        newElem.setAttribute('data-more-text', elemAttributesObj['more-text'] || null);
        newElem.setAttribute('data-less-text', elemAttributesObj['less-text'] || null);
        newElem.setAttribute('underline-color', elemAttributesObj['underline-color'] || null);
        newElem.setAttribute('underline-color-dark', elemAttributesObj['underline-color-dark'] || null);
        newElem.style.display = 'block';
        const content = extractContent(comment.parentNode)
        for (var i = 0; i < content.childNodes.length; i++) {
          newElem.appendChild(content.childNodes[i])
        }

        comment.nextSibling.remove();
        comment.parentNode.insertBefore(newElem, comment);
        
      }
    });
  }

  setTimeout(() => {
    ApplyExpandablePoints(ref)
  }, 50);
}

export const addHtmlElement = (content) => {
  const regexstart = /(<!--START_EXPANDABLE)(.|\\n)*?(-->)/g;
  const regexend = /(<!--END_EXPANDABLE)(.|\\n)*?(-->)/g;
  const str = `${content}`;

  // newElem.setAttribute('data-more-text', elemAttributesObj['more-text'] || null);
  // newElem.setAttribute('data-less-text', elemAttributesObj['less-text'] || null);
  // newElem.setAttribute('underline-color', elemAttributesObj['underline-color'] || null);
  // newElem.setAttribute('underline-color-dark', elemAttributesObj['underline-color-dark'] || null);

  // let strnew = str.replaceAll(regexstart, '<span class="expandable_point" data-more-text="more" data-less-text="less">');
  let strnew = str.replaceAll(regexstart, (match) => {
    const nodeValuesArray = match.split('-');
    const elemAttributesObj = {};
    nodeValuesArray.forEach(value => {
      const splitvalue = value.split("=");
      elemAttributesObj[splitvalue[0].replaceAll('_', '-')] = splitvalue[1] && splitvalue[1].replaceAll('"', "");
    });
    console.log("🚀 ~ file: ExpandablePoints.js ~ line 144 ~ strnew ~ elemAttributesObj", elemAttributesObj)
    return `<span class="expandable_point" data-more-text="${elemAttributesObj['more-text']}" data-less-text="${elemAttributesObj['less-text']}" underline-color="${elemAttributesObj['underline-color']}">`;
  });
  strnew = strnew.replaceAll(regexend, '</span>')

  return strnew;
}