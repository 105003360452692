import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
// import { useTranslation } from 'react-i18next';

// import { primaryColor, white } from 'colorPalette';
// import playBookMark from '../bookmark.svg';
// import playVideo from '../playButton.svg';
import VideoSegmentPlayer from './VideoSegmentPlayer';
// import { Link } from 'react-router-dom';

const ArticleImageWrapper = styled.div`
  display: block;
  /* max-height: 350px; */
  /* background-size: cover; */
  /* background-position: 50% 50%; */
  /* background-repeat: no-repeat; */
  position: relative;
  width: ${({ isSubDigest }) => isSubDigest ? '80%' : '100%'};
  min-height: ${({ isMobileDigestCover }) => isMobileDigestCover ? 'auto' : '200px'};
  img {
    width: 100%;
    border-radius: 6px;
    /* height: 100%; */
    /* max-height: 350px; */
  }
  margin: ${({ isMobileDigestCover }) => isMobileDigestCover ? '0 auto' : '24px auto'};

  @media (max-height: 560px) {
    /* height: 200px; */
  }
  @media (max-height: 760px) {
    /* height: 250px; */
  }
`;

// const TagsListWrapper = styled.div`
//   width: calc(100% - 10px);
//   position: absolute;
//   left: 10px;
//   bottom: 10px;
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
// `;

// const TagWrapper = styled(Link)`
//   display: inline-block;
//   padding: 7px;
//   background-color: ${primaryColor.level1};
//   border-radius: 2px;
//   color: ${white};
//   font-size: 12px;
//   line-height: 16px;
//   font-weight: 700;
//   margin: 5px;
//   text-transform: uppercase;
//   cursor: pointer;

//   &:hover {
//     color: ${white};
//   }
//   span {
//     color: rgba(255, 255, 255, .5);
//   }
  
//   @media (max-width: 500px) {
//     font-size: 12px;
//     line-height: 16px;
//   }
// `;

// const VideoContainer = styled.div`
//   margin: ${({ isMobileDigestCover }) => isMobileDigestCover ? '0 auto' :  '24px auto'};
//   height: ${({ isSubDigest, isMobileDigestCover }) => isSubDigest ? '280px' : isMobileDigestCover ? '150px' :'350px'};
//   position: relative;
//   width: ${({ isSubDigest }) => isSubDigest ? '65%' : '100%'};
//   @media (max-width: 760px) {
//     height: ${({ isSubDigest, isMobileDigestCover }) => isSubDigest ? '200px' : isMobileDigestCover ? '150px' :'250px'};
//   }
//   @media (max-width: 560px) {
//     width: ${({ isSubDigest }) => isSubDigest ? '90%' : '100%'};
//     height: ${({ isSubDigest, isMobileDigestCover }) => isSubDigest ? '170px' : isMobileDigestCover ? '120px' : '200px'};
//   }

//   > div {
//     margin: 0 auto;
//   }
// `;

// const SummariesVideoOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, .7);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 15px;
// `;

// const SummariesMobileVideoOverlay = styled(Link)`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   /* background-color: rgba(0, 0, 0, .7); */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 15px;
// `;

// const PlayVideoButton = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin: 5px;
//   cursor: pointer;
//   width: 45%;
//   text-align: center;
// `;

// const KeySegmentPlay = styled(Link)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin: 5px;
//   cursor: pointer;
//   text-align: center;
//   width: 45%;
// `;
// const PlayVideoIconWrapper = styled.div`
//   border: 2px solid #fff;
//   border-radius: 50%;
//   padding: ${({ padding }) => padding};
//   img {
//     width: ${({ imgWidth }) => imgWidth}px;

//     @media (max-width: 768px) {
//       width: 30px;
//     }
//   }
// `;
// const PlayVideoText = styled.div`
//   font-family: Metropolis;
//   font-size: 16px;
//   font-weight: 700;
//   letter-spacing: 0.47px;
//   color: #ffffff;
//   margin-top: 10px;
//   @media (max-width: 768px) {
//     font-size: 14px;
//   }
// `;

// const connvertSecondsToTimeFormat = (time) => {
//   const date = new Date(null);
//   date.setSeconds(time);
//   if (date.toISOString().substr(11, 2) === "00") {
//     return date.toISOString().substr(14, 5);
//   }
//   return date.toISOString().substr(11, 8);
// }
const RenderDigestCover = ({ 
  data,
  history,
  setPlayingVideo,
  playingVideoID,
  isSubDigest,
  publishInfo,
  isMobileDigestCover,
  theme
}) => {
  // const [showVideoOverlay, setShowVideoOverlay] = useState(false);
  // const [totalVideoDuration, setTotalVideoDuration] = useState(false);
  const videoRef = useRef(null);
  // const { t: translation } = useTranslation();
  // useEffect(() => {
  //   if(data && data.type.code === '255') {
  //     setShowVideoOverlay(true);
  //   }
  // }, [data]);

  useEffect(() => {
    if (playingVideoID && playingVideoID !== data.id && videoRef.current) {
      videoRef.current.pauseVideo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingVideoID])

  const createYTRef = useCallback(() => {
    const validUrl = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (data.cover_pic.match(validUrl)) {
      let video_id = data.cover_pic.match(validUrl)[1];
      videoRef.current = new window.YT.Player(`youtubePlayer-${data.id}`, {
        videoId: video_id,
        height: '100%',
        width: '100%',
        allowfullscreen: true,
        playerVars: {
          fs: 0,
        },
        events: {
          onReady: value => {
            // setTotalVideoDuration(videoRef.current.getDuration())
          },
        },
      });
      videoRef.current.addEventListener("onStateChange", (state) => {
        if (state.data === 1) {
          setPlayingVideo(data.id);
        }
      });
    }
  }, [data, setPlayingVideo]);

  const loadVideo = useCallback(() => {
    if (data.cover_pic && !videoRef.current && window.YT) {
      if (typeof window.YT.Player === 'undefined') {
        window.onYouTubeIframeAPIReady = () => {
          createYTRef();
        }
      } else {
        createYTRef();
      }
    }
  }, [createYTRef, data.cover_pic]);

  useEffect(() => {
    
    if (data && data.cover_pic && data.cover_pic.includes('https://www.youtube.com/watch?v') && !videoRef.current) {
      // loadVideo();
    }
  }, [data, loadVideo]);
  if (data) {
    return (
      <>
        {data.cover_pic && (
          <>
            {!data.cover_pic || (data.cover_pic && !data.cover_pic.includes('https://www.youtube.com/watch?v')) ? (
              <ArticleImageWrapper
                isSubDigest={isSubDigest}
                isMobileDigestCover={isMobileDigestCover}
              >
                <Image src={
                  data.cover_pic && data.cover_pic.indexOf('youtube.com') > -1 ?
                    `https://i3.ytimg.com/vi/${data.cover_pic.substring(data.cover_pic.indexOf('=') + 1, data.cover_pic.length)}/hqdefault.jpg`
                    : data.cover_pic
                } />
                {/* {!isMobileDigestCover && !isSubDigest && data.categories && data.categories.length > 0 && (
                  <TagsListWrapper>
                    {data.categories.slice(0, 3).map(category => (
                      <TagWrapper
                        to={`/category/${category.slug}`}
                        key={category.slug}
                        onClick={() => {
                          // history.push(`/category/${category.slug}`)
                        }}
                      >
                        <span>#</span>
                        {`${category.title}`}
                      </TagWrapper>
                    ))}
                  </TagsListWrapper>
                )} */}
                {isSubDigest && publishInfo()}
              </ArticleImageWrapper>
            ) : (
              <VideoSegmentPlayer
                data={data}
                theme={theme}
              />
                // <VideoContainer isMobileDigestCover={isMobileDigestCover} isSubDigest={isSubDigest}>
                //   <div id={`youtubePlayer-${data.id}`} />
                //   {showVideoOverlay && !isMobileDigestCover && (
                //     <SummariesVideoOverlay>
                //       <KeySegmentPlay
                //         to={`/videoDigest/summary/video/${data.id}`}
                //         target="_blank"
                //       >
                //         <PlayVideoIconWrapper
                //           imgWidth={40}
                //           padding="16px"
                //         >
                //           <Image src={playBookMark} />
                //         </PlayVideoIconWrapper>
                //         <PlayVideoText>{`${translation("watchKeySegment")} (${connvertSecondsToTimeFormat((data.video_summary && data.video_summary.total_length) || 0)})`}</PlayVideoText>
                //       </KeySegmentPlay>
                //       <PlayVideoButton
                //         onClick= {() =>{
                //           setShowVideoOverlay(false);
                //           if (videoRef.current) {
                //             videoRef.current.playVideo();
                //           }
                //         }}
                //       >
                //         <PlayVideoIconWrapper
                //           imgWidth={40}
                //           padding="17px 13px 14px 20px"
                //         >
                //           <Image src={playVideo} />
                //         </PlayVideoIconWrapper>
                //         <PlayVideoText>{`${translation("watchFullVideo")} (${totalVideoDuration ? connvertSecondsToTimeFormat(totalVideoDuration) : ''})`}</PlayVideoText>
                //       </PlayVideoButton>
                //     </SummariesVideoOverlay>
                //   )}
                //   {showVideoOverlay && isMobileDigestCover && (
                //     <SummariesMobileVideoOverlay to={`/videoDigest/summary/video/${data.id}`}
                //       target="_blank" />
                //   )}
                // </VideoContainer>
              )}
          </>
        )}
      </>
    )
  }

  return <></>
};

export default RenderDigestCover;