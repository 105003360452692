import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import SubscribeInfoWrapper from './SubscribeInfoWrapper';
import Banner from './Banner';
import Footer from '../NewsDigests/Desktop/DesktopDigestItem/Footer';
import { fetchNewsLetterLandingData } from 'Api';
import { Loader } from 'semantic-ui-react';
import Testimonial from './Testimonial';


const Wrapper = styled.div`
`;


const LoaderWrapper = styled.div`
  position: relative;
  height: calc(100vh - 100px);
`;

const NewsLetterLanding = ({ location }) => {
  const [data, setData] = useState(null);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [expTestingKey, setExpTestingKey] = useState(null);

  useEffect(() => {
    if (location && location.search) {
      const key = queryString.parse(location.search) ? queryString.parse(location.search).exp : null;
      setExpTestingKey(key);
    }
  }, [location])

  useEffect(() => {
    fetchNewsLetterLandingData({ marcommPage: 'newsletter_page_3' }).then(response => {
      setData(response.data.marcomm.newsletter_page_3);
    })
      .catch(error => {
        console.log("NewsLetterLanding -> error", error);
      })
  }, []);
  console.log(data)
  return (
    <div>
      {data ? (
        <Wrapper>
          {data.top && (
            <Banner data={data.top} expTestingKey={expTestingKey} onSubscribeSuccess={() => setIsSubscribe(true)} isSubscribe={isSubscribe}/>
          )}
          
          {data.testimonial && (
            <Testimonial data={data.testimonial} />
          )}

          {data.subscribe_form && (
            <SubscribeInfoWrapper
              expTestingKey={expTestingKey}
              data={data.subscribe_form}
              onSubscribeSuccess={() => setIsSubscribe(true)}
              isSubscribe={isSubscribe}
              imageSetData={data.top[`image_set_${expTestingKey}`] ? data.top[`image_set_${expTestingKey}`].addl_data : data.top.image_set.addl_data}
              bottomData={data.bottom}
            />
          )}
        </Wrapper>
      ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      <Footer />
    </div>
  );
}

export default NewsLetterLanding;