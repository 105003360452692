import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

const Wrapper = styled.div``;
const ItemWrapper = styled.div`
  margin: 15px 0;
  .ui.radio.checkbox {
    width: 100%;
  }
`;

const CheckboxQuestionType = ({ data, handlePublishData }) => {
  const [selectedValue, setSelectedValue] = useState([]);
  useEffect(() => {
    if (selectedValue) {
      handlePublishData(selectedValue);
    }
  }, [selectedValue, handlePublishData]);
  return (
    <Wrapper>
      {data && data.length > 0 && data.map(item => (
        <ItemWrapper>
          <Checkbox
            checked={selectedValue.includes(item.id)}
            label={item.title}
            onChange={() => {
              const selectedData = JSON.parse(JSON.stringify(selectedValue));
              if (selectedData.includes(item.id)) {
                selectedData.splice(selectedData.indexOf(item.id), 1);
              } else {
                selectedData.push(item.id)
              }

              setSelectedValue(selectedData)
              console.log(selectedData)
            }}
          />
        </ItemWrapper>
      ))}
    </Wrapper>
  )
};

export default CheckboxQuestionType;