/* istanbul ignore file */

import axios from 'axios';


export function setAxiosAuthorizationHeader() {
  // const token = localStorage.getItem('token');
  // const token = '30742481093545b38c2c59dd661a0dbf';
  let guestUuid = localStorage.getItem('Guest-UUID');
  const userData = localStorage.getItem('userData');
  if(userData) {
    guestUuid = JSON.parse(userData).guest_uuid
  }

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  if (selectedLanguage) {
    axios.defaults.headers.common[`Accept-Language`] = JSON.parse(selectedLanguage).code;
  }
  if (guestUuid) {
    // axios.defaults.headers.common.Authorization = `${token}`;
    axios.defaults.headers.common['Guest-UUID'] = `${guestUuid}`;
    // axios.defaults.headers.common['HTTP_APP_VERSION'] = `${34}`;
    axios.defaults.headers.common['app-version'] = `${34}`;
  } else {
    axios.defaults.headers.common.Authorization = null;
    delete axios.defaults.headers.common.Authorization;
  }
}

export function removeHeaderTransform() {
  return {
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization; // eslint-disable-line no-param-reassign
        return data;
      },
    ],
  };
}