import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Button, Image } from 'semantic-ui-react';
import { addNewsSubscription } from 'Api';
import CloseIcon from './close.svg';
import { useTranslation } from 'react-i18next';

const EmailInputWrapper = styled.div`
  margin: 45px 0 20px;
  /* display: flex;
  justify-content: center; */

  @media (max-width: 768px) {
    margin: 25px 0 20px;
  }
`;

const EmailInput = styled(Input)`
  width: 100%;
  input {
    height: 58px;
    width: 100%;
    font-family: 'Metropolis', sans-serif;
    font-size: 20px;
    color: rgba(101, 101, 101, .8);

    @media (max-width: 768px) {
      height: 40px;
      width: 100%;
      font-family: 'Metropolis', sans-serif;
      font-size: 16px;
      color: rgba(101, 101, 101, .8);
    }
  }
`;

const SubscribeButton = styled(Button)`
  font-family: 'Metropolis', sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 2px !important;
  background-color: #ed1c24 !important;
  width: 100% !important;
  text-transform: uppercase !important;
  margin-top: 50px !important;

  @media (max-width: 768px) {
    font-size: 18px !important;
    margin-top: 20px !important;
    margin-top: 20px !important;
  }
  
  @media (max-width: 580px) {
    font-size: 16px !important;
    margin-top: 20px !important;
    margin-top: 15px !important;
  }
`;

const Heading = styled.div`
  font-size: 30px;
  line-height: 36px;
  /* margin: 30px; */
  font-family: 'PT Serif', Georgia, serif;
  display: flex;
  justify-content: center;
  color: #333;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    margin-top: 20px;
  }

  @media (max-width: 580px) {
    font-size: 20px;
    line-height: 20px;
    margin-top: 15px;
  }
`;


const CloseIconWrapper = styled.div`
  width: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
    width: 15px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  line-height: 24px;
  /* font-weight: 500; */
  color: rgba(101, 101, 101, .8);
  font-family: 'Metropolis', sans-serif;
  margin: 15px 0;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 580px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
const Container = styled.div`
  padding: 50px 0;
  font-family: 'Metropolis', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,.76);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 550px;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 100px 50px;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 60%;
    /* height: 100%; */
    overflow: auto;
    padding: 40px 20px;
  }
   @media (max-width: 580px) {
      width: 60%;
      padding: 40px 20px;
    }
    
   @media (max-width: 440px) {
      width: 80%;
      padding: 30px 10px;
    } 
`;
const EmailError = styled.div`
  font-size: 14px;
  color: #DC143C;
  display: flex;
  justify-content: center;
`;

const SubscribeToNewsLetterFull = (props) => {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [subscription, confirmSubscription] = useState(false);
  const [alreadySubscribe, setAlreadySubscribe] = useState(false);
  const { t: translation } = useTranslation();

  useEffect(() => {
    const subscribed = localStorage.getItem('subscribed');
    if (subscribed) {
      confirmSubscription(true);
      setAlreadySubscribe(true);
    };
  }, [confirmSubscription]);

  const validateEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(regx.test(email)) {
      return true;      
    }

    return false;
  }


const isTnd = process.env.REACT_APP_ENV === "tnd-production" || process.env.REACT_APP_ENV === "tnd-staging";
const isUnd = process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
const isOped = process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';
const isClip = process.env.REACT_APP_ENV === 'clip-production';

  let desc = translation("subscribeModal.desc") ;

if(isTnd) {
  desc = translation("subscribeModal.tndDesc");
} else if(isUnd) {
  desc = translation("subscribeModal.undDesc");
} else if(isOped) {
  desc = translation("subscribeModal.opedDesc");
} else if(isClip) {
  desc = translation("subscribeModal.opedDesc");
}

  // const setSize = useCallback(() => {
  //   if (window.innerWidth <= 576) setScreen(true);
  //     else setScreen(false)
  // }, [])

  // // const mobileScreen = window.innerWidth <= 576;
  // useEffect(() => {
  //   if (window) {
  //     if (window.innerWidth <= 576) setScreen(true);
  //     window.addEventListener('resize', setSize);
  //   }
  // }, [setSize])

  useEffect(() => {
      document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
  }, []);
  return (
    <Container
      open={props.subscription}
      onClick={() => props.subscribe(false)}
      // closeIcon
      size="tiny"
      style={{
        backgroundColor: 'rgba(255, 255, 255, .75)',
      }}
    >
      <ContentWrapper onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}>
        <CloseIconWrapper
          onClick={() => props.subscribe(false)}
        >
          <Image src={CloseIcon} />
        </CloseIconWrapper>
        {/* <EmailWrapper>
          <Email name="envelope open outline" size="massive" />
        </EmailWrapper> */}
        <Heading>
          {subscription ? alreadySubscribe ? translation("subscribeModal.alreadySubscribeMessage") : translation("subscribeModal.subscribeThankMessage") : translation("subscribeModal.modalTitle")}
        </Heading>
        <Description>
         {desc.split('\n').map(text => <p>{text}</p>)}
        </Description>
        {!subscription && (
          <EmailInputWrapper>
            <EmailInput
              placeholder='Email'
              onChange={e => setEmail(e.target.value)}
              />
            <SubscribeButton
              onClick={() => {
                const validatedEmail = validateEmail(email);
                if (validatedEmail) {
                  addNewsSubscription({ email }).then(response => {
                    setEmailError(null);
                    setEmail(null);
                    confirmSubscription(true)
                    localStorage.setItem('subscribed', true);
                  }).catch(error => {
                  console.log("SubscribeToNewsLetterFull -> error", error.response)
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.detail) {
                      setEmailError(error.response.data.detail);
                  }
                    
                    if (window && window.Raven) window.Raven.captureException(error.response);
                  });
                } else {
                  setEmailError('Please enter a valid email');
                }
                }}
              >
              Subscribe
            </SubscribeButton>
          </EmailInputWrapper>
        )}
          {emailError && (
            <EmailError>{emailError}</EmailError>
          )}
      </ContentWrapper>
    </Container>
  );
}

export default SubscribeToNewsLetterFull;