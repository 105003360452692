import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'react-device-detect';
import { varientTitle } from 'global';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 101;
  display: ${({ showAppBannerAds }) => showAppBannerAds ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: flex-end;
`;

const Container = styled.div`
  transform: ${({ showAppBannerAds }) => showAppBannerAds ? `translateY(0)` : `translateY(100%)`};
  padding: 20px 20px 30px;
  background-color: #fff;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  transition: all 1s;
`;

const SectionTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 22px;
  line-height: 1.3;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
`;

const GetTheAppButton = styled.a`
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  background-color: #ec1d24;
  margin-top: 20px;
  cursor: pointer;
  display: block;

  &:hover {
    color: #ffffff;
  }
`;

const ContinueInMobileButton = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.81);
  padding: 10px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.35);
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
`;
const MobileAppBannerAds = ({ showAppBannerAds, onContinueMobileWeb, digestSlug }) => {
  const { t: translation } = useTranslation();
  // const isTnd = process.env.REACT_APP_ENV === "tnd-production" || process.env.REACT_APP_ENV === "tnd-staging";
  const isUnd = process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
  const isOped = process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';
  const isClip = process.env.REACT_APP_ENV === 'clip-production';
  let playStoreUrl = "https://play.google.com/store/apps/details?id=in.travelnewsdigest";
  let AppStoreUrl = "https://apps.apple.com/us/app/travel-news-digest/id1501703558";

  if(isOped) {
    playStoreUrl =  "https://ideastoday.onelink.me/kp5H?pid=website_header&c=Website%20header&af_web_dp=https%3A%2F%2Fideastoday.in&type=digests";
    AppStoreUrl = null;
  }
  
  if(isClip) {
    playStoreUrl =  "https://play.google.com/store/apps/details?id=com.getdailybrief.tv&hl=en_IN";
    AppStoreUrl = null;
  }

  if(isUnd) {
    playStoreUrl = null;
    AppStoreUrl = null;
  }
  let showads = false;
  if(isAndroid && playStoreUrl) {
    showads = true;
  }
  if (isIOS && AppStoreUrl) {
    showads = true;
  }

  useEffect(() => {
    if (showads && sessionStorage.getItem('isCloseBannerAds') !== 'true') {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return () => {
      clearAllBodyScrollLocks();
    }
  })


  return (
    <>
      {(showads && sessionStorage.getItem('isCloseBannerAds') !== 'true') && (
        <Wrapper
          showAppBannerAds={showAppBannerAds}
          onClick={() => {
            sessionStorage.setItem('isCloseBannerAds', true);
            onContinueMobileWeb();
            enableBodyScroll();
          }}
        >
          <Container
            showAppBannerAds={showAppBannerAds}
            onClick={(e) => e.stopPropagation()}
          >
            <SectionTitle>{translation("digestDetail.bannerAds.title", {
              siteName: varientTitle
            })}</SectionTitle>
            <GetTheAppButton
              href={isAndroid ? `${playStoreUrl}&slug=${digestSlug}` : `${AppStoreUrl}?digestId=${digestSlug}`}
              target="_blank"
              onClick={() => {
                enableBodyScroll();
                onContinueMobileWeb();
                sessionStorage.setItem('isCloseBannerAds', true);
              }}
            >{translation("digestDetail.bannerAds.getTheAppButtonText")}</GetTheAppButton>
            <ContinueInMobileButton
              onClick={() => {
                sessionStorage.setItem('isCloseBannerAds', true);
                onContinueMobileWeb();
                enableBodyScroll();
              }}
            >{translation("digestDetail.bannerAds.ContinueInMobileText")}</ContinueInMobileButton>
          </Container>
        </Wrapper>
      )}
    </>
  );
}

export default MobileAppBannerAds;