const primaryColor = {
  level1: '#E12830',
};

const secondaryColor = {
  level1: '#002452',
}

const grey = {
  level0: '#696969',
  level1: '#A9A9A9',
  level2: '#808080',
  level3: '#b0b0b0',
  level4: '#5E5E5E',
  level5: '#d8d8d8',
  level6: '#e9e9e9',
  level7: '#f6f6f6',
};

const categoryDiscover = "#6b6b6b";

const white = '#fff';
const black = '#000';

export {
  primaryColor,
  secondaryColor,
  grey,
  white,
  black,
  categoryDiscover
};