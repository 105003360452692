// export const lightTheme = {
//     main: "#fff",
//     mainRGB: "255, 255, 255",
//     main2: "#F8F8FC",
//     main3: "#fff",
//     main4: "#e7eaf7",
//     fontColor: "#272727",
//     fontColorRGB: "0, 0, 0",
//     fontColor2: "#383E53",
//     fontColor3: "#696969",
//     about1: "#272727",
//     sourceBrandName: "#747474",
//     externalLinkIcon: "#404040",
//     menuBarFont: "#5C5C5C",
//     closeIconBtn: "#000000",
// }

// export const darkTheme = {
//     main: "#2A2D32",
//     mainRGB: "42, 45, 50",
//     main2: "#070703",
//     main3: "#34373C",
//     main4: "#34373C",
//     fontColor: "#fff",
//     fontColorRGB: "255, 255, 255",
//     fontColor2: "#9ba2bb",
//     fontColor3: "#b5b5b5",
//     about1: "#ffffff",
//     sourceBrandName: "#a6a6a6",
//     externalLinkIcon: "#737373",
//     menuBarFont: "#8f8f8f",
//     closeIconBtn: "#000000",
// }


export const lightTheme = {

    primaryColor: {
        level1: "#FFFFFF",
        level1RGB: "255, 255, 255",
        level2: "#F8F8FC",
        level3RGB: "255, 255, 255",
    },

    secondaryColor: {
        level1: "#FFFFFF",
        level2: "#E8ECFC",
        level3: "#E8ECFC",
        level4: "#FFFFFF",
        level5: "#F0F0F0",
    },

    fontColor: {
        level1: "#696969",
        level2: "#272727",
        level2RGB: "0, 0, 0",
        level3: "#383E53",
        level4: "#74767E"
    },

    misc: {
        main4: "#E7EAF7", 
        sourceBrandName: "#747474",
        // externalLinkIcon: "#404040",
        menuBarFont: "#5C5C5C",
        // closeIconBtn: "#000000",
    },
}

export const darkTheme = {

    primaryColor: {
        level1: "#2A2D32",
        level1RGB: "42, 45, 50",
        level2: "#070703",
        level3RGB: "0, 0, 0",
    },

    secondaryColor: {
        level1: "#34373C",
        level2: "#34373C",
        level3: "#12151D",
        level4: "#2A2C31", 
        level5: "#34373C",
    },

    fontColor: {
        level1: "#B5B5B5",
        level2: "#FFFFFF",
        level2RGB: "255, 255, 255",
        level3: "#9BA2BB",
        level4: "#aeafb1"
    },

    misc: {
        main4: "#34373C",
        sourceBrandName: "#A6A6A6",
        // externalLinkIcon: "#737373",
        menuBarFont: "#8F8F8F",
        // closeIconBtn: "#000000",
    },
}


// For Reference: ${props => props.theme.primaryColor.level1};