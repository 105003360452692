import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Header, Icon, Image } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { grey, primaryColor, white } from 'colorPalette';
// import sanitizeHtml from 'sanitize-html';
import ReactPlayer from 'react-player';

import addReadLater from '../../Assets/add_read_later.png';
import readLaterAdded from '../../Assets/read_later_added.png';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
const IMAGE_MIN_HEIGHT = 100;
const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  /* height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`}; */
  padding: 10px 10px 0 10px;
  position: relative;
  box-shadow: 0 0 25px 0 #d8d8d8;
  height: 100%;
  font-family: 'Metropolis', sans-serif;

  @media (max-width: 900px) and (min-width: 768px) {
    width: 60%;
  }

  @media (max-width: 768px) and (min-width: 600px) {
    width: 70%;
  }
  
  @media (max-width: 600px) and (min-width: 480px) {
    padding: 0;
    width: 90%;
  }
  
  @media (max-width: 480px) {
    padding: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  /* height: ${({ totalIgnoreHeight }) => `calc(100vh - ${totalIgnoreHeight}px)`}; */
  /* overflow: auto; */
  height: 100%;
  overflow: auto;
  display: ${({ showMore }) => showMore ? 'block' : 'flex'};
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  max-height: 250px;
  min-height: ${({ minHeight }) => minHeight}px;
  /* height: ${({ height }) => height}px; */
  width: 100%;
  position: relative;
  background: #dcdcdc url("${({ imageUrl }) => imageUrl}");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  img {
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: ${({ showMore }) => `translate(-50%, -${showMore ? 0 : 50}%)`};
  }
  @media (max-width: 768px) {
    max-height: 210px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const TagWrapper = styled.div`
  display: inline-block;
  padding: 3px 7px;
  background-color: ${primaryColor.level1};
  border-radius: 2px;
  color: ${white};
  font-size: 12px;
  font-weight: 700;
  margin: 5px;
  text-transform: uppercase;

  span {
    color: rgba(255, 255, 255, .5);
  }
  
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const TagsListWrapper = styled.div`
  width: calc(100% - 10px);
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const DigestContentWrapper = styled.div`
  margin-top: 20px;
  padding: 0 10px;
  /* max-height: ${({ maxHeight }) => maxHeight}px; */
  /* padding-bottom: ${({ showMore }) => showMore ? 70 : 0}px; */
  margin-bottom: ${({ footerHeight, showMore }) => showMore ? 30 : footerHeight}px;
  /* height: ${({ showMore }) => showMore ? '100%' : 'calc(100vh - 400px)'}; */
  /* flex: 1; */
  overflow: ${({ showMore }) =>  showMore ? 'auto' : 'hidden'};
  position: relative;

  @media (max-width: 768px) {
    margin-top: 5px;
    padding: 0 10px;
    /* padding-bottom: ${({ footerHeight, showMore }) => showMore ? 50 : footerHeight}px; */
  }
`;

const DigestTitle = styled(Header)`
  font-size: 18px !important;
  font-family: 'Metropolis', sans-serif !important;
  margin-bottom: 5px !important;
  @media (max-width: 500px) {
    font-size: 16px !important;
  }
`;

const DigestSubTitle = styled.div`
  font-size: 12px !important;
  line-height: 14px;
  color: ${white};
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
`;

const DigestDesc = styled.div`
  font-size: 16px;
  line-height: 20px;
  img {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const ArticleItemFooter = styled.div`
  position: ${({ showMore }) => showMore ? 'static' : 'absolute' };
  width: 100%;
  left: 0;
  bottom: 0;
`;

const ArticleFooterStatisticsWrapper = styled.div`
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArticleActionTextWrapper = styled.a`
  background-color: ${grey.level0};
  padding: 5px;
  display: block;
  .actionText {
    font-size: 12px;
    line-height: 14px;
    margin-top: 2px;
    color: ${white};
  }
`;

const ArticleStatistics = styled.div`
  color: ${grey.level2};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  span {
    margin-right: 4px;
    display: inline-block;
  }

  .divider {
    margin: 0 6px 0 2px;
    width: 5px;
    height: 5px;
    background-color: ${grey.level2};
    border-radius: 50%;
  }
`;

const ArticleShareItems = styled.div`
  display: flex;
  align-items: center;
`;

const BookmarkArticle = styled.div`
  margin: 0 5px;
  i {
    font-size: 18px !important;
  }
`;

const ShareArticle = styled.div`
  i {
    font-size: 18px !important;
  }
`;

const MenuItems = styled.div`
  width: 16px;
  margin: 0 5px;
`;

const DigestInfoText = styled.div`
  margin: 5px 0;
  color: ${grey.level2};
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const DigestCreatedBy = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #2c2a2a;
  margin: 4px 0;
  span {
    color: ${grey.level2};
  }

  .label {
    padding: 7px 10px;
    background-color: #ed3833;
    color: ${white};
    font-size: 16px;
  }

  .divider {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: ${grey.level2};
    border-radius: 50%;
    margin: 0 5px;
  }
`;

const ContinuesWatchWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, .5);
`;
const ContinuesWatchButton = styled.div`
  width: 60%;
  padding: 15px 20px;
  background-color: ${white};
  border: 1px solid ${grey.level3};
  margin: 10px auto;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
`;

// const DownloadAppWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   background-color: ${black};
//   padding: 18px 10px;
// `;

// const DropdownIcon = styled.div`
//   position: absolute;
//   right: 5px;
//   ${({ close }) => close ?
//   `
//     top: 50%;
//     transform: translateY(-50%);
//   `
//   :`
//     top: 5px;
//   `}
//   i {
//     color: ${white};
//   }
// `;

// const CloseDownloadApp = styled.div`
//   color: ${white};
//   text-align: center;
//   font-weight: 700;
//   font-size: 16px;
// `;

// const OpenDownloadAppWrapper = styled.div`
  
// `;

// const DownloadAppTitle = styled.div`
//   font-size: 16px;
//   line-height: 20px;
//   font-weight: 900;
//   color: ${white};
//   text-align: center;
//   width: 150px;
//   padding: 10px 0;
//   position: relative;
//   margin: 0 auto;
  
//   &:after {
//     content: '';
//     width: 130px;
//     height: 4px;
//     display: block;
//     margin: 0 auto;
//     background-color: ${primaryColor.level1};
//     position: absolute;
//     left: 10px;
//     bottom: 0;
//   }
// `;

// const DownloadAppButton = styled.div`
//   width: 160px;
//   margin: 0 auto;
//   text-align: center;
//   margin-top: 25px;
//   button {
//     background-color: ${primaryColor.level1} !important;
//     color: ${white} !important;
//   }
// `;

const HideReadingButton = styled.div`
  padding: 5px 3px 7px;
  background-color: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  z-index: 10;

  i {
    font-size: 18px !important;
    vertical-align: bottom !important;
  }
`;

const ArticleItem = ({ 
  data,
  onDigestBookMark,
  onDigestReadLater,
  openDownloadApp,
  headerHeight,
  onClickDownloadAppDropdown,
  onSetDisableSwipe,
  onClickTags
}) => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  const contentWrapperRef = useRef(null);
  const imageWrapperRef = useRef(null);
  const [showMoreData, setShowMoreData] = useState(false);
  const [showContinuesWtatch, setShowContinuesWtatch] = useState(false);
  const [articleFooterHeight, setArticleFooterHeight] = useState(0);
  const [isSetDimensions, setIsSetDimensions] = useState(false);

  let wrapperHeight = null;
  let imageMinHeight = IMAGE_MIN_HEIGHT;
  // let contentMaxHeight = 100;
  let contentWrapperHeight = 100;
  if (wrapperRef.current && contentWrapperRef.current && imageWrapperRef.current && !isSetDimensions) {
    wrapperHeight = wrapperRef.current.offsetHeight;
    contentWrapperHeight = contentWrapperRef.current.offsetHeight;

    const imageHeight = (wrapperHeight - (articleFooterHeight + contentWrapperHeight + 30));

    if(imageHeight < IMAGE_MIN_HEIGHT) {
      setShowContinuesWtatch(true);
    }
    imageWrapperRef.current.style.minHeight = `${imageHeight > IMAGE_MIN_HEIGHT ? imageHeight > 210 ? 210 : imageHeight : IMAGE_MIN_HEIGHT}px`;
    setIsSetDimensions(true);
  }
  
  useEffect(() => {
    if (history.location.hash === '#showMore') {
      setShowMoreData(true);
      onSetDisableSwipe(true);
      if(isSetDimensions) {
        imageWrapperRef.current.style.minHeight = '210px';
      }
    } else {
      onSetDisableSwipe(false);
      setShowMoreData(false);
      if (isSetDimensions) {
        const imageHeight = (wrapperHeight - (articleFooterHeight + contentWrapperHeight + 20));
        imageWrapperRef.current.style.minHeight = `${imageHeight > IMAGE_MIN_HEIGHT ? imageHeight > 210 ? 210 : imageHeight : IMAGE_MIN_HEIGHT}px`;
      }
    }
  }, [articleFooterHeight, contentWrapperHeight, history.location, isSetDimensions, onSetDisableSwipe, wrapperHeight]);

  let digestDesc = data.digest;

  if(digestDesc && !showMoreData && digestDesc.split(" ").length > 70) {
    digestDesc = digestDesc.split(" ").slice(0, 70).join(" ");
    digestDesc = digestDesc + ' ...';
    if (!showContinuesWtatch) {
      setShowContinuesWtatch(true);
    }
  }
  return (
    <>
      {data && (
        <Wrapper 
          ref={wrapperRef}
          headerHeight={headerHeight}
          key={data.id}
          showMore={showMoreData}
        >
          {showMoreData && (
            <HideReadingButton
              onClick={e => {
                e.stopPropagation();
                if (!showMoreData) {
                  // setShowMoreData(true);
                  history.replace(`/${data.id}#showMore`);
                } else {
                  // setShowMoreData(false);
                  history.replace(`/${data.id}`);
                }
              }}
            >
              <Icon name="arrow left" size="large" />
            </HideReadingButton>
          )}
          <Container
            showMore={showMoreData}
            totalIgnoreHeight={headerHeight + (showMoreData ? 0 : articleFooterHeight) + 20}
          >
            <ImageWrapper
              ref={imageWrapperRef}
              showMore={showMoreData}
              minHeight={imageMinHeight}
              // imageUrl={data.cover_pic}
            >
              {!data.cover_pic || (data.cover_pic && !data.cover_pic.includes('https://www.youtube.com/watch?v')) ? (
                <>
                  <Image src={
                    data.cover_pic.indexOf('youtube.com') > -1 ?
                      `https://i3.ytimg.com/vi/${data.cover_pic.substring(data.cover_pic.indexOf('=') + 1, data.cover_pic.length)}/hqdefault.jpg`
                      : data.cover_pic
                  } />
                  {data.categories && data.categories.length > 0 && (
                    <TagsListWrapper>
                      {data.categories.slice(0, 3).map(category => (
                        <TagWrapper
                          onClick={() => onClickTags(category)}
                        >
                          <span>#</span>
                          {`${category.title}`}
                        </TagWrapper>
                      ))}
                    </TagsListWrapper>
                  )}
                </>
              ) : (
                <VideoContainer>
                  <ReactPlayer
                    controls
                    playing={true}
                    volume="0"
                    muted
                    width="100%"
                    height="100%"
                    url={data.cover_pic}
                    config={{
                      youtube: {
                        // playerVars: { showinfo: 0 }
                      },
                    }}
                  />
                </VideoContainer>
              )}
            </ImageWrapper>
            <DigestContentWrapper
              showMore={showMoreData}
              ref={contentWrapperRef}
              footerHeight={articleFooterHeight + 10}
              // maxHeight={contentMaxHeight}
            >
              <DigestTitle as="h2">{data.title}</DigestTitle>
              {data.format_human && data.source && data.source.author && data.source.author.name && (
                <DigestCreatedBy>
                  {data.format_human && (
                    <span className="label">
                      {`${data.format_human}`}
                    </span>
                  )}
                  {data.format_human && data.source && data.source.author && data.source.author.name && (
                    <span className="divider" />
                  )}
                  {data.source && data.source.author && data.source.author.name && (<span>{`By ${data.source.author.name}`}</span>)}
                </DigestCreatedBy>
              )}
              <DigestInfoText>
                {data.source && data.source.host && (
                  <>
                    <span>{`Tap for more at `}</span>
                    <a
                      href={data.source && data.source.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        logAmplitudeEvent('Article Opened', {
                          article_id: data.id,
                          article_type: data.type.code,
                          action: 'click',
                          slug: data.slug,
                          category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
                        });
                      }}
                    >{data.source && data.source.host}</a>
                    {data.first_published_at_human && (<span>/</span>)}
                  </>
                )}
                <span>{data.first_published_at_human || ''}</span>
              </DigestInfoText>
              <DigestDesc>
                {data.digest && data.markup && data.markup.apply ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:digestDesc }}
                  />
                  // <ReactMarkdown source={digestDesc} />
                ) : (
                    <div>
                      {
                        digestDesc && digestDesc.split(/\n/).map((para, index) => (
                          <p key={index}>{para}</p>
                        ))
                      }
                      {/* {
                        data.digest && data.digest.split(/\n/).map((para, index) => (
                          <p key={index}>{para}</p>
                        ))
                      }
                      {
                        data.digest && data.digest.split(/\n/).map((para, index) => (
                          <p key={index}>{para}</p>
                        ))
                      }
                      {
                        data.digest && data.digest.split(/\n/).map((para, index) => (
                          <p key={index}>{para}</p>
                        ))
                      }
                      {
                        data.digest && data.digest.split(/\n/).map((para, index) => (
                          <p key={index}>{para}</p>
                        ))
                      }
                      {
                        data.digest && data.digest.split(/\n/).map((para, index) => (
                          <p key={index}>{para}</p>
                        ))
                      } */}
                    </div>
                  )}
                {/* {
                  data.digest && data.digest.split(/\n/).map((para, index) => (
                    <p key={index}>{para}</p>
                  ))
                } */}
                {/* {data.digest && data.digest.split(' ').filter(char => char.trim() !== "").length > 100 && !showMoreData && ( */}
                {showContinuesWtatch && !showMoreData && (
                  <ContinuesWatchWrapper>
                    <ContinuesWatchButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!showMoreData) {
                          // setShowMoreData(true);
                          history.push(`/${data.id}#showMore`);
                        } else {
                          // setShowMoreData(false);
                          history.push(`/${data.id}`);
                        }
                      }}
                    >
                      {`${showMoreData ? 'Hide' : 'Continues'} reading`}
                    </ContinuesWatchButton>
                  </ContinuesWatchWrapper>
                )}
              </DigestDesc>
            </DigestContentWrapper>
            <ArticleItemFooter
              ref={a => {
                if(a) {
                  setArticleFooterHeight(a.offsetHeight);
                }
              }}
              showMore={showMoreData}
            >
              <ArticleFooterStatisticsWrapper>
                <ArticleStatistics
                  onClick={e => {
                    e.stopPropagation();
                    // onClickDownloadAppDropdown(true);
                    history.push(`/digest/${data.slug}/comments`)
                  }}
                >
                  {data.likes > 0 && (
                    <>
                      <span>{data.likes}</span>
                      <span>likes</span>
                    </>
                  )}
                  {data.comments > 0 && (
                    <>
                      {data.likes > 0 && (
                        <span className="divider" />
                      )}
                      <span>{data.comments}</span>
                      <span>comments</span>
                    </>
                  )}
                  {data.comments === 0 && (
                    <>
                      {data.likes > 0 && (
                        <span className="divider" />
                      )}
                      <span>Write a comment...</span>
                    </>
                  )}
                </ArticleStatistics>
                <ArticleShareItems>
                  <MenuItems
                    onClick={e => {
                      e.stopPropagation();
                      onDigestReadLater(data);
                    }}
                    // onClick={() => onClickDownloadAppDropdown(true)}
                  >
                    <Image src={data.readlater ? readLaterAdded : addReadLater} />
                  </MenuItems>
                  <BookmarkArticle
                    onClick={e => {
                      e.stopPropagation();
                      onDigestBookMark(data);
                    }}
                    // onClick={() => onClickDownloadAppDropdown(true)}
                  >
                    <Icon
                      name={data.like ? "heart" : "heart outline"}
                      size="large"
                      color={data.like ? 'red' : 'black'}
                    />
                  </BookmarkArticle>
                  <ShareArticle
                    onClick={e => {
                      e.stopPropagation();
                      history.push(`/${data.id}#share`);
                    }}
                    // onClick={() => onClickDownloadAppDropdown(true)}
                  >
                    <Icon
                      name="share alternate"
                      size="large"
                    />
                  </ShareArticle>
                </ArticleShareItems>
              </ArticleFooterStatisticsWrapper>
              <ArticleActionTextWrapper
                href={data.source.link}
                onClick={() => {
                  logAmplitudeEvent('Article Opened', {
                    article_id: data.id,
                    article_type: data.type.code,
                    action:'bottom',
                    slug: data.slug,
                    category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
                  });
                }}
                target="_blank"
              >
                <DigestSubTitle>{data.subtitle}</DigestSubTitle>
                <div className="actionText">{data.source.title}</div>
              </ArticleActionTextWrapper>
            </ArticleItemFooter>
          </Container>
          {/* {showMoreData && (
            <ContinuesWatchButton
              onClick={(e) => {
                e.stopPropagation();
                if (!showMoreData) {
                  // setShowMoreData(true);
                  history.replace(`/digests/${data.id}#showMore`);
                } else {
                  // setShowMoreData(false);
                  history.replace(`/digests/${data.id}`);
                }
              }}
            >
              {`${showMoreData ? 'Hide' : 'Continues'} reading`}
            </ContinuesWatchButton>
          )} */}
          
          {/* <DownloadAppWrapper
            onClick={() => {
              if(!openDownloadApp) {
                onClickDownloadAppDropdown(true);
              }
            }}
          >
            {!openDownloadApp && (
              <CloseDownloadApp>
                <div>Download the App</div>
              </CloseDownloadApp>
            )}
            {openDownloadApp && (
              <OpenDownloadAppWrapper>
                <DownloadAppTitle>
                  <div>GET INFORMED</div>
                  <div>GET INSPIRED</div>
                </DownloadAppTitle>
                <DownloadAppButton>
                  <Button primary>Download the app</Button>
                </DownloadAppButton>
              </OpenDownloadAppWrapper>
            )}
            <DropdownIcon 
              onClick={() => onClickDownloadAppDropdown(!openDownloadApp)}
              close={!openDownloadApp}
            >
              <Icon name="angle up" size="large"/>
            </DropdownIcon>
          </DownloadAppWrapper> */}
        </Wrapper>
      )}
    </>
  );
}

export default ArticleItem;
