import React, { useReducer, useEffect, useRef } from "react";
import produce from "immer";
import { useHistory } from "react-router-dom";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import {
  // BrowserView,
  // MobileView,
  isMobile,
  isTablet,
  isBrowser,
} from "react-device-detect";
import styled from "styled-components";
import { v1 as uuidv1 } from "uuid";

import MobileNewsDigests from "./Mobile";
import {
  fetchDigestListApi,
  deleteBookmarkDigest,
  bookmarkDigest,
  addReadLater,
  deleteReadLater,
  addReadDigest,
  fetchTopDigestsList,
} from "Api";
import DesktopNewsDigest from "./Desktop";
import { logAmplitudeEvent } from "Utils/amplitudeLoad";

const AmplitudePageType = "HomePage";

const PAGE_SIZE = 5;

const NewsDigestWrapper = styled.div`
  font-family: "Metropolis", sans-serif;
`;

// const UnreadCountToastWrapper = styled.div`
//   position: fixed;
//   bottom: 20px;
//   left: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
// `;
// const UnreadCountToast = styled.div`
//   padding: 10px 20px;
//   border-radius: 6px;
//   background-color: #323232;
//   color: #fff;
//   font-size: 16px;
//   font-weight: 700;

// `;
/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "SET_FETCH_ARTICLE_LOADER":
        if (action.isRefresh) {
          draft.videoDigestList = [];
        }
        draft.fetchArticleLoader = action.value;
        draft.loadMoreBtn = null;
        break;
      case "SET_ACTIVE_PAGINATION":
        draft.activePagination = action.value;
        if (action.removeLoadMoreText) {
          draft.loadMoreBtn = null;
        }
        break;
      case "SET_VIDEO_DIGESTS_LIST":
        if (
          action.unread_count &&
          draft.videoDigestList &&
          draft.videoDigestList.length > 0
        ) {
          draft.unreadArticleCount = action.unread_count;
        }
        if (action.fetchUuid) {
          if (action.fetchUuid === draft.fetchUuid) {
            if (action.isRefresh) {
              draft.videoDigestList = action.value;
              draft.openRefreshSuccessfully = true;
            } else {
              if (action.value.length < PAGE_SIZE) {
                draft.videoDigestList = draft.videoDigestList
                  .concat(action.value)
                  .concat(Array(PAGE_SIZE - action.value.length));
              } else {
                draft.videoDigestList = draft.videoDigestList.concat(
                  action.value
                );
              }
            }
          }
        } else {
          if (action.isRefresh) {
            draft.videoDigestList = action.value;
            draft.openRefreshSuccessfully = true;
          } else {
            if (action.value.length < PAGE_SIZE) {
              draft.videoDigestList = draft.videoDigestList
                .concat(action.value)
                .concat(Array(PAGE_SIZE - action.value.length));
            } else {
              draft.videoDigestList = draft.videoDigestList.concat(
                action.value
              );
            }
          }
        }
        if (action.fetchUuid) {
          if (action.fetchUuid === draft.fetchUuid) {
            draft.fetchArticleLoader = false;
          }
        } else {
          draft.fetchArticleLoader = false;
        }
        draft.isLoadFirstTime = true;
        if (action.filterSuggestion) {
          draft.filterSuggestion = action.filterSuggestion;
        } else if (action.isRefresh) {
          draft.filterSuggestion = {};
        }
        if (action.pillsData) {
          draft.pillsData = action.pillsData || [];
        }
        draft.disablePagination = !action.hasNext || !!action.loadMoreBtn;
        draft.formatData = action.formatData;
        draft.loadMoreBtn = action.loadMoreBtn;
        break;
      case "SET_VIDEO_DIGEST_READ_LIST":
        draft.videoDigestList = draft.videoDigestList.map((item) => {
          if (item.id === action.id) {
            item.read = true;
          }

          return item;
        });
        break;
      case "SET_LOAD_FIRST_TIME":
        draft.isLoadFirstTime = action.value;
        break;
      case "SET_ACTIVE_DIGEST_ID":
        draft.activeDigestId = action.value;
        break;
      case "SET_DIGEST_BOOKMARK":
        draft.bookmarkLoader = false;
        draft.videoDigestList = draft.videoDigestList.map((digest) => {
          if (digest.id === action.digestId) {
            digest.like = action.value;
            if (action.value) {
              digest.likes += 1;
            } else {
              digest.likes -= 1;
            }
          }
          return digest;
        });
        break;

      case "SET_DIGEST_READ_LATER":
        draft.readLaterLoader = false;
        draft.videoDigestList = draft.videoDigestList.map((digest) => {
          if (digest.id === action.digestId) {
            digest.readlater = action.value;
          }
          return digest;
        });

        draft.bannerTopStories.articles = draft.bannerTopStories.articles.map(
          (digest) => {
            if (digest.id === action.digestId) {
              digest.readlater = action.value;
            }
            return digest;
          }
        );
        break;
      case "SET_CATEGORY_FILTER":
        draft.categoryFilter = action.value;
        draft.exploreFilter = null;
        draft.activePagination = 1;
        draft.videoDigestList = [];
        draft.isLoadFirstTime = false;
        draft.fetchArticleLoader = true;
        break;
      case "SET_EXPLORE_FILTER":
        draft.exploreFilter = action.value;
        draft.categoryFilter = null;
        draft.activePagination = 1;
        draft.videoDigestList = [];
        draft.isLoadFirstTime = false;
        // draft.fetchArticleLoader = true;
        break;
      case "SET_SELCETED_TAGS":
        draft.selectedTags = action.value;
        draft.activePagination = 1;
        break;
      case "SET_UNREAD_COUNT":
        draft.unreadArticleCount = action.value;
        break;
      case "SET_REFRESHED_SUCCESSFULLY":
        draft.openRefreshSuccessfully = action.value;
        break;
      case "SET_SELECTED_TOPIC_SOURCE":
        draft.selectedTopic = action.topic;
        draft.selectedSource = action.source;
        draft.activePagination = 1;
        break;
      case "SET_BOOKMARK_LOADER":
        draft.bookmarkLoader = action.value;
        break;
      case "SET_READ_LATER_LOADER":
        draft.readLaterLoader = action.value;
        break;
      case "SET_SEARCH_PAGE_DATA":
        const {
          activePagination,
          digestList,
          selectedSource,
          selectedTopic,
          currentDigestIndex,
        } = action.value;
        draft.isLoadFirstTime = true;
        draft.fetchArticleLoader = false;
        draft.videoDigestList = digestList;
        draft.activePagination = activePagination;
        draft.selectedTopic = selectedTopic;
        draft.selectedSource = selectedSource;
        draft.currentActiveDigest = currentDigestIndex;
        break;
      case "SET_FETCH_TOP_STORIES_LOADER":
        draft.topStoriesLoader = action.value;
        break;
      case "SET_TOP_STORIES":
        draft.bannerTopStories = action.value;
        draft.sideBarTopStories = action.sideBarTopStories;
        draft.topStoriesLoader = false;
        break;
      case "SET_CLEAR_SEARCH_VALUE":
        draft.videoDigestList = [];
        draft.filterSuggestion = {};
        break;
      case "SET_DISABLE_PAGINATION":
        draft.disablePagination = action.value;
        break;
      case "SET_FETCH_DIGESTS_UUID":
        draft.fetchUuid = action.value;
        break;
    }
  });

const initialState = {
  fetchArticleLoader: true,
  videoDigestList: [],
  activePagination: 1,
  isLoadFirstTime: null,
  exploreFilter: null,
  categoryFilter: null,
  selectedTags: null,
  unreadArticleCount: null,
  selectedTopic: null,
  selectedSource: null,
  openRefreshSuccessfully: false,
  bookmarkLoader: false,
  readLaterLoader: false,
  filterSuggestion: {},
  currentActiveDigest: null,
  bannerTopStories: { articles: [] },
  sideBarTopStories: null,
  topStoriesLoader: true,
  pillsData: [],
  disablePagination: false,
  formatData: null,
  loadMoreBtn: "",
};
const NewsDigests = ({
  location: { state: locationState, pathname },
  match: { params },
  location,
  handleUserNotFound,
  theme,
  setTheme,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const unreadCountTimeoutRef = useRef(null);
  const exploreId = useRef(null);
  const categoryId = useRef(null);
  const typeRef = useRef(null);
  const {
    activePagination,
    videoDigestList,
    isLoadFirstTime,
    fetchArticleLoader,
    // exploreFilter,
    // categoryFilter,
    selectedTags,
    unreadArticleCount,
    openRefreshSuccessfully,
    selectedSource,
    selectedTopic,
    bookmarkLoader,
    readLaterLoader,
    currentActiveDigest,
    topStoriesLoader,
    bannerTopStories,
    sideBarTopStories,
    filterSuggestion,
    pillsData,
    disablePagination,
    formatData,
    loadMoreBtn,
  } = state;

  const onVisitArticle = (article) => {
    logAmplitudeEvent("Article Read", {
      article_id: article.id,
      article_type: article.type.code,
      slug: article.slug,
      category_name:
        article && article.categories
          ? article.categories.map((item) => item.title).join(",")
          : "",
    });
    addReadDigest({ digests: [article.id] })
      .then((response) => {
        dispatch({ type: "SET_VIDEO_DIGEST_READ_LIST", id: article.id });
      })
      .catch((error) => {
        if (window && window.Raven)
          window.Raven.captureException(error.response);
      });
  };

  useEffect(() => {
    if (openRefreshSuccessfully) {
      setTimeout(() => {
        dispatch({ type: "SET_REFRESHED_SUCCESSFULLY", value: false });
      }, 5000);
    }
  }, [openRefreshSuccessfully]);

  const handleFetchTopStories = ({ category, explore }) => {
    dispatch({ type: "SET_FETCH_TOP_STORIES_LOADER", value: true });
    
    fetchTopDigestsList({
      // categories: category ? category.slug : null,
      // explore: explore ? explore.slug : null,
    })
      .then((response) => {
        console.log("response.data.sidebar", response.data.sidebar);
        dispatch({
          type: "SET_TOP_STORIES",
          value: response.data.hero,
          sideBarTopStories: response.data.sidebar,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          localStorage.clear();
        }
        if (window && window.Raven)
          window.Raven.captureException(error.response);
      });
  };
  useEffect(() => {
    let category = null;
    if (pathname.includes("category")) {
      category = {
        slug: params.typeSlug || null,
      };
    }
    let explore = null;
    if (pathname.includes("explore")) {
      explore = {
        slug: params.typeSlug || null,
      };
    }
    handleFetchTopStories({ category, explore });
    if ((category || explore) && isLoadFirstTime) {
      fetchDigestsList({ isRefresh: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.typeSlug, pathname]);

  useEffect(() => {
    if (
      location.search &&
      videoDigestList &&
      videoDigestList.length > 0 &&
      isLoadFirstTime
    ) {
      const params = new URLSearchParams(location.search);
      const searchValue = params.get("text");
      dispatch({ type: "SET_CLEAR_SEARCH_VALUE" });
      fetchDigestsList({ isRefresh: true, searchValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (unreadArticleCount) {
      clearTimeout(unreadCountTimeoutRef.current);
      unreadCountTimeoutRef.current = setTimeout(() => {
        dispatch({ type: "SET_UNREAD_COUNT", value: null });
      }, 5000);
    }
  }, [unreadArticleCount]);

  const checkCategorySlug = (value) => {
    if (value) {
      if (isNaN(parseInt(value, 10))) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDigestsList = ({
    currentPage,
    isRefresh,
    searchValue,
    topic,
    source,
    fetchUuid,
  }) => {
    const params2 = new URLSearchParams(location.search);
    const filterKey = params2.get("type");
    if (!searchValue && location.search && !topic) {
      searchValue = params2.get("text");
    }
    if (pathname.includes("explore")) {
      typeRef.current = "explore";
      exploreId.current = params.typeSlug || null;
    } else {
      exploreId.current = null;
    }
    if (pathname.includes("category")) {
      if ((params.typeSlug || null) !== "feed") {
        typeRef.current = "category";
        categoryId.current = params.typeSlug || null;
      } else {
        categoryId.current = null;
      }
    } else {
      categoryId.current = null;
    }
    dispatch({ type: "SET_FETCH_ARTICLE_LOADER", value: true, isRefresh });
    const dyanamicKeys = {};
    const page = isRefresh ? 1 : currentPage || activePagination;
    if (formatData && page !== 1) {
      dyanamicKeys.format_page_data = JSON.parse(formatData);
    }
    const localStorageData = localStorage.getItem("selectedLanguage");
    if (localStorageData) {
      const selectedLanguage = JSON.parse(localStorageData);
      if (
        selectedLanguage.default_filter_key &&
        selectedLanguage.default_filter_value
      ) {
        dyanamicKeys[selectedLanguage.default_filter_key] =
          selectedLanguage.default_filter_value;
      }
    }
    fetchDigestListApi({
      digestType: "200,275,250,300,255",
      // excludeTypes: '',
      // digestType: 275,
      page: isRefresh ? 1 : currentPage || activePagination,
      size: PAGE_SIZE,
      ids:
        (videoDigestList.length === 0 && params && params.digestSlug) || null,
      // ids: 'ford-delays-plant-opening-due-to-chip-shortage',
      explore: exploreId.current,
      formats: params.formatId,
      categories: checkCategorySlug(categoryId.current)
        ? categoryId.current
        : undefined,
      filter_key: categoryId.current ? filterKey : "",
      filter_key_id: checkCategorySlug(categoryId.current)
        ? undefined
        : categoryId.current,
      tags:
        (topic && topic.slug) ||
        (searchValue
          ? null
          : selectedTopic && (selectedTopic.slug || selectedTopic.id)),
      webSources:
        (source && source.id) ||
        (searchValue ? null : selectedSource && selectedSource.id),
      search: searchValue || (!(topic || source) ? "" : null),
      dyanamicKeys,
    })
      .then((response) => {
        if (
          (videoDigestList.length === 0 || isRefresh) &&
          response.data.results.length > 0
        ) {
          if (window.scrollTop > 100) {
            // if (!response.data.results[0].read) {
            //   onVisitArticle(response.data.results[0])
            //   response.data.results[0].read = true;
            // }
            let replaceUrl = `/${response.data.results[0].slug}`;
            if (exploreId.current) {
              replaceUrl = `/${typeRef.current}/${params.typeSlug}/${response.data.results[0].slug}`;
            }
            if (categoryId.current) {
              replaceUrl = `/${typeRef.current}/${params.typeSlug}/${response.data.results[0].slug}`;
            }
            history.push(replaceUrl);
          }

          dispatch({
            type: "SET_ACTIVE_DIGEST_ID",
            value: response.data.results[0].id,
          });
        }

        let suggestedFilter = response.data.suggested_filters;
        if (suggestedFilter && Object.keys(suggestedFilter).length === 0) {
          suggestedFilter = null;
        }
        dispatch({
          type: "SET_VIDEO_DIGESTS_LIST",
          value: response.data.results,
          isRefresh,
          hasNext: response.data.has_next && !response.data.load_more_btn,
          loadMoreBtn: response.data.load_more_btn,
          unread_count: response.data.unread_count,
          filterSuggestion: suggestedFilter,
          pillsData:
            (response.data.additional_data &&
              response.data.additional_data.pills) ||
            [],
          formatData: JSON.stringify(response.data.format_page_data),
          fetchUuid: fetchUuid,
          // digestsFilterSources: suggestedFilter && suggestedFilter.sources && suggestedFilter.sources.results,
          // digestsFilterTopics: suggestedFilter && suggestedFilter.tags && suggestedFilter.tags.results,
        });
      })
      .catch((error) => {
        dispatch({ type: "SET_VIDEO_DIGESTS_LIST", value: [] });
        // if (window) window.Raven.captureException(error.response);

        if (
          error &&
          error.response &&
          error.response.status === 403 &&
          handleUserNotFound
        ) {
          handleUserNotFound();
        }
      });
  };

  useEffect(() => {
    if (selectedTags) {
      fetchDigestsList({ isRefresh: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!(locationState && locationState.searchPageData)) {
      let searchValue = null;
      if (location.search) {
        searchValue = params.get("text");
      }
      if (videoDigestList && videoDigestList.length === 0 && !isLoadFirstTime) {
        dispatch({ type: "SET_LOAD_FIRST_TIME", value: true });
        let topic = locationState && locationState.tagData;
        if (topic) {
          dispatch({ type: "SET_SELECTED_TOPIC_SOURCE", topic });
        } else {
          if (pathname.includes("tags")) {
            topic = {
              slug: params.tagSlug,
              is_stream: true,
            };
          }
          dispatch({ type: "SET_SELECTED_TOPIC_SOURCE", topic });
        }

        if (pathname.includes("search") && topic) {
          // searchValue = topic.title;
          searchValue = null;
          history.replace(`/search?text=${topic.title}&topic=${topic.slug}`);
          // topic = null;
        } else if (params.get("topic")) {
          topic = {
            slug: params.get("topic"),
            title: searchValue,
          };
          dispatch({ type: "SET_SELECTED_TOPIC_SOURCE", topic });
          searchValue = null;
          history.replace(`/search?text=${topic.title}&topic=${topic.slug}`);
        }

        if (!pathname.includes("search") || searchValue || topic) {
          setTimeout(() => {
            const fetchUuid = uuidv1();
            dispatch({ type: "SET_FETCH_DIGESTS_UUID", value: fetchUuid });
            fetchDigestsList({
              isRefresh: true,
              topic,
              searchValue,
              fetchUuid,
            });
          }, 100);
        } else {
          dispatch({
            type: "SET_FETCH_ARTICLE_LOADER",
            value: false,
            isRefresh: true,
          });
        }
      }
    } else if (!isLoadFirstTime) {
      dispatch({
        type: "SET_SEARCH_PAGE_DATA",
        value: locationState.searchPageData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDigestsList, videoDigestList]);

  useEffect(() => {
    if (
      (currentActiveDigest || currentActiveDigest === 0) &&
      locationState &&
      locationState.searchPageData
    ) {
      setTimeout(() => {
        history.replace(`/${locationState.searchPageData.currentDigest.slug}`, {
          searchPageData: null,
        });
      }, 100);
    }
  }, [currentActiveDigest, history, locationState]);
  // useEffect(() => {
  //   if (activePagination > 1) {
  //     fetchDigestsList({ isRefresh: false});
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activePagination, exploreFilter, categoryFilter, selectedTags]);

  const onDigestBookMark = (data) => {
    if (!bookmarkLoader) {
      dispatch({ type: "SET_BOOKMARK_LOADER", value: true });
      if (data.like) {
        deleteBookmarkDigest({ digestId: data.id })
          .then((response) => {
            if (response.status === 202) {
              dispatch({
                type: "SET_DIGEST_BOOKMARK",
                value: false,
                digestId: data.id,
              });
              // ToastsStore.success('Digest bookmark removed successfully.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      } else {
        bookmarkDigest({ digestId: data.id, data, pageType: AmplitudePageType })
          .then((response) => {
            if (response.status === 201) {
              dispatch({
                type: "SET_DIGEST_BOOKMARK",
                value: true,
                digestId: data.id,
              });
              // ToastsStore.success('Digest bookmark added successfully.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      }
    }
  };

  const onDigestReadLater = (data) => {
    if (!readLaterLoader) {
      dispatch({ type: "SET_READ_LATER_LOADER", value: true });
      if (data.readlater) {
        deleteReadLater({ digestId: data.id })
          .then((response) => {
            if (response.status === 202) {
              dispatch({
                type: "SET_DIGEST_READ_LATER",
                value: false,
                digestId: data.id,
              });
              // ToastsStore.success('Removed from Read Later.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      } else {
        addReadLater({ digestId: data.id, data, pageType: AmplitudePageType })
          .then((response) => {
            if (response.status === 201) {
              dispatch({
                type: "SET_DIGEST_READ_LATER",
                value: true,
                digestId: data.id,
              });
              // ToastsStore.success('Saved to Read Later.');
            }
          })
          .catch((error) => {
            if (window && window.Raven)
              window.Raven.captureException(error.response);
            ToastsStore.error("Something went wrong.");
          });
      }
    }
  };

  return (
    <NewsDigestWrapper>
      <ToastsContainer
        position={ToastsContainerPosition.BOTTOM_LEFT}
        store={ToastsStore}
      />
      {(isBrowser || isTablet || true) && (
        <div>
          <DesktopNewsDigest
            videoDigestList={videoDigestList}
            onVisitArticle={onVisitArticle}
            locationState={locationState}
            loadMoreBtn={loadMoreBtn}
            handleLoaderMore={() => {
              dispatch({
                type: "SET_ACTIVE_PAGINATION",
                value: activePagination + 1,
                removeLoadMoreText: true,
              });
              setTimeout(() => {
                window.scrollTo(0, window.scrollY + 100);
              }, 100);
              fetchDigestsList({
                currentPage: activePagination + 1,
                topic: selectedTopic,
              });
            }}
            loadVideoDigest={fetchArticleLoader}
            activePagination={activePagination}
            topStoriesLoader={topStoriesLoader}
            onTopStoriesReadLater={onDigestReadLater}
            sideBarTopStories={sideBarTopStories}
            bannerTopStories={bannerTopStories}
            disablePagination={disablePagination}
            onChangePagination={(value) => {
              dispatch({ type: "SET_ACTIVE_PAGINATION", value });
              fetchDigestsList({ currentPage: value, topic: selectedTopic });
            }}
            location={location}
            onDigestBookMark={onDigestBookMark}
            onDigestReadLater={onDigestReadLater}
            filterSuggestion={filterSuggestion}
            pageSize={PAGE_SIZE}
            selectedTopic={selectedTopic}
            pathname={pathname}
            params={params}
            pillsData={pillsData}
            onClickRefresh={() => {
              dispatch({ type: "SET_ACTIVE_PAGINATION", value: 1 });
              fetchDigestsList({ isRefresh: true });
            }}
            onClickTags={(value) => {
              dispatch({ type: "SET_SELCETED_TAGS", value });
              history.push(`/category/${value.slug}`);
            }}
            exploreId={exploreId.current}
            categoryId={categoryId.current}
            typeRef={typeRef.current}
            handleFetchDigestsList={({
              searchValue,
              topic,
              source,
              isClearSearch,
              isClearSearchData,
            }) => {
              dispatch({ type: "SET_SELECTED_TOPIC_SOURCE", topic, source });
              if (!isClearSearch) {
                if (isClearSearchData) {
                  dispatch({ type: "SET_CLEAR_SEARCH_VALUE" });
                }
                fetchDigestsList({
                  isRefresh: true,
                  searchValue,
                  topic,
                  source,
                });
              } else {
                dispatch({ type: "SET_CLEAR_SEARCH_VALUE" });
              }
            }}
            onSetCategoryFilter={(value) => {
              if (value.type === "explore") {
                dispatch({ type: "SET_EXPLORE_FILTER", value });
              } else if (value.type === "feed") {
                dispatch({ type: "SET_CATEGORY_FILTER", value: null });
              } else {
                categoryId.current = value.slug;
                dispatch({ type: "SET_CATEGORY_FILTER", value });

                handleFetchTopStories({ category: value });
              }
            }}
            theme={theme}
            setTheme={setTheme}
          />
        </div>
      )}
      {isMobile && !isTablet && false && (
        <div>
          <MobileNewsDigests
            params={params}
            onVisitArticle={onVisitArticle}
            locationState={locationState}
            videoDigestList={videoDigestList}
            loadVideoDigest={fetchArticleLoader}
            activePagination={activePagination}
            currentActiveDigest={currentActiveDigest}
            onChangePagination={(value) => {
              dispatch({ type: "SET_ACTIVE_PAGINATION", value });
              fetchDigestsList({ currentPage: value });
            }}
            onDigestBookMark={onDigestBookMark}
            onDigestReadLater={onDigestReadLater}
            pageSize={PAGE_SIZE}
            onClickTags={(value) => {
              dispatch({ type: "SET_SELCETED_TAGS", value });
              history.push(`/category/${value.slug}`);
            }}
            selectedTags={selectedTags}
            openRefreshSuccessfully={openRefreshSuccessfully}
            onClickRefresh={() => {
              dispatch({ type: "SET_ACTIVE_PAGINATION", value: 1 });
              fetchDigestsList({ isRefresh: true });
            }}
            exploreId={exploreId.current}
            categoryId={categoryId.current}
            typeRef={typeRef.current}
          />
        </div>
      )}
      {/* {unreadArticleCount && videoDigestList && videoDigestList.length > 10 && (
      <UnreadCountToastWrapper>
        <UnreadCountToast>{`${unreadArticleCount || 100} unread stories below`}</UnreadCountToast>
      </UnreadCountToastWrapper>
      )} */}
    </NewsDigestWrapper>
  );
};

export default NewsDigests;
