import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DigestCreatedByInfo, DigestPublishInfo } from '../NewsDigests/Desktop/CommonComponents';
import DigestActionWrapper from '../NewsDigests/Desktop/DesktopDigestItem/DigestActionsWrapper';
import { Image } from 'semantic-ui-react';

const Wrapper = styled(Link)`
  display: block;
  color: #000;

  &:hover {
    color: #000;
  }
  /* margin: 20px 0; */
  padding: 60px 0;
  border-bottom: solid 1px #979797;

  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const DigestTitle = styled.div`
  display: block;
  font-family: 'PT Serif', serif;
  font-size: 40px;
  line-height: 1.13;
  color: rgba(${props => props.theme.fontColor.level2RGB}, 0.8);
  display: flex;
  align-items: center;

  ${({ nullTitle }) => nullTitle ? `
    justify-content: center;
  `:``}
  // &:hover {
  //   color: rgba(0, 0, 0, 0.8);
  // }

  @media (max-width: 768px) {
    font-size: 32px;
  }

  span {
    font-family: 'PT Serif', serif;
    font-size: 99px;
    line-height: 0.3;
    color: rgba(153, 188, 239, 0.52);
    margin-right: 20px;
    display: block;

    @media (max-width: 768px) {
      font-size: 74px;
      margin-right: 10px;
    }
    
    @media (max-width: 580px) {
      font-size: 60px;
    }
  }
`;

const DigestCreatedByInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #d0d0d0;
  padding: 25px 0;
  @media (max-width: 768px) {
    /* width: 90%; */
    /* margin: 15px auto; */
    padding: 15px 0;
  }
`;

const DigestActionsContainer = styled.div`
  flex: 1;
`;

const DigestImageWrapper = styled.div`
  margin: 25px 0 30px;
  img {
    width: 100%;
  }
`;

const DigestPublishInfoWrapper = styled.div`
  border-bottom: solid 1px #d0d0d0;
  padding: 25px 0;

  @media (max-width: 768px) {
    padding: 15px 0;
  }
`;

const ArticleDesc = styled.div`
  font-size: 15px;
  line-height: 1.8;
  margin: 10px 0;
  color: ${props => props.theme.fontColor.level1};
  font-family: 'Merriweather', serif;
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 10px;
    min-height: 10px;
    overflow-wrap: anywhere;
  }

  ul,
  ol {
    margin: 10px 0;
  }

  li {
    margin-bottom: 5px;
  }

  @media (max-width: 500px) {
    font-size: 18px;

    ul,
    ol {
      margin: 5px 0;
      padding-left: 20px;
    }
  }

   blockquote {
    margin: 15px 0;
    display: block;
    font-style: italic;
    position: relative;
    &:before {
        content: "";
        width: 4px;
        height: 100%;
        background-color: #333;
        position: absolute;
        top: 0;
        left: -15px;
      }

      @media (max-width: 768px) {
        margin: 10px 0;
        &:before {
          left: -10px;
        }
      }
  }
`;

const DigestItem = ({
  data,
  onDigestReadLater,
  onDigestBookMark,
  index,
  isStream,
  setShareArticleData
}) => {
  let digestDesc = (data && data.digest_preview) || '';

  // console.log("🚀 ~ file: DigestItem.js ~ line 149 ~ data", data)
  if (data && data.digest && data.type.code === '300') {
    const regex = /\[@([\w-]+)]\((\d+)\)/gm;
    let m;
    const matchArray = [];
    while ((m = regex.exec(data.digest)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      const matchObject = {
        digestTitle: m[0],
        slug: m[1],
        id: m[2],
        index: m.index
      }
      matchArray.push(matchObject);
    }

    if (matchArray && matchArray.length > 0) {
      digestDesc = data.digest_preview.substring(0, matchArray[0].index)
    }
  }
  return (
    <Wrapper
        to={`/digests/${data.slug}`}
    >
      <DigestTitle
        nullTitle={!data.title}
      >
        {
          isStream && (
            <span>{index}</span>
          )
        }
        {data.title}</DigestTitle>
      <DigestCreatedByInfoWrapper>
        <DigestCreatedByInfo data={data} />
        <DigestActionsContainer>
          <DigestActionWrapper
            data={data}
            shareUrl={data && data.source && (data.source.sl || data.source.link)}
            onDigestReadLater={onDigestReadLater}
            onDigestBookMark={onDigestBookMark}
            onClickShare={(data) => {
              setShareArticleData(data);
            }}
          />
        </DigestActionsContainer>
      </DigestCreatedByInfoWrapper>
      {data.linked_sources && data.linked_sources.length > 0 && (
        <DigestPublishInfoWrapper>
          <DigestPublishInfo data={data}/>
        </DigestPublishInfoWrapper>
      )}
      {data.cover_pic && (
        <DigestImageWrapper>
          <Image src={
            data.cover_pic ? data.cover_pic.indexOf('youtube.com') > -1 ?
              `https://i3.ytimg.com/vi/${data.cover_pic.substring(data.cover_pic.indexOf('=') + 1, data.cover_pic.length)}/hqdefault.jpg`
              : data.cover_pic : data.cover_pic} />
        </DigestImageWrapper>
      )}
      <div>
        {data && data.markup && data.markup.apply ? (
          <ArticleDesc
            className="articleDesc"
            data-digestId={data.id}
            dangerouslySetInnerHTML={{
              __html: digestDesc
            }}
          />
          // <ReactMarkdown source={digestDesc} />
        ) : (
            <ArticleDesc
              className="articleDesc"
              data-digestId={data.id}
            >
              {
                digestDesc && digestDesc.split(/\n/).map((para, index) => (
                  <p key={index}>{para}</p>
                ))
              }
            </ArticleDesc>
          )}
      </div>
    </Wrapper>
  )
};

export default DigestItem;
