import amplitude from 'amplitude-js';

const isStage = process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'tnd-staging' || process.env.REACT_APP_ENV === 'und-staging' || process.env.REACT_APP_ENV === 'oped-staging';

export const initAmplitude = () => {
  if(!isStage) {
    let apiKey = "f31a464b7fb9c2bf58f8a6c1532938f1";
    if (process.env.REACT_APP_ENV === 'tnd-production') {
      apiKey = "f31a464b7fb9c2bf58f8a6c1532938f1";
    } else if (process.env.REACT_APP_ENV === 'und-production') {
      apiKey = "d76e56fc363fc1ebb6d1e9796d81070e";
    } else if (process.env.REACT_APP_ENV === 'oped-production') {
      apiKey = "88cbe341e9c7bd73146ca5975aaeac02";
    } else if (process.env.REACT_APP_ENV === 'clip-production') {
      apiKey = "88cbe341e9c7bd73146ca5975aaeac02";
    }
    const uuid = localStorage.getItem('Guest-UUID');
    const userData = localStorage.getItem('userData');
    const amplitude_config = {includeReferrer: true, includeUtm: true};
    if (userData) {
      amplitude.init(apiKey, amplitude_config);
      setUserProperties(userData);
    } else {
      amplitude.init(apiKey, uuid, amplitude_config , () => console.log('Init Success')  )
    }
  }
};

export const setUserProperties = (userDetail, isLogin) => {
  if (!isStage) {
    amplitude.setUserProperties(userDetail);
    if (isLogin) {
      logAmplitudeEvent('Logged In', {
        login_type: userDetail.login_type
      });
    }
  }
};

export const clearUserProperties = (userDetail) => {
  amplitude.clearUserProperties();
  logAmplitudeEvent('Logged Out', {
    logout_type: userDetail.login_type
  });
};

export const logAmplitudeEvent = (eventName, eventProperties) => {
  if (!isStage) {
    amplitude.logEvent(eventName, eventProperties);
  }
}