import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import editionTitleLeft from './editionTitleLeft.svg';
import editionTitleRight from './editionTitleRight.svg';

const Wrapper = styled.div`
  display: block;
  width: 65%;
  margin: 0 auto;
  max-width: 1000px;


  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 90%;
  }
`;

const ImageWrapper = styled.div`
  padding: 50px 30px 50px 6%;
  width: 40%;
  transform: rotate(5deg);
  transform-origin: 50% 50%;
  transition: .3s all;
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 30px 20px 10px 6%;
  }
`;
const ContentWrapper = styled.div`
  padding: 50px 6% 50px 0;
  flex: 1;
  text-align: center;
  @media (max-width: 767px) {
    padding: 30px 6% 30px 6%;
  }
`;
const DateWrapper = styled.span`
  position: relative;
  padding: 0 15px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 900;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 13px;
    height: 17px;
    top: -10px;
  }

  &::before {
    background:  url(${editionTitleLeft}) no-repeat 0 0;
    left: 0;
  }
  
  &::after {
    background: url(${editionTitleRight}) no-repeat 0 0;
    right: 0;
  }

`;
const EditionDesc = styled.div`
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 300;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const EditionTitle = styled.div`
  margin-bottom: 12px;
  color: #f33;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 900;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const AuthorName = styled.div`
  text-transform: uppercase;
  margin-bottom: 0;
  color: rgba(0,0,0,.75);
  font-size: 16px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const EditionItem = styled.a`
  border: 4px solid red;
  margin: 30px 0;
  display: flex;
  color: #000;

  &:hover {
    color: #000;

    ${ImageWrapper} {
      transform: rotate(-5deg);
    }

    ${EditionTitle} {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 900;
  margin: 50px 0 25px;
  text-decoration: underline;
  text-align: center;
`;

const PastEditions = ({ data, sectionTitle }) => (
  <Wrapper>
    <SectionTitle>{sectionTitle}</SectionTitle>
    {data && JSON.parse(data) && JSON.parse(data).length > 0 && JSON.parse(data).map(edition => (
      <EditionItem href={edition.link} target="_blank">
        <ImageWrapper>
          <Image src={edition.image}/>
        </ImageWrapper>
        <ContentWrapper>
          <DateWrapper>{edition.date}</DateWrapper>
          <EditionTitle>{edition.title}</EditionTitle>
          <EditionDesc>{edition.preview}</EditionDesc>
          <AuthorName>{edition.author}</AuthorName>
        </ContentWrapper>
      </EditionItem>
    ))}
  </Wrapper>
);

export default PastEditions;