/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import produce from 'immer';
import { Header, Divider, Icon } from 'semantic-ui-react';
import commaNumber from 'comma-number';
import ShowMoreText from 'react-show-more-text';
import { grey, white } from 'colorPalette';

const VideoDetailWrapper = styled.div`
  margin-top: 15px;
  position: relative;
`;

const VideoTitleInfo = styled.div`
   @media (max-width: 768px) {
    padding: 0 10px;
    padding-right: 30px;
    position: relative;
  }
`;

const VideoTitle = styled(Header)`
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const VideoViewsInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${grey.level2};
  span {
    display: inline-block;
  }
  .dot-separator {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${grey.level2};
    margin: 5px;
  }
`;

const VideoDescriptionWrapper = styled.div`
  @media (max-width: 768px) {
    display: ${({ active }) => active ? 'block' : 'none'};
    padding: 10px;
    width: 100%;
    box-shadow: 1px 1px 1px 1px #d8d8d8;
  }
`;

const VideoDescriptionContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VideoDescriptionHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ChannelTitle = styled(Header)``;

const VideoDescription = styled.div`
  margin: 5px 0 0 0;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoOverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 50px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VideoOverlayButton = styled.div`
  background: rgba(0,0,0,0.7);
  border: 1px solid rgba(255,255,255,0.5);
  color: #fff;
  direction: ltr;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 6px 8px 10px;
  width: ${({ width }) =>  width || 150}px;
  text-align: center;
  cursor: pointer;
  font-family: Noto Sans;
  font-weight: 700;
`;

const VideoTitleInfoIcon = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
`;

const PageWrapper = styled.div`
  margin: 0 25px 0 25px;
  @media (max-width: 768px) {
    margin: 0 0;
  }
`;

const PlayerContainer = styled.div`
  background-color: ${white};
  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    top: ${({ headerHeight }) => headerHeight}px;
    left: 0;
    z-index: 20;
  }
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_PLAYING_VIDEO':
        draft.playingVideo = action.value;
        break;
      case 'SET_VIDEO_CURRENT_TIME':
        draft.prevVideoTime = draft.currentTime;
        draft.currentTime = action.value;
        break;
      case 'SET_HANDLE_MANUAL_VIDEO':
        draft.handleManualVideo = action.value;
        break;
      case 'SET_ACTIVE_DESCRIPTION':
        draft.activeVideoDescription = action.value;
        break;
      case 'SET_SEGMENT_COUNTDOWN':
        draft.segmentCountDown = action.value;
        break;
    }
  });

const VideoColumn = ({
  videoDetail,
  segmentsData,
  activeSegment,
  onChangeSegment,
  onSeekActive,
  onChangeSeekActive,
  onSetNextActiveSegment,
  nextActiveSegment,
  onChnageRef,
  segmentLoading,
  setOnReadyVideo,
  headerHeight,
}) => {
  const videoRef = useRef(null);
  const intervalRef = useRef(null);
  const currentTimeRef = useRef(0);
  const activeSegRef = useRef(null);
  const nextActiveSegmentRef = useRef(null);
  const onSeekActiveRef = useRef(onSeekActive);
  const videoDurationRef = useRef(null);
  const handleManualVideoRef = useRef(false);
  const segmentCountDownRef = useRef(null);
  const segmentCountDownIntervalRef = useRef(null);
  const continueWatchingRef = useRef(false);

  useEffect(() => {
    onSeekActiveRef.current = onSeekActive;
  }, [onSeekActive]);

  const resetSegmentCountDown = () => {
    dispatch({ type: 'SET_SEGMENT_COUNTDOWN', value: false });
    clearInterval(segmentCountDownIntervalRef.current);
    segmentCountDownIntervalRef.current = null;
    segmentCountDownRef.current = null;
  }

  useEffect(() => {
    // if (segmentLoading) {
    //   dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
    // } else {
    //   dispatch({ type: 'SET_PLAYING_VIDEO', value: true });
    // }
  }, [segmentLoading])

  useEffect(() => {
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    playingVideo: true,
    prevVideoTime: null,
    currentTime: null,
    handleManualVideo: false,
    activeVideoDescription: false,
    segmentCountDown: false,
  })
  const { title, publishedAt, channelTitle, description } = videoDetail.snippet;
  const { viewCount } = videoDetail.statistics;
  const { playingVideo, activeVideoDescription, segmentCountDown } = state;

  const setSegemntCountdown = useCallback(({ time, restart }) => {
    dispatch({ type: 'SET_SEGMENT_COUNTDOWN', value: true });
    if(!restart) {
      segmentCountDownRef.current = parseInt(activeSegRef.current.timestamp_end - time);
    }

    if (!segmentCountDownIntervalRef.current) {
      segmentCountDownIntervalRef.current = setInterval(() => {
        if (segmentCountDownRef.current <= 0) {
          resetSegmentCountDown();
        } else {
          segmentCountDownRef.current = segmentCountDownRef.current - 1;
        }
      }, 1000);
    }
  }, []);
  const onSeekTo = useCallback(
    () => {
      if (activeSegment && !handleManualVideoRef.current) {
        videoRef.current.seekTo(parseFloat(activeSegment.timestamp_start));
        activeSegRef.current = activeSegment;

        resetSegmentCountDown();
        continueWatchingRef.current = false;
      } else {
        activeSegRef.current = activeSegment;
        continueWatchingRef.current = false;
        dispatch({ type: 'SET_HANDLE_MANUAL_VIDEO', value: false });
        handleManualVideoRef.current = false;
        resetSegmentCountDown();
      }
  }, [activeSegment]);

  useEffect(() => {
    if (activeSegment && videoRef && videoRef.current ) {
      onSeekTo();
    }
  }, [activeSegment, onSeekTo, videoRef]);

  useEffect(() => {
    if (nextActiveSegment) {
      nextActiveSegmentRef.current = nextActiveSegment;
    } else {
      nextActiveSegmentRef.current = null;
    }
  }, [nextActiveSegment])

  const checkNextActiveSegment = ({ activeTime }) => {
    if(activeTime > activeSegRef.current.timestamp_start && activeTime < activeSegRef.current.timestamp_end) {
      dispatch({ type: 'SET_HANDLE_MANUAL_VIDEO', value: false });
      handleManualVideoRef.current = false;
      onSetNextActiveSegment(null);
      continueWatchingRef.current = false;
    } else {
      if (activeTime.toString() !== activeSegRef.current.timestamp_start) {
        let nextActiveSegmentData = null;
        let activeSegmentData = null;
        segmentsData.forEach((segment, index) => {
          if (activeTime > segment.timestamp_start && activeTime < segment.timestamp_end) {
            segment.index = index;
            activeSegmentData = segment;
          } else if (activeTime < segment.timestamp_start && !nextActiveSegmentData) {
            nextActiveSegmentData = segment;
          }
        });
        if(activeSegmentData) {
          onSetNextActiveSegment(null);
          onChangeSegment(activeSegmentData)
        } else if (nextActiveSegmentData) {
          onSetNextActiveSegment(nextActiveSegmentData)
        }
      }
    }
    resetSegmentCountDown();
  }

  useEffect(() => {
    if (playingVideo && videoRef && videoRef.current && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (videoRef.current) {
          try {
            const time = videoRef.current.getCurrentTime();
            if (Math.abs(currentTimeRef.current - time) > 2 && !onSeekActiveRef.current) {
              dispatch({ type: 'SET_HANDLE_MANUAL_VIDEO', value: true });
              handleManualVideoRef.current = true;
              checkNextActiveSegment({ activeTime: time });
            }

            if (activeSegRef.current && !handleManualVideoRef.current) {
                const currentActive = segmentsData[activeSegRef.current.index + 1];
              if (time >= activeSegRef.current.timestamp_end && currentActive && time <= currentActive.timestamp_start && !activeSegRef.current.manuallyChanged && !continueWatchingRef.current) {
                currentActive.index = activeSegRef.current.index + 1;
                onChangeSegment(currentActive);
              } 
            }

            const nextSegement = segmentsData[activeSegRef.current.index + 1]
            if (activeSegRef.current && nextSegement && activeSegRef.current.timestamp_end - 5 <= time && activeSegRef.current.timestamp_end - 6 < time && activeSegRef.current.timestamp_end > time && !segmentCountDownRef.current && !continueWatchingRef.current) {
              setSegemntCountdown({time});
            }

            if (activeSegRef.current.manuallyChanged) {
              setTimeout(() => {
                activeSegRef.current.manuallyChanged = false;
              }, 200);
            }
            if(nextActiveSegmentRef.current) {
              if(time >= nextActiveSegmentRef.current.timestamp_start) {
                onChangeSegment(nextActiveSegmentRef.current);
                continueWatchingRef.current = false;
                dispatch({ type: 'SET_HANDLE_MANUAL_VIDEO', value: false });
                handleManualVideoRef.current = false;
                onSetNextActiveSegment(null);
              }
            }
            currentTimeRef.current = time;
            if (onSeekActiveRef.current) {
              setTimeout(() => {
                onChangeSeekActive(false);
              }, 2000);
            }

            dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: time });
            
          } catch (error) {
          console.log("intervalRef.current -> error", error)
            
          }
        }
      }, 100);
    } else {
      // clearInterval(intervalRef.current);
      // intervalRef.current = null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingVideo, videoRef.current]);

  useEffect(() => {
    if (videoRef.current) {
      if(playingVideo) {
        if (segmentCountDownRef.current) {
          setSegemntCountdown({ restart: true });
        }
        videoRef.current.playVideo();
      } else {
        clearInterval(segmentCountDownIntervalRef.current);
      }
    }
  }, [playingVideo, setSegemntCountdown]);


  const createYTRef = useCallback(() => {
    videoRef.current = new window.YT.Player(`youtubePlayer`, {
      videoId: videoDetail.id,
      height: '100%',
      width: '100%',
      playerVars: {
        fs: 0,
      },
      events: {
        onDuration: value => {
          videoDurationRef.current = value;
        },
        requestFullScreen: console.log,
        onReady: () => {
          setOnReadyVideo()
          onSeekTo();
        },
      },
    });
    videoRef.current.addEventListener("onStateChange", (state) => {
      if (state.data === 0) {
        onChangeSegment(segmentsData[0]);
        onSetNextActiveSegment(null);
        videoRef.current.seekTo(parseFloat(segmentsData[0].timestamp_start));
        dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
      } else if(state.data === 1) {
        dispatch({ type: 'SET_PLAYING_VIDEO', value: true });
      } else if(state.data === 2) {
        dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
      }
    });
  }, [onChangeSegment, onSeekTo, onSetNextActiveSegment, segmentsData, setOnReadyVideo, videoDetail.id])

  const loadVideo = useCallback(() => {
    if (videoDetail.url && !videoRef.current && window.YT) {
      if (typeof window.YT.Player === 'undefined') {
        window.onYouTubeIframeAPIReady = () => {
          createYTRef();
        }
      } else {
        createYTRef();
      }
    }
  }, [createYTRef, videoDetail.url]);

  useEffect(() => {
    if (videoDetail.url && !videoRef.current) {
      loadVideo();
    }
  }, [loadVideo, onChangeSegment, onSeekTo, onSetNextActiveSegment, playingVideo, segmentsData, setOnReadyVideo, videoDetail]);
  
  const connvertSecondsToTimeFormat = (time) => {
    const date = new Date(null);
    date.setSeconds(time);
    if (date.toISOString().substr(11, 2) === "00") {
      return date.toISOString().substr(14, 5);
    }
    return date.toISOString().substr(11, 8);
  }
  return (
    <PageWrapper>
      <PlayerContainer headerHeight={headerHeight} ref={onChnageRef}>
        <PlayerWrapper>
            <>
              <VideoOverlayWrapper>
                {segmentCountDown && (
                  <VideoOverlayButton 
                    width="120"
                    onClick={() => {
                      const currentActive = segmentsData[activeSegRef.current.index + 1];
                      currentActive.index = activeSegRef.current.index + 1;
                      onSetNextActiveSegment(currentActive);
                      continueWatchingRef.current = true;
                      resetSegmentCountDown();
                    }}
                  >
                    Continue Watching
                  </VideoOverlayButton>
                )}
                {segmentCountDown && (
                  <VideoOverlayButton>
                    {`Next Segment in ${segmentCountDownRef.current}`}
                  </VideoOverlayButton>
                )}
              </VideoOverlayWrapper>
            </>
          <div id="youtubePlayer" />
        </PlayerWrapper>
      </PlayerContainer>

      <VideoDetailWrapper>
        <VideoTitleInfo
          onClick={() => {
            dispatch({ type: 'SET_ACTIVE_DESCRIPTION', value: !activeVideoDescription });
          }}
        >
          <VideoTitle as="h3">{title}</VideoTitle>
          <VideoViewsInfo>
            <span>{`${commaNumber(viewCount)} Views`}</span>
            <span className="dot-separator" />
            <span>{`${moment(publishedAt).format('MMM DD YYYY')}`}</span>
            <span className="dot-separator" />
            <span>{`${connvertSecondsToTimeFormat(videoDetail.summaryLength)}`}</span>
          </VideoViewsInfo>
          <VideoTitleInfoIcon>
            <Icon name={activeVideoDescription ? "triangle up" : "dropdown"} />
          </VideoTitleInfoIcon>
        </VideoTitleInfo>
        <Divider />
        <VideoDescriptionWrapper active={activeVideoDescription}>
          <VideoDescriptionContainer>
            <VideoDescriptionHeader>
              <div>
                <ChannelTitle as="h4">{channelTitle}</ChannelTitle>
              </div>
            </VideoDescriptionHeader>
            {description && (
              <VideoDescription>
                <ShowMoreText
                  /* Default options */
                  lines={3}
                  more='Show more'
                  less='Show less'
                  anchorClass=''
                  onClick={console.log}
                  expanded={false}
                >
                  {description.split('\n').map((data, index) => {
                    return <p key={`data-${index}`}>{data}</p>;
                  })}
                </ShowMoreText>
              </VideoDescription>
            )}
          </VideoDescriptionContainer>

        </VideoDescriptionWrapper>
      </VideoDetailWrapper>
    </PageWrapper>
  );
}

export default VideoColumn;