import React from 'react';
import styled from 'styled-components';
import SubscribeInputField from './SubscribeInputField';

const Wrapper = styled.div`
  padding: 50px;
  text-align: center;
  width: 80%;
  max-width: 1100px;
  margin: 30px auto;
  border-radius: 14px;
  box-shadow: 5px 7px 57px 0 rgba(0,0,0,0.05);

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    padding: 20px;
    margin: 20px auto;
    width: 95%;
  }
`;

// const Title = styled.div`
//   font-family: 'Metropolis', sans-serif;
//   font-size: 28px;
//   font-weight: bold;
//   color: rgba(0, 0, 0, 0.8);

//   @media (max-width: 768px) {
//     font-size: 20px;
//   }
  
//   @media (max-width: 580px) {
//     font-size: 16px;
//   }
// `;

// const TagLine = styled.div`
//   font-family: 'Metropolis', sans-serif;
//   font-size: 22px;
//   font-weight: 500;
//   line-height: 1.52;
//   color: rgba(70, 70, 70, 0.8);
//   margin-top: 10px;

//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
  
//   @media (max-width: 580px) {
//     font-size: 14px;
//   }
// `;
const SubscribeInputFieldWrapper = styled.div`
  width: 60%;
  margin: 30px auto 30px;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;


const SubscribeInfoWrapper = ({ data, onSubscribeSuccess, isSubscribe, expTestingKey }) => {
  return (
    <Wrapper>
      <SubscribeInputFieldWrapper>
        <SubscribeInputField 
          onSubscribeSuccess={onSubscribeSuccess}
          isSubscribe={isSubscribe}
          expTestingKey={expTestingKey}
        />
      </SubscribeInputFieldWrapper>
    </Wrapper>
  );
}

export default SubscribeInfoWrapper;