import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon, Image, Loader } from "semantic-ui-react";
import CutsomIcon from "Icon";

import { postHostFollow } from "Api";

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(${(props) => props.theme.primaryColor.level1RGB}, 0.9);
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled.div`
  width: 40%;
  min-height: 100px;
  background-color: ${(props) => props.theme.primaryColor.level1};
  box-shadow: 2px 3px 20px 0 rgba(0, 0, 0, 0.18);
  border-radius: 17px;
  padding: 0 0 0;
  font-family: "Metropolis", sans-serif;
  color: ${(props) => props.theme.fontColor.level2};
  position: relative;
  max-width: 600px;
  text-align: left;
  max-height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 60%;
  }

  @media (max-width: 767px) {
    width: 70%;
  }

  @media (max-width: 580px) {
    width: 95%;
    /* padding: 20px 10px */
  }
`;

const ContentWrapper = styled.div`
  padding: 0 50px 45px;
  margin-top: 45px;
  height: calc(100% - 45px);
  overflow: auto;
  flex: 1;

  @media (max-width: 580px) {
    width: 95%;
    padding: 0 30px 25px;
    margin-top: 25px;
  }
`;

const ModalTitle = styled.div`
  font-weight: 800;
  color: ${(props) => props.theme.fontColor.level2};
  font-size: 20px;
  line-height: 1.4;

  @media (max-width: 767px) {
    font-size: 18px;
  }

  @media (max-width: 580px) {
    font-size: 16px;
  }
`;

const ListWrapper = styled.div``;

const ListItemWrapper = styled.div`
  padding: ${({ hideBorder }) => (hideBorder ? `25px 0 0` : `25px 0`)};
  border-bottom: ${({ hideBorder }) => (hideBorder ? 0 : 1)}px solid #d8d8d8;
  display: flex;
  /* align-items: center; */
  padding-right: 115px;
  position: relative;
  width: 100%;
  color: #000;

  &:hover {
    color: #000;
  }

  @media (max-width: 767px) {
    padding-right: 95px;
  }

  @media (max-width: 580px) {
    padding-right: 45px;
  }
`;

const FollowButtonWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  /* transform: translateY(-50%); */
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 34px;
  color: ${({ isFollowed }) =>
    isFollowed ? "#fff" : (props) => props.theme.fontColor.level2};
  border: 1px solid ${({ isFollowed }) => (isFollowed ? "#ed3833" : "#b0b0b0")};
  background-color: ${({ isFollowed }) =>
    isFollowed ? "#ed3833" : "transparent"};
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 767px) {
    font-size: 12px;
    width: 90px;
  }

  @media (max-width: 580px) {
    width: 40px;

    i {
      margin: 0;
    }
    span {
      display: none;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const SourceContentWrapper = styled.div`
  flex: 1;
  padding: 0 0 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 580px) {
    padding: 0 0 0 10px;
  }
`;

const SourceDesc = styled.a`
  font-size: 16px;
  font-weight: 800;
  line-height: 1.2;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  color: ${(props) => props.theme.fontColor.level2};
  margin-bottom: ${({ singleSource }) => (singleSource ? 0 : 5)}px;

  &:hover {
    color: ${(props) => props.theme.fontColor.level2};
  }

  div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
  }

  @media (max-width: 580px) {
    font-size: 14px;

    div {
      padding-right: 20px;
    }
  }
`;

const SourceBrandName = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: ${(props) => props.theme.misc.sourceBrandName};

  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primaryColor.level1};
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i,
  svg {
    width: 12px;
    height: 12px;
    vertical-align: top;
  }
`;

const ExternalLinkIcon = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  width: auto !important;
  padding-right: 0 !important;

  @media (max-width: 580px) {
    i,
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;
const LinkedSourcesList = ({ data, updateListData, onClose, title }) => {
  const [isFollowedLoading, setIsFollowedLoading] = useState(false);
  const [listData, setListData] = useState(data);
  const [hostId, setHostId] = useState(null);

  useEffect(() => {
    console.log(' listData data',data);
    setListData(data);
  }, [data]);

  const onHandleFollow = (host) => {
    console.log('host onHandleFollow',host.id);
    setHostId(host.id)
    if (!isFollowedLoading) {
      setIsFollowedLoading(true);
      postHostFollow({ hosts: [host.id] })
        .then((response) => {
          setIsFollowedLoading(false);
          const newData = response.data.results[0];
          const updatedList = listData.map((item) => {
            if (item.id === host.id && newData) {
              return {
                ...item,
                ...newData,
              };
            }

            return item;
          });

          setListData(updatedList);
          updateListData(updatedList);
        })
        .catch((error) => {});
    }
  };

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClose();
      }}
    >
      <Wrapper>
        <CloseIconWrapper
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClose();
          }}
        >
          <CutsomIcon name="closeIcon" color="#979797" />
        </CloseIconWrapper>
        <ContentWrapper
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <ModalTitle>{`${title || "For this story, we read"}`}</ModalTitle>
          <ListWrapper>
            {listData &&
              listData.length > 0 &&
              listData.map((item, index) => (
                <ListItemWrapper
                  key={index}
                  hideBorder={index + 1 === listData.length}
                  // href={item.host_name}
                  onClick={(e) => {
                    // e.preventDefault();
                    e.stopPropagation();
                  }}
                  target="_blank"
                >
                  <LogoWrapper
                    onClick={(e) => {
                      // e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Image src={item.logo_link} />
                  </LogoWrapper>
                  <SourceContentWrapper>
                    {item.links &&
                      item.links.filter((link) => link.title && link.link)
                        .length > 0 &&
                      item.links
                        .filter((link) => link.title && link.link)
                        .map((linkItem) => (
                          <SourceDesc
                            href={linkItem.link}
                            singleSource={
                              item.links.filter(
                                (link) => link.title && link.link
                              ).length === 1
                            }
                            target="blank"
                            ref={(a) => {
                              if (!a) return;

                              const childElemnt = a.querySelector("div");
                              childElemnt.style.whiteSpace = "unset";
                              const width = a.offsetWidth;
                              childElemnt.style.display = "inline-block";
                              childElemnt.style.width = "auto";
                              childElemnt.style.maxWidth = `${width - 10}px`;
                              childElemnt.style.whiteSpace = "nowrap";
                              childElemnt.style.position = "relative";
                            }}
                          >
                            <div title={linkItem.title}>
                              {linkItem.title}
                              <ExternalLinkIcon>
                                <CutsomIcon
                                  name="externalLink"
                                  size={14}
                                  color="#404040"
                                />
                              </ExternalLinkIcon>
                            </div>
                          </SourceDesc>
                        ))}
                    <SourceBrandName
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {item.brand_name}
                    </SourceBrandName>
                  </SourceContentWrapper>
                  <FollowButtonWrapper
                    isFollowed={item.selected}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onHandleFollow(item);
                    }}
                  >
                    {isFollowedLoading === true && hostId===item.id ? (
                      <Loader size="small" active color="white" />
                    ) : null}

                    <Icon name="bell outline" />
                    <span>{item.selected ? "Following" : "Follow"}</span>
                  </FollowButtonWrapper>
                </ListItemWrapper>
              ))}
          </ListWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default LinkedSourcesList;
