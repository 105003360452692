import React from 'react';
import styled from 'styled-components';
import SubscribeInputField from './SubscribeInputField';
import MobileStoreButton from 'react-mobile-store-button';

const Wrapper = styled.div`
  padding: 50px;
  text-align: center;
  width: 80%;
  max-width: 1100px;
  margin: 30px auto;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 5px 7px 57px 0 rgba(0,0,0,0.05);

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    padding: 30px;
    margin: 20px auto;
    width: 95%;
  }
`;

const Title = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 768px) {
    font-size: 20px;
  }
  
  @media (max-width: 580px) {
    font-size: 16px;
  }
`;

const TagLine = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.52;
  color: rgba(70, 70, 70, 0.8);
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
  
  @media (max-width: 580px) {
    font-size: 14px;
  }
`;
const SubscribeInputFieldWrapper = styled.div`
  width: 60%;
  margin: 10px auto 20px;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  background-image: url("${({ bgImg }) => bgImg}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -50% 0px;

  @media (max-width: 767px) {
    background-image: unset;
  }
`;

const SiteInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 250px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    width: 230px;
  }
  
  @media (max-width: 900px) {
    width: 200px;
  }
  
  @media (max-width: 580px) {
    width: 150px;
  }

  img {
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SubscribeInfoWrapper = ({ data, onSubscribeSuccess, isSubscribe, expTestingKey, imageSetData, bottomData }) => {
  return (
    <Container
      bgImg={imageSetData.block1}
    >
      <Wrapper>
        <Title>{data[`headline_${expTestingKey}`] ? data[`headline_${expTestingKey}`].content : data.headline.content}</Title>
        <TagLine>{data[`subtitle_${expTestingKey}`] ? data[`subtitle_${expTestingKey}`].content : data.subtitle.content}</TagLine>
        <SubscribeInputFieldWrapper>
          <SubscribeInputField 
            onSubscribeSuccess={onSubscribeSuccess}
            isSubscribe={isSubscribe}
            expTestingKey={expTestingKey}
          />
        </SubscribeInputFieldWrapper>
        <TagLine>{data[`disclaimer_${expTestingKey}`] ? data[`disclaimer_${expTestingKey}`].content : data.disclaimer.content}</TagLine>
      </Wrapper>
      <SiteInfoWrapper>
        <LogoWrapper>
          <img src={imageSetData.red_logo}  alt=""/>
        </LogoWrapper>
        <ButtonsWrapper>
          <MobileStoreButton 
            store="android"
            url={bottomData[`play_store_url_${expTestingKey}`] ? bottomData[`play_store_url_${expTestingKey}`].content : bottomData.play_store_url.content}
            width="200px"
            height="100px"
          />
        </ButtonsWrapper>
      </SiteInfoWrapper>
    </Container>

  );
}

export default SubscribeInfoWrapper;