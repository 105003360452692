import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Header, Image } from "semantic-ui-react";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    background-color: #eaeae8;
    min-height: 40vh;
    border-bottom: 1px solid #000000;

    @media (max-width: 768px) {
        min-height: 110vh;
    }
`;

const Wrapper = styled.div`
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    jusify-content: space-around;
    padding: 30px 0;
    height: 100%;

    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column-reverse;
        justify-content: space-around;
    }
`;

const BannerContent = styled.div`
    flex-basis: 60%;
`;

const HiddenHeadingWrapper = styled.div`
    width: 90%;
    margin 0 auto;
    display: none;

    @media (max-width: 768px) {
        display: block;
        padding-top: 50px;
    }
`;

const HeadingWrapper = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const PageTitle = styled(Header)`
    font-size: 32px;
    line-height: 40px;
    font-family: GothamBookRegular;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

// const ListWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     margin-bottom: 80px;
//     padding-top: 30px;

//     @media (max-width: 768px) {
//         padding-top: 40px;
//         margin-bottom: 40px;
//         flex-wrap: wrap;
//         justify-content: center;
//     }
// `;

// const ListItem = styled.div`
//     display: flex;
//     flex-direction: row;
//     margin-right: 20px;
// `;

// const ListItemTitle = styled.p`
//     font-weight: bold;
//     font-size: 22px;
//     line-height: 36px;
//     color: ${({ source, option }) =>
//         source === option ? "#000000" : "#697378"};
//     &:hover {
//         color: ${({ option, source }) =>
//             source === option ? "#000000" : "#0DC806"};
//     }
//     cursor: pointer;

//     @media (max-width: 768px) {
//         font-size: 15px;
//         line-height: 24px;
//     }
// `;

const ProductDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

const ProductDesc = styled.div`
    width: 60%;
    // margin-bottom: 20px;
    min-height: 100px;
    font-style: normal;
    font-weight: normal;
    margin-top: 20px;

    @media (max-width: 768px) {
        width: 100%;
        // margin-bottom: 30px;

        font-size: 15px;
        line-height: 24px;
    }
`;

const ProductDescText = styled.p`
    font-size: 18px;
    line-height: 28px;
`;

const ProductDescBtnWrapper = styled.p`
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

const ProductDescBtn = styled.button`
    border-radius: 25px;
    background-color: #000000;
    color: #ffffff;
    font-size: 22px;
    padding: 12px 30px;
    font-weight: 700;
    font-family: Metropolis;

    @media (max-width: 768px) {
        font-size: 15px;
        font-style: normal;
        line-height: 24px;
    }
`;

const Segment6 = ({ data6 }) => {
    // const [option, setOption] = useState(1);
    const option = 1;
    const [activeSection, setActiveSection] = useState({});

    useEffect(() => {
        setActiveSection(data6 && data6.menus[option - 1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    return (
        <Container>
            <HiddenHeadingWrapper>
                <PageTitle as="h2">{data6 && data6.title}</PageTitle>
            </HiddenHeadingWrapper>
            <Wrapper>
                <BannerContent>
                    <HeadingWrapper>
                        <PageTitle as="h2">{data6 && data6.title}</PageTitle>
                    </HeadingWrapper>
                    

                    <ProductDescWrapper>
                        <ProductDesc>
                            <ProductDescText>
                                {activeSection && activeSection.content}
                            </ProductDescText>
                        </ProductDesc>
                        <ProductDescBtnWrapper>
                        <a target={'__new'} href={'https://onelink.to/8chy8d'}>
                            <ProductDescBtn>
                                Get the app{" "}
                                {activeSection && activeSection.title}
                            </ProductDescBtn>
                            </a>
                        </ProductDescBtnWrapper>
                    </ProductDescWrapper>
                </BannerContent>

                <ImageWrapper>
                    <Image
                        src={
                            activeSection &&
                            activeSection.image
                        }
                        style={{ width: "500px" }}
                    />
                </ImageWrapper>
            </Wrapper>
        </Container>
    );
};

export default Segment6;
