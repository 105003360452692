/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import rewindIcon from './Rewind2.svg';
import darkRewindIcon from './darkRewind2.svg';
import fastForwardIcon from './Fast-forward2.svg';
import darkFastForwardIcon from './darkFast-forward2.svg';
import playIcon from './Play2.svg';
import darkPlayIcon from './darkPlay2.svg';
import pauseIcon from './Pause2.svg';
import darkPauseIcon from './darkPause2.svg';
import muteIcon from './Mute2.svg';
import darkMuteIcon from './darkMute2.svg';
import VolumeIcon from './Volume.svg';
import darkVolumeIcon from './darkVolume.svg';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CustomizedControlWrapper = styled.div`
  /* position: absolute; */
  /* top: 0; */
  right: 0;
  bottom: 0;
  left: 0;
  /* transform: translateY(100%); */
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const CustomizedControlTop = styled.div`
  /* flex: 1; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
`;

const ControllerActions = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.secondaryColor.level5};
  /* width: 50%; */
  /* margin-top: 25px; */
  /* justify-content: space-between; */
  /* justify-content: space-between; */
  justify-content: center;
  opacity: 1;
  position: relative;
  transition: all ${({ showControlActions }) => showControlActions ? 0.2 : 0.5}s;
`;

const PlayPauseWrapper = styled.div`
  padding: 10px 15px;
  /* margin: 0 20px; */
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CustomizedControlBottom = styled.div`
  position: relative;
  .rangeslider__handle {
    display: none;
  }

  .rangeslider-horizontal {
    border-radius: 0;
    height: 6px;

    @media (max-width: 768px) {
      height: 4px;
    }
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: #dc3d4d;
    border-radius: 0;
  }
  .rangeslider {
    background-color: #d7d7d7;
    margin: 0;
  }
`;

const TrackMarksOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
`;

const RewindImage = styled.img`
  width: 22px;

  @media (max-width: 768px) {
    width: 20px;
  }
  /* height: 25px; */
`;

const VolumeImage = styled.img`
  width: 18px;
  /* height: 20px; */
`;
const FastForwardImage = styled.img`
  width: 22px;
  /* height: 25px; */

  @media (max-width: 768px) {
    width: 20px;
    /* height: 22px; */
  }
`;

const PlayImage = styled.img`
  width: 24px;
  /* height: 22px; */
  @media (max-width: 768px) {
    width: 20px;
    /* height: 20px; */
  }
`;

const PauseImage = styled.img`
  width: 20px;
  /* height: 22px; */
  @media (max-width: 768px) {
    width: 18px;
    /* height: 20px; */
  }
`;

const ActionWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const VolumeActionWrapper = styled(ActionWrapper)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;

const VideoDurationText = styled.div`
  padding: 3px;
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
  font-size: 13px;
  font-weight: 900;
  display: inline-block;
  margin-left: 10px;
  border-radius: 4px;
`;

const MiddleActionWrapper = styled.div`
  display: flex;
  justify-content: ${({hideVideoActions}) => hideVideoActions ? 'center' : 'space-between'};
  align-items: center;
  width: 50%;
`;

const converthmsToSeconds = (value) => {
  const hms = value;
  const a = hms.split(':');
  let seconds = 0;
  if (a.length === 3) {
    seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
  } else if (a.length === 2) {
    seconds = (+a[0]) * 60 + (+a[1]);
  } else {
    seconds = (+a[0]);
  }

  return seconds;
};

const calculatePrevSegmentLength = (activeIndex, segmentList) => {
  if (segmentList.length > 0) {
    const completedSegments = segmentList.slice(0, activeIndex);
    return completedSegments.map((segment) => segment.timestamp_end - segment.timestamp_start).reduce((a, b) => a + b, 0);
  }

  return 0;
};
const connvertSecondsToTimeFormat = (time) => {
  const date = new Date(null);
  date.setSeconds(time);
  if (date.toISOString().substr(11, 2) === "00") {
    return date.toISOString().substr(14, 5);
  }
  return date.toISOString().substr(11, 8);
}
const VideoSegmentPlayer = ({ data, theme }) => {
  const segmentVideoRef = useRef(null);
  // const controlVideoRef = useRef(null);
  const videoLengthRef = useRef(0);
  const totalVideoLength = useRef(0);
  // const segmentsLengthRef = useRef(0);
  const segmentListRef = useRef(0);
  const activeSegmentRef = useRef(null);
  const videoIntervalRef = useRef(null);
  // const controlActionTimeout = useRef(null);
  const [playingVideo, setPlayingVideo] = useState(false);
  const [hideVideoActions, setHideVideoActions] = useState(false);
  const [videoLength, setVideoLength] = useState(0);
  // const [segmentList, setSegmentList] = useState([]);
  const [sliderValue, setSliderValue] = useState(0);
  const [segmentCompleted, setSegmentCompleted] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  // const [showControlActions, setShowControlActions] = useState(false);
  const [segmentTrackMarks, setSegmentTrackMarks] = useState([]);
  // console.log("🚀 ~ file: VideoSegmentPlayer.js ~ line 87 ~ VideoSegmentPlayer ~ segmentList", segmentList)

  useEffect(() => {
    if(data.video_summary && data.video_summary.summary) {
      // setSegmentList(data.video_summary.summary.map((item, index) => ({
      //   ...item,
      //   index
      // })))
      segmentListRef.current = data.video_summary.summary.map((item, index) => ({
        ...item,
        index
      }))

      const progressTrackMarks = (segmentListRef.current || []).map((segmentItem, segmentItemIndex) => {
        const prevCount = (segmentListRef.current || []).slice(0, segmentItemIndex).reduce((acc, segmItem) => (segmItem.timestamp_end - segmItem.timestamp_start) + acc, 0);
        return parseFloat(prevCount.toFixed(2));
      });

      setSegmentTrackMarks(progressTrackMarks);

    }
  }, [data]);

  const createYTRef = () => {
    const validUrl = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    if (data.cover_pic.match(validUrl) && !segmentVideoRef.current) {
      let video_id = data.cover_pic.match(validUrl)[1];
      console.log("🚀 ~ file: VideoSegmentPlayer.js ~ line 112 ~ createYTRef ~ video_id", video_id)
      
      segmentVideoRef.current = new window.YT.Player(`segment-youtube-${data.id}`, {
        videoId: video_id,
        width: '100%',
        
        //height: 720,
        allowfullscreen: true,
        playerVars: {
          fs: 0,
          controls: 0,
          cc_lang_pref: 0,
          rel: 0,
        }
      });
      segmentVideoRef.current.addEventListener("onReady", (value) => { 
        if (segmentListRef.current && segmentListRef.current.length > 0) {
          setMuteVideo(segmentVideoRef.current.isMuted());
          value.target.seekTo(segmentListRef.current[0].timestamp_start, true);
          activeSegmentRef.current = segmentListRef.current[0];
          setSliderValue(0);
        }
        // setTimeout(() => {
        //   segmentVideoRef.current.playVideo();
        //   setPlayingVideo(true);
        // }, 500);
      });
      segmentVideoRef.current.addEventListener("onStateChange", (state) => {
        if (state.data === 1) {
          setPlayingVideo(true);
        } else if(state.data === 2) {
          setPlayingVideo(null);
        }
      });
    }
  }
  const loadVideo = () => {
    if (typeof window.YT.Player === 'undefined') {
      window.onYouTubeIframeAPIReady = () => {
        createYTRef();
      }
    } else {
      createYTRef();
    }
  }

  useEffect(() => {
    loadVideo();
    videoLengthRef.current = converthmsToSeconds(data.timestamp_length || data.timestamp_full_length || '00:01');
    setVideoLength(converthmsToSeconds(data.timestamp_length || data.timestamp_full_length || '00:01'));
    totalVideoLength.current = converthmsToSeconds(data.timestamp_full_length || data.timestamp_length || '00:01')
    setHideVideoActions(!(data.timestamp_full_length || data.timestamp_length));
  }, []);

  const handleVideoDuration = () => {
    clearInterval(videoIntervalRef.current);
    videoIntervalRef.current = setInterval(() => {
      if (segmentVideoRef.current && activeSegmentRef.current) {
        const currentTime = segmentVideoRef.current.getCurrentTime();
        const duration = activeSegmentRef.current ? calculatePrevSegmentLength(activeSegmentRef.current.index, segmentListRef.current) + (currentTime - activeSegmentRef.current.timestamp_start) : currentTime;
        setSliderValue(duration <= videoLength ? duration : videoLength);
        if (activeSegmentRef.current) {
          if (currentTime >= activeSegmentRef.current.timestamp_end) {
            const nextSegment = segmentListRef.current[activeSegmentRef.current.index + 1];
            if (nextSegment) {
              segmentVideoRef.current.seekTo(nextSegment.timestamp_start, true);
              activeSegmentRef.current = nextSegment;
              const duration = calculatePrevSegmentLength(activeSegmentRef.current.index, segmentListRef.current);
              setSliderValue(duration <= videoLength ? duration : videoLength);
            } else {
              segmentVideoRef.current.pauseVideo();
              setSegmentCompleted(true);
              // segmentVideoRef.current.seekTo(segmentListRef.current[0].timestamp_start, true);
              // activeSegmentRef.current = segmentListRef.current[0];
              // setSliderValue(segmentListRef.current[0].timestamp_start)
            }
          }
        }
      }
    }, 10);
  }
  useEffect(() => {
    if(playingVideo) {
      handleVideoDuration();
    } else {
      clearInterval(videoIntervalRef.current);
    }
  }, [playingVideo])

  // const setActionTimeout = () => {
  //   if(showControlActions) {
  //     clearTimeout(controlActionTimeout.current);
  //     controlActionTimeout.current = setTimeout(() => {
  //       setShowControlActions(false);
  //     }, 2000);
  //   }
  // }
  // useEffect(() => {
    // setActionTimeout();
  // }, [showControlActions]);

  const rewindVideo = async () => {
    const currentTime = await segmentVideoRef.current.getCurrentTime();
    segmentVideoRef.current.pauseVideo();
    if (currentTime > 10) {
      if (segmentListRef.current.length === 0 || (activeSegmentRef.current && currentTime - 10 >= activeSegmentRef.current.timestamp_start)) {
        segmentVideoRef.current.seekTo(currentTime - 10, true);
        const duration = calculatePrevSegmentLength(activeSegmentRef.current.index, segmentListRef.current) + currentTime - 10 - activeSegmentRef.current.timestamp_start;
        setSliderValue(duration <= videoLength ? duration : videoLength);
      } else {
        if (activeSegmentRef.current && currentTime - 10 < activeSegmentRef.current.timestamp_start) {
          const prevRemaing = activeSegmentRef.current.timestamp_start - (currentTime - 10);

          const prevSegment = segmentListRef.current[activeSegmentRef.current.index - 1];
          if (prevSegment) {
            activeSegmentRef.current = prevSegment;
            segmentVideoRef.current.seekTo(prevSegment.timestamp_end - prevRemaing, true);
            const duration = calculatePrevSegmentLength(activeSegmentRef.current.index, segmentListRef.current) + prevSegment.timestamp_end - prevRemaing - prevSegment.timestamp_start;
            setSliderValue(duration <= videoLength ? duration : videoLength);
          }
        }
      }
      setTimeout(() => {
        segmentVideoRef.current.playVideo();
      }, 50);
    } else {
      segmentVideoRef.current.pauseVideo();
    }
  }

  const fastForwardVideo = async () => {
    const currentTime = await segmentVideoRef.current.getCurrentTime();
    segmentVideoRef.current.pauseVideo();
    if (currentTime + 10 < totalVideoLength.current) {
      if(segmentListRef.current.length === 0 || (activeSegmentRef.current && currentTime + 10 <= activeSegmentRef.current.timestamp_end)) {
        segmentVideoRef.current.seekTo(currentTime + 10, true);
        const duration = calculatePrevSegmentLength(activeSegmentRef.current.index, segmentListRef.current) + (currentTime + 10 - activeSegmentRef.current.timestamp_start)
        setSliderValue(duration <= videoLength ? duration : videoLength);
      } else {
        if(activeSegmentRef.current && currentTime + 10 > activeSegmentRef.current.timestamp_end) {
          const nextRemaing = currentTime + 10 - activeSegmentRef.current.timestamp_end;

          const nextSegment = segmentListRef.current[activeSegmentRef.current.index + 1];
          if(nextSegment) {
            activeSegmentRef.current = nextSegment;
            segmentVideoRef.current.seekTo(nextSegment.timestamp_start + nextRemaing, true);
            const duration = calculatePrevSegmentLength(activeSegmentRef.current.index, segmentListRef.current) + nextRemaing;
            setSliderValue(duration <= videoLength ? duration : videoLength);
          }
        }
      }
      setTimeout(() => {
        console.log('22222');
        segmentVideoRef.current.playVideo();
      }, 50);
    } else {
      segmentVideoRef.current.pauseVideo();
    }
  }

  return (
    <>
      <Wrapper>
        <div id={`segment-youtube-${data.id}`}/>
        <VideoOverlay
          onClick={(e) => {
            if (segmentCompleted) {
              e.stopPropagation();
              // setActionTimeout();
              segmentVideoRef.current.seekTo(segmentListRef.current[0].timestamp_start, true);
              activeSegmentRef.current = segmentListRef.current[0];
              setSliderValue(0)
              setSegmentCompleted(false);
              console.log('33333');
              segmentVideoRef.current.playVideo();
            } else{
              e.stopPropagation();
              // setActionTimeout();
              if (segmentVideoRef.current) {
                if (playingVideo) {
                  segmentVideoRef.current.pauseVideo();
                } else {
                  console.log('44444');
                  segmentVideoRef.current.playVideo();
                }
              }
            }
          }}
        />
      </Wrapper>
      <CustomizedControlWrapper>
        
        <CustomizedControlBottom>
          {!hideVideoActions && (
            <VideoDurationText>{`${sliderValue === 0 ? '00:00' : connvertSecondsToTimeFormat(sliderValue)} / ${connvertSecondsToTimeFormat(videoLength)}`}</VideoDurationText>
          )}
          <div style={{ position: 'relative'}}>
            <Slider
              min={0}
              max={videoLength}
              value={sliderValue}
              orientation='horizontal'
              // onChange={this.handleChangeReverse}
            />
            <TrackMarksOverlay>
              {[...Array(videoLength)].map((elm, index) => (
                <span
                  key={`marks-${index}`}
                  style={{
                    flex: 1,
                    position: 'relative'
                    // backgroundColor: segmentList.map(segment => Math.round(segment.timestamp_start)).includes(index) ? '#000' : 'transparent'
                  }}
                >
                  {segmentTrackMarks.slice(1,segmentTrackMarks.length).map(item => parseInt(item, 10)).includes(index) ? (
                    <span 
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        width: window.innerWidth <= 768 ? 5 : 7,
                        background: 'rgba(0, 0, 0, 0.6)',
                        left: 0,
                        transform: 'translateX(-50%)',
                      }}
                    />
                  ) : null}
                </span>
              ))}
            </TrackMarksOverlay>
          </div>
        </CustomizedControlBottom>
        <CustomizedControlTop
          onClick={() => {
            // setShowControlActions(!showControlActions);
          }}
        >
          {/* {showControlActions && ( */}
            <ControllerActions>
              <VolumeActionWrapper
                onClick={(e) => {
                  // if (showControlActions) {
                  //   setActionTimeout();
                    e.stopPropagation();
                    if(segmentVideoRef.current.isMuted()) {
                      segmentVideoRef.current.unMute();
                      setMuteVideo(false);
                    } else {
                      segmentVideoRef.current.mute();
                      setMuteVideo(true);
                    }
                    
                  //   rewindVideo();
                  // }
                }}
              >
                {muteVideo ? 
                  ( 
                    theme === "light" ? ( <VolumeImage src={muteIcon} /> ) : ( <VolumeImage src={darkMuteIcon} /> )
                  ) : ( 
                    theme === "light" ? ( <VolumeImage src={VolumeIcon} /> ) : ( <VolumeImage src={darkVolumeIcon} /> )
                  )
                }
              </VolumeActionWrapper>
              <MiddleActionWrapper hideVideoActions={hideVideoActions}>
                {!hideVideoActions && (
                  <ActionWrapper
                    onClick={(e) => {
                      // if (showControlActions) {
                        // setActionTimeout();
                        e.stopPropagation();
                        rewindVideo();
                      // }
                    }}
                  >
                    {theme === "light" ? (
                        <RewindImage
                          src={rewindIcon}
                        />
                      ) : (
                        <RewindImage
                          src={darkRewindIcon}
                        />
                      )
                    }
                  </ActionWrapper>
                )}
                <PlayPauseWrapper
                  onClick={(e) => {
                  if (segmentCompleted) {
                      e.stopPropagation();
                      // setActionTimeout();
                      segmentVideoRef.current.seekTo(segmentListRef.current[0].timestamp_start, true);
                      activeSegmentRef.current = segmentListRef.current[0];
                      setSliderValue(0)
                      setSegmentCompleted(false);
                      console.log('33333');
                      segmentVideoRef.current.playVideo();
                    } else{
                      e.stopPropagation();
                      // setActionTimeout();
                      if (segmentVideoRef.current) {
                        if (playingVideo) {
                          segmentVideoRef.current.pauseVideo();
                        } else {
                          console.log('44444');
                          segmentVideoRef.current.playVideo();
                        }
                      }
                    }
                  }}
                >
                  {playingVideo ? 
                    ( 
                      theme === "light" ? ( <PauseImage src={pauseIcon} /> ) : ( <PauseImage src={darkPauseIcon} /> )
                    ) : ( 
                      theme === "light" ? ( <PlayImage src={playIcon} /> ) : ( <PlayImage src={darkPlayIcon} /> )
                    )
                  }
                </PlayPauseWrapper>
                {!hideVideoActions  && (
                  <ActionWrapper
                    onClick={(e) => {
                      // if (showControlActions) {
                        // setActionTimeout();
                        e.stopPropagation();
                        fastForwardVideo();
                      // }
                    }}
                  >
                    {theme==="light" ? (
                        <FastForwardImage
                          src={fastForwardIcon}
                        />
                      ):(
                        <FastForwardImage
                          src={darkFastForwardIcon}
                        />
                      )
                    }
                  </ActionWrapper>
                )}

              </MiddleActionWrapper>
              {/* <div width="50px" style={{marginLeft: 30, width: 50 }}/> */}
            </ControllerActions>
          {/* )} */}
        </CustomizedControlTop>
      </CustomizedControlWrapper>
    </>
  )
};

export default VideoSegmentPlayer;