import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (open ? "none" : "none")};
  z-index: 1000;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 768px) {
    display: ${({ open }) => (open ? "flex" : "none")};
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.primaryColor.level1};
  padding: 0 20px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const DragAreaWrapper = styled.div`
  padding: 10px 0;
`;
const DragAreaIcon = styled.div`
  width: 100px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  border-radius: 5px;
`;

const MenuList = styled.div``;
const SliderItem = styled(Link)`
  display: flex;
  align-items: center;
  font-family: "Metropolis-Bold", sans-serif;
  font-size: 15px;
  padding: 10px 10px;
  color: ${({ active }) =>
    active ? "#DC3D4D" : (props) => props.theme.misc.menuBarFont};
`;

const ImageWrapper = styled.div`
  margin-right: 25px;
`;

const MainheaderActionSheet = React.forwardRef((props, ref) => {
  const pageYRef = useRef(0);
  const [headerModal, setHeaderModal] = useState(false);
  React.useImperativeHandle(ref, () => ({
    foo() {
      setHeaderModal(true);
      //alert("Hello world");
      console.log("Hello world foo");
    },
  }));
  useEffect(() => {
    //setHeaderModal(openHeaderModal)
    console.log("openHeaderModal useEffect in BottomSheetMenu");
  });
  //   useEffect(() => {
  //    ;
  //     if (categoryId) {
  //       if (isNaN(parseInt(categoryId, 10))) {
  //         selectCategory({ slug: categoryId, id: categoryId })
  //       }
  //     }
  //   }, [categoryId])
  return (
    <Wrapper
      open={headerModal}
      onClick={() => {
        setHeaderModal(false);
        console.log("dismiss");
      }}
    >
      <Container
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <DragAreaWrapper
          onTouchMove={(event) => {
            console.log("onTouchMove active");
            if (pageYRef.current === 0) {
              pageYRef.current = event.touches[0].pageY;
            } else {
              if (event.touches[0].pageY - pageYRef.current > 20) {
                //onDismiss();
              }
            }
          }}
        >
          <DragAreaIcon />
        </DragAreaWrapper>
        <MenuList>
          <SliderItem>
            <ImageWrapper>
              <Image
                width={20}
                src="http://cdn.onlinewebfonts.com/svg/img_552474.png"
              />
            </ImageWrapper>
            <Link style={{ color: "gray" }} to={"/category/feed"}>
              Read Now
            </Link>
          </SliderItem>
          <SliderItem
            onClick={() =>
              window.open(
                "https://onelink.to/8chy8d",
                "_blank" // <- This is what makes it open in a new window.
              )
            }
          >
            <ImageWrapper>
              <Image
                width={20}
                src="http://cdn.onlinewebfonts.com/svg/img_395480.png"
              />
            </ImageWrapper>
            Get the app
          </SliderItem>
          <SliderItem
            onClick={() =>
              window.open(
                "https://dashboard.getdailybrief.com/",
                "_blank" // <- This is what makes it open in a new window.
              )
            }
          >
            <ImageWrapper>
              <Image
                width={24}
                src="http://cdn.onlinewebfonts.com/svg/img_519130.png"
              />
            </ImageWrapper>
            Creators
          </SliderItem>
        </MenuList>
      </Container>
    </Wrapper>
  );
});

export default MainheaderActionSheet;
