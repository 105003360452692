import React from 'react';
import styled from 'styled-components';
import { grey } from 'colorPalette';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import PlayStoreIcon from './GooglePlayIcon.png';
import AppStoreIcon from './AppleStoreIcon.png';
const Wrapper = styled.div`
  /* position: fixed;
  right: 0;
  bottom: 0;
  width: 12%;
  padding: 10px; */
  flex: 1;
  border-top: 1px solid rgba(0,0,0, 0.05);
  /* margin-right: 10%; */
  font-family: 'Metropolis', sans-serif;
  padding: 10px 0;
  display: ${({ isLoadMoreFooter }) => isLoadMoreFooter ? 'none' : 'block'};
  /* @media (max-width: 1200px) {
    margin-right: 3%;

  }

  @media (max-width: 1024px) {
    width: 17%;
    margin-right: 3%;
  } */

  @media (max-width: 768px) {
    /* display: none; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E8ECFC;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FooterItem = styled(Link)`
  display: flex;
  align-items: center;
  margin: 5px 7px 5px 0;
  font-weight: 500;
  font-size: 14px;
  color: ${grey.level3};

  &:hover {
    color: ${grey.level3};
  }

  .divider {
    width: 5px;
    height: 5px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    background-color: ${grey.level3};
    display: block;
  }
`;

const FooterItemAppWrapper = styled.div`
  width: 220px;
  display: flex;
  flex-wrap: wrap;
`;

const FooterItemApp = styled.a `
  width: 100px;
  display: block;
  margin: 5px 5px 5px 0;

  img {
    width: 100%;
  }
`;

const DigestFooter = ({ sliderFixed, footerRef, isLoadMoreFooter }) => {
  const { t: translation } = useTranslation();
  const isClip = process.env.REACT_APP_ENV === 'clip-production';
  return (
    <Wrapper
      ref={footerRef}
      isLoadMoreFooter={isLoadMoreFooter}
    >
      {/* {sliderFixed && ( */}
        <Container>
          {/* <FooterItem>
            Help
          </FooterItem> */}
          <FooterItem
            to="/privacy"
            target="_blank"
          >
            {/* <span className="divider" /> */}
            {translation("digestFeed.footer.privacyText")}
          </FooterItem>
          <FooterItem
            to="/terms" 
            target="_blank"
          >
            <span className="divider" />
            {translation("digestFeed.footer.termsText")}
          </FooterItem>
          <FooterItem
            to="/about" 
            target="_blank"
          >
            <span className="divider" />
            {translation("digestFeed.footer.aboutText")}
          </FooterItem>
        </Container>
        <FooterItemAppWrapper>
          <FooterItemApp
            href={isClip ? "https://play.google.com/store/apps/details?id=com.getdailybrief.tv&hl=en_IN" :"https://play.google.com/store/apps/details?id=in.ideastoday"}
            target="_blank"
          >
            <Image src={PlayStoreIcon} />
          </FooterItemApp>
          <FooterItemApp
            href="https://apps.apple.com/in/app/dailybrief-news-briefings/id1559506634"
            target="_blank"
          >
            <Image src={AppStoreIcon} />
          </FooterItemApp>
        </FooterItemAppWrapper>
      {/* )} */}
    </Wrapper>
  );
}

export default DigestFooter;