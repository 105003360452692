import React, { useState } from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import dividerImage from './dividerImage.svg';
import SubscribeInputField from './SubscribeInputField';

const Wrapper = styled.div`
  position: relative;
`;
const BannerImageContentWrapper = styled.div`
  position: relative;
  background-image: ${({ bgImg }) => `url("${bgImg}")`};
  background-size: contain;
  background-repeat: no-repeat;
`;
const BannerTitle = styled.div`
  font-size: 28px;
  line-height: 1.3;
  font-weight: 900;
  border: 4px solid #F33;
  padding: 10px 20px;
  color: #F33;
  text-transform: uppercase;
`;

const BannerContent = styled.div`
  /* position: absolute; */
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
`;

const ImageWrapper = styled.div`
  margin: 20px 0;
`;

const BannerDesc = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 60%;
  font-size: 20px;
  line-height: 1.3;
  max-width: 800px;

  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;

const SubscribeInputFieldWrapper = styled.div`
  width: 60%;
  margin: 30px auto;
  max-width: 800px;
  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;

const Banner = ({ data, expTestingKey }) => {
  const [isSubscribe, setIsSubscribe] = useState(false)
  return (
    <Wrapper>
      <BannerImageContentWrapper bgImg={data[`image_${expTestingKey}`] ? data[`image_${expTestingKey}`].content : data.image.content}>
        {/* <Image src={data.image.content} /> */}
        <BannerContent>
          <BannerTitle>{data[`name_${expTestingKey}`] ? data[`name_${expTestingKey}`].content : data.name.content}</BannerTitle>
          <ImageWrapper>
            <Image src={dividerImage}/>
          </ImageWrapper>
        </BannerContent>
        <BannerDesc>{data[`description_${expTestingKey}`] ? data[`description_${expTestingKey}`].content : data.description.content}</BannerDesc>
      <SubscribeInputFieldWrapper>
        <SubscribeInputField
          onSubscribeSuccess={() => setIsSubscribe(true)}
          isSubscribe={isSubscribe}
            buttonText={data[`button_${expTestingKey}`] ? data[`button_${expTestingKey}`].content : data.button.content}
        />
      </SubscribeInputFieldWrapper>
      </BannerImageContentWrapper>
    </Wrapper>
  );
}

export default Banner;