import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import desfultIcon from './plane.png';
import { grey, categoryDiscover } from 'colorPalette';

const CategoriesListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
`;

const CategoryItem = styled.div`
  width: ${window.innerWidth / 3 - 30}px;
  height: ${window.innerWidth / 3 - 30}px;
  max-width: 125px;
  max-height: 125px;
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${grey.level5};
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 340px) {
    width: ${window.innerWidth / 2 - 50}px;
    height: ${window.innerWidth / 2 - 50}px;

  }
`;

const CategoryTitle = styled.div`
  text-align: center;
  min-height: 32px;
  margin: 5px 0;
  padding: 0 5px; 
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
  color: ${categoryDiscover};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  /* @media (max-width: 600px) and (min-width: 340px) {
    font-size: 14px;
  }
  */
  
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const ImageWrapper = styled.div`
  width: 30%;
  margin: 0 auto;
  height: ${window.innerWidth / 3 - 30}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 600px) and (min-width: 480px) {
    justify-content: center;
  }

  @media (max-width: 480px) and (min-width: 340px) {
    justify-content: flex-end;
  }
  
  @media (max-width: 340px) {
    height: 55px;
    justify-content: flex-end;
  }
`;


const CategoriesListView = ({ categoriesData, onSelectCategory }) => (
  <CategoriesListWrapper>
    {categoriesData.map(category => (
      <CategoryItem
        onClick={() => onSelectCategory(category)}
      >
        <ImageWrapper>
          <Image
            src={category.icon || desfultIcon}
          />
        </ImageWrapper>
        <CategoryTitle>{category.title}</CategoryTitle>
      </CategoryItem>
    ))}
  </CategoriesListWrapper>
);

export default CategoriesListView;