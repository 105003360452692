import React, { useReducer, useEffect, useRef } from 'react';
import produce from 'immer';
import styled from 'styled-components';

import Header from 'Components/Header';
import Footer from 'Components/Footer';
import VideoColumn from './VideoColumn';
import SegmentColumn from './SegmentColumn';

import { fetchSummaryData } from 'Api';
import { Loader } from 'semantic-ui-react';
const Wrapper = styled.div`
  display: flex;
  font-family: GothamBookRegular;
  background-color: #fff;
  padding-top: ${({ headerHeight }) => headerHeight}px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: ${({ mobilepaddingtop }) => mobilepaddingtop}px;
  }
`;

const LoaderWrapper = styled.div`
  height: calc(100vh - 150px);
`;

const GridColumn = styled.div`
  width: ${({ width }) => width}%;
  padding: ${({ padding }) => padding || '20px 15px'};
  border-right: ${({ borderRight }) => borderRight ? 1 : 0}px solid #d8d8d8;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  margin: 0 5px;
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    border-right: 0px solid #d8d8d8;
    padding: 10px 0 0;
  }
`;

const GridContent = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'SET_VIDEO_DETAIL':
        draft.videoDetail = action.value;
        break;
      case 'SET_ACTIVE_SEGMENT':
        draft.activeSegment = action.value;
        draft.onSeekActive = true;
        break;
      case 'SET_ONSEEK_ACTIVE':
        draft.onSeekActive = action.value;
        break;
      case 'SET_SEGMENT_DATA':
        draft.segmentsData = action.value.map((segment, index) => ({
          ...segment,
        }));
        draft.loadingData = false;
        break;
      case 'SET_LOADING_DATA':
        draft.loadingData = action.value;
        break;
      case 'SET_NEXT_ACTIVE_SEGMENT':
        draft.nextActiveSegment = action.value;
        break;
      case 'SET_WRAPPER_PADDING':
        draft.wrapperPadding = action.value;
        break;
      case 'SET_SEGMENT_LOADER':
        draft.segmentLoading = action.value;
        if(action.value) {
          draft.segmentLoadingData = action.segmentLoadingData;
        } else {
          draft.segmentLoadingData = null;
          draft.activeSegment = action.activeSegment;
          draft.onSeekActive = true;
        }
        break;
      case 'SET_ON_VIDEO_READY':
        draft.videoReady = true;
    }
  });

const initialState = {
  videoDetail: {},
  segmentsData: [],
  activeSegment: null,
  onSeekActive: false,
  loadingData: true,
  nextActiveSegment: null,
  segmentLoading: false,
  segmentLoadingData: null,
  videoReady: false,
};

const VideoDetail = ({ location, match: { params } }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const headerRef = useRef(null)
  const {
    videoDetail,
    segmentsData,
    activeSegment,
    onSeekActive,
    nextActiveSegment,
    loadingData,
    wrapperPadding,
    segmentLoading,
    segmentLoadingData,
    videoReady
  } = state;
  useEffect(() => {
    dispatch({ type: 'SET_LOADING_DATA', value: true });
    fetchSummaryData({ id: params.id, metadata: true }).then(response => {
      if(response.data) {
        dispatch({ type: "SET_SEGMENT_DATA", value: (response.data.video_summary && response.data.video_summary.summary) || []});
        let videoDetail = {
          url: response.data.cover_pic,
        };
        if (response.data.video_summary.metadata) {
          videoDetail = {
            ...videoDetail,
            ...response.data.video_summary.metadata,
          }
          videoDetail.summaryLength = response.data.video_summary.total_length;
          dispatch({ 
            type: "SET_VIDEO_DETAIL",
            value: videoDetail
          });
        }
      }
    });
  }, [params.id]);

  useEffect(() => {
    if (segmentsData && segmentsData.length > 0 && !activeSegment) {
      const segment = segmentsData[0];
      segment.index = 0;
      dispatch({ type: 'SET_ACTIVE_SEGMENT', value: segment});
    }
  }, [activeSegment, segmentsData]);

  useEffect(() => {
    if (segmentLoading) {
      setTimeout(() => {
        dispatch({
          type: "SET_SEGMENT_LOADER",
          value: false,
          activeSegment: segmentLoadingData,
        })
      }, 500);
    }
  }, [segmentLoading, segmentLoadingData])
  return (
    <div>
      <Header fixed headerRef={headerRef}/>
      {loadingData && (
        <LoaderWrapper>
          <Loader active={loadingData} />
        </LoaderWrapper>
      )}
      {!loadingData && (
        <Wrapper
          mobilepaddingtop={wrapperPadding}
          headerHeight={(headerRef.current && headerRef.current.clientHeight) || 0}
        >
          <GridColumn bgColor="#f6f6f6" width={65}>
            <GridContent>
              {Object.keys(videoDetail).length > 0 && (
                <VideoColumn
                  headerHeight={(headerRef.current && headerRef.current.clientHeight) || 0}
                  videoDetail={videoDetail}
                  onChnageRef={a => {
                    if(a) {
                      dispatch({ type: 'SET_WRAPPER_PADDING', value: a.clientHeight + 35})
                    }
                  }}
                  segmentsData={segmentsData || []}
                  activeSegment={activeSegment}
                  onSeekActive={onSeekActive}
                  nextActiveSegment={nextActiveSegment}
                  segmentLoading={segmentLoading}
                  onChangeSegment={(value, segmentLoadingValue) => {
                    if (segmentLoadingValue) {
                      dispatch({
                        type: 'SET_SEGMENT_LOADER',
                        value: segmentLoadingValue,
                        segmentLoadingData: value,
                      })
                    } else {
                      dispatch({ type: 'SET_ACTIVE_SEGMENT', value });
                    }
                  }}
                  onChangeSeekActive={value => {
                    dispatch({ type: 'SET_ONSEEK_ACTIVE', value });
                  }}
                  onSetNextActiveSegment={value => {
                    dispatch({ type: 'SET_NEXT_ACTIVE_SEGMENT', value });
                  }}
                  setOnReadyVideo={() => {
                    dispatch({ type: 'SET_ON_VIDEO_READY' });
                  }}
                />
              )}
            </GridContent>
          </GridColumn>
          <GridColumn width={35} bgColor="#fff" padding="20px 0">
            <GridContent fixed >
              <SegmentColumn
                segmentsData={segmentsData}
                activeSegment={activeSegment}
                nextActiveSegment={nextActiveSegment}
                segmentLoading={segmentLoading}
                onChangeSegment={value => {
                  if (videoReady) {
                    value.manuallyChanged = true;
                    dispatch({ type: 'SET_NEXT_ACTIVE_SEGMENT', value: null });
                    dispatch({ type: 'SET_ACTIVE_SEGMENT', value });
                  }
                }}
              />
            </GridContent>
          </GridColumn>
        </Wrapper>
      )}
      <Footer />
    </div>
  );
};

export default VideoDetail;