import React, { useState } from 'react';
import styled from 'styled-components';
// import { Input } from 'semantic-ui-react';
// import { addNewsSubscription } from 'Api';/
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
// import Icon from 'Icon';

const Wrapper = styled.div`
  position: relative;
`;

const SubmitButton = styled.div`
  font-family: Metropolis;
  font-weight: 900;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #22BC66;
  padding: 18px 25px;
  position: relative;
  top: 0;
  right: 0;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 13px 20px;
  }
  
  @media (max-width: 768px) {
    padding: 12px 20px;
  }
  
  @media (max-width: 580px) {
    padding: 20px 20px;
    font-size: 16px;
    line-height: 14px;
    position: static;
    margin-top: 20px 0;
    width: 100%;
  }
`;

// const InputField = styled(Input)`
//   width: 100%;

//   input {
//     padding: 12px 170px 12px 15px !important;
//     border-radius: 5px !important;
//     border: solid 1px #d0d0d0 !important;
//     background-color: #f2f2f2 !important;
//     font-family: 'Metropolis', sans-serif;
//     font-size: 22px;
//     font-weight: 500;
//     line-height: 26px !important;
//     color: rgba(70, 70, 70, 0.37);

//     &::placeholder {
//       font-style: italic;
//     }

//     @media (max-width: 1024px) {
//       font-size: 5px;
//       line-height: 24px !important;
//       padding: 8px 170px 8px 10px !important;
//     }
    
//     @media (max-width: 768px) {
//       font-size: 18px;
//       line-height: 22px !important;
//       padding: 8px 170px 8px 10px !important;
//     }

//     @media (max-width: 580px) {
//       padding: 8px 10px 8px 10px !important;
//     }
//     }
// `;

const EmailError = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #e02020;
  text-align: left;
  margin-top: 5px;
`;

// const validateEmail = email => {
//   // eslint-disable-next-line no-useless-escape
//   const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (regx.test(email)) {
//     return true;
//   }
//   return false;
// }


const SubscribeInputField = ({
  onSubscribeSuccess,
  isSubscribe,
  expTestingKey,
  referralCode,
}) => {
  const [emailError] = useState(null);
  const [emailValue] = useState('');

  const handleUserSubscribtion = () => {
    logAmplitudeEvent("Subscribed Button Clicked - Newsletter LP",
                      {
                        email: emailValue,
                        exp_version: expTestingKey
                      });
    if (!referralCode)
      window.open('https://api.whatsapp.com/send?phone=919265134890&text=Hey%21+I+just+got+to+know+about+DailyBrief.+I+would+like+to+subscribe+for+free%21&source=&data=&app_absent=')
    else
      window.open(`https://api.whatsapp.com/send?phone=919265134890&text=Hey%21+I+just+got+to+know+about+DailyBrief.+I+would+like+to+subscribe+for+free%21+My+referral+code+is:+${referralCode}.&source=&data=&app_absent=`)                 
  
  };

  return (
    <Wrapper>

      {emailError && (
        <EmailError>{emailError}</EmailError>
      )}
      <SubmitButton
        onClick={handleUserSubscribtion}     >{isSubscribe ? 'subscribed' : 'subscribe on WhatsApp'}
      </SubmitButton>
    </Wrapper>
  )
};

export default SubscribeInputField;