import React from 'react';
import styled from 'styled-components';
import { grey } from 'colorPalette';
import moment from 'moment';
import { Image } from 'semantic-ui-react';
import { varientTitle } from 'global';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import facebookIcon from './facebook.svg';
import darkFacebookIcon from './darkfacebook.svg';
// import twitterIcon from './885751_line_512x512.svg';
// import twitterIcon from './twitter.svg';
import darkTwitterIcon from './darktwitter.svg';
// import instagramIcon from './instagram.svg';
import darkInstagramIcon from './darkinstagram.svg';

const Wrapper = styled.div`
  background-color: ${props => props.theme.primaryColor.level1};
  padding: 10px 0;
  font-family: 'Metropolis', sans-serif;
`;

const Container = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    width: 90%;
  }
  
  @media (max-width: 800px) {
    width: 95%;
  }
  
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;

const FooterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: ${grey.level3};
  a {
    font-size: 16px;
    line-height: 20px;
    color: ${grey.level3};

    &:hover {
      color: ${grey.level3};
      opacity: .8;
    }
  }
  .divider {
    width: 5px;
    height: 5px;
    margin: 0 8px;
    border-radius: 50%;
    background-color: ${grey.level3};
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 18px;

    a {
      font-size: 14px;
    }

  }
  
  @media (max-width: 660px) {
    font-size: 12px;
    line-height: 16px;
    flex-direction: column;
    text-align: center;
    a {
      font-size: 12px;
    }
    
  }
`;

const TermsConditionsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 660px) {
    margin-top: 10px;
  }
`;

const SocialIconWrapper = styled.a`
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  color: #b0b0b0;
  width: 30px;
  img { width: 100%; }

  @media (max-width: 800px) {
    width: 28px;
  }

  @media (max-width: 580px) {
    width: 26px;
  }
  
`;

const Footer = (theme) => {
  const { t: translation } = useTranslation();
  const isTnd = process.env.REACT_APP_ENV === "tnd-production" || process.env.REACT_APP_ENV === "tnd-staging";
  const isUnd = process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
  const isOped = process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';
  const isClip = process.env.REACT_APP_ENV === 'clip-production' || process.env.REACT_APP_ENV === 'clip-staging';
  let facebookUrl = "https://www.facebook.com/travelnewsdigest/";
  let instaUrl = 'https://www.instagram.com/travelnewsdigest/';
  let twitterUrl = "https://twitter.com/TNDigest";

  if(isTnd) {
    facebookUrl = "https://www.facebook.com/travelnewsdigest/";
    instaUrl = 'https://www.instagram.com/travelnewsdigest/';
    twitterUrl = "https://twitter.com/TNDigest";
  }
  
  if (isUnd) {
    facebookUrl = "https://www.facebook.com/urbannewsdigest";
    instaUrl = 'https://www.instagram.com/travelnewsdigest/';
    twitterUrl = "https://twitter.com/TNDigest";
  }
  
  if (isOped) {
    facebookUrl = "https://www.facebook.com/getdailybrief";
    instaUrl = 'https://www.instagram.com/getdailybrief/';
    twitterUrl = "https://twitter.com/getdailybrief";
  }
  
  if (isClip) {
    facebookUrl = "https://www.facebook.com/getdailybrief";
    instaUrl = 'https://www.instagram.com/getdailybrief/';
    twitterUrl = "https://twitter.com/getdailybrief";
  }
  return (
    <Wrapper>
      <Container>
        <FooterContentWrapper>
          <span>{`@ ${moment(new Date()).format('YYYY')} ${varientTitle} - ${translation("digestDetail.footer.footerInfo")}`}</span>
          <TermsConditionsWrapper>
            <span className="divider" />
            <Link to="/privacy" target="_blank">{translation("digestDetail.footer.privacyText")}</Link>
            <span className="divider" />
            <Link to="/terms" target="_blank">{translation("digestDetail.footer.termsText")}</Link>
            <span className="divider" />
            <Link to="/about" target="_blank">{translation("digestDetail.footer.aboutText")}</Link>
            {/* <span className="divider" />
            <span>Sitemap</span> */}
          </TermsConditionsWrapper>
        </FooterContentWrapper>
        <SocialIconsContainer>
          <SocialIconWrapper href={facebookUrl} rel="noopener noreferrer" target="_blank">
            <Image src={ darkFacebookIcon } />
          </SocialIconWrapper>
          <SocialIconWrapper href={instaUrl} rel="noopener noreferrer" target="_blank">
            <Image src={ darkInstagramIcon } />
          </SocialIconWrapper>
          <SocialIconWrapper href={twitterUrl} rel="noopener noreferrer" target="_blank">
            <Image src={ darkTwitterIcon }/>
          </SocialIconWrapper>
        </SocialIconsContainer>
      </Container>
    </Wrapper>
  );
}

export default Footer;
