import React from 'react';
import styled from 'styled-components';
import RenderDigestCover from '../RenderDigestCover';
import { DigestCreatedByInfo, DigestPublishInfo } from '../../CommonComponents';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import { Image } from 'semantic-ui-react';
import { grey } from 'colorPalette';
import { Link } from 'react-router-dom';
import Icon from 'Icon';
import { ApplyExpandablePoints } from 'Utils/ExpandablePoints';
const AmplitudePageType = 'ArticlePage';

const Wrapper = styled.div``;

const ArticleTitle = styled(Link)`
  font-size: 28px;
  line-height: 1.3;
  font-weight: 500;
  margin-top: 20px;
  font-family: 'PT Serif', Georgia, serif;
  color: rgba(0, 0, 0, .8);
  cursor: pointer;
  display: block;
  text-align: center;
  &:hover {
    color: rgba(0, 0, 0, .8);
  }
  @media (max-width: 1024px) {
    font-size: 22px;
    margin-top: 10px;
    line-height: 30px;
  }
  
  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 10px;
    line-height: 1.3;
    width: 100%;
    margin: 0 auto;

  }
 
  @media (max-width: 560px) {
    font-size: 24px;
    margin-top: 10px;
    line-height: 1.3;
  }
`;


const ArticleDesc = styled.div`
  font-size: 20px;
  line-height: 1.8;
  margin: 10px 0;
  color: #696969;
  font-family: 'Merriweather', serif;
  img {
    max-width: 100%;
  }

  h1, h2, h3 {
    font-family: 'PT Serif', Georgia, serif !important;
  }
  @media (max-width: 1024px) {
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    font-size: 16px;
    
  }


  p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 12px;
    min-height: 10px;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;

    }

  }

  ul {
    @media (max-width: 768px) {
      padding-left: 20px;
    }
  }


  li {
    margin: 0 0 8px 0;
  }

  blockquote {
    margin: 15px 0;
    display: block;
    font-style: italic;
    position: relative;

    &:before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: #333;
      position: absolute;
      top: 0;
      left: -15px;
    }

    @media (max-width: 768px) {
      margin: 10px 0;
      &:before {
        left: -10px;
      }
    }
  }

  figure {
    text-align: center;

    img {
      width: 80% !important;
    }
  }
`;

const DigestCreatedByInfoWrapper = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const DigestPublishWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 90px);
  margin: 10px 0;
  position: absolute;
  width: 100%;
  padding: 10px 0;
  top: 0;
  left: 10px;
`;

const DigestIconWrapper = styled.div`
  overflow: hidden;
  padding-right: 5px;
  width: 45px;
  img  {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    padding-right: 10px;
    border-right: 2px solid #fff;
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

const DigestInfoText = styled.div`
  color: #fff;
  text-shadow: 0 0 6px #000000;
  font-size: 14px;
  line-height: 18px;
  /* display: flex; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  flex: 1;
  font-family: 'Metropolis', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
    width: 90%;
    margin: 0 auto;
  }

  span {
    margin-right: 5px;
    color: rgba(0, 0, 0, .8);
  }

  a {
    color: #fff;
  }


  .divider {
    margin: 0 5px;
  }
`;

const ReadAtButton = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px 20px 20px;
  border: 1px solid ${grey.level3};
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  font-family: 'Metropolis', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 30px; */
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    padding: 15px;
  }

  @media (max-width: 580px) {
    padding: 15px 35px 15px 15px;
  }

  &:hover {
    color: #333;
  }
`;

const ExternalLinkIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  @media (max-width: 580px) {
    right: 10px;

    i, svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const SubDigestDividerWrapper = styled.div`
  border: 1px solid #d9d9d9;
  width: 50%;
  margin: 50px auto 35px;
  position: relative;
`;

const DividerNumberContainer = styled.div`
  padding: 0 20px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const SubDigestDividerNumber = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
  /* display: flex;
  justify-content: center; */
  background-color: #e02020;
  padding: 1px;
  border-radius: 50%;
  font-family: 'PT Serif', Georgia, serif;
  font-size: 27px;
  line-height: 27px;
  color: #fff;

  span {
    display: block;
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RenderCollectionDigest = ({ 
  item, 
  collectionDigests,
  history,
  setPlayingVideo,
  playingVideoID,
  theme
}) => {
  let data = null;
  if (collectionDigests) {
    collectionDigests.forEach(digest => {
      if (digest.slug === item.slug) {
        data = digest;
      }
    })
  }

  const publishInfo = () => (
    <DigestPublishWrapper>
      {data.source && data.source.host_data && data.source.host_data.logo_link && (
        <DigestIconWrapper>
          <Image src={data.source && data.source.host_data && data.source.host_data.logo_link} />
        </DigestIconWrapper>
      )}
      <DigestInfoText
      >
        {data.source && data.source.host && (
          <>
            <a
              href={data.source && data.source.link}
              onClick={event => {
                event.stopPropagation();
                logAmplitudeEvent('Article Opened', {
                  article_id: data.id,
                  article_type: data.type.code,
                  action: 'bottom',
                  pageType: AmplitudePageType,
                  slug: data.slug,
                  category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
                });
              }}
              target="_blank"
              rel="noopener noreferrer"
            >{data.source && data.source.host}</a>
            {/* {data.first_published_at_human && (<span className="divider">/</span>)} */}
          </>
        )}
        {item.source && item.source.authors && item.source.authors.length > 0 && (
          <div>{item.source.authors.map((author, index) => `${index !== 0 ? ',' : ''} ${author.name}`)}</div>
        )}
      </DigestInfoText>
    </DigestPublishWrapper>
  )
  return (
    <Wrapper>
      {data && (
        <>
          <SubDigestDividerWrapper>
            <DividerNumberContainer>
              <SubDigestDividerNumber>
                <span>
                  {item.index + 1}
                </span>
              </SubDigestDividerNumber>
            </DividerNumberContainer>
          </SubDigestDividerWrapper>
          <ArticleTitle
            to={`/digests/${data.slug}`}
            target="_blank"
          >{data.title}</ArticleTitle>
          <DigestCreatedByInfoWrapper>
            <DigestCreatedByInfo data={data} />
          </DigestCreatedByInfoWrapper>
          
          <RenderDigestCover 
            data={data}
            history={history}
            setPlayingVideo={setPlayingVideo}
            playingVideoID={playingVideoID}
            isSubDigest
            publishInfo={publishInfo}
            theme={theme}
          />
          <ArticleDesc
            className="articleDesc"
            ref={ApplyExpandablePoints}
          >
            {data.digest && data.markup && data.markup.apply ? (
              <ArticleDesc
                dangerouslySetInnerHTML={{ __html: data.digest || '' }}
              />
              // <ReactMarkdown source={data.digest} />
            ) : (
                <ArticleDesc>
                  {
                    data.digest && data.digest.split(/\n/).map((para, index) => (
                      <p key={index}>{para}</p>
                    ))
                  }
                </ArticleDesc>
              )}
          </ArticleDesc>
          {data.source.host && (
            <ReadAtButton
              // href={`https://${data.source.host}`}
              // href={`${data.source.link}`}
              // target="_blank"
              onClick={() => {
                logAmplitudeEvent('Article Opened', {
                  article_id: data.id,
                  article_type: data.type.code,
                  action: 'click-link',
                  pageType: AmplitudePageType,
                  slug: data.slug,
                  category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
                });
              }}
            >
              <DigestPublishInfo data={data} hideFollowButton />
              {/* <DigestIconWrapper>
                        <Image src={articleData.source && articleData.source.host_data && articleData.source.host_data.logo_link} />
                      </DigestIconWrapper>
                      {`${translation("digestDetail.readOnButtonText")} ${articleData.source.host}`}
                      */}
              <ExternalLinkIcon>
                <Icon name="externalLink" />
              </ExternalLinkIcon>
              {/* <DigestIconWrapper>
                <Image src={data.source && data.source.host_data && data.source.host_data.logo_link} />
              </DigestIconWrapper>
              {`Read on ${data.source.host}`}
              <ExternalLinkIcon>
                <Icon name="externalLink" />
              </ExternalLinkIcon> */}
            </ReadAtButton>
          )}

          {/* <ReadReasonWrapper
            href={`${data.source.link}`}
            target="_blank"
            onClick={() => {
              logAmplitudeEvent('Article Opened', {
                article_id: data.id,
                article_type: data.type.code,
                action: 'click-link',
                pageType: AmplitudePageType,
                slug: data.slug,
                category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
              });
            }}
          >
            <ReadReasonTitle>
              {data.source.read_reason_title}
            </ReadReasonTitle>
            <ReadReasonDesc>
              {data.source.read_reason}
            </ReadReasonDesc>
            <ReadAtButton>
              <DigestIconWrapper>
                <Image src={data.source && data.source.host_data && data.source.host_data.logo_link} />
              </DigestIconWrapper>
              {`Read on ${data.source.host}`}
            </ReadAtButton>
          </ReadReasonWrapper> */}
        </>
      )}
    </Wrapper>
  );
}

export default RenderCollectionDigest;