import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const Wrapper = styled.div`
  margin: 30px 0;
`;
const ArticleItemWrapper = styled.div`
  margin: 25px 0;

  @media (max-width: 768px) {
    padding: 15px;
    background-color: #fff;
  }
`;

const ArticleItem = ({ theme }) => {
  console.log('theme ArticleItem',theme);
  return (
    <ArticleItemWrapper>
      <ContentLoader
        width="100%"
        height="280px"
        // viewBox="0 0 100% 100%"
        backgroundColor={theme === "light" ? "#f3f3f3" : "#333"}
        foregroundColor={theme === "light" ? "#ecebeb" : "#999"}
      >
        <rect x="0" y="0" rx="5" ry="5" width="55%" height="20" />
        <rect x="0" y="30" rx="4" ry="4" width="55%" height="20" />
        <rect x="65%" y="0" rx="4" ry="4" width="30%" height="150" />
        <rect x="0" y="70" rx="4" ry="4" width="40%" height="12" />
        <rect x="0" y="120" rx="4" ry="4" width="55%" height="13" />
        <rect x="0" y="140" rx="4" ry="4" width="55%" height="13" />
        <rect x="0" y="160" rx="4" ry="4" width="55%" height="13" />
        <rect x="0" y="180" rx="4" ry="4" width="55%" height="13" />
        <rect x="0" y="200" rx="4" ry="4" width="55%" height="13" />

        <circle cx="20" cy="250" r="20" />
        <rect
          x="70"
          y="235"
          rx="5"
          ry="5"
          width="calc(55% - 70px)"
          height="10"
        />
        <rect
          x="70"
          y="250"
          rx="5"
          ry="5"
          width="calc(55% - 70px)"
          height="10"
        />
      </ContentLoader>
    </ArticleItemWrapper>
  );
};
const ArticleListShimmer = ({ innerLoader, theme }) => (
  <Wrapper>
    {innerLoader ? (
      <ArticleItem theme={theme} />
    ) : (
      <>
        <ArticleItem theme={theme} />
        <ArticleItem theme={theme} />
        <ArticleItem theme={theme} />
        <ArticleItem theme={theme} />
        <ArticleItem theme={theme} />
        <ArticleItem theme={theme} />
      </>
    )}
  </Wrapper>
);

export default ArticleListShimmer;
