import React, { useReducer, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { Button, Icon, Modal, Input, Image } from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-2'
// import { useHistory } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import 'react-phone-input-2/lib/style.css';
// import AppleLogin from 'react-apple-login';
import { primaryColor, grey, white } from 'colorPalette';
import PageHeader from 'Components/Header';
import {
  socialLogin,
  sendPhoneOtp,
  verifyPhoneOtp,
} from 'Api';
import { setAxiosAuthorizationHeader } from 'Utils/axios';
import { setUserProperties } from 'Utils/amplitudeLoad';
import closeIcon from './close.svg';
import { varientTitle } from 'global';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import OutsideClick from 'Components/OutsideClick';

let facebookId = '229460644890970';
// let googleId = '591016328578-kee7i69nsap4dl5qneii43hef45oqeb5.apps.googleusercontent.com';
let googleId = '331748828581-q31ddbokl44ck5vv2nhro223rapqquka.apps.googleusercontent.com';
if (process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging') {
  facebookId = '245541393437607';
  googleId = '19206282515-e7gp824sggb71bhp3t2mpohavju816ce.apps.googleusercontent.com'
}

if (process.env.REACT_APP_ENV === 'clip-production') {
  facebookId = '287613313102053';
  googleId = '19206282515-e7gp824sggb71bhp3t2mpohavju816ce.apps.googleusercontent.com'
}

const Container  = styled.div`
  padding: 100px 0;
  font-family: 'Metropolis', sans-serif;
  ${({ isDesktop, theme }) => isDesktop ? `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: ${theme === 'dark' ? 'rgba(255, 255, 255, 0.6) ': 'rgba(0, 0, 0, 0.7)'};
    display: flex;
    align-items: center;
    justify-content: center;
  ` :``}

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;

  ${({ isDesktop, theme }) => isDesktop ? `
    background-color: ${theme.primaryColor.level1};
    padding: 100px 5%;
    border-radius: 5px;
  ` : ``};

   @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 100px 10px;
    }
   /* @media (max-width: 580px) {
      width: 400px;
      padding: 100px 10px;
    }
   @media (max-width: 440px) {
      width: 300px;
      padding: 30px 10px;
    } */
`;

const FaceBookWrapper = styled.div`
  margin: 10px 0;
  button {
    height: 50px;
    width: 330px;
    padding: 10px 15px;
    border-radius: 5px !important;

     @media (max-width: 768px) {
      height: 50px;
      width: 330px
    }

   @media (max-width: 580px) {
      height: 42px;
      width: 280px
    }


  }
`;

const GoogleWrapper = styled.div`
  margin: 10px 0;

  button {
    height: 50px;
    width: 330px;
    padding: 10px 15px;
    border-radius: 5px !important;
    justify-content: center;

   @media (max-width: 768px) {
      height: 50px;
      width: 330px
    }

   @media (max-width: 580px) {
      height: 42px;
      width: 280px
    }
  }
`;

const AuthButton = styled(Button)`
  width: 300px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px 20px !important;
  position: relative;
  text-transform: capitalize;
  @media (max-width: 440px) {
    width: 275px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
  }
`;


const PhoneAuthButton = styled(Button)`
  height: 50px !important;
  width: 300px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px 20px !important;
  background-color: ${primaryColor.level1} !important;
  color: ${white} !important;
  position: relative;

 @media (max-width: 768px) {
      height: 50px !important;
      width: 300px !important;
    }

   @media (max-width: 580px) {
      height: 42px !important;
      width: 300px !important;
    }

  i {
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }

  @media (max-width: 440px) {
    width: 275px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
  }
`;

const AuthButtonIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
`;

const PhoneButtonWrapper = styled.div`
  margin: 10px 0;
`;

const PageTitle = styled.div`
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: Metropolis-Bold, sans-serif;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 1.3;
  }
  @media (max-width: 580px) {
    font-size: 22px;
    line-height: 1.3;
  }
`;

const PageSubTitle = styled.div`
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  color: ${grey.level0};

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 15px;
  }

   @media (max-width: 580px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;


const ModalContent = styled(Modal.Content)`
  padding: 15px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
`;

const PhoneInputWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  input {
    width: 100%;
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }
`;

const LabelText = styled.label`
  font-size: 14px;
  color: ${grey.level1};
  display: inline-block;
  margin-bottom: 5px;
`;

const OtpWrapper = styled.div`
  margin-top: 25px;
  width: 90%;
  .ui.input,
  input {
    width: 100%;
  }
`;

const GroupButtonWrapper = styled.div`
  width: 90%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SaveButton = styled(Button)`
  background-color: ${primaryColor.level1} !important;
  color: ${white} !important;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${primaryColor.level1};
`;

const ResendOtpWrapper = styled.div`
  text-align: right;

  span {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
  }
  .countDown {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    color: ${grey.level2};
  }
`;


const PrivacyPolicyText = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: ${grey.level3};
  /* font-weight: 700; */
  width: 80%;
  margin-top: 15px;

  a {
    color: ${grey.level3};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${grey.level3};
    }
  }
  span {
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
  width: 25px;

  @media (max-width: 768px) {
    width: 25px;
    top: 10px;
    right: 20px;
  }
`;

const SocialLoginError = styled.div`
  color: #D8000C;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const AccountRedirectText = styled.div`
  font-size: 16px;
  color: ${grey.level2};
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  span {
    color: ${grey.level4};
    font-weight: 700;
    margin-left: 4px;
    cursor: pointer;
  }
`;
const OtpInputField = styled(Input)`
  input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
`;
/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'SET_PHONE_SIGNUP_MODAL':
        draft.phoneSignupModal = action.value;
        if(!action.value) {
          draft.showOtpField = false;
          draft.phoneNumberValue = '';
          draft.countryCode = 91;
          draft.phoneNumber = '';
          draft.otpValue =  '';
          draft.phoneNumberError = '';
          draft.otpError = '';
        }
        break;
      case 'SET_SHOW_OTP_FIELD':
        draft.showOtpField = action.value;
        draft.phoneNumberError = action.value;
        draft.otpLoader = false;
        break;
      case 'SET_PHONE_NUMBER':
        draft.phoneNumberValue = action.value;
        draft.phoneNumber = action.phoneNumber;
        draft.countryCode = action.countryCode;
        draft.phoneNumberError = null;
        draft.showOtpField = false;
        draft.otpValue = '';
        break;
      case 'SET_OTP_VALUE':
        draft.otpValue = action.value;
        break;
      case 'SET_PHONE_NUMBER_ERROR':
        draft.phoneNumberError = action.value;
        draft.otpLoader = false;
        break;
      case 'SET_OTP_ERROR':
        draft.otpError = action.value;
        break;
      case 'SET_SOCIAL_LOGIN_ERROR':
        draft.socialLoginError = action.value;
        break;
      case 'SET_OTP_LOADER':
        draft.otpLoader = action.value;
        break;
      case 'SET_OTP_COUNT_DOWN':
        if(action.value) {
          draft.otpCountDown = action.value;
        }

        if (action.minus) {
          draft.otpCountDown = draft.otpCountDown - 1;
        }
        break;
      case 'SET_SIGN_UP_MODAL':
        draft.showSignUp = action.value;
        break;
    }
  });

const initialState = {
  phoneSignupModal: false,
  showOtpField: false,
  phoneNumberValue: '',
  countryCode: 91,
  phoneNumber: '',
  otpValue: '',
  phoneNumberError: '',
  otpError: '',
  socialLoginError: null,
  otpLoader: false,
  otpCountDown: 30,
  showSignUp: false,
}
const LoginPage = ({ isDesktop, onCloseLoginModal, onLogin, openLoginModal, theme, setTheme }) => {
console.log("🚀 ~ file: index.js ~ line 447 ~ LoginPage ~ theme", theme)
  const [state, dispatch] = useReducer(reducer, initialState);
  const loginModalRef = useRef(null);
  const resendOtpCountIntervalRef = useRef(null);
  const { t: translation } = useTranslation();
  // const history = useHistory();
  const {
    phoneSignupModal,
    phoneNumberValue,
    showOtpField,
    phoneNumber,
    countryCode,
    otpValue,
    phoneNumberError,
    otpError,
    socialLoginError,
    otpLoader,
    otpCountDown,
    showSignUp
  } = state;

  const handleKeyUp = useCallback(
    event => {
      const keys = {
        27: () => {
          event.preventDefault();
          onCloseLoginModal();
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };
      if (keys[event.keyCode]) {
        keys[event.keyCode]();
      }
    },
    [onCloseLoginModal],
  );


  useEffect(() => {
    if (openLoginModal) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('keyup', handleKeyUp, false);
    }

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.body.style.overflow = null;
    };
  }, [handleKeyUp, openLoginModal]);
  
  const startOtpCountDown = () => {
    clearInterval(resendOtpCountIntervalRef.current);
    resendOtpCountIntervalRef.current = null;
    dispatch({ type: 'SET_OTP_COUNT_DOWN', value: 30 });
    let count = 30;
    resendOtpCountIntervalRef.current = setInterval(() => {
      count--;
      dispatch({ type: 'SET_OTP_COUNT_DOWN', minus: true  });
      if(count <= 0) {
        clearInterval(resendOtpCountIntervalRef.current);
        resendOtpCountIntervalRef.current = null;
      }
    }, 1000);

  }
  const onSavePhoneModal = (resendOtp) => {
    dispatch({ type: 'SET_OTP_LOADER', value: true });
    dispatch({ type: 'SET_OTP_ERROR', value: null });
    if(!otpLoader) {
      if (showOtpField && !resendOtp) {
        verifyPhoneOtp({
          phone: phoneNumber,
          country_code: countryCode,
          otp: otpValue,
        }).then(response => {

          dispatch({ type: 'SET_OTP_LOADER', value: false });
          ToastsStore.success("Logged in successfully.");
          localStorage.setItem('Guest-UUID', response.data.guest_uuid);
          const userData  = response.data;
          userData.login_type = 'phone';
          localStorage.removeItem('privilege');
          if (userData.is_subscriber) {
            localStorage.setItem('subscribed', userData.is_subscriber);
          }
          localStorage.setItem('userData', JSON.stringify(userData));
          const userProperties = {
            name: userData.name,
            phone: userData.phone,
            uuid: userData.guest_uuid,
            login_type: 'phone',
          }
          setUserProperties(userProperties, true)
          setAxiosAuthorizationHeader();
          if (onLogin) {
            onLogin(true);
          }
          onCloseLoginModal();
        })
          .catch(error => {
          
            dispatch({ type: 'SET_OTP_LOADER', value: false });
            if (error.response && error.response.status === 400) {
              dispatch({ type: 'SET_OTP_ERROR', value: (error.response && error.response.data && error.response.data.msg) || 'Something went wrong' })
            } else {
              dispatch({ type: 'SET_OTP_ERROR', value: (error.response && error.response.data && error.response.data.msg) || 'Something went wrong' })
            }

            if (window && window.Raven) window.Raven.captureException(error.response);
          })
      } else {
        // eslint-disable-next-line no-useless-escape
        const regx = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/g;
        if (phoneNumber && phoneNumber.trim() !== '' && regx.test(`+${countryCode}${phoneNumber}`)) {
          sendPhoneOtp({
            phone: phoneNumber,
            country_code: countryCode
          }).then(response => {
            ToastsStore.success("Sent OTP successfully.");
            startOtpCountDown();
            dispatch({ type: 'SET_SHOW_OTP_FIELD', value: true });
          })
            .catch(error => {
              if (window && window.Raven) window.Raven.captureException(error.response);
              if (error.response && error.response.status === 400) {
                dispatch({ type: 'SET_PHONE_NUMBER_ERROR', value: 'Something went wrong!'});
              } else {
                dispatch({ type: 'SET_PHONE_NUMBER_ERROR', value: 'Something went wrong!' })
              }
            })
        } else {
          dispatch({ type: 'SET_PHONE_NUMBER_ERROR', value: 'Enter valid phone number.' })
        }
      }
    }
  }

  useEffect(() => {
    if(socialLoginError) {
      setTimeout(() => {
        dispatch({ type: 'SET_SOCIAL_LOGIN_ERROR', value: null });
      }, 5000);
    }
  }, [socialLoginError]);
  return (
    <>
      {!isDesktop  && (
        <PageHeader theme={theme}
        setTheme={setTheme}/>
      )}
      <ToastsContainer
        position={ToastsContainerPosition.BOTTOM_LEFT}
        store={ToastsStore}
      />
      <Container
        isDesktop={isDesktop}
        theme={theme}
        onClick={() => {
          loginModalRef.current = null;
          onCloseLoginModal();
        }}
      >
        {/* <OutsideClick
          elementRef={loginModalRef}
          handleOutsideClick={() => {
            loginModalRef.current = null;
            onCloseLoginModal();
          }}
          activeOutsideClick={true}
        /> */}
        <ContentWrapper 
          onClick={e => e.stopPropagation()}
          ref={loginModalRef} 
          isDesktop={isDesktop}
        >
          <CloseIcon
            onClick={() => {
              if (onCloseLoginModal) {
                onCloseLoginModal();
              }
            }}
          >
            {/* <Icon name="close"  size="large" /> */}
            <Image src={closeIcon} />
          </CloseIcon>
          <PageTitle>{showSignUp ? translation("loginModal.sigupTitle") : translation("loginModal.loginTitle")}</PageTitle>
          <PageSubTitle>
            {showSignUp ? translation("loginModal.sigupSubTitle", { varientTitle }) : translation("loginModal.loginSubTitle")}
          </PageSubTitle>
          <FaceBookWrapper>
            <FacebookLogin
              isMobile={false}
              // appId="294690778184600" //myAppID
              appId={facebookId} //original id
              // autoLoad={true}
              fields="name,email,picture"
              // onClick={props => console.log(props, 'facebook Success')}
              callback={props => {
                socialLogin({
                  token_access: props.accessToken,
                  type: 'facebook'
                }).then(response => {
                  ToastsStore.success("Logged in successfully.");
                  localStorage.setItem('Guest-UUID', response.data.guest_uuid);
                  let dashboardAccess = response.data.dashboard_access;
                  if (dashboardAccess && dashboardAccess.roles && dashboardAccess.roles.length) {
                    let privilege = dashboardAccess.roles.find(role => role.name === "Super User");
                    localStorage.removeItem('privilege');
                    if (privilege && privilege.name) {
                      localStorage.setItem('privilege', privilege.name);
                    }
                  }
                  const userData = response.data;
                  
                  userData.login_type = 'facebook';
                  if (userData.is_subscriber) {
                    localStorage.setItem('subscribed', userData.is_subscriber);
                  }
                  localStorage.setItem('userData', JSON.stringify(userData));
                  const userProperties = {
                    name: userData.name,
                    email: userData.email,
                    phone: userData.phone,
                    uuid: userData.guest_uuid,
                    login_type: 'phone',
                  }
                  setUserProperties(userProperties, true);
                  setAxiosAuthorizationHeader();
                  if (onLogin) {
                    onLogin(true)
                  }
                  onCloseLoginModal();
                })
                  .catch(error => {
                    if (window && window.Raven) window.Raven.captureException(error.response);
                    dispatch({ type: 'SET_SOCIAL_LOGIN_ERROR', value: "Something went wrong!"});
                  });
              }}
              icon="fa-facebook"
              render={renderProps => (
                <AuthButton
                  onClick={renderProps.onClick}
                  color='facebook'>
                  <AuthButtonIconWrapper>
                    <Icon name='facebook' size="large" />
                  </AuthButtonIconWrapper>{translation("loginModal.facebookText")}</AuthButton>
              )}
            />
          </FaceBookWrapper>
          <GoogleWrapper>
            <GoogleLogin
              render={renderProps => (
                <AuthButton 
                  onClick={renderProps.onClick}
                  color='google plus'>
                  <AuthButtonIconWrapper>
                    <Icon name='google' size="large"/>
                  </AuthButtonIconWrapper>
                  {translation("loginModal.googleText")}
                </AuthButton>
              )}
              // clientId="1060369717378-50vlljp24oo9dvhl39dccv403foqd9je.apps.googleusercontent.com"
              clientId={googleId}
              buttonText="CONTINUE WITH GOOGLE "
              onSuccess={props => {
                socialLogin({
                  token_access: props.tokenId,
                  type: 'google'
                }).then(response => {
                  ToastsStore.success("Logged in successfully.");
                  const userData = response.data;
                  userData.login_type = 'google';
                  console.log("userData.is_subscriber", userData, userData.is_subscriber)
                  if (userData.is_subscriber) {
                    localStorage.setItem('subscribed', userData.is_subscriber);
                  }
                  localStorage.setItem('userData', JSON.stringify(userData));
                  localStorage.setItem('Guest-UUID', response.data.guest_uuid);
                  let dashboardAccess = response.data.dashboard_access;
                  console.log('dashboardAccess', dashboardAccess)
                  if (dashboardAccess && dashboardAccess.roles && dashboardAccess.roles.length) {
                    let privilege = dashboardAccess.roles.find(role => role.name === "Super User");
                    localStorage.removeItem('privilege');
                    if (privilege && privilege.name)
                      localStorage.setItem('privilege', privilege.name);
                  }
                  const userProperties = {
                    name: userData.name,
                    email: userData.email,
                    phone: userData.phone,
                    uuid: userData.guest_uuid,
                    login_type: 'phone',
                  }
                  setUserProperties(userProperties, true)
                  setAxiosAuthorizationHeader();
                  if (onLogin) {
                    onLogin(true)
                  }
                  onCloseLoginModal();
                })
                .catch(error => {
                  if (window && window.Raven) window.Raven.captureException(error.response);
                  dispatch({ type: 'SET_SOCIAL_LOGIN_ERROR', value: "Something went wrong!" });
                });
                }}
              onFailure={error => console.log(error, 'Google Error')}
              cookiePolicy={'single_host_origin'}
            />
          </GoogleWrapper>
          {/* <AppleLogin
            clientId="web.in.travelnewsdigest"
            redirectURI="localhost:3000/redirect" 
          /> */}
          <PhoneButtonWrapper>
            <PhoneAuthButton
              onClick={() => dispatch({ type: 'SET_PHONE_SIGNUP_MODAL', value: true })}
            >
              <AuthButtonIconWrapper>
                <Icon name='phone' size="large" />
              </AuthButtonIconWrapper>
              {translation("loginModal.phoneText")}
            </PhoneAuthButton>
          </PhoneButtonWrapper>
          <AccountRedirectText>
            {showSignUp ? translation("loginModal.alreadyHaveAccText") : translation("loginModal.NoAccount")}
            <span
              onClick={() => {
                dispatch({ type: 'SET_SIGN_UP_MODAL', value: !showSignUp})
              }}
            >{showSignUp ? translation("loginModal.signinText") : translation("loginModal.createOneText")}</span>
          </AccountRedirectText>
          {socialLoginError && (
            <SocialLoginError>{socialLoginError}</SocialLoginError>
          )}
          <PrivacyPolicyText>
            {translation("loginModal.privacyPolicyText.0")} <Link to="/terms" target="_blank">{translation("loginModal.privacyPolicyText.1")}</Link> {translation("loginModal.privacyPolicyText.2")} <Link to="/privacy" target="_blank">{translation("loginModal.privacyPolicyText.3")}</Link>
          </PrivacyPolicyText>
        </ContentWrapper>
      </Container>
      <Modal
        open={phoneSignupModal}
        onClose={() => dispatch({ type: 'SET_PHONE_SIGNUP_MODAL', value: false })}
        size="small"
      >
        <Modal.Header>{translation("loginModal.phoneSignup.title")}</Modal.Header>
        <ModalContent>
          <PhoneInputWrapper>
            <LabelText>{translation("loginModal.phoneSignup.labelText")}</LabelText>
            <PhoneInput
              country={'in'}
              value={phoneNumberValue}
              onKeyDown={e => {
                if(e.keyCode === 13) {
                  e.preventDefault();
                  onSavePhoneModal();
                }
              }}
              onChange={(value, data, e ) => {
                if (value) {
                  dispatch({
                    type: 'SET_PHONE_NUMBER',
                    value,
                    phoneNumber: value.replace(/[^0-9]+/g, '').slice(data.dialCode.length),
                    countryCode: data.dialCode
                  });
                }
              }}
            />
            {phoneNumberError && <ErrorMessage>{phoneNumberError}</ErrorMessage>}
          </PhoneInputWrapper>
          {showOtpField && (
            <OtpWrapper>
              <LabelText>{translation("loginModal.phoneSignup.enterOtp")}</LabelText>
              <OtpInputField
                value={otpValue}
                type="number"
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    onSavePhoneModal();
                  }
                }}
                onChange={e => dispatch({ type: 'SET_OTP_VALUE', value: e.target.value })}
              />

              <ResendOtpWrapper>
                {otpCountDown > 0 ? (
                  <div className="countDown">
                    {`${otpCountDown} Sec`}
                  </div>
                ): (
                  <span
                    onClick={onSavePhoneModal}
                  >{translation("loginModal.phoneSignup.resendOtp")}</span>
                )}
              </ResendOtpWrapper>
              {otpError && <ErrorMessage>{otpError}</ErrorMessage>}
            </OtpWrapper>
          )}
          <GroupButtonWrapper>
            <Button
              onClick={() => dispatch({ type: 'SET_PHONE_SIGNUP_MODAL', value: false })}
            >{translation("loginModal.phoneSignup.cancelButtonText")}</Button>
            <SaveButton
              onClick={() => onSavePhoneModal()}
              disabled={otpLoader || (showOtpField && (otpValue.trim() === "" || otpValue.trim().length < 4))}
            >{showOtpField ? translation("loginModal.phoneSignup.loginButtonText") : translation("loginModal.phoneSignup.sendOtpText")}</SaveButton>
          </GroupButtonWrapper>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginPage;