import React from 'react';
import styled from 'styled-components';
import { white } from 'colorPalette';

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 20px;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  background-color: #5a5a5a;
  color: ${white};
  border-radius: 5px;
`;

const Snackbar = ({ message }) => (
  <Container>
    <Wrapper>
      {message}
    </Wrapper>
  </Container>
);

export default Snackbar;