import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
 } from 'react-share';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import mailIcon from './mail.svg';

// import { white } from 'colorPalette';
import OutsideClick from 'Components/OutsideClick';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import { varientTitle, variantMarketing } from 'global';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: ${({ isDesktop }) => isDesktop ? 0 : 'auto'};
  bottom: ${({ isDesktop, isSearchShare }) => isDesktop ? 0 : isSearchShare ? 0 : 'auto'};
  height: ${({ isDesktop, isSearchShare }) => isDesktop ? 'auto' : isSearchShare ? '100vh' : '100vh'};
  background: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  justify-content: ${({ isDesktop }) => isDesktop ? 'center' : 'flex-end'};
  align-items: ${({ isDesktop }) => isDesktop ? 'center' : 'flex-start'};
`;

const Container = styled.div`
  width: ${({ isDesktop }) => isDesktop ? 40 :  100}%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background-color: ${props => props.theme.primaryColor.level1};
  justify-content: space-between;
  /* box-shadow: 0 0 10px 0 #d8d8d8; */
  border-radius: 5px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const EmailIconWrapper = styled.div`
  padding: 10px;
  background-color: #34373C;
`;

const ShareButtonWrapper = styled.a`
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  button {
    outline: none;
  }
  button,
  svg {
    width: 50px !important;
    height: 50px !important;
  }
`;
const ShareDialogBox = ({ 
  open,
  onClose,
  shareUrl,
  title,
  isDesktop,
  data,
  isSearchShare,
  shareText
}) => {
  const shareDialogRef = useRef(null);
  const { t: translation } = useTranslation();
  useEffect(() => {
    if (data && data.type) {
      logAmplitudeEvent('Article Shared', {
        article_id: data.id, article_type: data.type && data.type.code,
        slug: data.slug,
        category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
      });
    }
  }, [data])
  // const text = `Check out this story: ${data && data.title}. ${shareUrl} from ${varientTitle} - ${variantMarketing}`;
  const text = shareText || translation(
    'digestFeed.shareText', 
    { 
      title: data && data.title,
      shareUrl,
      varientTitle,
      variantMarketing
    });
  return (
    <>
      {open && (
        <Wrapper
          isDesktop={isDesktop}
          isSearchShare={isSearchShare}
        >
          <OutsideClick 
            elementRef={shareDialogRef}
            handleOutsideClick={() => {
              shareDialogRef.current = null;
              onClose();
            }}
            activeOutsideClick={open}
          />
          <Container
            ref={shareDialogRef}
            isDesktop={isDesktop}
          >
              <ShareButtonWrapper
              href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&body=${text}`}
              target="_blank"
              >
                {/* <EmailIcon /> */}
                <EmailIconWrapper>
                  <Image src={mailIcon} />
                </EmailIconWrapper>
              </ShareButtonWrapper>
              <ShareButtonWrapper>
                <FacebookShareButton
                  url={shareUrl}
                  quote={text}
                >
                  <FacebookIcon />
                </FacebookShareButton>
              </ShareButtonWrapper>
              <ShareButtonWrapper>
                <LinkedinShareButton
                  url={shareUrl}
                  title={title}
                  summary={text}
                  source={shareUrl}
                >
                  <LinkedinIcon />
                </LinkedinShareButton>
              </ShareButtonWrapper>
              <ShareButtonWrapper>
                <RedditShareButton
                  url={shareUrl}
                  title={text}
                >
                  <RedditIcon />
                </RedditShareButton>
              </ShareButtonWrapper>
              <ShareButtonWrapper>
                <TelegramShareButton
                  url=" "
                  title={text}
                >
                  <TelegramIcon />
                </TelegramShareButton>
              </ShareButtonWrapper>
              <ShareButtonWrapper>
                <TwitterShareButton
                  url="  "
                  title={text}
                >
                  <TwitterIcon />
                </TwitterShareButton>
              </ShareButtonWrapper>
              <ShareButtonWrapper>
                <WhatsappShareButton
                  url=" "
                  title={text}
                  // separator="#"
                >
                  <WhatsappIcon />
                </WhatsappShareButton>
              </ShareButtonWrapper>
            {/* ))} */}
          </Container>
        </Wrapper>
      )}
    </>
  );
}

export default ShareDialogBox;