import React from 'react';
import './App.css';
import Main from './Container';
import 'semantic-ui-css/semantic.min.css';
import loadYtApi, { loadFontFamily, loadAnalyticsApi, loadOnesignal } from './Utils/LoadYtApi';
import loadFontsAsync from './Utils/loadFontsAsync';
function App() {
  loadYtApi();
  loadFontFamily();
  loadAnalyticsApi();
  window.WebFontConfig = {
    google: {
      families: ['Lato:300,400,700:latin-ext', 'Open+Sans:wght@300', 'Crimson+Text:wght@300', 'PT+Serif:wght@400,700','Merriweather:wght@300'],
    },
  };
  loadFontsAsync(document);
  loadOnesignal();
  return (
    <Main />
  );
}

export default App;
