import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

const Wrapper = styled.div``;

const ItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 15px 0;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;
const ArticleContentWrapper = styled.div`
  margin-left: 20px;
  flex: 1;
`;

const ItemTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
  color: #343434;
  font-family: 'Metropolis', sans-serif;
`;

const PublicationsSuggestions = ({ data, onClickItem }) => (
  <Wrapper>
    {data.map(item => (
      <ItemWrapper
        key={item.id}
        onClick={() => {
          onClickItem({ text: item.brand_name })
        }}
      >
        <IconWrapper>
          <Image src={item.logo_link} />
        </IconWrapper>
        <ArticleContentWrapper>
          <ItemTitle>{item.brand_name}</ItemTitle>
        </ArticleContentWrapper>
      </ItemWrapper>
    ))}
  </Wrapper>
);

export default PublicationsSuggestions;