import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { DigestCreatedByInfo } from '../CommonComponents';
import PublicationInfo from './PublicationInfo';
import DigestStreamShimmer from './DigestStreamShimmer';
import { fetchTagsDetail } from 'Api';
import DigestActions from '../DigestActions';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import RelatedDigests from '../DesktopDigestItem/RelatedDigests';

const Container = styled.div`
  background-color: #fff;
`;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Content = styled.div`
  width: 60%;
  max-width: 750px;
  margin: 0 auto;
  font-family: 'Metropolis', sans-serif;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
  
  @media (max-width: 580px) {
    width: 100%;
  }
`;

const ArticleItem = styled(Link)`
  display: block;
  border-radius: 10px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  padding: 15px 20px;
  display: flex;
  margin-bottom: 15px;
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 580px) {
    display: block;
    width: 95%;
    margin: 0 auto 15px;
  }

  @media (max-width: 580px) {
    padding: 15px 0 0;
  }
`;

const ArticleItemContent = styled.div`
  width: 65%;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 580px) {
    width: 100%;
    padding-right: 0;
  }
`;
const ArticleImageWrapper = styled.div`
  width: 35%;

  img {
    width: 100%;
    border-radius: 4px;
  }
`;

const ArticleTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 580px) {
    width: 65%;
    padding-right: 10px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const MobileViewArticleTitle = styled.div`
  display: flex;
  padding: 0 15px;
`;

const PublicationActionWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  @media (max-width: 580px) {
    display: block;
  }
`;

const PublicationWrapper = styled.div`
  width: calc(100% - 110px);
  @media (max-width: 580px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const ArticleShareItems = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-end;
    margin-top: 10px;
  }

  @media (max-width: 580px) {
    border-top: 1px solid #e1e1e1;
    padding: 10px 15px;
    /* display: block; */
  }
`;


const TagDetailView = styled.div`
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  margin: 30px 0;
  @media (max-width: 580px) {
    margin: 0 0 20px 0;
  }
`;

const TagDetailContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to bottom,rgba(0,0,0,0) 52%,rgba(0,0,0,0.5) 83%);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 580px) {
    padding: 15px;
  }
`;

const TagDetailContentWrapper = styled.div`
  padding: 20px;
  border-left: 4px solid #e02020;
  @media (max-width: 580px) {
    padding: 10px 15px;
  }
`;

const TagDetailTitle = styled.div`
  font-family: 'PT Serif', Georgia, serif;
  font-size: 40px;
  line-height: 1.3;
  text-shadow: 1px 1px rgba(0,0,0,0.5);
  color: #ffffff;


  @media (max-width: 580px) {
    font-size: 30px;
  }
`;

const DigestCreatedByInfoWrapper = styled.div`
  @media (max-width: 580px) {
    padding: 0 15px;
  }
`;

const NoDataFoundText = styled.div`
  font-size: 18px;
  font-weight: 900;
  color: #929292;
  margin-top: 100px;
  text-align: center;
`;

const RelatedDigestsWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  max-width: 1200px;
  @media(max-width: 1024px) {
    width: 90%;
  }

  /* @media(max-width: 900px) {
    width: 85%;
  } */

  @media(max-width: 768px) {
    width: calc(100% - 80px);
  }
  
  @media(max-width: 580px) {
    width: 100%;
  }
`;

const DigestStreamView = ({ 
  data,
  onDigestReadLater,
  onDigestBookMark,
  onClickShare,
  loadVideoDigest,
  tagDetail
}) => {
  const [tagData, setTagData] = useState(tagDetail);
  const [loadingTagData, setLoadingTagData] = useState(false);

  useEffect(() => {
    if (tagData && !tagData.cover_image && !loadingTagData) {
      setLoadingTagData(true);
      fetchTagsDetail({ tagSlug: tagDetail.slug }).then(response => {
        setTagData(response.data);
        setLoadingTagData(false);
      })
      .catch(error => console.log(error))
    } else if (tagData && tagData.cover_image && loadingTagData) {
      setLoadingTagData(false);
    }
  }, [loadingTagData, tagData, tagDetail.slug]);
  
  return (
    <Container>
      <Wrapper>
        <Content>
          {!loadingTagData && (
            <TagDetailView>
              <Image src={tagData.cover_image} />
              <TagDetailContentContainer>
                <TagDetailContentWrapper>
                  <TagDetailTitle>{tagData.title}</TagDetailTitle>
                </TagDetailContentWrapper>
              </TagDetailContentContainer>
            </TagDetailView>
          )}
          {loadVideoDigest && data && data.length === 0 && (
            <DigestStreamShimmer />
          )}
          {!loadVideoDigest && data && data.length === 0 && (
            <NoDataFoundText>No Data</NoDataFoundText>
          )}
          {data && data.length > 0 && data.map(item => (
            <ArticleItem
              to={`/digests/${item.slug}`}
              onClick={() => {
                logAmplitudeEvent('StreamDigest Clicked',{
                  digest_id: item.id,
                  stream_slug: tagDetail.slug,
                  slug: item.slug,
                  category_name: item && item.categories ? item.categories.map(item => item.title).join(',') : ''
                })
                window.scroll(0, 0);
              }}
            >
              <ArticleItemContent>
                <div>
                  {window.innerWidth > 580 ? (
                    <ArticleTitle>{item.title}</ArticleTitle>
                  ) : (
                    <MobileViewArticleTitle>
                      <ArticleTitle>{item.title}</ArticleTitle>
                      <ArticleImageWrapper>
                        <Image src={item.cover_pic} />
                      </ArticleImageWrapper>
                    </MobileViewArticleTitle>
                  )}
                  <DigestCreatedByInfoWrapper>
                    <DigestCreatedByInfo data={item} />
                  </DigestCreatedByInfoWrapper>
                </div>
                <PublicationActionWrapper>
                  <PublicationWrapper>
                    <PublicationInfo data={item} />
                  </PublicationWrapper>
                  <ArticleShareItems>
                    <DigestActions 
                      data={item}
                      onDigestReadLater={onDigestReadLater}
                      onDigestBookMark={onDigestBookMark}
                      onClickShare={onClickShare}
                    />
                  </ArticleShareItems>
                </PublicationActionWrapper>
              </ArticleItemContent>
              {window.innerWidth > 580 && (
                <ArticleImageWrapper>
                  <Image src={item.cover_pic}/>
                </ArticleImageWrapper>
              )}
            </ArticleItem>
          ))}
        </Content>
        <RelatedDigestsWrapper>
          <RelatedDigests 
            articleData={data}
            data={(data && data[0] && data[0].related_articles) || []}
            onDigestReadLater={onDigestReadLater}
            digestStream={true}
          />
        </RelatedDigestsWrapper>
        {loadVideoDigest && data && data.length > 0 && (
          <DigestStreamShimmer innerLoader/>
        )}
      </Wrapper>
    </Container>
  )
};

export default DigestStreamView;