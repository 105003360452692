import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import { addNewsSubscription } from 'Api';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

const Wrapper = styled.div`
  position: relative;
`;

const SubmitButton = styled.div`
  font-family: GothamBookRegular;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: #e02020;
  padding: 18px 25px;
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 13px 20px;
  }
  
  @media (max-width: 768px) {
    padding: 12px 20px;
  }
  
  @media (max-width: 580px) {
    padding: 15px 20px;
    font-size: 14px;
    line-height: 14px;
    position: static;
    margin-top: 10px;
    width: 100%;
  }
`;

const InputField = styled(Input)`
  width: 100%;

  input {
    padding: 12px 170px 12px 15px !important;
    border-radius: 5px !important;
    border: solid 1px #d0d0d0 !important;
    background-color: #f2f2f2 !important;
    font-family: 'Metropolis', sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px !important;
    color: rgba(70, 70, 70, 0.37);

    &::placeholder {
      font-style: italic;
    }

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 24px !important;
      padding: 8px 170px 8px 10px !important;
    }
    
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px !important;
      padding: 8px 170px 8px 10px !important;
    }

    @media (max-width: 580px) {
      padding: 8px 10px 8px 10px !important;
    }
    }
`;

const EmailError = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #e02020;
  text-align: left;
  margin-top: 5px;
`;

const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regx.test(email)) {
    return true;
  }
  return false;
}


const SubscribeInputField = ({
  onSubscribeSuccess,
  isSubscribe,
  expTestingKey
}) => {
  const [emailError, setEmailError] = useState(null);
  const [emailValue, setEmailValue] = useState('');

  const handleUserSubscribtion = () => {
    logAmplitudeEvent("Subscribed Button Clicked - Newsletter LP",
                      {
                        email: emailValue,
                        exp_version: expTestingKey
                      })
    if (!isSubscribe) {
      const validatedEmail = validateEmail(emailValue);
      if (validatedEmail) {
        addNewsSubscription({ email: emailValue }).then(response => {
          setEmailError(null);
          setEmailValue(null);
          onSubscribeSuccess(true);
          ToastsStore.success('Thanks for subscribing!');
          localStorage.setItem('subscribed', true);
        }).catch(error => {
          console.log("SubscribeToNewsLetterFull -> error", error.response)
          if (error.response && error.response.status === 400 && error.response.data && error.response.data.detail) {
            setEmailError(error.response.data.detail);
          }

          if (window && window.Raven) window.Raven.captureException(error.response);
        });
      } else {
        setEmailError('Please enter a valid email');
      }
    }
  };

  return (
    <Wrapper>
      <ToastsContainer
        position={ToastsContainerPosition.BOTTOM_LEFT}
        store={ToastsStore}
      />
      <InputField
        disabled={isSubscribe}
        onKeyDown={e => {
          if(e.which === 13) {
            e.preventDefault();
            handleUserSubscribtion()
          }
        }}
        onChange={e => {
          setEmailValue(e.target.value);
          if(emailError) {
            setEmailError(null);
          }
        }}
        placeholder="Enter your email address"
      />
      {emailError && (
        <EmailError>{emailError}</EmailError>
      )}
      <SubmitButton
        onClick={handleUserSubscribtion}
      >{isSubscribe ? 'subscribed' : 'subscribe'}</SubmitButton>
    </Wrapper>
  )
};

export default SubscribeInputField;