import React, {  useState } from "react";
import styled from "styled-components";
//import styles from "./Testimonial.module.css";
import { users } from "Utils/users";
import QuoteContainer from "Components/QuoteContainer";
import ImageContainer from "Components/ImageContainer";

import "../../Utils/main.css";
// const TESTIMONIAL_DELAY = 3000;
const Wrapper = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #000;
 
`;
// const Container = styled.div`
//   width: 100%;
// `;
const HeadingWrapper = styled.div`
  max-width: 800px;
  margin: 25px auto 25px auto;
  text-align: center;
  h2 {
    font-family: Metropolis;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 10px;
    @media (max-width: 992px) {
      font-size: 36px;
      line-height: 40px;
    }
    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  P {
    opacity: 0.6;
    font-family: Metropolis;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
`;
// const ContentWrapper = styled.div`
//   font-family: Metropolis;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-around;
// `;
// const Block = styled.div`
//   display: inline-block;
//   font-size: 1rem;
//   text-align: center;
//   vertical-align: top;
//   width: 33%;
//   @media (max-width: 992px) {
//     width: 100%;
//     text-align: center;
//   }
//   img {
//     display: inline-block;
//     background-position: -465px 0;
//     background-size: 1000%;
//     height: 80px;
//     width: 120px;
//   }
//   P {
//     font-family: Metropolis;
//     opacity: 0.6;
//     font-size: 16px;
//     line-height: 24px;
//   }
// `;

// const MainTitle = styled.div`
//   color: #369af8;
//   font-size: 18px;
//   font-weight: 700;
//   margin-bottom: 5%;
// `;

// const SubTextDiv = styled.div`
// color: #727272;
// font-size: 14px;
// font-weight: 200;
// line-height: 24px;
// padding: 0 25%;
// }
// `;

const TestimonialSegment = ({ data }) => {
  //const [testimonialData, setTestimonialData]
  const [userSelect, setUserSelect] = useState(0);
  const [animation, setAnimation] = useState(false);
  // console.log('userSelect TESTIMONIAL TEST',userSelect);
  // console.log('animation TESTIMONIAL TEST',animation);
  return (
       <Wrapper>
       
       <HeadingWrapper>
      <h2>What our users say</h2>
    </HeadingWrapper>
  
       <div className="app">
        <QuoteContainer
          quote={users[userSelect].quote}
          name={users[userSelect].name}
         // profession={users[userSelect].profession}
          animation={animation} />
        <ImageContainer
          image={users[userSelect].image}
          userSelect={userSelect}
          setUserSelect={setUserSelect}
          users={users}
          animation={animation}
          setAnimation={setAnimation} />
      </div></Wrapper>
  );
};

export default TestimonialSegment;
