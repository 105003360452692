import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Header, Image } from "semantic-ui-react";
import googlePlayStore from '../../Components/Header/googlePlayStore.png';
import appStore from '../../Components/Header/appstore_icon.svg';


const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    min-height: 90vh;
    border-bottom: 1px solid #000000;
`;

const Wrapper = styled.div`
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 70px 0;

    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
        justify-content: space-around;
    }
`;

const BannerContent = styled.div`
    flex-basis: 40%;
`;

const PageTitle = styled(Header)`
    font-size: 48px;
    font-family: GothamBookRegular;

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 40px;
    }
`;

const HeadingWrapper = styled.div``;

const BannerDesc = styled.div`
    font-size: 24px;
    line-height: 30px;
    margin-top: 22px;

    @media (max-width: 768px) {
        font-size: 21px;
        line-height: 28px;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        margin-top: 30px;
    }
`;

const BannerImage = styled(Image)`
    max-height: 65vh;
    transform: ${({ rotateAng }) => `rotate(${rotateAng}deg)`};
    transform-origin: center;
    @media (max-width: 768px) {
        // height: 55vh
    }
`;

const SignBtnWrapper = styled.p`
    display: flex;
    align-items: flex-start;
    padding-top: 30px;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

// const SignBtn = styled.button`
//     border-radius: 25px;
//     background-color: #000000;
//     color: #ffffff;
//     padding: 8px 35px;
//     font-size: 18px;
//     font-weight: 700;
//     line-height: 28px;
//     font-family: Metropolis;
//     @media (max-width: 768px) {
//         display: none;
//         font-size: 15px;
//         line-height: 24px;
//     }
// `;

const Segment1 = ({ data1 }) => {
	const sectionContainerRef = useRef(null);
	// const [imageRotateDeg, setImageRotateDeg] = useState(0);
    const [imageRotateDeg] = useState(0);
	const handleScroll = (event) => {
		if (window.scrollY < sectionContainerRef.current.offsetHeight) {
			// setImageRotateDeg(15 - window.scrollY/60);
		}
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])
	return (
		<Container ref={sectionContainerRef}>
			<Wrapper>
				<BannerContent>
					<HeadingWrapper>
						<PageTitle as="h2">{data1 && data1.title}</PageTitle>
					</HeadingWrapper>
					<BannerDesc>{data1 && data1.subtitle}</BannerDesc>
					<SignBtnWrapper>
						<a target={'__new'} href={'https://onelink.to/8chy8d'}>
							<Image src={googlePlayStore} height={55} alt="Get it on Google Play" />
						</a>
                        <a style={{marginLeft:'2%'}}  target={'__new'} href={'https://apps.apple.com/in/app/dailybrief-news-briefings/id1559506634'}>
							<Image  src={appStore} height={55} alt="Get it on App Store" />
						</a>
						{/*<SignBtn>Get it on Google Play</SignBtn>*/}
					</SignBtnWrapper>
				</BannerContent>
				<ImageWrapper>
					<BannerImage
						rotateAng={imageRotateDeg}
						src={
							data1 &&
							data1.image
						}
					/>
				</ImageWrapper>
			</Wrapper>
		</Container>
	);
};

export default Segment1;
