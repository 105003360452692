import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import LikeIcon from './Like.svg';
import UnLikeIcon from './Unlike.svg';
import { addUserExperienceDigest } from 'Api';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';


const Wrapper = styled.div`
  padding: 30px 0;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Title = styled.div`
  font-family: 'Metropolis', sans-serif;;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.28;
  text-align: center;
  color: rgba(${props => props.theme.fontColor.level2RGB}, 0.8);
`;

// const SubTitle = styled.div`
//   font-family: 'Metropolis', sans-serif;;
//   font-size: 18px;
//   line-height: 1.28;
//   text-align: center;
//   color: rgba(101, 101, 101, 0.8);
//   margin-top: 10px;
// `;

const IconsWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto 0;

  @media (max-width: 580px) {
    width: 50%;

  }

  @media (max-width: 440px) {
    width: 60%;

  }

`;

const LikeItemWrapper = styled.div`
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px ${({ active }) => active ? '#6dd400' : '#d7d7d7'};
  background-color: ${props => props.theme.primaryColor.level1};
  padding: 17px 15px;
  border-radius: 50%;
  cursor: pointer;
`;

const UnLikeItemWrapper = styled.div`
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px ${({ active }) => active ? '#e02020' : '#d7d7d7'};
  background-color: ${props => props.theme.primaryColor.level1};
  padding: 17px 15px;
  border-radius: 50%;
  cursor: pointer;
`;

const UserExperience = ({ data, digestId, articleData }) => {
  const [actievValue, setActievValue] = useState(null)
  const { t: translation } = useTranslation();
  useEffect(() => {
    if (data.feedback && data.feedback.feedback) {
      setActievValue(data.feedback.feedback);
    }
  }, [data])
  const handleDigestAction = (value) => {
    if (value !== actievValue) {
      logAmplitudeEvent("Feedback Given",{
        article_id:  digestId,
        feedback: value,
        slug: articleData.slug,
        category_name: articleData && articleData.categories ? articleData.categories.map(item => item.title).join(',') : '',
        question_title: (data.feedback && data.feedback.question) || '',
        // question_id: (data.feedback && data.feedback.question && data.feedback.question.id) || ''
      });
    }
    addUserExperienceDigest({ digestId, value }).then(response => {
      if(value === actievValue) {
        setActievValue(null);
      } else {
        setActievValue(value);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }
  return (
    <Wrapper>
      {data && (
        <>
          <Title>{(data.feedback && data.feedback.question) || translation("digestDetail.expirienceTitle")}</Title>
          
          <IconsWrapper>
            <LikeItemWrapper
              active={actievValue === "thumbsup-ing"}
              onClick={() => {
                handleDigestAction("thumbsup-ing")
              }}
            >
              <Image src={LikeIcon} />
            </LikeItemWrapper>
            <UnLikeItemWrapper
              active={actievValue === "down-ing"}
              onClick={() => {
                handleDigestAction("down-ing")
              }}
            >
              <Image src={UnLikeIcon} />
            </UnLikeItemWrapper>
          </IconsWrapper>
        </>
      )}
    </Wrapper>
  );
}

export default UserExperience;