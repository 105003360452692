import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const Wrapper = styled.div`
  padding: 50px;
`;

const ShimmerLoader = () => (
  <ContentLoader
    width="100%"
    height="700px"
    // viewBox="0 0 100% 100%"
  >
    <rect x="0" y="0" rx="5" ry="5" width="90%" height="20" />
    <rect x="0" y="30" rx="4" ry="4" width="90%" height="20" />
    <rect x="0" y="70" rx="4" ry="4" width="60%" height="10" />

    <circle cx="20" cy="130" r="20" />
    <rect x="50" y="115" rx="5" ry="5" width="calc(55% - 70px)" height="10" />
    <rect x="50" y="130" rx="5" ry="5" width="calc(55% - 70px)" height="10" />

    <rect x="0" y="170" rx="4" ry="4" width="100%" height="250" />

    <rect x="0" y="440" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="460" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="480" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="500" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="520" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="540" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="560" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="580" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="600" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="620" rx="4" ry="4" width="100%" height="10" />
  </ContentLoader>
);

const DigestViewShimmer = () => (
  <Wrapper>
    <ShimmerLoader />
  </Wrapper>
);

export default DigestViewShimmer;
