import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const Wrapper = styled.div`
  margin: 30px 0;
`;
const ArticleItemWrapper = styled.div`
  margin: 25px 0;

  @media (max-width: 768px) {
    padding: 15px;
    background-color: #fff;
  }
`;

const ArticleItem = () => (
  <ArticleItemWrapper>
    <ContentLoader
      width="100%"
      height="500px"
      // viewBox="0 0 100% 100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
      <rect x="0" y="40" rx="4" ry="4" width="100%" height="30" />
      <rect x="0" y="90" rx="4" ry="4" width="40%" height="20" />
      <rect x="70%" y="90" rx="4" ry="4" width="30%" height="20" />
      <circle cx="20" cy="140" r="20" />
      <circle cx="45" cy="140" r="20" />
      <circle cx="75" cy="140" r="20" />
      <rect x="110" y="120" rx="4" ry="4" width="30%" height="15" />
      <rect x="110" y="140" rx="4" ry="4" width="30%" height="15" />
      <rect x="0" y="170" rx="4" ry="4" width="100%" height="250" />


      {/* <rect x="0" y="120" rx="4" ry="4" width="55%" height="13" />
      <rect x="0" y="140" rx="4" ry="4" width="55%" height="13" />
      <rect x="0" y="140" rx="4" ry="4" width="55%" height="13" />
      <rect x="0" y="160" rx="4" ry="4" width="55%" height="13" />
      <rect x="0" y="180" rx="4" ry="4" width="55%" height="13" />
      <rect x="0" y="200" rx="4" ry="4" width="55%" height="13" />

      <rect x="70" y="235" rx="5" ry="5" width="calc(55% - 70px)" height="10" />
      <rect x="70" y="250" rx="5" ry="5" width="calc(55% - 70px)" height="10" /> */}
    </ContentLoader>
  </ArticleItemWrapper>
);
const TagsPageShimmer = ({ innerLoader }) => (
  <Wrapper>
    {innerLoader ? (
      <ArticleItem />
    ) : (
        <>
          <ArticleItem />
          <ArticleItem />
          <ArticleItem />
          <ArticleItem />
          <ArticleItem />
          <ArticleItem />
        </>
      )}
  </Wrapper>
);

export default TagsPageShimmer;