import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import {
  isBrowser,
  isTablet,
  isMobile
} from "react-device-detect";
import { useTranslation } from 'react-i18next';

import { grey } from 'colorPalette';
import arrowLeft from './arrow_left.svg';
import arrowRight from './arrow_right.svg';
import DesktopDigestCard from './DesktopDigestCard';
import MobileDigestCard from './MobileDigestCard';
import MobileRelatedDigest from './MobileRelatedDigest';

const Wrapper = styled.div`
  padding: 0 0 50px 0;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const SectionTitleWrapper = styled.div`
  position: relative;
  border: 1px solid ${grey.level5};
  width: 90%;
  margin: 64px auto;

  @media (max-width: 768px) {
    border: none;
    margin: 0;
    width: 100%;
  }
  /* .border {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform-translate
  } */
`;

const SectionTitle = styled.div`
  width: 320px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  color: ${props => props.theme.fontColor.level2};
  background-color: ${props => props.theme.primaryColor.level1};
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: -0.2px;
  font-family: 'PT Serif', Georgia, serif;

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    position: static;
    transform: translate(0, 0);
    text-align: left;
    padding: 10px 0;
    font-size: 28px;
  }

`;

const DigestsListWrapper = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }


  .slick-track {
    display: flex !important;
    padding: 10px 0;
  }

  .slick-slide {
    height: inherit !important;


    @media (max-width: 580px) {
      width: ${window.innerWidth/1.5}px !important;
    }
    
    @media (max-width: 400px) {
      width: ${window.innerWidth/1.2}px !important;
    }
  }
  .slick-slide > div {
    height: 100% !important;
  }
`;

const NextPrevIcon = styled.div`
  cursor: pointer;
  opacity: .7;
  width: 60px;
  height: auto;

  @media (max-width: 1024px) {
    width: 50px;
  }
  &.slick-prev {
    left: -100px;
    @media (max-width: 1024px) {
      left: -50px;
    }
    
    @media (max-width: 768px) {
      left: -40px;
    }
  }
  
  &.slick-next {
    right: -100px;
    @media (max-width: 1024px) {
      right: -50px;
    }
    
    @media (max-width: 768px) {
      right: -40px;
    }
  }
  &:hover {
    opacity: 1;
  }
`;

const DigestCardWrapper = styled.div`
  margin: 0 auto;
  width: 90% !important;
  height: 100%;
  outline: none;
  display: block !important;
`;

const MobileViewWrapper = styled.div`
    display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const DesktopViewWrapper = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }

  .slick-next::before {
    display: none;
  }

  .slick-prev::before {
    display: none;
  }
`;


const NextIcon = ({ onClick, className, currentSlide, slideCount, ...props }) => {
  let count = 3;
  if(window.innerWidth <= 768) {
    count = 2;
  };
  return (
    <>
      {slideCount - count !== currentSlide && (
        <NextPrevIcon
          className={className}
          onClick={onClick}
          prev
        >
          <Image src={arrowRight} />
          {/* <Icon name="angle right"/> */}
        </NextPrevIcon>
      )}
    </>
  );
}
const PrevIcon = ({ onClick, className, currentSlide }) => (
    <>
      {currentSlide > 0 && (
        <NextPrevIcon
          className={className}
          onClick={onClick}>
          <Image src={arrowLeft} />
          {/* <Icon name="angle left"/> */}
        </NextPrevIcon>
      )}
    </>
  );

const RelatedDigests = ({ 
  data,
  articleData,
  onDigestReadLater,
  digestStream,
  onClickShare,
  theme
}) => {
  const history = useHistory();
  const { t: translation } = useTranslation();
  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <NextIcon totalLength={data && data.length}/>,
    prevArrow: <PrevIcon />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          arrows: false,
          variableWidth: true,
        }
      },
    ]
  };
  return (
    <>
      {data && data.length > 0 && (
        <Wrapper>
          <SectionTitleWrapper>
            {/* <span className="border" /> */}
            <SectionTitle>{translation("digestDetail.relatedDigest.sectionTitle")}</SectionTitle>
          </SectionTitleWrapper>
          <DigestsListWrapper>
            {/* {window.innerWidth > 768 ? ( */}
            <DesktopViewWrapper>

                <Slider {...sliderSettings}>
                  {data.map(item => {
                    let digestDescText = item.digest;
                    const charCount = isMobile && !isTablet ? 70 : 150;
                    if (digestDescText && digestDescText.length > charCount) {
                      digestDescText = digestDescText.substring(0, charCount) + '...'
                    }
                    return (
                      <DigestCardWrapper key={item.id}>
                        {(isBrowser || isTablet) && (
                          <DesktopDigestCard 
                            item={item} 
                            digestDescText={digestDescText} 
                            history={history}
                          />
                        )}
                        {isMobile && !isTablet && (
                          <MobileDigestCard 
                            item={item} 
                            digestDescText={digestDescText} 
                            history={history}
                          />
                        )}
                      </DigestCardWrapper>
                    )}
                  )}
                </Slider>
            </DesktopViewWrapper>
            {/* ) : ( */}
              <MobileViewWrapper>
                <MobileRelatedDigest 
                  digestStream={digestStream}
                  onDigestReadLater={onDigestReadLater}
                  articleData={articleData} 
                  data={data} 
                  onClickShare={onClickShare}
                  theme={theme}
                />
              </MobileViewWrapper>
            {/* )} */}
          </DigestsListWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default RelatedDigests;