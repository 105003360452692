import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import showAllIcon from './showAllIcon.svg';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import Icon from 'Icon';
import { Link } from 'react-router-dom';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const Wrapper = styled.div`
  padding: 25px 0 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 0 auto;
    padding: 15px 0 0;
  }
`;
const ShowAllText = styled(Link)`
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  color: #272727;
  display: flex;
  cursor: pointer;

  i {
    color: #4cb09f;
  }

  span {
    margin-left: 4px;
  }
  @media (max-width: 580px) {
    font-size: 11px;
  }
`;

const Title = styled.div`
  font-size: 23px;
  line-height: 1.2;
  color: #272727;
  font-weight: 900;

  @media (max-width: 768px) {
    font-size: 18px;
  }
  
  @media (max-width: 580px) {
    font-size: 16px;
  }
`;

const ArtcilesList  = styled.div`
  overflow-x: auto;
  padding: 20px 0;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
`;

const ArtcilesListContainer  = styled.div`
  display: flex;
  white-space: nowrap;
`;

const ItemWrapper  = styled(Link)`
  width: 30%;
  white-space: break-spaces;
  min-width: 300px;
  background-color: #f1f3f9;
  padding: 10px 15px;
  margin: 0 20px 0 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 580px) {
    width: 70%;
    min-width: 220px;
  }
`;

const DigestDateWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  font-size: 16px;
  color: #747474;
  i {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TitleText = styled.div`
  color: #272727;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;

  .title-text {
    font-weight: 900;

    + p {
      display: inline;
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
  
  @media (max-width: 580px) {
    font-size: 14px;
  }
`;

const ListHorizontal = ({
  data,
  onDigestReadLater,
  onClickShare,
  onClickCategory,
  setPlayingVideo
 }) => {
  const generateShowAllUrl = () => {
    let url = '/'
    if (data.config && data.config.click_action === 'PILL') {
      url = `/category/${data.config.filter_key_id}?type=${data.config.filter_key}`;
    }

    if (data.config && data.config.click_action === 'FORMAT') {
      url = `/format/${data.id}`;
    }

    return url
  }
  return (
    <>
      <Wrapper>
        <Title>{data.section_name}</Title>
        <ShowAllText
          to={generateShowAllUrl()}
          target="_blank"
        >
          {data.top_explore_text}
          <span>
            <Image src={showAllIcon} />
          </span>
        </ShowAllText>
      
      </Wrapper>
      {data.digests && data.digests.length > 0 && (
        <ArtcilesList>
          <ArtcilesListContainer>
            {data.digests.map((item, index) => {
              return (
              <ItemWrapper 
                to={`/digests/${item.slug}`}
                key={item.id}
              >
                <TitleText>
                  {/* {} */}
                    {data.config && data.config.digest_preview_hide_override ? `` : (
                      <ResponsiveEllipsis
                        unsafeHTML={`<span class="title-text">${item.title}</span> ${item.digest_preview}` || ''}
                        maxLine={index === 0 ? data.digest_text_lines + 1 : data.digest_text_lines}
                        ellipsis='...'
                        trimRight={false}
                        // basedOn='words'
                      />
                    )}
                </TitleText>
                  {data.config && data.config.default_f_visible_date && (
                    <DigestDateWrapper>
                      <Icon name="clockIcon" size={16}/>
                      {item.first_published_at_human}
                    </DigestDateWrapper>
                  )}
              </ItemWrapper>
            )})}
          </ArtcilesListContainer>
        </ArtcilesList>
      )}
    </>
  );
};

export default ListHorizontal;