import React from 'react';
import styled from 'styled-components';
import Icon from 'Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionItem = styled.div`
  cursor: pointer;
  margin: 0 5px;
`;

const DigestActions = ({
  data,
  onDigestReadLater,
  onDigestBookMark,
  onClickShare,
  iconColor
}) => {
  return (
  <Wrapper>
    {onDigestReadLater && (
      <ActionItem
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          onDigestReadLater(data);
        }}
      >
        <Icon
          name={data.readlater ? 'readLaterAdded' : 'addReadLater'}
          // key="button-icon"
          size={18}
            color={data.readlater ? '#E02020' : (iconColor || '#A2A2A2')}
        />
          {/* <Image src={data.readlater ? readLaterAdded : addReadLater} /> */}
      </ActionItem>
    )}
    {onDigestBookMark && (
      <ActionItem
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          onDigestBookMark(data);
        }}
      >
        <Icon
          name={data.like ? 'likeAdded' : 'like'}
          color={data.like ? '#E02020' : (iconColor || '#A2A2A2')}
          size={18}
        />
        {/* <Image src={data.like ? BookMarkHover : BookMarkIcon} /> */}
      </ActionItem>
    )}
    {onClickShare && (
      <ActionItem
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          onClickShare(data);
        }}
      >
          <Icon
            name='share'
            color={iconColor || "#A2A2A2"}
            size={18}
          />
        {/* <Image src={share} /> */}
      </ActionItem>
    )}
  </Wrapper>
);}

export default DigestActions;
