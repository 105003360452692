import React, { useEffect } from "react";

const ImageContainer = ({
  image,
  userSelect,
  setUserSelect,
  users,
  setAnimation,
  animation,
}) => {
  console.log("userSelect in Props", userSelect);
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("userSelect in Intervel", userSelect);
      setUserSelect(userSelect <= 0 ? users.length - 1 : userSelect - 1);
      setAnimation(true);
      setTimeout(() => {
        setAnimation(false);
      }, 500);
    }, 3000);
    return () => clearInterval(interval);
  }, [setAnimation, setUserSelect, userSelect, users.length]);

  //  setInterval(() => {
  //     console.log('userSelect in Intervel',userSelect);
  //     setUserSelect(userSelect <= 0 ? users.length - 1 : userSelect - 1);
  //     setAnimation(true);
  //     setTimeout(() => {
  //       setAnimation(false);
  //     }, 500);
  //   }, 5000);
  // return () => clearInterval(interval);

  const userPrev = () => {
    setUserSelect(userSelect >= users.length - 1 ? 0 : userSelect + 1);
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 500);
  };
  const userNext = () => {
    //console.log('userSelect in userNext',userSelect);
    setUserSelect(userSelect <= 0 ? users.length - 1 : userSelect - 1);
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 500);
  };
  return (
    <div className="image__container">
      <img src={image} alt="" className={animation ? "animationImg" : null} />
      <div className="navigation__buttons">
        <button className="prev__btn" onClick={userPrev}></button>
        <button className="next__btn" onClick={userNext}></button>
      </div>
    </div>
  );
};

export default ImageContainer;
