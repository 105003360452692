import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { white } from 'colorPalette';
import { Input, Image } from 'semantic-ui-react';
import closeIcon from './close.svg';
import { addNewsSubscription } from 'Api';

const Wrapper = styled.div`
  display: none;
  padding: ${({ openForm }) => openForm ? '30px 20px' : '10px 20px'};
  background-color: #002452;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 5%;
  border-radius: 4px;
  width: 300px;
  text-align: center;
  /* transition: all 2s; */
  cursor: ${({ openForm }) => openForm ? 'unset' : 'pointer'};
`;

const Title = styled.div`
  font-size: ${({ openForm }) => openForm ? 20 : 16}px;
  font-weight: 700;
  color: ${white};
  padding-right: 22px;
`;

const FormWrapper = styled.div`
  margin-top: 15px;
`;

const SubDesc = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${white};
`;

const InputWrapper = styled.div`
  margin: 25px 0;

  .input,
  input {
    width: 100%;
    border-radius: 0 !important;
    font-size: 16px;
  }

  input {
    outline: none !important;
    border: none !important;
    padding: 5px;
  }
`;

const ButtonWrapper = styled.div`
  padding: 10px 20px;
  background-color: #DC143C;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  color: ${white};
  text-align: center;
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: ${({ openForm }) => openForm ? '5px' : '50%'};
  right: 5px;
  width: 22px;
  padding: 5px;
  cursor: pointer;
  transform: ${({ openForm }) => openForm ? 'translateY(0)' : 'translateY(-50%)'};
`;

const EmailError = styled.div`
  font-size: 14px;
  color: #DC143C;
  text-align: left;
  margin: 5px;
`;

const ThankYouBody = styled.div`
  font-size: 15px;
  line-height: 22px;
  margin: 14px 0;
  color: ${white};

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const SubscribeToNewsLetter = () => {
  const [openForm, setOpenForm] = useState(false);
  const [hideSubscribe, setHideSubscribe] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [subscription, confirmSubscription] = useState(false);

  useEffect(() => {
    const subscribed = localStorage.getItem('subscribed');
    if (subscribed) confirmSubscription(true);
  }, [confirmSubscription]);

  const validateEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(regx.test(email)) {
      return true;      
    }

    return false;
  }

  const isTnd = process.env.REACT_APP_ENV === "tnd-production" || process.env.REACT_APP_ENV === "tnd-staging";
  const isUnd = process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
  const isOped = process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';
  const isClip = process.env.REACT_APP_ENV === 'clip-production';

  let desc = [
    `Get the world's best journalism in your inbox in short, 2 minute digests.`,
    `0% Spam, 100% Content.`
  ];

  if(isTnd) {
    desc = [
      `Get the world's best travel journalism in your inbox in 60-word digests.`,
      `0% Spam, 100% Content.`
    ];
  } else if(isUnd) {
    desc = [
      `Get the world's best journalism in your inbox in short, 2 minute digests.`,
      `0% Spam, 100% Content.`
    ];
  } else if(isOped) {
    desc = [
      `Get the world's best journalism in your inbox in short, 2 minute digests.`,
      `0% Spam, 100% Content.`
    ];
  } else if(isClip) {
    desc = [
      `Get the world's best journalism in your inbox in short, 2 minute digests.`,
      `0% Spam, 100% Content.`
    ];
  }
  return (
    <>
      {!hideSubscribe && !subscription && (
        <Wrapper
          openForm={openForm}
          onClick={() => {
            if(!openForm) {
              setOpenForm(true);
            }
          }}
        >
            <CloseIconWrapper
              openForm={openForm}
              onClick={e => {
                e.stopPropagation();
                if(openForm) {
                  setOpenForm(false);
                } else {
                  setHideSubscribe(true);
                }
              }}
            >
              <Image src={closeIcon} />
            </CloseIconWrapper>
            <Title
              openForm={openForm}
            >
              {subscription ? 'Thanks for subscribing.' : 'Subscribe to our newsletter'}
            </Title>
            {openForm && !subscription && (
              <FormWrapper>
                <SubDesc>
                  {desc.map(text => <div>{text}</div>)}
                </SubDesc>
                <InputWrapper>
                  <Input 
                    placeholder="Email"
                    onChange={e => setEmail(e.target.value)}/>
                  {emailError && (
                    <EmailError>{emailError}</EmailError>
                  )}
                </InputWrapper>
                <ButtonWrapper
                  onClick={() => {
                    const validatedEmail = validateEmail(email);
                    if(validatedEmail) {
                      addNewsSubscription({ email }).then(response => {
                        setEmailError(null);
                        setEmail(null);
                        confirmSubscription(true)
                        localStorage.setItem('subscribed', true);
                      }).catch(error => {
                        if (window && window.Raven) window.Raven.captureException(error.response);
                      });
                    } else {
                      setEmailError('Enter valid email');
                    }
                  }}
                >Subscribe</ButtonWrapper>
              </FormWrapper>
          )}
          {subscription && openForm && (
            <>
              <ThankYouBody>
                We promise we'll only send you great content
              </ThankYouBody>
            </>
          )}
        </Wrapper>
      )}
    </>
  );
}

export default SubscribeToNewsLetter;