import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterTagPills = styled.div`
  border-radius: 3px;
  background-color: #dc2b33;
  font-family: 'Metropolis', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: #ffffff;
  margin: 5px;
  padding: 5px;
  cursor: pointer;

  span {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const TagsSuggestions = ({ data, onClickItem }) => (
  <Wrapper>
    {data.map(item => (
      <FilterTagPills 
        key={item.id}
        onClick={() => {
          onClickItem({text: item.title, isTagData: true, data: item})
        }}
      >
        <span>#</span>{`${item.title}`}
      </FilterTagPills>
    ))}
  </Wrapper>
);

export default TagsSuggestions;