import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const ItemWrapper = styled.div`
  padding: 10px 0;
  cursor: pointer;
`;
const ItemTitle = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.8);
`;

const PeopleSuggestions = ({ data, onClickItem }) => (
  <Wrapper>
    {
      data.map(item => (
        <ItemWrapper
          onClick={() => {
            onClickItem({ text: item.name })
          }}
        >
          <ItemTitle>
            {`${item.name}, ${item.host.brand_name || ''}`}
          </ItemTitle>
        </ItemWrapper>
      ))
    }
  </Wrapper>
);

export default PeopleSuggestions;