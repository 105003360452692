import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { postDigestFeedback } from 'Api';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
import TextQuestionType from './TextQuestionType';
import RadioQuestionType from './RadioQuestionType';
import CheckboxQuestionType from './CheckboxQuestionType';

const Wrapper = styled.div`
  background-color: #f4f4f4;
  padding: 30px;
  border-top: 4px solid #e13530;
  margin: 25px 0 10px;

  @media (max-width: 580px) {
    padding: 15px;
  }
`;

const QuestionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #333333;

  @media (max-width: 768px) {
    font-size: 16px;
  }
  
  @media (max-width: 580px) {
    font-size: 14px;
  }
`;

const SubmitButton = styled.div`
  border-radius: 4px;
  background-color: #e02020;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.42px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  text-transform: uppercase;
  margin-top: 15px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

const FeedbackBox = ({ questionData, articleData }) =>{
  const [data, setData] = useState(questionData);
  const [feedbackValue, setFeedbackValue] = useState(null);
  const [rerenderFeedback, setRerenderFeedBack] = useState(true);
  const [postFeedbackLoading, setPostFeedbackLoading] = useState(false);
  const [questionType, setQuestionType] = useState(null);
  // const [publishAnswerData, setPublishAnswerData] = useState([]);
  const publishAnswerDataRef = useRef([])
;
  const handleFeedbackSubmit = () => {
    if (!postFeedbackLoading && publishAnswerDataRef.current && publishAnswerDataRef.current.length > 0) {
      setPostFeedbackLoading(true)
      postDigestFeedback({ data: publishAnswerDataRef.current, questionId: questionData.id, digestId: articleData.id}).then(response => {
        setPostFeedbackLoading(false);
        setData(response.data)
        setFeedbackValue(null);
        publishAnswerDataRef.current = [];
        setRerenderFeedBack(true);
        logAmplitudeEvent("Feedback Given", {
          article_id: articleData.id,
          feedback: feedbackValue,
          slug: articleData.slug,
          category_name: articleData && articleData.categories ? articleData.categories.map(item => item.title).join(',') : '',
          question_title: (questionData.title) || '',
          question_id: (questionData.id) || ''
        });
        ToastsStore.success('Feedback Sent.');
      })
      .catch(() => {
        setPostFeedbackLoading(false);
        ToastsStore.error('Something went wrong');
      })
    }
  }

  useEffect(() => {
    if (rerenderFeedback) {
      setTimeout(() => {
        setRerenderFeedBack(false);
      }, 50);
    }
  }, [rerenderFeedback]);

  useEffect(() => {
    const choices = (data && data.choices) || [];
    if (choices.length > 0) {
      setQuestionType(choices[0].type);
    }
    // setQuestionType('CHECKBOX');
  }, [data]);
  return (
    <>
      {data && data.is_active && (
        <Wrapper>
          <ToastsContainer
            position={ToastsContainerPosition.BOTTOM_LEFT}
            store={ToastsStore}
          />
          <QuestionTitle>{data.title}</QuestionTitle>
          {questionType && !rerenderFeedback && (
            <>
              {questionType === 'TEXT' && (
                <TextQuestionType
                  data={data.choices || []}
                  handlePublishData={(data) => {
                    const answerData = [];
                    Object.keys(data).forEach(item => {
                      if (data[item] && data[item].trim() !== '') {
                        answerData.push({
                          choice: parseInt(item),
                          value: data[item]
                        })
                      }
                    })
                    // if ((answerData && answerData.length > 0) || publishAnswerDataRef.currentpublishAnswerDataRef.length > 0) {
                      publishAnswerDataRef.current = answerData;

                    // }
                    console.log(answerData)
                  }}
                />
              )}
              {questionType === 'RADIO' && (
                <RadioQuestionType
                  data={data.choices || []}
                  handlePublishData={data => {
                    publishAnswerDataRef.current = [{ choice: data, value: true }];
                  }}
                />
              )}
              {questionType === 'CHECKBOX' && (
                <CheckboxQuestionType
                  data={data.choices || []}
                  handlePublishData={data => {
                    const answerData = [];
                    data.forEach(item => {
                      answerData.push({
                        choice: item,
                        value: true,
                      });
                    })

                    publishAnswerDataRef.current = answerData;
                  }}
                />
              )}
            </>
          )}
          {/* {data.choices && data.choices[0] && data.choices[0].title && (
            <QuestionDesc>
              {data.choices && data.choices[0] && data.choices[0].title}
            </QuestionDesc>
          )}
            <TextArea
              value={feedbackValue}
              onChange={(e) => setFeedbackValue(e.target.value)}
              placeholder="Type something…"
              rows={4}
            /> */}
          <SubmitButton
            onClick={handleFeedbackSubmit}
          >Submit</SubmitButton>
        </Wrapper>
      )}
    </>
  );
}

export default FeedbackBox;