import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import Header from 'Components/Header';
import Footer from '../NewsDigests/Desktop/DesktopDigestItem/Footer';
import Banner from './Banner';
import PastEdition from './PastEdition';
import { Loader } from 'semantic-ui-react';
import Marquee from "react-smooth-marquee"


import { fetchNewsLetterLandingData } from 'Api';

const Wrapper = styled.div`
  font-family: 'Metropolis', sans-serif;
  /* padding-top: 50px; */
`;

const FeaturesWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const FeatureItem = styled.div`
  font-size: 18px;
  font-weight: 900;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const FeatureDivider = styled.div`
  margin: 0 25px;
  width: 3px;
  height: 30px;
  background-color: #F33;
  transform: rotate(45deg);

  @media (max-width: 767px) {
    height: 3px;
    width: 30px;
    transform: rotate(0deg);
    margin: 15px 0;
  }
`;

const MarqueeContent = styled.div`
  font-size: 28px;
  line-height: 1.3;
  padding: 0 15px;
  white-space: nowrap;
  margin: 20px 0;

  @media (max-width: 767px) {
    font-size: 22px;
  }

  .MarqueeContent {
    padding: 0 50px;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: calc(100vh - 100px);
`;
const NewsLetterLanding = ({ location }) => {
  const [data, setData] = useState(null);
  const [expTestingKey, setExpTestingKey] = useState(null);
  useEffect(() => {
    fetchNewsLetterLandingData({ marcommPage: 'newsletter_page_2' }).then(response => {
      setData(response.data.marcomm.newsletter_page_2);
    })
      .catch(error => {
        console.log("NewsLetterLanding -> error", error);
      })
  }, []);

  useEffect(() => {
    if (location && location.search) {
      const key = queryString.parse(location.search) ? queryString.parse(location.search).exp : null;
      setExpTestingKey(key);
    }
  }, [location]);

  let featuresData = [];
  if (data && data.mid[`features_${expTestingKey}`] && data.mid[`features_${expTestingKey}`].content) {
    featuresData = JSON.parse(data.mid[`features_${expTestingKey}`].content);
  } else if (data) {
    featuresData = JSON.parse(data.mid.features.content);
  }
  return (
  <>
      <Header isLandingPage isHideRightSide/>
      {data ? (
        <Wrapper>
          <Banner data={data.top} expTestingKey={expTestingKey}/>
          <FeaturesWrapper>
            {featuresData.length > 0 && featuresData.map((feature, index) => (
              <>
                <FeatureItem>{feature}</FeatureItem>
                {JSON.parse(data.mid.features.content).length - 1 !== index && (
                  <FeatureDivider />
                )}
              </>
            )) }
          </FeaturesWrapper>
          <PastEdition 
            data={data.mid[`past_editions_${expTestingKey}`] ? data.mid[`past_editions_${expTestingKey}`].content : data.mid.past_editions.content} 
            sectionTitle={data.mid[`past_editions_title_${expTestingKey}`] ? data.mid[`past_editions_title_${expTestingKey}`].content : data.mid.past_editions_title.content}/>
          <MarqueeContent>
            <Marquee direction="left">
              {data.mid[`testimonial_${expTestingKey}`] ? data.mid[`testimonial_${expTestingKey}`].content : data.mid.testimonial.content}
            </Marquee>
          </MarqueeContent>
        </Wrapper>
      ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
      )}
    <Footer />
  </>
);}

export default NewsLetterLanding;