import React from 'react';
import styled from 'styled-components';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
// import { MobileDigestCard } from '../../../TopDigests/MobileView';
import ArticleItem from '../../../ArticleItem';
const Wrapper = styled.div``;

const MobileCaseB = ({ 
  data,
  onDigestReadLater,
  relatedDigestVertical,
  onClickShare,
  theme
}) => {
  return (
  <Wrapper>
    {data && data.length > 0 && data.map(item => (
      <ArticleItem
        data={item}
        onVisitArticle={() => {
          logAmplitudeEvent('RelatedDigest Clicked', {
            current_digest: window.location.href,
            related_digest: item.slug,
            related_digest_vertical: relatedDigestVertical
          });
          window.scroll(0, 0);
          // history.push(`/${item.slug}`);
        }}
        // onClickTags={onClickTags}
        // onDigestBookMark={onDigestBookMark}
        onDigestReadLater={onDigestReadLater}
        onClickShare={onClickShare}
        isRelatedDigest
        // onClickCategory={onClickDigestsCategory}
        // setPlayingVideo={id => setPlayingVideoID(id)}
        // playingVideoID={playingVideoID}
        theme={theme}
      />
      // <MobileDigestCard 
      //   item={item}
      //   key={item.id}
      //   onClickDigest={() => {
      //     logAmplitudeEvent('RelatedDigest Clicked', {
      //       current_digest: window.location.href,
      //       related_digest: item.slug,
      //       related_digest_vertical: relatedDigestVertical
      //     });
      //     window.scroll(0, 0);
      //     // history.push(`/${item.slug}`);
      //   }}
      //   onDigestReadLater={onDigestReadLater}
      //   onClickShare={onClickShare}
      //   isRelatedDigest
      // />
    ))} 
  </Wrapper>
);}

export default MobileCaseB;