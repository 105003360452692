let siteTitle = "Fairfest Media";
let siteMarketing = "";
if (process.env.REACT_APP_ENV === 'tnd-staging' || process.env.REACT_APP_ENV === 'tnd-production' ) {
  siteTitle = "Travel News Digest";
  siteMarketing = "Travel Industry news in 60 words.";
} else if (process.env.REACT_APP_ENV === 'und-staging' || process.env.REACT_APP_ENV === 'und-production') {
  siteTitle = 'Urban News Digest';
  siteMarketing = "Urban Industry news in 60 words.";
} else if (process.env.REACT_APP_ENV === 'oped-staging' || process.env.REACT_APP_ENV === 'oped-production') {
  siteTitle = "DailyBrief";
  siteMarketing = "News briefings worth your time.";
} else if (process.env.REACT_APP_ENV === 'clip-production') {
  siteTitle = "Clip";
  siteMarketing = "Short videos worth your time.";
}
export const varientTitle = siteTitle;
export const variantMarketing = siteMarketing;

export const allowedHtmlTags = ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
  'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
  'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe', 'img'];

