import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;
const ItemWrapper = styled.div`
  margin: 15px 0;
`;

const ItemTitle = styled.div`
  color: rgba(101, 101, 101, 0.8);
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
  
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

const TextArea = styled.textarea`
  border-radius: 6px;
  background-color: #ffffff;
  outline: none;
  border: none;
  width: 100%;
  font-size: 16px;
  color: rgba(101, 101, 101, 1);
  padding: 10px;
  resize: none;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 580px) {
    font-size: 12px;
  }

  &::placeholder {
    color: rgba(0, 0, 0, .5);
  }
`;
const TextQuestionType = ({ data, handlePublishData }) => {
  const [answerData, setAnswerData] = useState({});

  useEffect(() => {
      console.log("🚀 ~ file: TextQuestionType.js ~ line 52 ~ useEffect ~ answerData", answerData)
    if (answerData && Object.keys(answerData).length > 0) {
      handlePublishData(answerData);
    }
  }, [answerData, handlePublishData])
  return (
    <Wrapper>
      {data && data.length > 0 && data.map((item, index) => (
        <ItemWrapper>
          <ItemTitle>{`${data.length > 1 ? `${index + 1}. `: ''}${item.title}`}</ItemTitle>
          <TextArea
            value={answerData[item.id] || ''}
            onChange={(e) => {
              setAnswerData({
                ...answerData,
                [item.id]: e.target.value
              });
            }}
            placeholder="Type something…"
            rows={4}
          />
        </ItemWrapper>
      ))}
    </Wrapper>
  )
};

export default TextQuestionType;