import React, { useEffect, useRef } from 'react';
import {
  Accordion,
  Icon,
  Image
} from 'semantic-ui-react';

import styled from 'styled-components';
import loaderSvg from './loader.gif';
import { white, primaryColor, grey, black } from 'colorPalette';

const Wrapper = styled.div`
  padding: 0 15px;

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 20px;
  }
`;

const AccordionItemWrapper = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

const SegmentsList = styled.div`
  padding: 10px 15px;
  height: calc(100vh - 150px);
  overflow: auto;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const AccordionContainer = styled.div`
  position: relative;
`;

const AccordionWrapper = styled.div`
  position: relative;
`;

const FlowLine = styled.div`
  position: absolute;
  left: 23px;
  width: 1px;
  height: 100%;
  background: #dbd9d9;
`;

const AccordionTitle = styled(Accordion.Title)`
  padding: 10px 10px !important;
  padding-left: ${({ borderLeftWidth, active }) => borderLeftWidth === 8 && active ? 10 : 18}px !important;
  box-shadow: 4px 4px 10px -2px ${grey.level5};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${grey.level6};
  border-left: ${({ borderLeftWidth, active }) => active ? borderLeftWidth : 1}px solid ${({ active, borderLeftWidth }) => active ? borderLeftWidth === 8 ? primaryColor.level1 : grey.level6 : grey.level6};
  background-color: ${({ active, activebgcolor }) => active ? activebgcolor : white };
  position: relative;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 768px) {
    margin: 10px 0 0;
  }

  .title {
    color: ${({ active, activecolor }) => active ? activecolor : black} !important;
    font-size: 14px !important;
    font-weight: ${({ active }) => active ? 900 : 500 };
    width: calc(100% - 150px);
    
    @media (max-width: 768px) {
      font-size: 14px !important;
      width: calc(100% - 70px);
    }
  }
`;

const TagsWrapper = styled.div`
  padding: 2px 5px 3px;
  width: 115px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ bgColor, active, activebgcolor }) => active ? activebgcolor : bgColor};
  color: ${({ active, activecolor }) => active ? activecolor : white };
  text-align: center;
  text-transform: uppercase;
`;


const AccordionTitleTime = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 135px;
  justify-content: flex-end;
  color: ${({ active, activecolor }) => active ? activecolor : grey.level3};
  font-size: 12px;
  @media (max-width: 768px) {
    flex-basis: 115px;
    font-size: 12px !important;
  }
  
`;

const DropdownIcon = styled.div`
  i {
    font-size: 18px !important;
    vertical-align: top !important;
    color: ${({ iconColor }) => iconColor || primaryColor.level1} !important;
  }

  i.red.icon {
    color: ${({ iconColor }) => iconColor || primaryColor.level1} !important;
  }
`;

const AccordionContent = styled.div`
  display: ${({ active }) => active ? 'block' : 'none'};
  padding: 10px 10px;
  background-color: ${white};
  border: 1px solid ${grey.level6};
  max-height: 200px;
  overflow: auto;
  margin: -4px auto 0;
  position: relative;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 100%);
`;

const connvertSecondsToTimeFormat = (time) => {
  const date = new Date(null);
  date.setSeconds(time);
  if (date.toISOString().substr(11, 2) === "00") {
    return date.toISOString().substr(14, 5);
  }
  return date.toISOString().substr(11, 8);
}
const SegmentColumn = ({
  segmentsData,
  onChangeSegment,
  activeSegment,
  segmentLoading,
}) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    if (activeSegment && wrapperRef.current && !activeSegment.manuallyChanged) {
      const activeElemIndex = segmentsData.findIndex(segment => segment.id === activeSegment.id);
      if (activeElemIndex - 2 > 0) {
        const activeElem = document.querySelector(`#accordion-${segmentsData[activeElemIndex - 3].id}`);
        wrapperRef.current.scrollTop = activeElem.offsetTop;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSegment]);
  return (
    <Wrapper>
      <SegmentsList
        ref={wrapperRef}
      >
        <AccordionContainer>
          <FlowLine />
          <AccordionWrapper>
            <Accordion fluid>
              {segmentsData && segmentsData.map((segment, index) => (
                <AccordionItemWrapper
                  id={`accordion-${segment.id}`}
                  key={`accordion-${index}`}>
                  <AccordionTitle
                    active={activeSegment && activeSegment.id === segment.id}
                    index={index}
                    borderLeftWidth={8}
                    activebgcolor={grey.level7 }
                    activecolor={black}
                    onClick={() => {
                      segment.index = index;
                      onChangeSegment(segment);
                    }}
                  >
                    {segment.watch_label && (
                      <TagsWrapper
                        bgColor={segment.bg_color}
                        active={activeSegment && activeSegment.id === segment.id}
                        activebgcolor={segment.bg_color}
                        activecolor={white}
                      >
                        {segment.watch_label}
                      </TagsWrapper>
                    )}
                    <DropdownIcon
                      iconColor={primaryColor.level1}
                    >
                      {segment.description && segment.description.trim() !== "" && (
                        <Icon
                          name={activeSegment && activeSegment.id === segment.id ? 'angle up' : 'angle down'}
                          color="red"
                          size="large"
                        />
                      )}
                    </DropdownIcon>
                    <span className="title">
                      {segment.title}
                    </span>
                    <AccordionTitleTime
                      active={activeSegment && activeSegment.id === segment.id}
                      activecolor={black}
                    >
                      <span>{`${connvertSecondsToTimeFormat(segment.timestamp_start)} - ${connvertSecondsToTimeFormat(segment.timestamp_end)}`}</span>
                    </AccordionTitleTime>
                  </AccordionTitle>
                  {segment.description && segment.description.trim() !== "" && (
                    <AccordionContent active={activeSegment && activeSegment.index === index}>
                      <p>
                        {segment.description}
                      </p>
                    </AccordionContent>
                  )}
                  {activeSegment && activeSegment.index === index && segmentLoading && (
                    <LoaderWrapper>
                      <Image src={loaderSvg} />
                    </LoaderWrapper>
                  )}
                </AccordionItemWrapper>
              ))}
            </Accordion>
          </AccordionWrapper>
        </AccordionContainer>
      </SegmentsList>
    </Wrapper>
  );
};

export default SegmentColumn;