import React, { useState, useEffect } from "react";
import styled from "styled-components";
//import { Input } from "semantic-ui-react";
import Icon from "Icon";
import { isMobileOnly } from "react-device-detect";
//import { addNewsSubscription } from "Api";
import { logAmplitudeEvent } from "Utils/amplitudeLoad";

// const Wrapper = styled.div`
//   position: fixed;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   padding: 1px;
//   border-top: 1px solid black;
//   background-color: #fff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 10;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     padding: 20px 10px 10px;
//   }
// `;
const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1px;
  border-top: 1px solid black;
  background-color: #fff;
  z-index: 10;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px 10px;
  }
`;

const IframeWrapper = styled.iframe`

  border:1px ;
  solid #EEE; 

  /* height:24vh;*/
  
`;

const NewsLetterText2 = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-top: 1%;
  margin-bottom: 1%;
  text-align: center;
  @media (max-width: 768px) {
    margin: 0 0 15px 0;
    text-align: center;
  }
`;
// const NewsLetterText = styled.div`
//   font-size: 16px;
//   font-weight: 700;
//   color: #000;
//   margin-right: 20px;

//   @media (max-width: 768px) {
//     margin: 0 0 15px 0;
//     text-align: center;
//   }
// `;

const SubscribeView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
// const EmailInput = styled(Input)`
//   width: 300px;
//   input {
//     font-size: 16px !important;
//     line-height: 1.5 !important;
//     padding: 4px 10px 5px !important;
//   }
// `;
// const SigupButton = styled.div`
//   padding: 8px 15px 10px;
//   border: 1px solid #fff;
//   color: #fff;
//   font-size: 16px;
//   line-height: 1.2;
//   cursor: pointer;
//   margin: 0 20px;
//   border-radius: 4px;
//   transition: all 0.5s;
//   @media (max-width: 768px) {
//     margin: 15px 0 0 0;
//     width: 300px;
//     text-align: center;
//   }

//   &:hover {
//     background-color: #fff;
//     color: #2a2a2b;
//   }
// `;


const CloseIcon = styled.div`
  padding: 10px 15px 15px;
  background-color: #e0e0e0;
  border-radius: 50%;
  margin-right: 2%;
  margin-bottom: 1%;
  cursor: pointer;
  position:absolute;
  right:0;
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
`;

// const EmailError = styled.div`
//   font-size: 14px;
//   color: #dc143c;
// `;

// const validateEmail = (email) => {
//    eslint-disable-next-line no-useless-escape
//   const regx =
//     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (regx.test(email)) {
//     return true;
//   }

//   return false;
// };
const StickyNewsLetterForm = ({ data, onSubscribe }) => {
  const [showNewsLetterModal, setShowNewsLetterModal] = useState(false);
  //const [email, setEmail] = useState(null);
  //const [emailError, setEmailError] = useState(null);
  const [showSuccessMessage] = useState(false);
  //const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const mobileFor =
      sessionStorage.getItem("isCloseStickyNewsLetter") !== "true" &&
      (sessionStorage.getItem("isCloseBannerAds") === "true" || isMobileOnly) &&
      data &&
      data.visible;

    const desktopFor =
      sessionStorage.getItem("isCloseStickyNewsLetter") !== "true" &&
      (sessionStorage.getItem("isCloseBannerAds") === "true" ||
        !isMobileOnly) &&
      data &&
      data.visible;

    if (isMobileOnly === true ? mobileFor : desktopFor) {
      setShowNewsLetterModal(true);
    } else {
      setShowNewsLetterModal(false);
    }
  }, [data]);
  return (
    <>
      {showNewsLetterModal && (
        <Container>
          {/* <NewsLetterText>{showSuccessMessage ? "Thanks for subscribing!" : data.title}</NewsLetterText> */}
          {!showSuccessMessage && (
            <>
              <NewsLetterText2>
                {showSuccessMessage ? "Thanks for subscribing!" : data.title}
              </NewsLetterText2>
              <SubscribeView>
        
                <IframeWrapper
                  src="https://dailybrief694.substack.com/embed"
                  height="80"
                  // width="100%"
                  //width="480"

                  frameborder="0"
                  scrolling="no"
                />
                
                  <CloseIcon
                    onClick={() => {
                      sessionStorage.setItem("isCloseStickyNewsLetter", true);
                      setShowNewsLetterModal(false);
                      logAmplitudeEvent("Close Button Clicked - Sticky Form");
                    }}
                  >
                    <Icon color="white" name="closeIcon" size={20} />
                  </CloseIcon>
              
              </SubscribeView>

              {/* <div>
                <EmailInput 
                  placeholder="Enter your email"
                  onChange={e => setEmail(e.target.value)}
                />
                {emailError && (
                  <EmailError>{emailError}</EmailError>
                )}
              </div>
              <SigupButton
                onClick={() => {
                  const validatedEmail = validateEmail(email);
                  logAmplitudeEvent("Newsletter Subscribe Button Clicked - Sticky Form",
                                    {email: email})
                  if (validatedEmail) {
                    addNewsSubscription({ email }).then(response => {
                      setEmailError(null);
                      setEmail(null);
                      setShowSuccessMessage(true);
                      setTimeout(() => {
                        setShowNewsLetterModal(false);
                        setShowSuccessMessage(false);
                        onSubscribe();
                      }, 10000);
                      localStorage.setItem('subscribed', true);
                    }).catch(error => {
                      console.log("SubscribeToNewsLetterFull -> error", error.response)
                      if (error.response && error.response.status === 400 && error.response.data && error.response.data.detail) {
                        setEmailError(error.response.data.detail);
                      }

                      if (window && window.Raven) window.Raven.captureException(error.response);
                    });
                  } else {
                    setEmailError('Please enter a valid email');
                  }
                }}
              >Subscribe</SigupButton> */}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default StickyNewsLetterForm;
