import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import Slider from "react-slick";
import desfultIcon from './plane.png';
import { grey, primaryColor, white } from 'colorPalette';

const ExploreListWrapper = styled.div`
  padding: 15px;
  overflow: hidden;
  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    outline: none;

    div {
      outline: none;
    }
  }
`;

const ExploreItem = styled.div`
`;

const ExploreItemWrapper = styled.div`
  width: 125px;
  min-height: 125px;
  margin: 2px auto;
  border: 1px solid ${grey.level5};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 600px) and (min-width: 480px) {
    width: 125px;
    min-height: 125px;
  }

  @media (max-width: 480px) and (min-width: 340px) {
    width: 100px;
    min-height: 100px;
  }
  
  @media (max-width: 340px) {
    width: 80px;
    min-height: 80px;
  }
`;

const ExplorerImageWrapper = styled.div`
  width: 50px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) and (min-width: 480px) {
    width: 50px;
    height: 80px;
    justify-content: center;
  }

  @media (max-width: 480px) and (min-width: 340px) {
    width: 40px;
    height: 55px;
    justify-content: flex-end;
  }
  
  @media (max-width: 340px) {
    width: 30px;
    height: 35px;
    justify-content: flex-end;
  }
  img {
    width: 100%;
  }
`;

const ExploreItemTitle = styled.div`
  width: 100%;
  padding: 5px;
  background-color: ${primaryColor.level1};
  font-size: 14px;
  font-weight: 900;
  color: ${white};
  text-align: center;
`;

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 200,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const ExploreListView = ({ exploreData, onExploreSelect}) => (
  <ExploreListWrapper>
    <Slider {...sliderSettings} >
      {exploreData.map(item => (
        <ExploreItem>
          <ExploreItemWrapper
            onClick={() => onExploreSelect(item)}
          >
            <ExplorerImageWrapper>
              <Image src={item.icon || desfultIcon} />
            </ExplorerImageWrapper>
            <ExploreItemTitle>
              {item.title}
            </ExploreItemTitle>
          </ExploreItemWrapper>
        </ExploreItem>
      ))}
    </Slider>
  </ExploreListWrapper>
);

export default ExploreListView;