import React from "react";
import styled from "styled-components";
import { Header, Image } from "semantic-ui-react";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    min-height: 60vh;
    max-height: 75vh;
    border-bottom: 1px solid #000000;

    @media (max-width: 768px) {
        min-height: 120vh;
    }
`;

const Wrapper = styled.div`
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px 0;

    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
        justify-content: space-around;
        padding: 30px 0;
    }
`;

const BannerContent = styled.div`
    flex-basis: 50%;
`;

const PageTitle = styled(Header)`
    font-size: 32px;
    line-height: 40px;
    font-family: GothamBookRegular;
    margin-top: 40px;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
    }
`;

const HeadingWrapper = styled.div`
    margin-top: 40px;
`;

const BannerDesc = styled.div`
    font-size: 18px;
    line-height: 28px;
    margin-top: 12px;

    @media (max-width: 768px) {
        font-size: 15px;
        line-height: 24px;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BannerImage = styled(Image)`
    height: 50vh;
    @media (max-width: 768px) {
        height: 40vh;
    }
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 40px;
`;

const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
`;

const ListImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
`;

const ListContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
`;

const ListItemTitle = styled.p`
    font-weight: bold;
    margin: 0 0 0.5em;
`;

const ListItemDesc = styled.p`
    margin-bottom: 0;
`;

const Section3 = ({ data3 }) => {
    return (
        <Container>
            <Wrapper>
                <ImageWrapper>
                    <BannerImage src={data3 && data3.image} />
                </ImageWrapper>
                <BannerContent>
                    <HeadingWrapper>
                        <PageTitle as="h2">{data3 && data3.title}</PageTitle>
                    </HeadingWrapper>
                    <BannerDesc>{data3 && data3.subtitle}</BannerDesc>

                    <ListWrapper>
                        {data3 &&
                            data3.list.map((item) => (
                                <ListItem>
                                    <ListImageWrapper>
                                        <Image
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                            }}
                                            src={item.icon}
                                        />
                                    </ListImageWrapper>
                                    <ListContent>
                                        <ListItemTitle>
                                            {item.title}
                                        </ListItemTitle>
                                        <ListItemDesc>{item.subtitle}</ListItemDesc>
                                    </ListContent>
                                </ListItem>
                            ))}
                    </ListWrapper>
                </BannerContent>
            </Wrapper>
        </Container>
    );
};

export default Section3;
