import { v1 as uuidv1 } from 'uuid';
import { obtainGuestUuid } from 'Api';

export const setGuestUuid = () => {
    obtainGuestUuid({
      deviceId: uuidv1()
    }).then(res => {
      localStorage.setItem('Guest-UUID', res.data.guest_uuid);
    })
      .catch(error => {
        if (window && window.Raven) window.Raven.captureException(error.response);
      });
};