import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishResorces from './Languages/english.json';
import hindiResorces from './Languages/hindi.json';
const resources = {
  en: {
    translation: englishResorces
  },
  hd: {
    translation: hindiResorces
  }
};

// const isTnd = process.env.REACT_APP_ENV === "tnd-production" || process.env.REACT_APP_ENV === "tnd-staging";
// const isUnd = process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
// const isOped = process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';
let language = "en";

// if(isTnd) {
//   language = "hd"
// }
// if(isOped) {
//   language = "en"
// }
// if(isUnd) {
//   language = "en"
// }

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;