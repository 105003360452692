import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ErrorImage from './404.svg';
import { useHistory } from 'react-router-dom';
import { white } from 'colorPalette';

const Wrapper = styled.div`
  height: ${({ headerHeight }) => `calc(100vh - ${headerHeight + 150}px)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div``;

const HomeButton = styled.div`
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 700;
  background-color: #de5233;
  border-radius: 20px;
  color: ${white};
  cursor: pointer;
`;

const Page404 = ({ headerHeight }) => {
  const history = useHistory();
  const { t: translation } = useTranslation();
  return (
    <Wrapper
      headerHeight={headerHeight || 0}
    >
      <ImageWrapper>
        <Image src={ErrorImage}/>
      </ImageWrapper>
      <HomeButton
        onClick={() => {
          history.push('/category/feed');
        }}
      >
        {translation("digestDetail.page404.home")}
      </HomeButton>
    </Wrapper>
  );
}
export default Page404;