import React from 'react';
import styled from 'styled-components';
// import { Image } from 'semantic-ui-react';
// import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import {
  isBrowser,
  isTablet,
  isMobile
} from "react-device-detect";

import DesktopDigestCard from './DesktopDigestCard';
import MobileDigestCard from './MobileDigestCard';
import MobileCaseB from './MobileCaseB';

const DigestsListWrapper = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  overflow: auto;

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track-piece {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;


const DigestCardWrapper = styled.div`
  margin: 0 10px;
  width: ${window.innerWidth}px !important;
  height: 100%;
  outline: none;
  display: block !important;
  white-space: pre-wrap !important;
`;

const SliderContainer = styled.div`
  display: flex;
  white-space: nowrap!important;
`;

const MobileRelatedDigest = ({
  data,
  articleData,
  onDigestReadLater,
  digestStream,
  onClickShare,
  theme
}) => {
  const history = useHistory();
  // const sliderSettings = {
  //   className: "slider variable-width",
  //   dots: false,
  //   infinite: false,
  //   centerMode: false,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   adaptiveHeight: true,
  //   nextArrow: <NextIcon totalLength={data && data.length}/>,
  //   prevArrow: <PrevIcon />,
  //   responsive: [
  //     {
  //       breakpoint: 769,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 580,
  //       settings: {
  //         slidesToShow: 1,
  //         arrows: false,
  //         variableWidth: true,
  //       }
  //     },
  //   ]
  // };
  let showVerticleListView;
  if (digestStream) showVerticleListView = true;
  else showVerticleListView = ((articleData && articleData.testing && articleData.testing.experiments && articleData.testing.experiments["related-digest-vertical"] && articleData.testing.experiments["related-digest-vertical"].case) || "B") === "B" ? true : false;
  return (
    <>
      <DigestsListWrapper>
        {!showVerticleListView ? (

          <SliderContainer
            ref={a => {
              if (!a) return;
              const itemList = a.querySelectorAll('.slider-item');
              let totalWidth = 0;
              itemList.forEach(categoryItem => {
                const width = window.innerWidth > 600 ? window.innerWidth/2 : window.innerWidth;
                totalWidth = totalWidth + width - 60;
              });
              a.style.width = `${totalWidth}px`;
            }}>
            {data.map(item => {
              let digestDescText = item.digest;
              const charCount = isMobile && !isTablet ? 70 : 150;
              if (digestDescText && digestDescText.length > charCount) {
                digestDescText = digestDescText.substring(0, charCount) + '...'
              }
              return (
                <DigestCardWrapper key={item.id} className="slider-item">
                  {(isBrowser || isTablet) && (
                    <DesktopDigestCard 
                      item={item} 
                      digestDescText={digestDescText} 
                      history={history}
                    />
                  )}
                  {isMobile && !isTablet && (
                    <MobileDigestCard 
                      item={item} 
                      digestDescText={digestDescText} 
                      history={history}
                      relatedDigestVertical={articleData && articleData.testing && articleData.testing.experiments && articleData.testing.experiments["related-digest-vertical"] && articleData.testing.experiments["related-digest-vertical"].case}
                    />
                  )}
                </DigestCardWrapper>
              )}
            )}
          </SliderContainer>
        ) : (
            <MobileCaseB 
              data={data}
              onClickShare={onClickShare}
              onDigestReadLater={onDigestReadLater}
              relatedDigestVertical={articleData && articleData.testing && articleData.testing.experiments && articleData.testing.experiments["related-digest-vertical"] && articleData.testing.experiments["related-digest-vertical"].case}
              theme={theme}
            />
        )}
      </DigestsListWrapper>
    </>
  );
}

export default MobileRelatedDigest;