import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader'

const Wrapper = styled.div`
  margin: 20px 0;
`;

const ArticleItemWrapper = styled.div`
  margin: 15px 0;
`;

const ArticleItem = () => (
  <ArticleItemWrapper>
    <ContentLoader
      width="100%"
      height="80px"
      // viewBox="0 0 100% 100%"
    >
      <circle cx="20" cy="20" r="20" />
      <rect x="50" y="0" rx="5" ry="5" width="calc(100% - 50px)" height="10" />
      <rect x="50" y="15" rx="5" ry="5" width="calc(100% - 50px)" height="10" />
      <rect x="50" y="40" rx="5" ry="5" width="calc(100% - 50px)" height="10" />
      <rect x="50" y="55" rx="5" ry="5" width="calc(100% - 50px)" height="10" />
      
    </ContentLoader>
  </ArticleItemWrapper>
);

const SideBarTopStoriesShimmer = () => (
  <Wrapper>
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
    <ArticleItem />
  </Wrapper>
);

export default SideBarTopStoriesShimmer