import Axios from "axios";
import { logAmplitudeEvent } from "Utils/amplitudeLoad";

let BASE_URL = "https://stage.opiniondigest.in";
if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "tnd-production"
) {
  BASE_URL = "https://api.travelnewsdigest.in";
} else if (process.env.REACT_APP_ENV === "und-production") {
  BASE_URL = "https://api.urbannewsdigest.in";
} else if (process.env.REACT_APP_ENV === "oped-production") {
  BASE_URL = "https://prod.opiniondigest.in";
} else if (process.env.REACT_APP_ENV === "clip-production") {
  BASE_URL = "https://api.theclipapp.com";
}

// if (process.env.REACT_APP_ENV === 'und-staging') {
//   BASE_URL = "https://stage.urbannewsdigest.in";
// } else if (process.env.REACT_APP_ENV === 'oped-staging') {
//   // BASE_URL = "https://prod.opiniondigest.in";
//   BASE_URL = "https://stage.opiniondigest.in";
// }

/**
 * Fetch summary data
 * @param {Number} id
 * @param {Object} metadata
 */
export const fetchSummaryData = ({ id, metadata }) =>
  Axios.get(`${BASE_URL}/digests/${id}/`, {
    params: {
      metadata,
    },
  });

/**
 * Fetch video digest list
 * @param {Number} digestType
 * @param {Number} page
 * @param {Number} size
 * @param {Number} explore
 * @param {Number} categories
 */
export const fetchVideoListApi = ({
  digestType,
  excludeTypes,
  page,
  size,
  explore,
  categories,
}) => {
  return Axios.get(`${BASE_URL}/user/digests/`, {
    params: {
      metadata: true,
      digest_types: digestType || 255,
      exclude_types: excludeTypes,
      page,
      size,
      explore,
      categories,
    },
  });
};

export const fetchTopDigestsList = ({ categories, explore }) =>
  Axios.get(`${BASE_URL}/home_feed/`, {
    params: {
      categories: categories,
      explore: explore,
    },
  });

export const fetchNewsLetterLandingData = ({ marcommPage }) => {
  logAmplitudeEvent("Loaded Landing Page", {
    marcommPage: marcommPage,
  });
  return Axios.get(`${BASE_URL}/marcomm/`, {
    params: {
      marcomm_page: marcommPage,
    },
  });
};

export const fetchLanguages = () => Axios.get(`${BASE_URL}/lang/`);
/**
 * Fetch digest list
 * @param {Number} digestType
 * @param {Number} page
 * @param {Number} size
 * @param {Number} explore
 * @param {Number} categories
 */
export const fetchDigestListApi = ({
  digestType,
  excludeTypes,
  page,
  size,
  explore,
  categories,
  search,
  webSources,
  tags,
  ids,
  filter_key,
  filter_key_id,
  formats,
  dyanamicKeys,
}) => {
  logAmplitudeEvent("Fetched Articles", {
    page_number: page,
    explore: explore,
    categories: categories,
  });
  return Axios.get(`${BASE_URL}/user/digests/`, {
    params: {
      metadata: true,
      preview_count: 2,
      digest_types: digestType || 255,
      exclude_types: excludeTypes,
      page,
      size,
      explore: explore || "my-feed",
      categories,
      search,
      web_sources: webSources,
      tags,
      slugs: ids,
      filter_key_id,
      filter_key,
      formats,
      ...dyanamicKeys,
    },
  });
};

/**
 * Post scocial login
 * @param {String} token_access
 * @param {String} type
 */

export const socialLogin = ({ token_access, type }) =>
  Axios.post(`${BASE_URL}/social/${type}/`, {
    token_access,
    access_token: token_access,
  });

/**
 * send phone OTP
 * @param {String} phone
 * @param {String} country_code
 */
export const sendPhoneOtp = ({ phone, country_code }) =>
  Axios.post(`${BASE_URL}/phone/otp/send/`, {
    phone,
    country_code,
  });

/**
 * Verify otp
 * @param {String} phone
 * @param {String} country_code
 * @param {String} otp
 */
export const verifyPhoneOtp = ({ phone, country_code, otp }) =>
  Axios.post(`${BASE_URL}/phone/otp/verify/`, {
    phone,
    country_code,
    otp,
  });

/**
 * Obtain guestID
 * @param {String} deviceId
 */
export const obtainGuestUuid = async ({ deviceId }) =>
  Axios({
    url: `${BASE_URL}/access/uuid/`,
    method: "POST",
    headers: {
      ...Axios.defaults.headers,
      "Device-id": deviceId,
    },
  });

/**
 * Fetch digest Comment
 * @param {Number} id
 */
export const fetchDigestsComments = ({ id }) =>
  Axios.get(`${BASE_URL}/digests/${id}/comments/`);

export const fetchConfig = () => Axios.get(`${BASE_URL}/utils/get_app_config/`);
/**
 * Fetch categories and explore data
 */
export const fetchCategoriesExplore = () =>
  Axios.get(`${BASE_URL}/categories/`, {
    params: {
      show_top_pick: true,
    },
  });

/**
 * Vote comment API
 * @param {Number} digestId
 * @param {Number} id
 * @param {String} action
 */
export const commentVoteAction = ({ digestId, id, action }) =>
  Axios.post(`${BASE_URL}/digests/${digestId}/comments/${id}/action/`, {
    action,
  });

/**
 * Post Digest Comment
 * @param {Number} digestId
 * @param {String} comment
 */
export const postDigestComment = ({ digestId, comment }) =>
  Axios.post(`${BASE_URL}/digests/${digestId}/comment/`, {
    comment,
  });

/**
 * Reply on digest comment
 * @param {Number} digestId
 * @param {String} comment
 * @param {Number} commentId
 */

export const replyDigestComment = ({ digestId, comment, commentId }) =>
  Axios.post(`${BASE_URL}/digests/${digestId}/comments/${commentId}/reply/`, {
    comment,
  });

/**
 * bookmark digest
 * @param {Number} digestId
 */
export const bookmarkDigest = ({ digestId, data, pageType }) => {
  pageType = pageType || "unknown";
  if (data) {
    logAmplitudeEvent("Article Liked", {
      article_id: digestId,
      article_type: data.type.code,
      page_type: pageType,
      slug: data.slug,
      category_name: data.categories
        ? data.categories.map((item) => item.title).join(",")
        : "",
    });
  }

  return Axios.post(`${BASE_URL}/digests/${digestId}/like/`);
};

/**
 * delete digest bokmark
 * @param {Number} digestId
 */
export const deleteBookmarkDigest = ({ digestId }) =>
  Axios.delete(`${BASE_URL}/digests/${digestId}/like/`);

/**
 * Edit digest comment
 * @param {Number} digestId
 * @param {String} comment
 * @param {Number} commentId
 */
export const editDigestComment = ({ digestId, comment, commentId }) =>
  Axios.patch(`${BASE_URL}/digests/${digestId}/comments/${commentId}/`, {
    comment,
  });

export const addUserExperienceDigest = ({ digestId, value }) =>
  Axios.patch(`${BASE_URL}/digests/${digestId}/feedback/`, {
    value,
  });

/**
 * delete digest comment
 * @param {Number} digestId
 * @param {Number} commentId
 */
export const deleteDigestComment = ({ digestId, commentId }) =>
  Axios.delete(`${BASE_URL}/digests/${digestId}/comments/${commentId}/`);

/**
 *
 * @param {Number} digestId
 */
export const addReadLater = ({ digestId, data, pageType }) => {
  if (digestId) {
    pageType = pageType || "unknown";
    logAmplitudeEvent("Article A2RLed", {
      article_id: digestId,
      page_type: pageType,
      slug: data.slug,
      category_name:
        data && data.categories
          ? data.categories.map((item) => item.title).join(",")
          : "",
    });
  }
  return Axios.post(`${BASE_URL}/digests/${digestId}/read_later/`);
};

/**
 *
 * @param {Number} digestId
 */
export const deleteReadLater = ({ digestId }) =>
  Axios.delete(`${BASE_URL}/digests/${digestId}/read_later/`);

/**
 *
 * @param {Number} digestId
 */
export const fetchDigestDetail = ({ digestId }) =>
  Axios.get(`${BASE_URL}/digests/${digestId}/`);

/**
 * Add read digests
 */

export const addReadDigest = ({ digests }) =>
  Axios.post(`${BASE_URL}/digests/reads/`, {
    digests,
  });

export const addNewsSubscription = ({ email }) =>
  Axios.post(`${BASE_URL}/news_letter/subscribe/?email=${email}`);

export const fetchTagsDetail = ({ tagSlug }) =>
  Axios.get(`${BASE_URL}/tags/${tagSlug}/`);

export const postHostFollow = ({ hosts }) =>
  Axios.post(`${BASE_URL}/hosts/select/`, {
    hosts,
  });

export const postTagFollow = ({ tags, unSelectedTags }) =>
  Axios.post(`${BASE_URL}/tags/select/?select_type=single`, {
    tags: tags || [],
    un_select_tags: unSelectedTags || [],
    select_type: "single",
  });

export const postDigestFeedback = ({ data, questionId, digestId }) =>
  Axios.post(`${BASE_URL}/feedback/questions/${questionId}/answer/`, {
    answer_data: data,
    digest: digestId,
  });
