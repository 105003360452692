import React from 'react';
import styled from 'styled-components';
import { white, primaryColor } from 'colorPalette';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
// import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import coverImgPlaceholder from 'Container/NewsDigests/Assets/coverImgPlaceholder.jpg';
import { DigestPublishInfo } from '../../../CommonComponents';

const Wrapper = styled(Link)`
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0.7) 72%, #000000), #999 url("${({ bgImg }) => bgImg}");
  background-position: 50% 50%;
  background-size: cover;
  height: 350px;
  box-shadow: 11px 11px 22px 0 rgba(0, 0, 0, 0.06);
`;

const ContentContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const DigestCardContent = styled.div`
  padding: 5px 20px;

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
  
  @media (max-width: 580px) {
    padding: 0 5px;
  }
`;

const DigestCardTitle = styled.div`
  font-family: Metropolis, Lato, sans-serif;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.3;
  color: ${white};
  margin-bottom: 10px;
  text-shadow: 1px 1px rgba(0,0,0,0.5);
`;

const DigestsTagsList = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const DigestTag = styled(Link)`
  display: inline-block;
  padding: 7px;
  background-color: ${primaryColor.level1};
  border-radius: 2px;
  color: ${white};
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  margin: 5px 10px 5px 0;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${white};
  }
  span {
    color: rgba(255, 255, 255, .5);
  }

  @media (max-width: 580px) {
    font-size: 12px;
    line-height: 12px;
    margin: 0 3px 3px 0;
  }
`;

const DigestInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
// const DigestIconWrapper = styled.div`
//   overflow: hidden;
//   padding-right: 5px;
//   width: 45px;
//   img  {
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//   }

//   @media (max-width: 768px) {
//     padding-right: 10px;
//     border-right: 2px solid #fff;
//     img {
//       width: 30px;
//       height: 30px;
//     }
//   }
// `;
// const DigestPublishInfo = styled.div`
//   padding-left: 5px;
//   flex: 1;
//   @media (max-width: 768px) {
//     padding-left: 10px;
//   }
// `;
// const DigestHostName = styled.div`
//   font-size: 14px;
//   line-height: 16px;
//   font-weight: 900;
//   color: ${white};
//   font-family: 'Metropolis', sans-serif;
//   word-break: break-all;
//   text-shadow: 0 0 6px #000000;
// `;
// const DigestPublishAt = styled.div`
//   font-size: 14px;
//   line-height: 16px;
//   font-weight: 500;
//   color: ${white};
//   font-family: 'Metropolis', sans-serif;
//   word-break: break-all;
//   text-shadow: 0 0 6px #000000;
//   text-transform: capitalize;
// `;

const MobileDigestCard = ({ item, digestDescText, history, relatedDigestVertical }) => (
  <Wrapper
    to={`/digests/${item.slug}`}
    bgImg={
      item.cover_pic && item.cover_pic.indexOf('youtube.com') > -1 ?
        `https://i3.ytimg.com/vi/${item.cover_pic.substring(item.cover_pic.indexOf('=') + 1, item.cover_pic.length)}/hqdefault.jpg`
        : (item.cover_pic || coverImgPlaceholder)
    }
    onClick={() => {
      logAmplitudeEvent('RelatedDigest Clicked', {
                          current_digest: window.location.href,
                          related_digest: item.slug,
                          related_digest_vertical: relatedDigestVertical
                        });
      window.scroll(0, 0);
      // history.push(`/${item.slug}`);
    }}
  >
    <ContentContainer>
      <DigestInfoWrapper>
        <DigestPublishInfo data={item} showCount={1} color="#fff" showTextShadow hideFollowButton disableShowModal/>
        {/* {item.source && item.source.host_data && item.source.host_data.logo_link && (
          <DigestIconWrapper>
            <Image src={item.source && item.source.host_data && item.source.host_data.logo_link} />
          </DigestIconWrapper>
        )}
        <DigestPublishInfo>
          <DigestHostName>{item.source && item.source.host}</DigestHostName>
          {item.source && item.source.authors && item.source.authors.length > 0 && (
            <DigestPublishAt>{item.source.authors.map((author, index) => `${index !== 0 ? ',' : ''} ${author.name}`)}</DigestPublishAt>
          )}
        </DigestPublishInfo> */}
      </DigestInfoWrapper>
      <DigestCardContent>
        <DigestCardTitle>{item.title}</DigestCardTitle>
        <DigestsTagsList>
          {item.categories && item.categories.length > 0 && (
            <>
              {item.categories.slice(0, 1).map(item => (
                <DigestTag 
                  key={item.id}
                  to={`/category/${item.slug}`}
                  onClick={e => {
                    e.stopPropagation();
                    // e.preventDefault();
                    // history.push(`/category/${item.slug}`)
                  }}
                ><span>#</span>{`${item.title}`}</DigestTag>
              ))}
            </>
          )}
        </DigestsTagsList>
      </DigestCardContent>
    </ContentContainer>
  </Wrapper>
);

export default MobileDigestCard;