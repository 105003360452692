import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { varientTitle, variantMarketing } from 'global';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from 'react-share';
import facebookIcon from './facebook.svg';
import whatsappIcon from './whatsapp.svg';
import twitterIcon from './twitter.svg';
import OutsideClick from 'Components/OutsideClick';
import Icon from 'Icon';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  -webkit-tap-highlight-color: transparent;
`;

const EllipsesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  span {
    width: 6px;
    height: 6px;
    border: solid 1px #5c5c5c;
    margin: 0 2px;
    border-radius: 50%;
  }
`;

const ShareButtonWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 5px;
  cursor: pointer;
  button {
    outline: none;
  }
  button,
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;

const ArticlesActionsListWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -10px;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e8e8;
  background-color: ${props => props.theme.primaryColor.level1};
  transform: translateY(100%);
  z-index: 10;
  width: 130px;
  padding: 0 10px;
`;
const ArticleActionsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: ${({ hideBorder }) => hideBorder ? 0 : 2}px solid #e8e8e8;
`;
const ArticleActionText = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.46px;
  color: ${props => props.theme.fontColor.level2};
  margin-left: 10px;
`;

const ArrowUp = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 16px 3px -17px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -20px;
  right: 5px;

  &:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg); /* Prefixes... */
  position: absolute;
  top: 14px;
  left: 0px;
  box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
}
`;
const DigestAction = ({
  shareUrl,
  data,
  onDigestReadLater,
  // onDigestBookMark,
  onClickShare
}) => {
  const [showActionsList, setShowActionsList] = useState(false);
  const actionWrapperRef = useRef(null);
  const shareText = data && data.share_data && data.share_data.share_text;
  const text = shareText || `Check out this story: ${data && data.title}. ${shareUrl} from ${varientTitle} - ${variantMarketing}`;
  const { t: translation } = useTranslation();
  return (
    <Wrapper>
      <ShareButtonWrapper>
        <FacebookShareButton
          url={shareUrl}
          quote={text}
          onClick={() => {
            if (data) {
              logAmplitudeEvent('Article Shared', {
                article_id: data.id, article_type: data.type.code,
                slug: data.slug,
                category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
              });
            }
          }}
        >
          <Image src={facebookIcon} />
          {/* <FacebookIcon /> */}
        </FacebookShareButton>
      </ShareButtonWrapper>
      <ShareButtonWrapper>
        <WhatsappShareButton
          url=" "
          title={text}
          onClick={() => {
            if (data) {
              logAmplitudeEvent('Article Shared', {
                article_id: data.id, article_type: data.type.code,
                slug: data.slug,
                category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
              });
            }
          }}
        >
          <Image src={whatsappIcon} />
        </WhatsappShareButton>
      </ShareButtonWrapper>
      <ShareButtonWrapper>
        <TwitterShareButton
          url=" "
          title={text}
          onClick={() => {
            if (data) {
              logAmplitudeEvent('Article Shared', {
                article_id: data.id, article_type: data.type.code,
                slug: data.slug,
                category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
              });
            }
          }}
        >
          <Image src={twitterIcon} />
        </TwitterShareButton>
      </ShareButtonWrapper>
      <EllipsesWrapper
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if(!showActionsList) {
            setShowActionsList(true);
          }
        }}
      >
        <span />
        <span />
        <span />
        {showActionsList && (
          <ArticlesActionsListWrapper
            ref={actionWrapperRef}
          >
            <ArrowUp />
            <ArticleActionsItem
              onClick={() => {
                onDigestReadLater(data);
                setShowActionsList(false);
              }}
            >
              <Icon
                name={data.readlater ? 'readLaterAdded' : 'addReadLater'}
                // key="button-icon"
                size={18}
                color={data.readlater ? '#E02020' : '#A2A2A2'}
              />
              <ArticleActionText>{translation('digestDetail.action.readLater')}</ArticleActionText>
            </ArticleActionsItem>
            {/* <ArticleActionsItem
              onClick={() => {
                onDigestBookMark(data);
                setShowActionsList(false);
              }}
            >
              <Icon
                name={data.like ? 'likeAdded' : 'like'}
                color={data.like ? '#E02020' : '#A2A2A2'}
                size={18}
              />
              <ArticleActionText>{translation('digestDetail.action.like')}</ArticleActionText>
            </ArticleActionsItem> */}
            <ArticleActionsItem
              onClick={() => {
                onClickShare(data);
                setShowActionsList(false);
              }}
              hideBorder
            >
              <Icon
                name='share'
                color="#A2A2A2"
                size={18}
              />
              <ArticleActionText>{translation('digestDetail.action.share')}</ArticleActionText>
            </ArticleActionsItem>
          </ArticlesActionsListWrapper>
        )}
      </EllipsesWrapper>
      <OutsideClick
        elementRef={actionWrapperRef}
        handleOutsideClick={() => {
          actionWrapperRef.current = null;
          setShowActionsList(false);
        }}
        activeOutsideClick={showActionsList}
      />
    </Wrapper>
  );
}

export default DigestAction;