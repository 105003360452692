/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Image, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { isAndroid, isIOS, isMobileOnly } from "react-device-detect";
import Logo from "./thevideodigest.png";
// import MobileLogo from './mobileLogo.svg';
import LogoUnd from "./undLogo.png";
// import { white } from 'colorPalette';
import TNDLogo from "Container/NewsDigests/Assets/TND Logo.png";
import OPEDLogo from "./opedLogo.svg";
import SphereLogo from "./sphere.png";
import darkOPEDLogo from "./darkOpedLogo.svg";
// import MobileOPEDLogo from './mobileOpedLogo.svg';
import LoginPage from "Container/Auth/Login";
import { setGuestUuid } from "Utils/setGuestId";
import { clearUserProperties, logAmplitudeEvent } from "Utils/amplitudeLoad";
import { fetchCategoriesExplore, fetchLanguages } from "Api";
import logoutIcon from "./logout.svg";
import loginIcon from "./login.svg";
import profileIcon from "./profile.svg";
import menuIcon from "./menu.svg";
import darkMenuIcon from "./darkMenu.svg";
import OutsideClick from "Components/OutsideClick";
import searchIcon from "./search.svg";
import darkSearchIcon from "./darkSearch.svg";
import DropDownRadio from "./DropDownRadio";
import lightmode from "./lightmode.svg";
import darkmode from "./darkmode.svg";
import MainheaderActionSheet from "./MainheaderActionSheet";
const Container = styled.div`
  background-color: ${(props) => props.theme.primaryColor.level1};
  box-shadow: 0 1px 19px -10px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: "Metropolis", sans-serif;
  ${({ fixed }) =>
    fixed
      ? `
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
  `
      : ``}

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 999;
  }
`;

const Wrapper = styled.div`
  width: ${({ width }) => width || "100%"};
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ isNewsDigest }) =>
    isNewsDigest ? "15px 0" : "15px 20px 15px 50px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  box-sizing: border-box;
  @media (max-width: 900px) {
    width: ${({ width }) => (width ? "90%" : "90%")};
    padding: ${({ isNewsDigest }) =>
      isNewsDigest ? "10px 20px" : "10px 10px"};
    position: ${({ fixed }) => (fixed ? "fixed" : "static")};
    top: 0;
    left: 0;
    z-index: 100;
  }

  @media (max-width: 768px) {
    height: ${isMobileOnly ? "60px" : "auto"};
    width: 100%;
    padding: ${({ isNewsDigest }) =>
      isNewsDigest ? "10px 20px" : "10px 10px"};
    position: ${({ fixed }) => (fixed ? "fixed" : "static")};
    top: 0;
    left: 0;
    z-index: 100;
  }
`;

const LogoWrapper = styled(Link)`
  display: block;
  width: ${({ isund, istnd, isoped, isClip }) =>
    isund ? 140 : isClip ? 90 : istnd || isoped || isClip ? 200 : 160}px;
  margin-right: 15px;
  @media (max-width: 768px) {
    width: ${({ isund, istnd, isoped, isClip }) =>
      isund ? 80 : isClip ? 80 : istnd || isoped || isClip ? 140 : 120}px;
    /* width:${({ isUnd, showads }) => (showads ? 30 : isUnd ? 60 : 150)}px; */
  }
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  /* @media (max-width: 768px) {
    width: ${({ isund, istnd, isoped }) =>
    isMobileOnly ? 40 : isund ? 140 : istnd || isoped ? 200 : 160}px;
  } */
`;

const EditButton = styled.a`
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  &:focus {
    outline: none !important;
  }
`;

const ProfileMenuPopup = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  z-index: 10;
  transform: translateY(100%);
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  padding: 0 20px;
  width: 180px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "auto")};
  overflow: auto;
`;

// const ProfileMenuItem = styled(Link)`
//   display: block;
//   padding: 15px 10px;
//   border-bottom: ${({ isLogout }) => isLogout ? 0 : 1}px solid #e8e8e8;
//   display: flex;
//   align-items: center;

//   @media (max-width: 580px) {
//     padding: 15px 10px;
//   }
// `;

const LogoutMenuItem = styled.div`
  padding: 15px 10px;
  border-bottom: ${({ isLogout }) => (isLogout ? 0 : 1)}px solid #e8e8e8;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 580px) {
    padding: 10px 0;
  }
`;

const ProfileMenuItemText = styled.div`
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.63px;
  color: ${({ active }) => (active ? "#000" : "#838383")};
  font-weight: ${({ active }) => (active ? 700 : 500)};
`;

// const ProfileMenuItemIcon = styled.div`
//   width: 16px;
//   margin-right: 15px;
// `;

const LogoutMenuItemIcon = styled.div`
  width: 16px;
  margin-right: 15px;
  transform-origin: center;
  transform: ${(hideRotate) =>
    hideRotate ? "rotate(0deg)" : "rotate(-90deg)"};
`;

const ProfileWrapper = styled.div`
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

const HeaderRightSide = styled.div`
  display: flex;
  justify-content: ${({ showEdit }) =>
    showEdit ? "space-between" : "flex-start"};
  align-items: center;
  flex-direction: row-reverse;
`;

const ThemeToggle = styled.div`
  width: 20px;
  display: flex;
  justifycontent: center;
  alignitems: center;
  cursor: pointer;
  margin: 0px 7px 0px 4px;
`;

const SearchBarWrapper = styled(Link)`
  /* display: none;  */
  width: ${({ showSearchBar }) => (showSearchBar ? "auto" : "400px")};
  border-radius: 5px;
  padding: 8px 10px 6px 10px;
  background-color: ${(props) => props.theme.secondaryColor.level2};
  flex: ${({ showSearchBar }) => (showSearchBar ? "1" : "unset")};
  margin-right: 20px;
  transition: all 0.5s;
  max-width: 300px;
  position: relative;
  cursor: pointer;
  @media (max-width: 768px) {
    width: ${({ showSearchBar }) => (showSearchBar ? "auto" : "250px")};
    margin-right: 10px;
  }

  @media (max-width: 580px) {
    width: 20px;
    height: 20px;
    padding: 7px 12px;
    margin-right: 10px;
    background-color: transparent;
  }
`;

const SearchIconWrapper = styled.div`
  width: 15px;
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  // opacity: .5;

  @media (max-width: 580px) {
    width: 18px;
    font-weight: 600;
  }
`;

const SerachBarText = styled.div`
  width: 100%;
  padding-left: 25px !important;
  color: #666b7f;
  font-size: 14px;
  font-family: "Metropolis", sans-serif;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 14px;
    padding-left: 30px !important;
  }

  @media (max-width: 580px) {
    display: none;
  }
`;

// const EllipseIconWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 30px;
//   padding: 10px 0;
//   @media (max-width: 768px) {
//     flex-direction: column;
//     width: auto;
//     height: 30px;
//     padding: 0 5px;
//   }
//   span {
//     width: 6px;
//     height: 6px;
//     border-radius: 50%;
//     border: 1px solid #838383;
//   }
// `;

// const SigninWrapper = styled.div`
//   cursor: pointer;
//   margin-right: 20px;
//   border-radius: 2px;
//   border: solid 1px #979797;
//   font-size: 16px;
//   line-height: 20px;
//   letter-spacing: 0.63px;
//   color: #838383;
//   padding: 6px 10px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 13px;
//     line-height: 16px;
//     padding: 4px 6px;
//     margin-right: 10px;
//   }
// `;

const DigestIconWrapper = styled.div`
  overflow: hidden;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    border-right: 2px solid #fff;
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

const LogoButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    /* flex: 1; */
    /* width: ${(window.innerWidth - 40) / 2 + 70}px; */
    width: ${(window.innerWidth - 40) / 2 + 0}px;
    justify-content: space-between;
  }
`;

// const OpenInAPPButton = styled.a`
//   font-family: 'Metropolis', sans-serif;
//   font-size: 18px;
//   line-height: 22px;
//   text-align: center;
//   color: #060606;

//   &:hover {
//     color: #060606;
//   }
// `;

const CustomAvatar = styled(Avatar)`
  @media (max-width: 580px) {
    width: 35px !important;
    height: 35px !important;
  }
`;

const MenuIcon = styled.div`
  display: none;
  width: 20px;
  margin-right: 10px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Header = ({
  fixed,
  isTrue,
  headerRef,
  openLogin,
  onLogin,
  onLogout,
  isLoadedExploreList,
  exploreData,
  pathname,
  params,
  history,
  showSearchBar,
  onSetCategoryFilter,
  isHideRightSide,
  handleUserNotFound,
  showEdit,
  redirectUrl,
  articleData,
  isLandingPage,
  digestSlug,
  handleMenuClick,
  isShowMenu,
  // isDigestDetailView,
  theme,
  setTheme,
}) => {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openHeaderModal, setOpenHeaderModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [exploreList, setExploreList] = useState(null);
  const [activeExplore, setActiveExplore] = useState(null);
  const [showMenuList, setShowMenuList] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const popupRef = useRef(null);
  const { t: translation } = useTranslation();
  const headerWrapperRef = useRef(null);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const child = useRef();
  // const searchTimeoutRef = useRef(null);
  useEffect(() => {
    function handleResize() {
      if (
        Math.abs(dimensions.width - window.innerWidth) >= 5 &&
        Math.abs(dimensions.height - window.innerHeight) >= 5
      )
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (pathname && params && params.typeSlug) {
      if (pathname.includes("explore")) {
        setActiveExplore(params.typeSlug || null);
      }
    } else {
      setActiveExplore(null);
    }
  }, [pathname, params]);

  useEffect(() => {
    if (exploreData) {
      setExploreList(exploreData);
    }
  }, [exploreData]);

  useEffect(() => {
    if (!isLoadedExploreList && !isLandingPage) {
      fetchCategoriesExplore()
        .then((response) => {
          setExploreList(response.data.explore.results);
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.status === 403 &&
            handleUserNotFound
          ) {
            handleUserNotFound();
          }

          if (window && window.Raven)
            window.Raven.captureException(error.response);
          console.log("error", error);
        });
    }
  }, [isLandingPage, handleUserNotFound, isLoadedExploreList]);
  const isUnd =
    process.env.REACT_APP_ENV === "und-production" ||
    process.env.REACT_APP_ENV === "und-staging";
  const isTnd =
    process.env.REACT_APP_ENV === "tnd-production" ||
    process.env.REACT_APP_ENV === "tnd-staging";
  const isOped =
    process.env.REACT_APP_ENV === "oped-production" ||
    process.env.REACT_APP_ENV === "oped-staging";
  const isClip = process.env.REACT_APP_ENV === "clip-production";

  useEffect(() => {
    if (openLogin) {
      setOpenLoginModal(openLogin);
    }
  }, [openLogin]);
  const isNewsDigest = isUnd || isTnd || isOped || isClip;
  const userData =
    localStorage.getItem("userData") &&
    JSON.parse(localStorage.getItem("userData"));

  const renderPopupMaxHeight = () => {
    if ((headerRef && headerRef.current) || headerWrapperRef.current) {
      const headerElm = headerRef
        ? headerRef.current
        : headerWrapperRef.current;
      const maxHeight = window.innerHeight - headerElm.offsetHeight - 50;
      return maxHeight;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (showMenuList) {
      setShowMenuList(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    fetchLanguages()
      .then((response) => {
        setLanguagesList(response.data.results);
        // const localStorageData = localStorage.getItem('selectedLanguage');
        // if(!localStorageData && response.data.results[0]) {
        //   localStorage.setItem('selectedLanguage', JSON.stringify(response.data.results[0]));
        // }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  let playStoreUrl =
    "https://play.google.com/store/apps/details?id=in.travelnewsdigest";
  let AppStoreUrl =
    "https://apps.apple.com/us/app/travel-news-digest/id1501703558";

  if (isOped) {
    playStoreUrl =
      "https://ideastoday.onelink.me/kp5H?pid=website_header&c=Website%20header&af_web_dp=https%3A%2F%2Fideastoday.in&type=digests";
    AppStoreUrl = null;
  }
  if (isClip) {
    playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.getdailybrief.tv&hl=en_IN";
    AppStoreUrl = null;
  }

  let showads = false;
  if (isAndroid && playStoreUrl) {
    showads = true;
  }
  if (isIOS && AppStoreUrl) {
    showads = true;
  }

  let LogoUrl = Logo;
  if (isOped) {
    // if (showads && isMobileOnly && window.innerWidth <= 768) {
    //   LogoUrl = MobileOPEDLogo;
    // } else {
   
    if (theme === "light") {
      LogoUrl = OPEDLogo;
    } else {
      LogoUrl = darkOPEDLogo;
    }
    // }
  } else if (isClip) {
    if (theme === "light") {
      LogoUrl = SphereLogo;
    } else {
      LogoUrl = SphereLogo;
    }
  } else if (isUnd) {
    // if (showads && isMobileOnly && window.innerWidth <= 768) {
    //   LogoUrl = MobileLogo;
    // } else {
    LogoUrl = LogoUnd;
    // }
  } else if (isTnd) {
    // if (showads && isMobileOnly && window.innerWidth <= 768) {
    //   LogoUrl = MobileLogo;
    // } else {
    LogoUrl = TNDLogo;
    // }
  }

  const themeToggler = () => {
    console.log('themeToggler ON');
    if (theme === "light") {
      localStorage.setItem("THEME_MODE", "dark");
      setTheme("dark");
    } else {
      localStorage.setItem("THEME_MODE", "light");
      setTheme("light");
    }
  };
  const handleHeaderMenu = () => {
    if (child.current) {
      child.current.foo();
    }
  };

  return (
    <>
      {!reload && <div />}
      <Container fixed={fixed}>
        <Wrapper
          id="headerWrapper"
          width={isNewsDigest ? "80%" : "100%"}
          ref={headerRef || headerWrapperRef}
          isNewsDigest={isNewsDigest}
        >
          <LogoButtonWrapper>
            {handleMenuClick ? (
              <MenuIcon onClick={handleMenuClick}>
                {theme === "light" ? (
                  <Image src={menuIcon} />
                ) : (
                  <Image src={darkMenuIcon} />
                )}
              </MenuIcon>
            ) : (
              <MenuIcon onClick={handleHeaderMenu}>
                {theme === "light" ? (
                  <Image src={menuIcon} />
                ) : (
                  <Image src={darkMenuIcon} />
                )}
              </MenuIcon>
            )}
            <LogoWrapper
              to="/"
              showads={showads && isMobileOnly ? `true` : undefined}
              onClick={() => {
                logAmplitudeEvent("HeaderLogo Clicked", {
                  current_page: window.location.href,
                });
              }}
              isund={isUnd ? `isUnd` : undefined}
              istnd={isTnd ? `isTnd` : undefined}
              isoped={isOped ? `isOped` : undefined}
              isClip={isClip ? `isClip` : undefined}
            >
              <Image src={LogoUrl} />
            </LogoWrapper>
            {/* {showads && isMobileOnly && window.innerWidth < 768 && (
              <OpenInAPPButton
                href={isAndroid ? `${playStoreUrl}&slug=${digestSlug}` : `${AppStoreUrl}?digestId=${digestSlug}`}
                target="_blank"
              >
                {translation('header.openInAppText')}
              </OpenInAPPButton>
            )} */}
          </LogoButtonWrapper>
          {showEdit &&
            showEdit === "Super User" &&
            localStorage.getItem("userData") && (
              <EditButton href={`${redirectUrl}${articleData}`} target="_blank">
                <DigestIconWrapper>
                  <Icon name="edit" />
                </DigestIconWrapper>
                Edit Page
              </EditButton>
            )}
          {!isHideRightSide && (
            <HeaderRightSide>
              <DropDownRadio data={languagesList} />
              <ProfileWrapper
                onClick={() => {
                  setShowMenuList(!showMenuList);
                }}
              >
                {localStorage.getItem("userData") ? (
                  <CustomAvatar
                    className="avatar"
                    size="40"
                    name={(userData && userData.name) || "undefined"}
                    round={true}
                  />
                ) : (
                  <CustomAvatar
                    className="avatar"
                    size="40"
                    round={true}
                    src={profileIcon}
                  />
                )}
                {/* ) : (
                      <EllipseIconWrapper>
                        <span />
                        <span />
                        <span />
                      </EllipseIconWrapper>
                  )} */}
                {showMenuList && (
                  <ProfileMenuPopup
                    ref={popupRef}
                    maxHeight={renderPopupMaxHeight()}
                  >
                    {!localStorage.getItem("userData") && (
                      <LogoutMenuItem
                        onClick={() => {
                          if (!localStorage.getItem("userData")) {
                            setOpenLoginModal(true);
                          } else {
                            const userData =
                              localStorage.getItem("userData") &&
                              JSON.parse(localStorage.getItem("userData"));
                            clearUserProperties(userData);
                            localStorage.removeItem("Guest-UUID");
                            localStorage.removeItem("userData");
                            setGuestUuid();
                            setReload(!reload);
                            if (onLogin) {
                              onLogin(false);
                            }
                            if (onLogout) {
                              onLogout();
                            }
                          }
                        }}
                      >
                        <LogoutMenuItemIcon hideRotate>
                          <Image src={loginIcon} />
                        </LogoutMenuItemIcon>
                        <ProfileMenuItemText>
                          {!localStorage.getItem("userData") && (
                            <span>{translation("header.loginText")}</span>
                          )}
                        </ProfileMenuItemText>
                      </LogoutMenuItem>
                    )}
                    {/* {exploreList && exploreList.map(item => (
                        <ProfileMenuItem
                          to={`/explore/${item.slug}`}
                          onClick={() => {
                            if (onSetCategoryFilter) {
                              item.type = "explore";
                              onSetCategoryFilter(item);
                            }
                          }}
                        >
                          <ProfileMenuItemIcon>
                            <Image src={item.icon}/>
                          </ProfileMenuItemIcon>
                          <ProfileMenuItemText
                            active={activeExplore === item.slug}
                          >
                            {item.title}
                          </ProfileMenuItemText>
                        </ProfileMenuItem>
                      ))} */}
                    {localStorage.getItem("userData") && (
                      <LogoutMenuItem
                        isLogout
                        onClick={() => {
                          if (!localStorage.getItem("userData")) {
                            setOpenLoginModal(true);
                          } else {
                            const userData =
                              localStorage.getItem("userData") &&
                              JSON.parse(localStorage.getItem("userData"));
                            clearUserProperties(userData);
                            localStorage.removeItem("Guest-UUID");
                            localStorage.removeItem("userData");
                            setGuestUuid();
                            setReload(!reload);
                            if (onLogin) {
                              onLogin(false);
                            }
                            if (onLogout) {
                              onLogout();
                            }
                          }
                        }}
                      >
                        <LogoutMenuItemIcon>
                          <Image src={logoutIcon} />
                        </LogoutMenuItemIcon>
                        <ProfileMenuItemText>
                          {localStorage.getItem("userData") && (
                            <span>{translation("header.logoutText")}</span>
                          )}
                        </ProfileMenuItemText>
                      </LogoutMenuItem>
                    )}
                  </ProfileMenuPopup>
                )}
              </ProfileWrapper>

              <ThemeToggle title="Switch theme" onClick={themeToggler}>
                {theme === "light" ? (
                  <Image src={darkmode}></Image>
                ) : (
                  <Image src={lightmode}></Image>
                )}
              </ThemeToggle>

              {isShowMenu && (
                <HeaderTab>
                  <Link
                    style={{ paddingRight: "20px", color: "gray" }}
                    to={"/category/feed"}
                  >
                    {translation("header.readNow")}
                  </Link>
                  <a
                    style={{ paddingRight: "20px", color: "gray" }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://onelink.to/8chy8d"}
                  >
                    {translation("header.getDailyBrief")}
                  </a>
                  <a
                    style={{ paddingRight: "20px", color: "gray" }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://dashboard.getdailybrief.com/"}
                  >
                    {translation("header.creators")}
                  </a>
                </HeaderTab>
              )}
              {/* {!localStorage.getItem('userData') && (
                  <SigninWrapper
                    onClick={() => {
                      setOpenLoginModal(true);
                      // history.push('/login')
                    }}
                >{translation('header.loginText')}</SigninWrapper>
                )} */}
              {/* {false && ( */}
              {showSearchBar && (
                <SearchBarWrapper to={`/search`}>
                  {/* themeToggler */}
                  <SearchIconWrapper>
                    {theme === "light" ? (
                      <Image src={searchIcon} />
                    ) : (
                      <Image src={darkSearchIcon} />
                    )}
                  </SearchIconWrapper>
                  <SerachBarText>{`${translation(
                    "header.searchbarPlaceholder"
                  )}...`}</SerachBarText>
                </SearchBarWrapper>
              )}
            </HeaderRightSide>
          )}
          <OutsideClick
            elementRef={popupRef}
            handleOutsideClick={() => {
              popupRef.current = null;
              setShowMenuList(false);
            }}
            activeOutsideClick={showMenuList}
          />
        </Wrapper>
      </Container>
      {openLoginModal && (
        <LoginPage
          isDesktop
          onLogin={onLogin}
          openLoginModal={openLoginModal}
          onCloseLoginModal={() => setOpenLoginModal(false)}
          theme={theme}
          setTheme={setTheme}
        />
      )}
      <MainheaderActionSheet ref={child} />
    </>
  );
};

export default Header;
