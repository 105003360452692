import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

const Wrapper = styled.div`
  text-align: center;
  padding: 30px 0;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.fontColor.level2};
  font-size: 24px;
  line-height: 1.4;
  font-weight: 900;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SubTitle = styled.div`
  color: ${props => props.theme.fontColor.level2};
  font-size: 18px;
  line-height: 1.2;
`;

const ImageWrapper = styled.div`
  // border-bottom: 1px solid #ccc;
  padding-bottom: 1px;
  position: relative;
  margin: 30px 0;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  background-color: ${props => props.theme.primaryColor.level1};
`;

const MessageView = ({ data }) => {
  return (
  <Wrapper>
    {data.config && data.config.image && (
      <ImageWrapper>
        <ImageContainer>
          <Image src={data.config && data.config.image}/>
        </ImageContainer>
      </ImageWrapper>
    )}
    <Title>{data.config && data.config.title}</Title>
    <SubTitle>{data.config && data.config.subtitle}</SubTitle>
  </Wrapper>
  );
}

export default MessageView;