import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import Header from 'Components/Header';
// import SubscribeInputField from './SubscribeInputField';
import SubscribeInfoWrapper from './SubscribeInfoWrapper';
import Icon from 'Icon';
import Footer from '../NewsDigests/Desktop/DesktopDigestItem/Footer';
import { fetchNewsLetterLandingData } from 'Api';
import { Loader } from 'semantic-ui-react';
// import Testimonial from './Testimonial';
import { logAmplitudeEvent } from 'Utils/amplitudeLoad';


const Wrapper = styled.div`
  padding: 30px 0;
  margin-top: 5px;

  @media (max-width: 580px) {
    padding: 25px 0;
  }
`;

const BannerContainer = styled.div`
  max-width: 1500px;
  margin: 0 auto;
`;

const BannerWrapper = styled.div`
  width: 90%;
  margin: 0 0 0 auto;
  
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const BannerTitle = styled.div`
  font-family: Metropolis;
  font-weight: 900;
  font-size: 73px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.8);
  width: 65%;

  .red {
    color: #e02020;
  }

  @media (max-width: 1024px) {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    font-size: 55px;
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 580px) {
    font-size: 45px;
  }
`;
const BannerImageContentWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`;

const BannerContentWrapper = styled.div`
  width: 40%;
  max-width: 800px;

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto
  }
`;

// const BannerImageWrapper = styled.div`
//   flex: 1;

//   img {
//     width: 100%;
//   }
//   @media (max-width: 768px) {
//     margin: 15px 0 30px;
//     width: 100%;
//   }
// `;

const BannderDesc = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 21px;
  font-weight: 30px;
  line-height: 1.52;
  color: rgba(0, 0, 0, 0.8);
  margin: 35px 0;

  @media (max-width: 1024px) {
    font-size: 28px;
    margin: 30px 0;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    margin: 25px 0;
  }
  @media (max-width: 580px) {
    font-size: 20px;
    margin: 20px 0;
  }
`;

// const SubscribeInputFieldWrapper = styled.div`
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const BannerTagLine = styled.div`
//   font-family: 'Metropolis', sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   line-height: 1.88;
//   color: #6b6b6b;
//   margin: 15px 0;
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const RedirectSiteButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const RedirectSiteButton = styled(Link)`
  font-family: GothamBookRegular;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  // letter-spacing: 0.6px;
  color: #6b6b6b;
  border-radius: 5px;
  border: solid 1px #d0d0d0;
  padding: 15px 50px 15px 20px;
  position: relative;
  display: inline-block;
  margin-top: 35px;

  &:hover {
    color: #6b6b6b;
  }

  @media (max-width: 580px) {
    font-size: 14px;
    padding: 15px 40px 15px 15px;
    margin-top: 20px;

  }
`;

const RedirectSiteIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: calc(100vh - 100px);
`;

const NewsLetterLanding = ({ location }) => {
  const [data, setData] = useState(null);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [expTestingKey, setExpTestingKey] = useState(null);
  const [referralCode, setReferralCode] = useState(null);

  logAmplitudeEvent('Page Loaded - Newsletter LP4')
  useEffect(() => {
    if (location && location.search) {
      const key = queryString.parse(location.search) ? queryString.parse(location.search).exp : null;
      let referral = queryString.parse(location.search) ? queryString.parse(location.search).utm_content : null;
      setReferralCode(referral);
      setExpTestingKey(key);
    }
  }, [location])

  useEffect(() => {
    fetchNewsLetterLandingData({ marcommPage: 'newsletter_page_wa' }).then(response => {
      setData(response.data.marcomm.newsletter_page_wa);
    })
      .catch(error => {
        console.log("NewsLetterLanding -> error", error);
      })
  }, [])
  return (
    <div>
      <Header isLandingPage isHideRightSide />
      {data ? (
        <Wrapper>
          {data.top && (
            <BannerContainer>
              <BannerWrapper>
                <BannerTitle dangerouslySetInnerHTML={{ __html: data.top[`headline_${expTestingKey}`] ? data.top[`headline_${expTestingKey}`].content : data.top.headline.content }}>
                </BannerTitle>
                <BannerImageContentWrapper>
                  <BannerContentWrapper>
                    <BannderDesc dangerouslySetInnerHTML={{ __html: data.top[`subtitle_${expTestingKey}`] ? data.top[`subtitle_${expTestingKey}`].content : data.top.subtitle.content }}></BannderDesc>
                  </BannerContentWrapper>
                </BannerImageContentWrapper>
              </BannerWrapper>
            </BannerContainer>
          )}
          {data.subscribe_form && (
            <SubscribeInfoWrapper
              expTestingKey={expTestingKey}
              data={data.subscribe_form}
              onSubscribeSuccess={() => setIsSubscribe(true)}
              isSubscribe={isSubscribe}
              referralCode={referralCode}
            />
          )}

          <RedirectSiteButtonWrapper>
            <RedirectSiteButton
              to="/"
              onClick={() => logAmplitudeEvent('Preview Button Clicked - Newsletter LP4',
                {
                  exp_version: expTestingKey
                })}
            >
              See our Website
              <RedirectSiteIcon>
                <Icon name="arrowRight" color="#e02020" size={18} />
              </RedirectSiteIcon>
            </RedirectSiteButton>
          </RedirectSiteButtonWrapper>

          <RedirectSiteButtonWrapper>
            <RedirectSiteButton
              onClick={() => {
                logAmplitudeEvent('Get the App Button Clicked - Newsletter LP4',
                  {
                    exp_version: expTestingKey
                  })
                window.open('https://play.google.com/store/apps/details?id=in.ideastoday', '_blank');
              }



              }
            >
              Get the Android App              <RedirectSiteIcon>
                <Icon name="arrowRight" color="#e02020" size={18} />
              </RedirectSiteIcon>
            </RedirectSiteButton>
          </RedirectSiteButtonWrapper>


        </Wrapper>
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <Footer />
    </div>
  );
}

export default NewsLetterLanding;