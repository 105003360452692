import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import VideoDetail from './VideoDetail';
// import HomePage from './HomePage';
import NewsDigests from './NewsDigests';
import TagsPage from './TagsPage';
import DesktopDigestItem from './NewsDigests/Desktop/DesktopDigestItem';
import AboutPage from './AboutPage';
// import TNDHomePage from './NewsDigests/HomePage';
// import CommentsPage from './NewsDigests/CommentsPage';
import DiscoverPage from './NewsDigests/DiscoverPage';
import LoginPage from './Auth/Login';
import SearchPage from './NewsDigests/Mobile/SearchPage';
import PrivacyPage from './TermsPrivacy/PrivacyPage.js';
import TermsPage from './TermsPrivacy/TermsPage';
import SubscribeToNewsLetter from './SubscribeToNewsLetter';
import './i18n';
import PageNotFound from './PageNotFound';
import NewsLetterLanding from './NewsLetterLanding';
import NewsLetterLanding2 from './NewsLetterLanding2';
import NewsLetterLanding3 from './NewsLetterLanding3';
import NewsLetterLanding4 from './NewsLetterLanding4';
// import SubscribeToNewsLetterFull from './SubscribeToNewsLetterFull';

import { obtainGuestUuid, fetchLanguages } from 'Api';
import { v1 as uuidv1 } from 'uuid';

import failureIcon from './failureIcon.png';
// import ProtectedRoute from 'Components/ProtectedRoute';

import { setAxiosAuthorizationHeader } from '../Utils/axios';
import { initAmplitude } from 'Utils/amplitudeLoad';
import { Loader } from 'semantic-ui-react';

import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from './themes.js'
import ClipHomePage from './ClipHomePage1';

const FailurePageWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;

const FailurePageImage = styled.div`
  width: 30%;
  img {
    width: 100%;
  }
`;

const FailurePageTitle = styled.div`
  margin-top: 25px;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
`;

const RefreshButton = styled.div`
  padding: 10px 15px;
  border: 1px solid #d3d3d3;
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, .7);
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
`;

const MainPage = () => {
  const [siteLoading, setSiteLoading] = useState(true);
  const [uuidFailure, setUuidFailure] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  // const [subscription, subscribe] = useState(false);
  let storedThemeMode = localStorage.getItem('THEME_MODE');
  if (storedThemeMode === null){
    localStorage.setItem('THEME_MODE', 'light');
    storedThemeMode = localStorage.getItem('THEME_MODE');
  }
  const [theme, setTheme] = useState(storedThemeMode);
  
  
  setAxiosAuthorizationHeader();
  const setGuestUuid = useCallback(() => {
    fetchLanguages()
      .then((response) => {
        const localStorageData = localStorage.getItem('selectedLanguage');
        if(!localStorageData && response.data.results[0]) {
          localStorage.setItem('selectedLanguage', JSON.stringify(response.data.results[0]));
          setAxiosAuthorizationHeader();
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    if (!localStorage.getItem('Guest-UUID') && siteLoading) {
      // navigator.mediaDevices.enumerateDevices().then(response => {
        obtainGuestUuid({
          deviceId: uuidv1()
        }).then(res => {
          localStorage.setItem('Guest-UUID', res.data.guest_uuid);
          initAmplitude();
          setAxiosAuthorizationHeader();
          setSiteLoading(false);
          setUuidFailure(false);
        })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              localStorage.clear();
            }
            setSiteLoading(false);
            setUuidFailure(true);
            if (window && window.Raven) window.Raven.captureException(error.response);
          });
      // });
    } else if(siteLoading) {
      initAmplitude();
      setSiteLoading(false);
    }
  }, [siteLoading]);


  useEffect(() => {
    // let interval;
    // if (!localStorage.getItem('session')) {
    //   localStorage.setItem('session', true);
    //   interval = setInterval(() => {
    //     subscribe(true)
    //     clearInterval(interval);
    //   }, 30000)
    // }
  }, []);

  useEffect(() => {
    setGuestUuid();
  }, [setGuestUuid]);

  const handleUserNotFound = () => {
    setUserNotFound(true);
  };

  const isTnd = process.env.REACT_APP_ENV === "tnd-production" || process.env.REACT_APP_ENV === "tnd-staging";
  const isUnd = process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
  const isOped = process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';
  const isClip = process.env.REACT_APP_ENV === 'clip-production';
  
  let pageTitle = 'Thevideodigest';
  if (isTnd) {
    pageTitle = 'Travel News Digest | Travel Industry News in 60 words';
  }

  if (isOped) {
    pageTitle = 'DailyBrief | News briefings worth your time.';
  }
  
  if (isClip) {
    pageTitle = 'DailyBrief | News briefings worth your time.';
  }

  if (isUnd) {
    pageTitle = 'Urban News Digest | Urban Industry News in 60 words';
  }
  
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ThemeProvider theme={ theme === "light" ? lightTheme : darkTheme }>
        {siteLoading && !uuidFailure ?
          <Loader active />
        : (
          <>
            <SubscribeToNewsLetter />
            {!uuidFailure && !userNotFound? (
              <Router>
                <Switch>
                {(isTnd || isUnd || isClip) ? (
                      <Route path="/" exact render={() => <Redirect
                        to={{
                          pathname: "/category/feed"
                        }}
                      />}/>
                  ): (
                       <Route path="/" exact render={() => <Redirect
                        to={{
                          pathname: "/homepage"
                        }}
                      />}/>
                  )}
                    
                  {/* <> */}
                    <Route path="/homepage" exact component={ props=> <ClipHomePage {...props} theme={theme} setTheme={setTheme}  />  }/>
                    <Route path="/login" exact component={ props=> <LoginPage {...props} theme={theme} setTheme={setTheme}  />  }/>
                    <Route path="/videoDigest/summary/video/:id" exact component={VideoDetail}/>
                    <Route path="/digests" exact render={() => <Redirect
                      to={{
                        pathname: "/category/feed"
                      }}
                    />}/>
                    <Route path="/category/:typeSlug" exact component={props => <NewsDigests {...props} handleUserNotFound={handleUserNotFound} theme={theme} setTheme={setTheme} />}/>
                    <Route path="/format/:formatId" exact component={props => <NewsDigests {...props} handleUserNotFound={handleUserNotFound} />}/>

                    {/* <Route path="/tags/:tagSlug" exact component={props => <NewsDigests {...props} handleUserNotFound={handleUserNotFound} />}/> */}
                    
                    <Route path="/tags/:tagSlug" exact component={props => <TagsPage {...props} handleUserNotFound={handleUserNotFound} theme={theme} setTheme={setTheme} />}/>

                    <Route path="/explore/:typeSlug" exact component={props => <NewsDigests {...props} handleUserNotFound={handleUserNotFound} />}/>
                    
                    <Route path="/search" exact render={props => <NewsDigests {...props} isSearchPage handleUserNotFound={handleUserNotFound} theme={theme} setTheme={setTheme} /> }
                      />
                    {/* {(isBrowser || isTablet || isOped) && ( */}
                      {/* <> */}
                        {/* <Route path="/:type/:typeSlug/:digestSlug" exact component={DesktopDigestItem}/> */}
                    <Route path="/digests/:digestSlug" exact component={props => <DesktopDigestItem {...props} setGuestUuid={setGuestUuid} handleUserNotFound={handleUserNotFound} theme={theme} setTheme={setTheme} />}/>
                    <Route path="/about" exact component={props => <AboutPage {...props} setGuestUuid={setGuestUuid} handleUserNotFound={handleUserNotFound} theme={theme} setTheme={setTheme} />}/>
                      {/* </> */}
                    {/* )} */}
                    {/* {isMobile && !isTablet && !isOped && (
                      <>
                        <Route path="/digests/:type/:typeSlug/:digestSlug" exact component={NewsDigests}/>
                        <Route path="/digests/:digestSlug" exact component={NewsDigests}/>
                        <Route path="/digest/:digestSlug/comments" exact component={CommentsPage}/>
                      </>
                    )} */}
                    <Route path="/newsletter" exact component={NewsLetterLanding}/>
                    <Route path="/whatsapp" exact component={NewsLetterLanding4}/>
                    <Route path="/newsletter-2" exact component={NewsLetterLanding2}/>
                    <Route path="/newsletter-3" exact component={NewsLetterLanding3}/>
                    <Route path="/discover" exact component={DiscoverPage}/>
                    <Route path="/discover/search" exact component={SearchPage}/>
                    <Route path="/privacy" exact component={PrivacyPage} />
                    <Route path="/terms" exact component={TermsPage} />
                    <Route path="/contact" exact component={() => { 
                        window.location.href = 'https://forms.gle/BAGUS5fyQjDfxXVaA'; 
                        return null;
                    }}/>
                    <Route path="*" exact component={PageNotFound} />
                  {/* </> */}
                </Switch>
              </Router>
            ):
            (
              <>
                {/* {userNotFound ? (
                  <UserNotFoundWrapper>

                  </UserNotFoundWrapper>
                ): ( */}
                  <FailurePageWrapper>
                    <FailurePageImage>
                      <Image src={failureIcon} />
                    </FailurePageImage>
                    <FailurePageTitle>Something went wrong. Please try again.</FailurePageTitle>
                    <RefreshButton
                      onClick={() => {
                        if (window.caches && window.caches.delete) {
                          localStorage.clear();
                          window.caches.delete()
                          window.location.reload();
                        }
                      }}
                    >Refresh</RefreshButton>
                  </FailurePageWrapper>
                {/* )} */}
              </>
            )}
          </>
        )}
      </ThemeProvider>
      {/* 
      {subscription && (
        <SubscribeToNewsLetterFull
          subscription={true}
          subscribe={subscribe}
        />
      )} */}
    </>
  );
};

export default MainPage;
