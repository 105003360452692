import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

import { logAmplitudeEvent } from 'Utils/amplitudeLoad';
const AmplitudePageType = 'HomePage';

const DigestInfoText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Metropolis', sans-serif;
  @media (max-width: 870px) {
    width: calc(100% - 90px);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const DigestInfo = styled.div`
  color: rgba(0, 0, 0, .8);
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  font-family: Metropolis, sans-serif;
  span {
    margin-right: 4px;
  }

  @media (max-width: 870px) {
    font-size: 14px;
    line-height: 18px;
  }
  
  @media (max-width: 580px) {
    font-size: 12px;
    line-height: 16px;
  }

  a {
    color: #000;
  }

  .divider {
    margin: 0 4px;
  }
`;

const DigestInfoPubleshed = styled.div`
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */

  a, span {
    display: block;
  }
`;

const DigestIconWrapper = styled.div`
  overflow: hidden;
  padding-right: 5px;
  width: 45px;
  img  {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    padding-right: 10px;
    border-right: 2px solid #fff;
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

const PublicationInfo = ({ data }) => {
  return (
    <DigestInfoText>
      {data.source && data.source.host_data && data.source.host_data.logo_link && data && data.digest && data.type.code !== '300' && (
        <DigestIconWrapper>
          <Image src={data.source && data.source.host_data && data.source.host_data.logo_link} />
        </DigestIconWrapper>
      )}
      <DigestInfo>
        <>
          <DigestInfoPubleshed>
            {data && data.type.code !== '300' && data.source && data.source.host && (
              <>
                {/* <div className="readMoreAt">Read more at</div> */}
                <a
                  href={data.source && data.source.link}
                  onClick={event => {
                    logAmplitudeEvent('Article Opened', {
                      article_id: data.id,
                      article_type: data.type.code,
                      action: 'click',
                      page_type: AmplitudePageType,
                      slug: data.slug,
                      category_name: data && data.categories ? data.categories.map(item => item.title).join(',') : ''
                    });
                    event.stopPropagation();
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >{data.source && data.source.host}</a>
                {/* <span className="divider">/</span> */}
              </>
            )}
            {data.source && data.source.authors && data.source.authors.length > 0 && (
              <div>{data.source.authors.map((author, index) => `${index !== 0 ? ',' : ''} ${author.name}`)}</div>
            )}
          </DigestInfoPubleshed>
        </>
      </DigestInfo>
    </DigestInfoText>
  )
};

export default PublicationInfo;