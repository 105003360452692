import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
// import { Image } from 'semantic-ui-react';
// import arrowLeft from '../arrow_left.svg';
// import arrowRight from '../arrow_right.svg';
import { Link } from 'react-router-dom';
import Icon from 'Icon';

const Wrapper = styled.div`
  position: relative;

  @media (max-width: 580px) {
    margin-bottom: 20px;
  }
`;

const SliderWrapper = styled.div`
  overflow: auto;
  position: relative;

  /* border-bottom: solid 1px #d9d9d9; */
  // box-shadow: 0 2px 2px -2px rgba(0,0,0,0.15);
  &::-webkit-scrollbar {
    display: none;
}
  @media (max-width: 768px) {
    overflow: auto;
    // width: calc(100% - 30px);
    height: 38px;
    // margin: 0 auto;
    scroll-behavior: smooth;
    /* border-bottom: solid 1px #d9d9d9; */
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track-piece {
      width: 0px;
      height: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap !important;
  transform: ${({ translatex }) => `translateX(${translatex}px)`};
  transition: transform .2s;
  // max-width: 1200px;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  // border-bottom: solid 1px #d9d9d9;

 @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SliderItem = styled(Link)`
  display: block;
  padding: ${({ active, explore, index }) => active ? '15px 10px 11px 10px' : explore ? '9px 10px 11px 10px' : '15px 10px 11px 10px'};
  font-size: ${({ active, explore }) => active ? explore ? '18px' : '16px' : explore ? '16px' : '16px'};
  line-height: ${({ active, explore }) => active ? explore ? '24px' : '18px' : explore ? '24px' : '18px'};
  font-weight: ${({ active, explore }) => active ? '900' : explore ? '700' : '700'};
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  /* letter-spacing: 1.2px; */
  text-transform: capitalize;
  color: ${({ active, explore, itemTextColor }) => active ? '#DC3D4D' : itemTextColor ? itemTextColor : props => props.theme.fontColor.level4 };
  border-bottom: ${({ active }) => active ? '4px solid #DC3D4D' : '4px solid transparent'};
  margin-right: 15px;
  &:hover {
    color: ${props => props.theme.fontColor.level2};
    /* font-size: ${({ explore }) => explore ? '20px' : '16px'};; */
    /* line-height: ${({ explore }) => explore ? '26px' : '20px'}; */
  }
  font-family: ${({ active }) => active ? 'Metropolis-Bold' : 'Metropolis'};

  @media (max-width: 768px) {
    padding: ${({ active, explore, index }) => active ? '13px 10px 13px 10px' : explore ? '7px 10px 13px 10px' : '13px 10px 13px 10px'};
    font-size: ${({ active, explore }) => active ? explore ? '16px' : '14px' : explore ? '14px' : '14px'};
    line-height: ${({ active, explore }) => active ? explore ? '22px' : '16px' : explore ? '22px' : '16px'};
  }

  @media (max-width: 580px) {
    font-size: ${({ active, explore }) => active ? explore ? '13px' : '13px' : explore ? '13px' : '13px'};
  }

`;
const PrevIcon = styled.div`
  cursor: ${({ disabled }) => disabled ? 'unset' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? '.6' : '.8'};
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  display: ${({ disabled }) => disabled ? 'none' : 'block'};
  &:hover {
    opacity: ${({ disabled }) => disabled ? '.6' : '1'};
  }

  @media (max-width: 768px) {
    left: -5px;
  }
`;
const NextIcon = styled.div`
  cursor: ${({ disabled }) => disabled ? 'unset' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? '.6' : '.9'};
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  display: ${({ disabled }) => disabled ? 'none' : 'block'};
  &:hover {
    opacity: ${({ disabled }) => disabled ? '.6' : '1'};
  }
  @media (max-width: 768px) {
    right: -5px;
  }
`;
const CategorySlider = ({ sliderData, categoryId, onClickItem, itemTextColor }) => {
  const sliderWrapperRef = useRef(null);
  const sliderRef = useRef(null);
  const [translatex, setTranslatex] = useState(0);
  const [tocuhStart, setTouchStart] = useState(null);
  const [mobileScroll, setMobileScroll] = useState(0);
  const [addEvnetListener, setAddEvnetListener] = useState(false);
  const [isSetSelectedCategory, setIsSetSelectedCatgegory] = useState(false);
  const [selectedCat, selectCategory] = useState(sliderData.find(data => data.selected));
  const onClickNextPrev = (type) => {
    if (sliderRef.current && sliderWrapperRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
      if(window.innerWidth > 768) {
        if(type === 'next') {
          if(sliderWidth > sliderWrapperWidth) {
            if (sliderWrapperWidth + (-translatex) < sliderWidth) {
              let translatexValue = sliderWidth - (sliderWrapperWidth + (-translatex));
              // console.log("onClickNextPrev -> translatexValue", translatexValue, sliderWrapperWidth)
              if (translatexValue >= sliderWrapperWidth) {
                translatexValue = (-translatex) + (sliderWrapperWidth - 50);
              } else {
                translatexValue = sliderWidth - sliderWrapperWidth;
              }

              setTranslatex(-translatexValue);
            }
          }
        } else if(type === 'prev') {
          if (translatex < 0) {
            let translatexValue = translatex + (sliderWrapperWidth - 50);

            if(translatexValue > 0) {
              translatexValue = 0;
            }

            setTranslatex(translatexValue);
          }
        }
      } else {
        const leftScroll = sliderWrapperRef.current.scrollLeft;
        if(type === "next") {
          setMobileScroll(leftScroll + 200);
          sliderWrapperRef.current.scroll(leftScroll + 200, 0);
        } else if(type === "prev") {
          setMobileScroll(leftScroll - 200);
          sliderWrapperRef.current.scroll(leftScroll - 200, 0);
        }
        // console.log("onClickNextPrev -> leftScroll", leftScroll)
      }
    }
  } 

  const onTouchStart = event => {
    setTouchStart(event.touches[0].clientX);
  }
  
  const onTouchEnd = event => {
    setTouchStart(null);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTouchMove = event => {
    if (tocuhStart && sliderRef.current && sliderWrapperRef.current) {
      const valueX = event.touches[0].clientX;
      var xDiff = valueX - tocuhStart;
      let translateValue = translatex;
      if(xDiff > 0 && Math.abs(xDiff) > 5) {
        translateValue = translatex + 10;
        if(translateValue > 0) {
          translateValue = 0;
        }
      } else if (xDiff < 0 && Math.abs(xDiff) > 5) {
        translateValue = translatex - 10;
        const sliderWidth = sliderRef.current.offsetWidth;
        const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
        if((-translateValue) > (sliderWidth - sliderWrapperWidth)) {
          translateValue = -(sliderWidth - sliderWrapperWidth)
        }
      }
      setTranslatex(translateValue);
    }
  };
  
  useEffect(() => {
    if (addEvnetListener) {
      if (sliderRef.current) {
        sliderRef.current.addEventListener("touchmove", onTouchMove);
        sliderRef.current.addEventListener("touchstart", onTouchStart);
        sliderRef.current.addEventListener("touchstart", onTouchEnd);
      }
    }

    return () => {
      if(sliderRef.current) {
        sliderRef.current.removeEventListener("touchmove", onTouchMove);
        sliderRef.current.removeEventListener("touchstart", onTouchStart);
        sliderRef.current.removeEventListener("touchstart", onTouchEnd);
      }
    }
  }, [addEvnetListener, onTouchMove]);

  const checkNextButtonDisable = () => {
    if (sliderRef.current && sliderWrapperRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
      if(Math.abs(translatex) >= (sliderWidth - sliderWrapperWidth)) {
        return true
      }

      return false;
    }
  }

  useEffect(() => {
    if(categoryId) {
      setIsSetSelectedCatgegory(false);
      if (isNaN(parseInt(categoryId, 10))) {
        selectCategory({ slug: categoryId, id: categoryId})
      }
    }
  }, [categoryId])

  const showSelectedCategory = () => {
    // let selectedItem = null;
    // sliderData.forEach(item => {
    //   if (item.selected ) {
    //     selectedItem = item;
    //   }
    // });

    if (selectedCat && sliderRef.current && sliderWrapperRef.current) {
      const selectedElem = document.getElementById(`${selectedCat.id}-category`);
      setIsSetSelectedCatgegory(true);
      if(selectedElem) {
        const selectedItemPositionRight = selectedElem.offsetLeft + selectedElem.offsetWidth;
        const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
        if (selectedItemPositionRight > sliderWrapperWidth) {
          const scrollValue = (selectedItemPositionRight - sliderWrapperWidth);
          if(window.innerWidth > 768) {
            setTranslatex(-scrollValue);
          } else {
            sliderWrapperRef.current.scroll(scrollValue, 0);
          }
        } else if (selectedElem.offsetLeft === 0) {
          sliderWrapperRef.current.scroll(0, 0);
        }
      }
    } else if (!selectedCat) {
      setIsSetSelectedCatgegory(true);
    }
  }
  return (
    <>
      {sliderData && sliderData.length > 0 && (
        <Wrapper>
          <PrevIcon
            // onClick={onClick}
            disabled={translatex === 0 && (window.innerWidth <= 768 ? mobileScroll === 0 :  true)}
            onClick={() => onClickNextPrev('prev')}
          >
            {/* <Image src={arrowLeft} /> */}
            <Icon name="sliderArrowLeft" size={16} color={itemTextColor || '#000'}/>
          </PrevIcon>
          <NextIcon
            disabled={checkNextButtonDisable()}
            onClick={() => onClickNextPrev('next')}
          >
            {/* <Image src={arrowRight} /> */}
            <Icon name="sliderArrowRight" size={16} color={itemTextColor || '#000'}/>
          </NextIcon>
          <SliderWrapper
            ref={sliderWrapperRef}
          >
            <Container
              ref={a => {
                if(!a) return;
                const categoryList = a.querySelectorAll('.category-item');
                let totalWidth = 0;
                categoryList.forEach(categoryItem => {
                  totalWidth = totalWidth + categoryItem.offsetWidth;
                });
                // a.style.width = `${totalWidth}px`;
                sliderRef.current = a;
                setAddEvnetListener(true);
                if (categoryId && !isSetSelectedCategory) {
                  showSelectedCategory();
                }
              }}
              translatex={translatex}
            >
              {sliderData.map((item, index) => {
                return (
                <SliderItem
                  to={item.slug ? `/category/${item.slug}` : `/category/${item.id}?type=${item.filter_key}`}
                  onClick={() => {
                    selectCategory(item);
                    onClickItem(item);
                  }}
                  index={index}
                  className="category-item"
                  key={item.id}
                  id={`${item.id}-category`}
                  active={selectedCat && (selectedCat.id === item.id ) ? true : !selectedCat ? index === 0 ? true : item.selected : null}
                  itemTextColor={itemTextColor}
                >
                  {item.title}
                </SliderItem>
              )})}
            </Container>
          </SliderWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default CategorySlider;