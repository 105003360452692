import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: auto;
  margin-top: 10px;
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track-piece {
    width: 0px;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
const Container = styled.div`
  display: flex;
  white-space: nowrap!important;
`;

const TabItem = styled.div`
  font-family: 'Metropolis', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ active }) => active ? `#f32826` : `rgba(0, 0, 0, 0.8)`};
  padding: 8px 8px;
  border-bottom: ${({ active }) => active ? '4px' : 0} solid #f32826;
  margin: 0 10px;
  cursor: pointer;
`;


const MobileSearchPageTab = ({
  onChange,
  activeSearchTab,
  filterSuggestion
}) => (
  <Wrapper>
    <Container>
      <TabItem
        active={activeSearchTab === 'stories'}
        onClick={() => {
          window.scroll(0, 0);
          onChange('stories');
        }}
      >Stories</TabItem>
      {filterSuggestion && filterSuggestion.people && filterSuggestion.people.results && filterSuggestion.people.results.length > 0 && (
        <TabItem
          active={activeSearchTab === 'people'}
          onClick={() => {
            window.scroll(0, 0);
            onChange('people');
          }}
        >People</TabItem>
      )}
      {filterSuggestion && filterSuggestion.sources && filterSuggestion.sources.results && filterSuggestion.sources.results.length > 0 && (
        <TabItem
          active={activeSearchTab === 'publications'}
          onClick={() => {
            window.scroll(0, 0);
            onChange('publications');
          }}
        >Publications</TabItem>
      )}
      {filterSuggestion && filterSuggestion.tags && filterSuggestion.tags.results && filterSuggestion.tags.results.length > 0 && (
          <TabItem
            active={activeSearchTab === 'tags'}
            onClick={() => {
              window.scroll(0, 0);
              onChange('tags');
            }}
          >Tags</TabItem>
        )}
    </Container>
  </Wrapper>
);

export default MobileSearchPageTab;